import React, { useEffect, useState } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import OrgUpdater from "../../service/orgUpdator"
import IntegrationForm from "../../components/integrations/IntegrationForm"
import Spinner from "../../components/common/Spinner"
import { navigate } from 'gatsby-plugin-intl'
import { path } from "../../config/routes"

const IntegrationNew = ({ onboarding, type }) => {
  const [integration, setIntegration] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const integrationType = useStoreState(state => state.integrationTypes.item)
  const resetIntegrationType = useStoreActions(actions => actions.integrationTypes.reset)
  const fetchIntegrationType = useStoreActions(actions => actions.integrationTypes.fetch)
  const { item: currentOrg, isPartner } = useStoreState(state => state.currentOrg)
  const {
    create: createIntegration,
    update: updateIntegration,
  } = useStoreActions(actions => actions.integrations)

  useEffect(() => {
    const { integrationTypeIDs = [] } = currentOrg?.vendor || {}
    if (!integrationTypeIDs.length && !type) {
      return
    }
    setIsLoading(true)
    fetchIntegrationType(integrationTypeIDs[0] || type).then(() => setIsLoading(false))
    return () => resetIntegrationType();
  }, [currentOrg, fetchIntegrationType, type, resetIntegrationType])

  useEffect(() => {
    const newIntegration = {
      groups: currentOrg.groups,
      integrationType,
      integrationTypeID: integrationType.id,
      name: integrationType.name,
      organizationID: currentOrg.id,
    }

    setIntegration(newIntegration)
  }, [currentOrg, integrationType, setIntegration])

  const onSubmit = async ({ integration }) => {
    const { id: integrationID } = integration.id
      ? await updateIntegration(integration)
      : await createIntegration(integration)
    if (!currentOrg.integrationID) await OrgUpdater.call({ id: currentOrg.id, integrationID })

    if (!onboarding) {
      navigate(path("SETTINGS") + path("INTEGRATIONS"))
    }

    return integration;
  }

  if (isLoading) {
    return <div className="d-flex justify-content-center mt-5">
      <Spinner />
    </div>
  }

  return (
    <>
      <h1 className="main-heading mb-5">
        <FormattedMessage
          id="containers.onboarding.stages.rws.h2"
          defaultMessage="Setup integration for {name}"
          values={{ name: integrationType.name || "" }}
        />
      </h1>
      <IntegrationForm
        integrationTypeID={integrationType.id}
        isCuisto={integrationType.isCuisto}
        isOnboarding={!!onboarding}
        integration={integration}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default IntegrationNew
