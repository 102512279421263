import React from "react"
import { Form } from "react-bootstrap"

const Switch = ({ onChange, isChecked, id, disabled } ) => (
  <Form>
    <Form.Check
      disabled={disabled}
      checked={isChecked}
      type="switch"
      id={id}
      onChange={onChange} />
  </Form>
)

export default Switch
