
import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreState, useStoreActions } from "easy-peasy"
import PageContainer from "../components/layout/PageContainer"
import TeamMembers from "../components/team/TeamMembers"
import TeamInviteForm from "../components/team/TeamInviteForm"
import { Card } from "react-bootstrap"

const TeamContainer = ({ isTabItem }) => {
  const fetchTeamMembers = useStoreActions(actions => actions.team.fetch)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const teamMembers = useStoreState(state => state.team.item)
  const isFetchingTeamMembers = useStoreState(state => state.team.loading)

  const handleFetchMembers = () => {
    fetchTeamMembers({
      organizationID: currentOrg.id,
      ownerID: currentOrg.owner
    })
  }
  useEffect(() => {
    handleFetchMembers()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8 mt-5">
          <Card>
            <Card.Body>
              <TeamInviteForm fullWidth onSubmit={handleFetchMembers} />
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
            <TeamMembers
              onRemove={handleFetchMembers}
              members={teamMembers}
              isLoading={isFetchingTeamMembers}
            />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default TeamContainer
