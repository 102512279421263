import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { useStoreState } from "easy-peasy"
import Source from "../../Source"
import { Row, Col } from "react-bootstrap"
import Icon from "../../Icon"
import Spinner from "../../common/Spinner"
import { LOCALE_STATE } from "../../../models/projectLocale"
import { isAPI, isCuistoProject } from "../../../helpers/project"
import { isDocumentTypeConnector, isLokaliseConnector } from "../../../helpers/connector"

const ProjectLocaleState = ({ project, projectLocale }) => {
  const { isPartner } = useStoreState(state => state.currentOrg)
  const intl = useIntl()
  const calculatingState = () => {
    return (
      <Row className="align-items-center">
        <Col md={1} className="p-0">
          <Spinner isSmall={true} />
        </Col>
        <Col md={11} className="d-flex flex-column">
          <h4 className="m-0">
            <FormattedMessage
              id="components.projects.locale.state.calculating"
              defaultMessage="Calculating…"
            />
          </h4>
        </Col>
      </Row>
    )
  }

  const completeSate = () => {
    return (
      <Row className="align-items-center">
        <Col md={1} className="p-0">
          <h2 className="m-0">
            <Icon name="check" className="text-green-600" />
          </h2>
        </Col>
        <Col md={11} className="d-flex flex-column">
          <h4 className="m-0">
            <FormattedMessage
              id="components.projects.locale.state.complete"
              defaultMessage="Complete"
            />
          </h4>
          <span>
            {projectLocale.lastDelivered(intl)}
          </span>
        </Col>
      </Row>
    )
  }

  const incompleteState = () => {
    return (
      <Row className="align-items-center">
        <Col md={1} className="p-0">
          <h2 className="m-0">
            <Icon name="alert-triangle" className="text-orange-300" />
          </h2>
        </Col>
        <Col md={11} className="d-flex flex-column">
          <h4 className="m-0">
            <FormattedMessage
              id="components.projects.locale.state.incomplete"
              defaultMessage="Incomplete"
            />
          </h4>
          {project.isActive && (
            <span>
              {projectLocale.lastDelivered(intl)}
            </span>
          )}
        </Col>
      </Row>
    )
  }

  const inProgressState = () => {
    return (
      <Row className="align-items-center">
        <Col md={1} className="p-0">
          <h2 className="m-0">
            <Icon name="clock" className="text-dark" />
          </h2>
        </Col>
        <Col md={11} className="d-flex flex-column">
          <h4 className="m-0">
            <FormattedMessage
              id="components.projects.locale.state.inProgress"
              defaultMessage="In progress"
            />
          </h4>
          {/* {!isPartner && (
            <span>
              <FormattedMessage
                id="components.projects.locale.state.inProgress.estimatedDelivery"
                defaultMessage="Estimated for {date}"
                values={{
                  date: estimatedDeliveryDate(QuoteCalculator.durationForLocale(projectLocale), intl.locale),
                }}
              />
            </span>
          )} */}
        </Col>
      </Row>
    )
  }

  const missingState = () => {
    return !isDocumentTypeConnector(project) ?
      (
        <Row className="align-items-center">
          <Col md={1} className="p-0">
            <h2 className="m-0">
              <Icon name="circle" className="text-red-500" />
            </h2>
          </Col>
          <Col md={11} className="d-flex flex-column">
            <h4 className="m-0">
              <FormattedMessage
                id="components.projects.locale.state.missing"
                defaultMessage="Missing"
              />
            </h4>
          </Col>
        </Row>
      ): 
      <></>
  }

  const sourceState = () => {
    return (
      <Row className="align-items-center">
        <Col md={1} className="p-0">
          <Source isSmall />
        </Col>
        <Col md={11} className="d-flex flex-column">
          <h4 className="m-0">
            <FormattedMessage
              id="components.projects.locale.state.source"
              defaultMessage="Source"
            />
          </h4>
          <span>
            {projectLocale.lastDelivered(intl)}
          </span>
        </Col>
      </Row>
    )
  }

  switch (projectLocale.state) {
    case LOCALE_STATE.COMPLETE:
      return completeSate()
    case LOCALE_STATE.INCOMPLETE:
      return incompleteState()
    case LOCALE_STATE.IN_PROGRESS:
      return inProgressState()
    case LOCALE_STATE.MISSING:
      return missingState()
    case LOCALE_STATE.SOURCE:
      return sourceState()
    case LOCALE_STATE.CALCULATING:
      return calculatingState()
    default:
      if (isAPI(project) || isCuistoProject(project) || isLokaliseConnector(project)) return null
      else return calculatingState()
  }
}

export default ProjectLocaleState
