import React from "react"
import Select, { components } from "react-select"

const FilterField = (props) => {
  const styles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "20rem",
      filter: state.isFocused
        ? "drop-shadow(0 0.5rem 1rem rgba(18, 38, 63, 0.15))"
        : "",
      border: "1px solid #E3EBF6",
      borderRadius: "0.25rem"
    }),
    menu: provided => ({
      ...provided,
      boxShadow: "none",
      marginTop: -2,
      padding: 0,
      borderRadius: "0 0 3px 3px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      borderTop: state.options.length === 0 ? "1px solid #D2DDEC" : "",
    }),
    control: (provided, state) => ({
      ...provided,
      padding: "0 10px",
      borderWidth: "0",
      borderRadius: state.isFocused ? "3px 3px 0 0" : "",
      boxShadow: "none",

      ":before": {
        content: '"\\E966"',
        fontFamily: "Feather",
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "2px 3px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#F9FBFD" : "transparent"
    })
  }

  const CustomOption = ({ data, children, ...rest }) => (
    <components.Option {...rest} className="border-top">
      <div className="d-flex flex-column px-3_5">
        <span className="font-weight-normal text-gray-600">{children}</span>
      </div>
    </components.Option>
  )

  return (
    <Select
      blurInputOnSelect
      components={{ Option: CustomOption }}
      styles={styles}
      {...props}
    />
  )
}

export default FilterField
