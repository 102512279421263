import React, { useEffect, useState } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import Spinner from "../../components/common/Spinner"
import IntegrationCard from "../../components/integrations/IntegrationCard"
import { IntegrationTypeList } from "../../components/integrations/IntegrationTypeList"
import { path } from "../../config/routes"
import OpenPortal from "../../components/billing/OpenPortal"
import { useChargebeeEntitlements } from "../../helpers/chargebee"

const withAction = ({ integrationLevel, onUpgrade }) => (
  ({ integrationType, onClick }) => (
    integrationType.integrationLevel <= integrationLevel
    ? <button
        onClick={() => onClick(integrationType)}
        key={integrationType.id}
        className="cards-grid-items__button cards-grid-items__button--new"
      >
        Configure
      </button>
    : <button onClick={onUpgrade} className="cards-grid-items__button btn-info">
        <FormattedMessage
          id="containers.admin.integrations.button.upgradeAction"
          defaultMessage="Upgrade"
        />
      </button>
  )
)

const IntegrationSettings = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [renderChargebee, setRenderChargebee] = useState(false);
  const { fetchAll: fetchAllIntegrationTypes } = useStoreActions(actions => actions.integrationTypes)
  const { updateDefaultIntegration, fetchPaidSubscription } = useStoreActions(actions => actions.currentOrg)
  const { fetchAvailableIntegrations } = useStoreActions(actions => actions.integrations)
  const availableIntegrations = useStoreState((state) => state.integrations.availableIntegrations)
  const publicIntegrations = useStoreState((state) => state.integrationTypes.public)
  const { item: currentOrg, isPartner } = useStoreState(state => state.currentOrg)
  const { 'integration-level': integrationLevel } = useChargebeeEntitlements();
  const { fetchSubscription } = useStoreActions(state => state.billing)
  const { hasNoSubscriptions } = useStoreState(
    state => state.billing
  )

  const redirect = (integrationType) => {
    navigate(path("NEW_INTEGRATION", {
      type: integrationType.id,
    }))
  }

  const onUpgrade = () => {
    navigate(path("UPGRADE_PLAN"))
  }

  useEffect(() => {
    setIsLoading(true)
    fetchAllIntegrationTypes()
      .then(() => fetchAvailableIntegrations({ organizationID: currentOrg.id }))
      .then(() => setIsLoading(false))
  }, [fetchAvailableIntegrations, fetchAllIntegrationTypes, currentOrg.id])

  useEffect(() => {
    if (currentOrg.id) {
      fetchPaidSubscription(currentOrg)
    }
  }, [fetchPaidSubscription, renderChargebee, currentOrg]);

  useEffect(() => {
    fetchSubscription({ organizationId: currentOrg.id })
  }, [currentOrg.id, fetchSubscription])

  if (isLoading) {
    return <div className="d-flex justify-content-center mt-5">
      <Spinner />
    </div>
  }

  return (
    <div className="container-fluid">
      {renderChargebee && (
        <OpenPortal setRenderChargebee={setRenderChargebee} />
      )}
      <div className= "page-section mt-5">
        <aside className="page-section__aside">
          <h2 className="page-section__heading">
            <FormattedMessage
              id="containers.admin.integrations.configured.heading"
              defaultMessage="Translation Management"
            />
          </h2>
        </aside>

        <IntegrationCard
          integrations={availableIntegrations}
          defaultIntegrationID={currentOrg.integrationID}
          disablePublicIntegrations={hasNoSubscriptions}
          onSelect={(integration) => updateDefaultIntegration({
            id: currentOrg.id,
            integration,
          })}
        />
      </div>

      <div className="page-section mt-6">
        <aside classNames="page-section__aside">
          <h2 className="page-section__heading">
            <FormattedMessage
              id="containers.admin.integrations.available.heading"
              defaultMessage="Available Integrations"
            />
          </h2>
        </aside>

        <IntegrationTypeList
          integrationsTypes={publicIntegrations}
          onClick={redirect}
          action={!isPartner ? withAction({ onUpgrade, integrationLevel }) : undefined}
        />
      </div>
    </div>
  )
}

export default IntegrationSettings
