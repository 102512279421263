import React from "react"
import { Form } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import TeamInviteForm from "../../../components/team/TeamInviteForm"
import parseBasePath from "../../../helpers/path"
import ROUTES from "../../../config/routes"
import { useOnboarding } from "../../Onboarding"

const Team = ({ onboarding }) => {
  const location = useLocation()
  const { hasPreviousStep, routeToNextStep, routeToBackStep } = useOnboarding()

  const onSubmit = () => {
    routeToNextStep()
  }

  return (
    <div className="d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.team.h2"
            defaultMessage="Invite your team"
          />
        </h1>
      </div>
      <div className="onboarding-form">
        <div className="w-50">
          <Form.Label className="card-title">
            <FormattedMessage
              id="containers.onboarding.workspace.h2"
              defaultMessage="Invite your coworkers to your locale account."
            />
          </Form.Label>
          <TeamInviteForm noLabel={true} fullWidth={true} onSubmit={onSubmit} />
          <hr className="my-4" />
          <div className="d-flex">
            {hasPreviousStep && (
              <button
                onClick={() => routeToBackStep()}
                className="btn btn-outline--secondary mr-5"
              >
                <FormattedMessage
                  id="containers.onboarding.team.backButton"
                  defaultMessage="Back"
                />
              </button>
            )}

            <button
              onClick={onSubmit}
              className="btn btn--primary btn-outline mr-0"
            >
              <FormattedMessage
                id="containers.onboarding.team.skipButton"
                defaultMessage="Skip"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
