import { FormattedMessage } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import Plans from "../components/billing/Plans"
import { redirect } from "../config/routes"
import { useLocation } from "@reach/router"
import Toast from "../components/Toast"
import { parse } from "query-string"
import { useStoreState } from "easy-peasy"

const PlanList = () => {
  const [showToast, setShowToast] = useState(false);
  const plans = useStoreState(state => state.currentOrg.plans)
  const location = useLocation();


  const onClick = (planId) => {
    redirect("PLAN_CHECKOUT", { item_price_id: planId })
  }

  useEffect(() => {
    if (parse(location.search).id) {
      setShowToast(true)
    }
  }, [])


  return (
    <>
        <Toast show={showToast} setShow={setShowToast}>
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="containers.planlist.toast"
              defaultMessage="Plan updated"
            />
          </p>
        </Toast>
      <Plans
        frequency="year"
        familyId="Connector-Portal"
        onClickPlan={onClick}
        currentPlanId={plans.find((plans) => plans !== "free")}
      />
    </>
  )
}

export default PlanList;
