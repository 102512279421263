import React, { useState, useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Row, Col, Button } from "react-bootstrap"
import GitLabWebhookCreator from "../../service/gitlabWebhookCreator"
import Spinner from "../common/Spinner"

const AddGitLabWebhook = ({ project }) => {
  const [isLoading, setIsLoading] = useState(false)

  const setGitLabWebhook = async () => {
    setIsLoading(true)
    await GitLabWebhookCreator.call(project)
    setIsLoading(false)
  }

  return (
    <Row className="mt-3">
      <Col>
        <h3 className="text-gray-700">
          <FormattedMessage
            id="components.projects.AddGitLabWebhook.title"
            defaultMessage="Webhooks"
          />
        </h3>
        <p className="text-gray-400">
          <FormattedMessage
            id="components.projects.AddGitLabWebhook.desscription"
            defaultMessage="This helps us automatically detect changes in your project."
          />
        </p>
        <p className="text-gray-700">
          URL: <span className="text-gray-500">{process.env.GITLAB_WEBHOOK_URL}</span>
        </p>
        <div className="d-flex justify-content-end">
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              onClick={setGitLabWebhook}
              className="btn btn-outline--primary"
            >
              <FormattedMessage
                id="components.projects.AddGitLabWebhook.button"
                defaultMessage="Add Webhook"
              />
            </Button>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default AddGitLabWebhook
