import React from "react";
import { INTEGRATION_IDS } from "../../models/integrationType"
import TextmasterForm from "../../components/integrations/textmaster/form"
import PlunetForm from "../../components/integrations/plunet/form"
import ProtemosForm from "../../components/integrations/protemos/form"
import EmailForm from "../../components/integrations/email/form"
import TradosForm from "../../components/integrations/trados/form"
import MemoqForm from "../../components/integrations/memoq/form"
import MemsourceForm from "../../components/integrations/memsource/form"
import CuistoProcessorForm from "./cuisto/form";

const integrationForm = (integrationTypeID) => {
  switch (integrationTypeID) {
    case INTEGRATION_IDS.email:
      return EmailForm
    case INTEGRATION_IDS.memoq:
      return MemoqForm
    case INTEGRATION_IDS.memsource:
      return MemsourceForm
    case INTEGRATION_IDS.plunet:
      return PlunetForm
    case INTEGRATION_IDS.protemos:
      return ProtemosForm
    case INTEGRATION_IDS.textmaster:
      return TextmasterForm
    case INTEGRATION_IDS.trados:
      return TradosForm
    default:
      return null
  }
}

const IntegrationForm = ({ integrationTypeID, isCuisto, ...props }) => {
  if (isCuisto) {
    return <CuistoProcessorForm {...props} integrationTypeID={integrationTypeID} />
  }

  const Form = integrationForm(integrationTypeID)

  return Form ? <Form {...props}/> : <></>
}

export default IntegrationForm;