import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import TeamInviteForm from "./TeamInviteForm"
import InfoModal from "../layout/modal/InfoModal"
import { STATUSES } from "../layout/FormContainer"
import { useStoreState } from "easy-peasy"

const TeamModal = ({ onSubmit }) => {
  const [status, setStatus] = useState(null)
  const { partnerText } = useStoreState(state => state.currentOrg)

  const Trigger = ({ onClick }) => (
    <button onClick={onClick} className="btn btn--secondary bg-gray-300 px-3 py-1 rounded-pill">
      <FormattedMessage
        id="components.catTool.settings.needHelp"
        defaultMessage="Need Help?"
      />
    </button>
  )

  const title = (
    <FormattedMessage
      id="components.team.teamModal.title"
      defaultMessage="Need help connecting your {brandName}?"
      values={{ brandName: partnerText.brandName }}
    />
  )

  const formatMessageValues = {
    a: msg => (
      <a
        target="_blank"
        className="text-gray-600 text-decoration-underline"
        href={process.env.DOCUMENTATION_URL}
      >
        {msg}
      </a>
    ),
  }

  const description = (
    <FormattedMessage
      id="components.team.teamModal.description"
      defaultMessage="Invite a team member or <a>get in touch with Locale support.</a>"
      values={formatMessageValues}
    />
  )

  const handleInviteSubmit = () => {
    onSubmit()
    setStatus(STATUSES.success)
  }

  return (
    <InfoModal
      status={status}
      setStatus={setStatus}
      Trigger={Trigger}
      scrollable={false}
      dialog
      centered
      modalTitleAs="h3"
      className="mt-n3"
    >
      <h3 className="mb-1">{title}</h3>
      <p>{description}</p>
      <TeamInviteForm noToast fullWidth noLabel onSubmit={handleInviteSubmit}/>
    </InfoModal>
  )
}

export default TeamModal
