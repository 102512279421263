import store from "../store/index"

export default class VendorTeamDestroyer {
  constructor(member) {
    this._member = member
  }

  static async call(args) {
    return new VendorTeamDestroyer(args).call()
  }

  async call() {
    try {
      await this.removeMember()
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  // PRIVATE METHODS
  removeMember() {
    return store.getActions().vendorTeam.delete(this.member)
  }

  get member() {
    return this._member
  }
}
