import React from "react"
import { useStoreState } from "easy-peasy"
import { useLocation, useMatch } from "@reach/router"
import { navigate } from "gatsby-plugin-intl"
import { parse } from "query-string"
import { redirect } from "../config/routes"
import { isConnectPath, isSignInPath } from "../helpers/authentication"
import { ONBOARDING_ROOT, stagePath } from "./Onboarding"
import { TYPES } from "../models/onboarding"

const Root = ({ children }) => {
  const location = useLocation()
  const currentPath = location.pathname
  const appMatch = useMatch("/:locale/*")
  const isRoot = useMatch("/:locale")
  const isOnboarding = !!currentPath.match(ONBOARDING_ROOT)
  const onboarding = useStoreState(state => state.onboarding.item)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const projects = useStoreState(state => state.projects.items)
  const query = parse(location.search)

  if (!isOnboarding && onboarding && !onboarding.isCompleted) {
    navigate(stagePath(onboarding.getPathOfStep()?.path || "", onboarding.type, query))
    if(query.connector) return null;
  }

  if (query?.workspace) {
    navigate(`/${appMatch["*"]}`, { replace: true })
  }

  if (isSignInPath(currentPath) || isConnectPath(currentPath)) {
    redirect("ROOT")
    return null
  }
  if (isRoot) {
    if (currentOrg?.id) {
      redirect("DASHBOARD")
      return null
    }
  }

  return <>{children}</>
}

export default Root
