import React, { useState, useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Card, Table } from "react-bootstrap"
import { formatPath } from "../../helpers/format"
import AddResource from "./resource/Add"
import AddResourcePattern from "./resourcePattern/Add"
import ProjectResourceListRow from "./resource/ListRow"
import ProjectPathPatternListRow from "./resourcePattern/List"

const getProjectLocaleParts = ({ locales }) => {
  const source = locales?.items.find(locale => locale.isSource);
  const targets = locales?.items.filter(locale => !locale.isSource);
  return { source, targets };
};

const getItemsFromResource = (project) => {
  if (!Array.isArray(project.resources)) {
    return [];
  }
  const { resources, locales } = project;
  return resources.map((resourceList) => locales.items.map(
      (locale) => {
        const matchResource = resourceList.find(resource => resource.projectLocaleID === locale.id);
        return matchResource && {
          projectLocaleID: matchResource.projectLocaleID,
          path: matchResource.path,
          pattern: matchResource.path,
          isSource: locale.isSource,
          locale,
        };
      }
    ).filter(Boolean)
  );
};

const getItemsFromPattern = (project) => {
  if (!Array.isArray(project.resourcePatterns)) {
    return [];
  }
  const { resourcePatterns } = project;
  const { source, targets } = getProjectLocaleParts(project);
  return resourcePatterns.map((pattern) => ([
    {
      projectLocaleID: source.id,
      path: formatPath(pattern, source),
      pattern,
      locale: source,
      isSource: true,
    },
    ...targets.map(target => ({
      projectLocaleID: target.id,
      path: formatPath(pattern, target),
      pattern,
      locale: target,
      isSource: false,
    })),
  ]));
};

const ProjectResources = ({ project }) => {
  const { locales, resourcePatterns } = project;
  const [resources, setResources] = useState([]);
  const enableProjectResourcePattern = Array.isArray(resourcePatterns);
  const ListRow = enableProjectResourcePattern ? ProjectPathPatternListRow : ProjectResourceListRow;
  const AddItem = enableProjectResourcePattern ? AddResourcePattern : AddResource;

  useEffect(() => {
    if (!enableProjectResourcePattern) {
      setResources(getItemsFromResource(project));
    } else {
      setResources(getItemsFromPattern(project));
    }
  }, [project, enableProjectResourcePattern]);

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">
          <FormattedMessage
            id="components.projects.projectResources.card.title"
            defaultMessage="Files"
          />
        </Card.Title>
        <AddItem project={project} />
      </Card.Header>
      <Table size="sm" className="table-no-wrap">
        <thead>
          <tr>
            <th width="1" />
            <th width="1">
              <FormattedMessage
                id="components.projects.projectResources.tableHeading.code"
                defaultMessage="code"
              />
            </th>
            <th>
              <FormattedMessage
                id="components.projects.projectResources.tableHeading.path"
                defaultMessage="path"
              />
            </th>
            <th width="1" />
          </tr>
        </thead>
        <tbody>
          {resources.map((resourceList, index) => (
            <ListRow
              key={index}
              project={project}
              locales={locales}
              resourceListIndex={index}
              resourceList={resourceList}
              resourcePatterns={resourcePatterns}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default ProjectResources;
