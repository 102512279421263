import React, { Fragment } from "react"
import Icon from "../../Icon"
import { FormattedMessage } from "gatsby-plugin-intl"
import { DateTime } from "luxon"
import humanizeString from "humanize-string"
import { formatNamespaceTitle, isGroupEnabled } from "../../../helpers/contentResource"

const Resource = ({ id, index, content, resourcesExcluded, onCheckboxChange }) => {
  const renderGroup = (group, level = 0, parentExcluded = false) => {
    const checked = isGroupEnabled(resourcesExcluded, group, parentExcluded)

    return (
      <Fragment key={id}>
        <tr>
          <td
            className={`ml-n${5 - level * 5} pt-${
              level > 0 ? "3" : "1"
            } pb-1 px-0 border-0 d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center">
              <input
                className="checkbox--selectable checkbox--offset-right"
                type="checkbox"
                id={group.id}
                onChange={e => {
                  onCheckboxChange(e, group.id)
                }}
                checked={checked}
              />
              {group?.url ? (
                <a
                  target="_blank"
                  className={`${
                    checked ? "text-gray-700" : "text-gray-400"
                  } font-weight-bold`}
                  href={group.url}
                >
                  <span className="mr-2">{humanizeString(group.title)}</span>
                  <Icon name="external-link" className="text-gray-500" />
                </a>
              ) : (
                <span
                  className={`${
                    checked ? "text-gray-700" : "text-gray-400"
                  } font-weight-bold`}
                >
                  {formatNamespaceTitle(group?.title)}
                </span>
              )}
            </div>
            {index === 0 && level === 0 && (
              <div className="d-flex align-items-center">
                <span className="text-gray-600 font-weight-bold">
                  <FormattedMessage
                    id="components.projects.content.resource.lastupdated"
                    defaultMessage="Last Updated"
                  />
                </span>
              </div>
            )}
          </td>
        </tr>
        {group.children.map((child, i) =>
          child.children.length === 0 ? (
            <tr key={child.id}>
              <td
                className={`pt-2 px-1 ml-${level * 5} ${
                  i === group.children.length - 1 ? "pb-4" : "pb-2"
                } ${
                  i > 0 ? "border-0" : ""
                } border-gray-200 d-flex align-items-center justify-content-between`}
              >
                <div>
                  {child.url ? (
                    <a
                      target="_blank"
                      className={`${
                        checked ? "text-gray-800" : "text-gray-400"
                      }`}
                      href={child.url}
                    >
                      <span className="mr-2">{child.title}</span>
                      <Icon name="external-link" className="text-gray-500" />
                    </a>
                  ) : (
                    <span
                      className={`my-auto ${
                        checked ? "text-gray-800" : "text-gray-400"
                      }`}
                    >
                      {child.title}
                    </span>
                  )}
                </div>
                {child.providerUpdatedAt && (
                  <span className="text-gray-600">
                    {DateTime.fromISO(child.providerUpdatedAt).toFormat(
                      "LLL-d"
                    )}
                  </span>
                )}
              </td>
            </tr>
          ) : (
            renderGroup(child, level + 1, !checked)
          )
        )}
      </Fragment>
    )
  }

  return renderGroup(content)
}

export default Resource
