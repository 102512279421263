import React, { useState, useEffect } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { useLocation } from "@reach/router"
import { Accordion } from "react-bootstrap"
import Copy from "../../Copy"
import Toast from "../../Toast"
import Icon from "../../Icon"
import { STATUSES } from "../../layout/FormContainer"
import { useOnboarding } from "../../../containers/Onboarding"
import StepField from "../../forms/StepField"
import { stateGenerator } from "../../../helpers/application"
import MemsourceCreateApp from "../../../img/partners/helpers/memsource-create-app.jpg"
import MemsourceFindSettings from "../../../img/partners/helpers/memsource-find-settings.jpg"
import MemsourceCopyClientId from "../../../img/partners/helpers/memsource-copy-client-id.jpg"
import MemsourceClickAllow from "../../../img/partners/helpers/memsource-click-allow.jpg"
import MemsourceRegion from "../../../img/partners/helpers/memsource-region.jpg"
import TeamModal from "../../team/TeamModal"
import { parse } from "query-string"
import { URLS } from "../../../config/urls"

const EVENT_KEYS = {
  NAME: "name",
  OAUTH_APP: "oauthApp",
  CLIENT_ID: "copyClientID",
  AUTHORIZATION: "authorization",
  TEMPLATE: "template",
}

const regionOptions = [
  {
    id: "eu",
    name: "EU",
    value: "https://cloud.memsource.com",
  },
  {
    id: "us",
    name: "US",
    value: "https://us.cloud.memsource.com",
  },
]

export const MemsourceForm = ({ isOnboarding, onExit, onSubmit, integration }) => {
  const location = useLocation()
  const { state } = parse(location.search)
  const intl = useIntl()
  const [status, setStatus] = useState()
  const vendorDetails = useStoreState(actions => actions.vendor.item)
  const getIntegration = useStoreActions(actions => actions.integrations.find)
  const currentIntegration = useStoreState(state => state.integrations.item)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showTeamInviteToast, setShowTeamInviteToast] = useState(false)
  const [authorizationLink, setAuthorizationLink] = useState()
  const [parsedState, setParsedState] = useState({})
  const fetchProjectTemplates = useStoreActions(actions => actions.projectTemplates.fetch)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)

  const defaultValues = {
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[memsource_clientId]": integration.memsource_clientId,
    "integration[memsource_domain]": integration.memsource_domain,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[vendorID]": integration.vendorID,
    "integration[templateID]": integration.templateID,
  }
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
  })

  const getDefaultDomain = () =>
    regionOptions.find(item => item.value === integration?.memsource_domain) ||
    regionOptions[0]

  useEffect(() => {
    if (!integration) return
    reset(defaultValues)
  }, [reset, integration])

  useEffect(() => {
    if (!state) return
    setParsedState(
      typeof window !== "undefined" &&
        JSON.parse(window.localStorage.getItem(state))
    )
  }, [state])

  useEffect(() => {
    if (!parsedState.integrationID) return
    getIntegration(parsedState.integrationID)
  }, [parsedState, getIntegration])

  const integrationID = useWatch({
    control,
    name: "integration[id]",
    defaultValue: integration?.memsource_clientId,
  })

  const clientId = useWatch({
    control,
    name: "integration[memsource_clientId]",
    defaultValue: integration?.memsource_clientId,
  })
  const domain = useWatch({
    control,
    name: "integration[memsource_domain]",
    defaultValue: getDefaultDomain(),
  })
  const name = useWatch({
    control,
    name: "integration[name]",
    defaultValue: integration?.name,
  })
  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const [activeKey, setActiveKey] = useState(EVENT_KEYS.NAME)

  const teamModalSubmit = () => setShowTeamInviteToast(true)

  useEffect(() => {
    if (!parsedState?.fromCallback || !currentIntegration.id) {
      return
    }

    setValue("integration[id]", parsedState.integrationID)
    setActiveKey(EVENT_KEYS.TEMPLATE)
  }, [parsedState.fromCallback, currentIntegration, setActiveKey])

  useEffect(() => {
    if(integrationID) {
      setActiveKey(EVENT_KEYS.TEMPLATE)
      fetchProjectTemplates({ ...integration, id: integrationID })
    }
  }, [integrationID])

  useEffect(() => {
    const buildHref = () => {
      const cleanClientId = clientId?.trim()
      const urlParts = location.pathname.split("/")
      const redirectURL = `/${urlParts.splice(2).join("/")}`
      const state = stateGenerator({
        clientId: cleanClientId,
        integrationID: integration?.id,
        domain: domain?.value,
        name: name?.trim(),
        redirectURL,
      })

      return `${domain?.value}/web/oauth/authorize?client_id=${cleanClientId}&response_type=code&state=${state}`
    }
    setAuthorizationLink(buildHref())
  }, [setAuthorizationLink, location, integration, clientId, domain])

  const stepFields = [
    {
      eventKey: EVENT_KEYS.NAME,
      title: (
        <FormattedMessage
          id="src.components.memsource.settings.integrationName"
          defaultMessage="Integration Name"
        />
      ),
      fields: [
        {
          name: "integration[name]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.memsource.settings.fields.name",
            defaultMessage: "Integration Name",
          })}`,
        },
      ],
      images: [],
    },
    {
      eventKey: EVENT_KEYS.OAUTH_APP,
      title: (
        <FormattedMessage
          id="src.components.memsource.settings.helpertitle1"
          defaultMessage="Create an OAuth app"
        />
      ),
      bullets: [
        {
          sectionTitle: (
            <FormattedMessage
              id="src.components.memsource.helper2.bullet1"
              defaultMessage={`
                Make sure you have the <b>Administrator</b> role on the <a>users page</>.
              `}
              values={{
                a: msg => (
                  <a
                    className="text-gray-800"
                    target="_blank"
                    rel="noreferrer"
                    href="https://cloud.memsource.com/web/user/list"
                  >
                    <span className="text-decoration-underline">{msg}</span>
                    <Icon name="external-link" className="ml-1" />
                  </a>
                ),
                b: msg => <b>{msg}</b>,
              }}
            />
          ),
        },
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet1"
          defaultMessage={`
            Click settings from the menu on the left
          `}
        />,
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet2"
          defaultMessage={`
            Scroll down to <b>Integrations</b> and click <b>Registered OAuth apps</b>
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
        {
          text: (
            <FormattedMessage
              id="src.components.memsource.settings.helper1.bullet3"
              defaultMessage={`
              Enter the following details in the screen that follows
            `}
            />
          ),
          bullets: [
            <FormattedMessage
              id="src.components.memsource.settings.helper1.sub.bullet1"
              defaultMessage={`Name: <b>Locale</b>`}
              values={{
                b: msg => <b>{msg}</b>,
              }}
            />,
            <FormattedMessage
              id="src.components.memsource.settings.helper1.sub.bullet2"
              defaultMessage={`Redirect URI: {copyToClip}`}
              values={{
                b: msg => <b>{msg}</b>,
                copyToClip: (() => {
                  const memsourceLink =
                    "https://app.locale.to/en/memsource/callback"
                  return (
                    <span>
                      <b className="mr-1 font-monospace">{memsourceLink}</b>
                      <Copy text={memsourceLink} />
                    </span>
                  )
                })(),
              }}
            />,
            <FormattedMessage
              id="src.components.memsource.settings.helper1.sub.bullet3"
              defaultMessage={`Description: <b>App that connects with www.locale.to</b>`}
              values={{
                b: msg => <b>{msg}</b>,
              }}
            />,
          ],
        },
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet4"
          defaultMessage={`Click <b>Save</b>`}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
      ],
      fields: [
        {
          name: "integration[groups]",
          type: "hidden",
        },
        {
          name: "integration[id]",
          type: "hidden",
        },
        {
          name: "integration[integrationTypeID]",
          type: "hidden",
        },
        {
          name: "integration[name]",
          type: "hidden",
        },
        {
          name: "integration[organizationID]",
          type: "hidden",
        },
        {
          name: "integration[vendorID]",
          type: "hidden",
        },
      ],
      images: [MemsourceFindSettings, MemsourceCreateApp],
    },
    {
      eventKey: EVENT_KEYS.CLIENT_ID,
      title: (
        <FormattedMessage
          id="src.components.memsource.settings.helpertitle1"
          defaultMessage="Copy the Client ID"
        />
      ),
      bullets: [
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet1"
          defaultMessage={`
            On the screen that follows copy the <b>Client ID</b>. If there are multiple apps make sure you copy the one with the name of <b>Locale</b>
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet2"
          defaultMessage={`
            Paste the <b>Client ID</b> in the field below and click <b>Next step</b>.
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
        <FormattedMessage
          id="src.components.memsource.settings.helper1.bullet2"
          defaultMessage={`
            Select the region where your Memsource data is Stored. If your Memsource URL has <b>US</b> at the start, it's <b>United States</b>, otherwise it's <b>European Union</b> (which is the default).
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
      ],
      fields: [
        {
          name: "integration[memsource_clientId]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.integration.settings.fields.memsource.clientId",
            defaultMessage: "Memsource Client ID",
          })}`,
          defaultValue: integration?.memsource_clientId,
        },
        {
          name: "integration[memsource_domain]",
          type: "select",
          label: `${intl.formatMessage({
            id: "components.integration.settings.fields.memsource.domain",
            defaultMessage: "Memsource region",
          })}`,
          options: regionOptions,
          defaultValue: getDefaultDomain(),
        },
      ],
      images: [MemsourceCopyClientId, MemsourceRegion],
    },
    {
      eventKey: EVENT_KEYS.AUTHORIZATION,
      noNext: true,
      title: (
        <FormattedMessage
          id="src.components.memsource.settings.helpertitle2"
          defaultMessage="Authorize OAuth App"
        />
      ),
      bullets: [
        <FormattedMessage
          id="src.components.memsource.helper2.bullet1"
          defaultMessage={`
            Make sure you are logged in on Memsource
          `}
        />,
        <FormattedMessage
          id="src.components.memsource.helper2.bullet1"
          defaultMessage={`
            Click the <b>Connect with Memsource</b> button
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
        <FormattedMessage
          id="src.components.memsource.helper2.bullet1"
          defaultMessage={`
            On the following page in Memsource, click <b>Allow</b>
          `}
          values={{
            b: msg => <b>{msg}</b>,
          }}
        />,
        <FormattedMessage
          id="src.components.memsource.helper2.bullet1"
          defaultMessage={`
            You will redirected back to Locale
          `}
        />,
      ],
      fields: [
        {
          name: "Authorize",
          type: "button",
          href: authorizationLink,
          label: `${intl.formatMessage({
            id: "components.integration.settings.fields.memsource.redrection",
            defaultMessage: "Authorize Locale",
          })}`,
        },
      ],
      images: [MemsourceClickAllow],
    },
    {
      eventKey: EVENT_KEYS.TEMPLATE,
      title: (
        <FormattedMessage
          id="src.components.memsource.settings.templatehelpertitle1"
          defaultMessage="Default project template"
        />
      ),
      bullets: [
        <FormattedMessage
          id="src.components.memsource.settings.templatehelper.bullet1"
          defaultMessage={`Select default project template that will be applied to all projects using this integration.`}
        />,
      ],
      fields: [
        {
          name: "integration[template]",
          type: "select",
          label: `${intl.formatMessage({
            id: "components.integration.settings.fields.memsource.templates",
            defaultMessage: "Templates",
          })}`,
          options: projectTemplates,
        },
      ],
      images: [],
    },
  ]

  const currentStepFieldIndex = stepFields.findIndex(
    step => step.eventKey === activeKey
  )

  const nextStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)
    if (stepIndex !== -1 && stepIndex + 1 < stepFields.length)
      setActiveKey(stepFields[stepIndex + 1].eventKey)
  }

  const backStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex === 0 && onExit) onExit()
    if (stepIndex === 0 && !onExit && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (stepIndex !== -1 && stepIndex - 1 >= 0)
      setActiveKey(stepFields[stepIndex - 1].eventKey)
  }

  useEffect(() => {
    if (clientId || domain || currentStepFieldIndex >= 0) clearErrors()
  }, [clientId, domain, currentStepFieldIndex, clearErrors])

  const checkAndSubmitForm = async ({ integration }) => {
    integration.templateID = integration.templateID ?? integration?.template?.id
    integration.memsource_domain = integration.memsource_domain ?? integration?.memsource_domain?.value

    delete integration.template
    try {
      clearErrors()
      const {
        memsource_clientId: memsourceClientId,
        memsource_domain: memsourceDomain,
      } = integration

      const requiredMessage = intl.formatMessage({
        id: "src.components.integration.Settings.required",
        defaultMessage: "This is required.",
      })

      if (activeKey === EVENT_KEYS.CLIENT_ID) {
      if (!memsourceClientId) {
        return setError("integration[memsource_clientId]", {
          type: "manual",
          message: requiredMessage,
        })
      }
    }

    if (activeKey === EVENT_KEYS.DOMAIN) {
      if (!memsourceDomain) {
        return setError("integration[memsource_domain]", {
          type: "manual",
          message: requiredMessage,
        })
      }
    }

    if (currentStepFieldIndex < stepFields.length - 1) {
      nextStep()
    } else {
      setStatus(STATUSES.loading)
      if (isOnboarding) routeToNextStep()
      const data = await onSubmit({
        integration
      })
      if (data) {
        setStatus(STATUSES.success)
        !isOnboarding && setShowSuccessToast(true)
      } else setStatus(STATUSES.error)
    }
      return true
    } catch (e) {
      setStatus(STATUSES.error)
      return false
    }
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <a
          target="_blank"
          rel="noreferrer"
          href={URLS.MEMSOURCE_WEBSITE}
          className="text-gray-500"
        >
          <span className="text-decoration-underline">
            <FormattedMessage
              id="components.textmaster.settings.dashboard"
              defaultMessage="Go to your Memsource dashboard"
            />
          </span>
          <Icon name="external-link" className="ml-1" />
        </a>
        <TeamModal onSubmit={teamModalSubmit} />
      </div>
      <Toast show={showTeamInviteToast} setShow={setShowTeamInviteToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.inviteToast"
            defaultMessage="Successfully invited team member ✨"
          />
        </p>
      </Toast>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.memsource.settings.successToast"
            defaultMessage="Successfully updated your credentials ✨"
          />
        </p>
      </Toast>
      <div className="onboarding-form">
        <div className="form-group">
          <Accordion activeKey={activeKey}>
            {stepFields.map((step, i) => (
              <StepField
                index={i}
                {...step}
                control={control}
                errors={errors}
                onSubmit={handleSubmit(checkAndSubmitForm)}
                noBack={i === 0 && !isOnboarding}
                onBack={backStep}
                status={status}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default MemsourceForm
