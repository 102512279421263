import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Accordion } from "react-bootstrap"

import Form from "./Form"
import Spinner from "../common/Spinner"
import { STATUSES } from "../layout/FormContainer"

const List = ({ bullets }) => {
  return <ul className="mb-0 pl-0">
    {bullets && bullets.map(bullet =>
      bullet?.sectionTitle ? (
        <span>{bullet?.sectionTitle}</span>
      ) : bullet?.sublistHeader ? (
        <h4 className="mb-2 mt-4">{bullet?.sublistHeader}</h4>
      ) : (
        <li className="ml-3">
          {bullet.text ? bullet.text : bullet}
          {bullet.bullets && bullet.bullets.length && <List bullets={bullet.bullets} />}
        </li>
      )
    )}
  </ul>
}

const StepField = ({
  index,
  eventKey,
  status,
  title,
  bullets,
  images,
  fields,
  onSubmit,
  onBack,
  control,
  errors,
  noBack,
  noNext,
}) => {
  return (
    <div className="position-relative d-flex flex-column my-4">
      {title && <div className="d-flex align-items-center">
        <h4 className="m-0 mr-2 px-2 py-1 bg-gray-200 rounded-circle">
          {index + 1}
        </h4>
        <h3 className="m-0">{title}</h3>
      </div>}
      <Accordion.Collapse eventKey={eventKey}>
        <div className="d-flex">
          <div className="d-flex flex-column py-3 px-4 w-50">
            <List bullets={bullets} />
            <Form
              className="mt-3"
              control={control}
              errors={errors}
              fields={fields}
              onSubmit={onSubmit}
            />
            <div className="d-flex mt-2">
              {!noBack && (
                <button
                  className="btn btn-outline--secondary mr-3"
                  onClick={onBack}
                >
                  <FormattedMessage
                    id="components.catTool.settings.back"
                    defaultMessage="Back"
                  />
                </button>
              )}
              {!noNext && (
                <button className="btn btn--primary mr-0" onClick={onSubmit}>
                  {status === STATUSES.loading ? (
                    <Spinner isSmall />
                  ) : (
                    <FormattedMessage
                      id="components.catTool.settings.nextStep"
                      defaultMessage="Next Step"
                    />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="helper-images">
            {images.map(image => (
              <img
                className="rws-help-image mt-1 border border-3 border-gray-300 rounded"
                src={image}
                alt="rws-account-id"
              />
            ))}
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  )
}

export default StepField
