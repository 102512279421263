import store from "../store/index"

export default class VendorUpdator {
  constructor(vendor) {
    this._vendor = vendor
  }

  static async call(args) {
    return new VendorUpdator(args).call()
  }

  async call() {
    try {
      await this.updateVendor()
      return true
    } catch (err) {
      return false
    }
  }

  updateVendor() {
    return store.getActions().currentVendor.update(this._vendor)
  }
}
