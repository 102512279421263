import React, { useState } from "react"
import Toast from "../Toast"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Alert } from "react-bootstrap"
import ModalForm from "../forms/ModalForm"
import { DateTime } from "luxon"
import { STATUSES } from "../layout/FormContainer"

export const PlanCancellationButton = () => {
  const intl = useIntl()

  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [showSuccessCancellation, setShowSuccessCancellation] = useState(false)
  const [status, setStatus] = useState()
  const { subscription } = useStoreState(state => state.billing)
  const { cancelSubscription, fetchSubscription } = useStoreActions(
    actions => actions.billing
  )
  const onSubmit = () => {
    setStatus(STATUSES.loading)
    cancelSubscription({ organizationId: currentOrg.id })
      .then(() => {
        setStatus(STATUSES.success)
        setShowSuccessCancellation(true)
        fetchSubscription({ organizationId: currentOrg?.id })
      })
      .catch(() => {
        setStatus(STATUSES.error)
      })
  }

  const Trigger = ({ onClick }) => (
    <a href="#!" onClick={onClick}>
      <FormattedMessage
        id="components.billing.planCancellation.toggle"
        defaultMessage="cancel your subscription"
      />
    </a>
  )

  return (
    <>
      <Toast
        show={showSuccessCancellation}
        setShow={setShowSuccessCancellation}
        delay={5000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.billing.planCancellation.success"
            defaultMessage="You have successfully cancelled your {planName} subscription"
            values={{ planName: subscription?.details?.planName }}
          />
        </p>
      </Toast>
      <ModalForm
        fields={[]}
        onSubmit={onSubmit}
        setStatus={setStatus}
        status={status}
        submitProps={{
          variant: "danger",
          title: `${intl.formatMessage({
            id: "components.billing.planCancellation.confirm",
            defaultMessage: "Confirm",
          })}`,
        }}
        Trigger={Trigger}
      >
        <Alert variant="danger">
          <FormattedMessage
            id="components.billing.planCancellation.modalForm.Alert"
            defaultMessage="Confirmation required"
            description="modal cancel current subscription confirmation alert"
          />
        </Alert>
        <h4>
          <FormattedMessage
            id="components.billing.planCancellation.modalForm.heading"
            defaultMessage="Are you sure you want to cancel your {planName} subscription?"
            description="modal cancel current subscription confirmation alert"
            values={{ planName: subscription?.details?.planName }}
          />
        </h4>
        <p>
          <FormattedMessage
            id="components.billing.planCancellation.modalForm.paragraph"
            defaultMessage="This will cancel the plan at the end of its billing cycle on {date}"
            description="modal cancel current subscription paragraph"
            values={{
              date: DateTime.fromISO(
                subscription?.details?.renewAt
              ).toLocaleString(DateTime.DATE_MED),
            }}
          />
        </p>
      </ModalForm>
    </>
  )
}
