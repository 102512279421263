import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import ProjectLocaleActions from "./Actions"
import { localeName } from "../../../helpers/locale"
import { isDocumentTypeConnector, isLokaliseConnector } from "../../../helpers/connector"
import LocaleCompletionChart from "./CompletionChart"
import ProjectLocaleState from "./State"
import Source from "../../Source"
import Icon from "../../Icon"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const ProjectLocaleRow = ({ projectLocale }) => {
  const intl = useIntl()
  const projectLocaleName = localeName(projectLocale.locale, intl)
  const project = useStoreState(state =>
    state.projects.byId(projectLocale.projectID)
  )
  const connector = useStoreState(state =>
    state.connectors.getConnector(project?.connector?.type)
  )

  const renderCompletionState = () => {
    if (projectLocale.isCompleted) return
    if (projectLocale.isSource) {
      if (projectLocale.segments === 0) {
        return (
          <>
            <Icon className="mr-2 text-warning" name="alert-triangle" />
            <FormattedMessage
              id="components.projects.locale.row.source.noContent"
              defaultMessage="No content detected"
            />
            <OverlayTrigger
              placement="right"
              delay={{ show: 400, hide: 1000 }}
              overlay={props => (
                <Tooltip {...props} className="text-align-left">
                  <FormattedMessage
                    id="components.projects.locale.row.source.noContent.tooltip1"
                    defaultMessage="The downloaded content is empty."
                  />
                  <br />
                  <FormattedMessage
                    id="components.projects.locale.row.source.noContent.tooltip2"
                    defaultMessage="Please ensure the project's language codes match Locale and {connector}."
                    values={{ connector: connector?.name }}
                  />
                </Tooltip>
              )}
            >
              <button
                className="btn--transparent p-0"
                onClick={e => e.preventDefault()}
              >
                <Icon className="text-gray-500" name="help-circle small" />
              </button>
            </OverlayTrigger>
          </>
        )
      }
      return projectLocale.words && projectLocale.segments ? (
        <FormattedMessage
          id="components.projects.locale.row.source.wordsAndSegments"
          defaultMessage="{words, plural, one {{words} word} other {{words, number, ::compact-short} words}} / {segments, plural, one {{segments} segment} other {{segments, number, ::compact-short} segments}}"
          values={{
            segments: projectLocale.segments,
            words: projectLocale.words,
          }}
        />
      ) : null
    }
    if (isDocumentTypeConnector(project) || isLokaliseConnector(project)) return
    return (
      projectLocale.missingWords && (
        <FormattedMessage
          id="components.projects.locale.row.target.words"
          defaultMessage="{words, plural, one {{words} word} other {{words, number, ::compact-short} words}} to complete"
          values={{ words: projectLocale.missingWords }}
        />
      )
    )
  }

  return (
    <li className="d-flex justify-content-between align-items-center py-3 bg-gray-0 border-t border-gray-200">
      <header className="d-flex items-align-center w-25">
        <div className="w-25 mr-2 d-flex justify-content-center">
          {projectLocale.isSource ? (
            <Source className="square-2_7 h-100 p-2" />
          ) : (
            <LocaleCompletionChart projectLocale={projectLocale} />
          )}
        </div>

        <h4 className="w-75 mb-0 ml-1">
          <span className="d-flex align-items-center font-bold text-gray-900">
            {projectLocaleName}

            <ProjectLocaleActions projectLocale={projectLocale} />
          </span>

          <span className="d-block text-gray-500 text-sm">
            {projectLocale.code}
          </span>
        </h4>
      </header>

      <h4 className="w-15 text-gray-800 text-center m-0 d-flex align-items-center justify-content-end">
        {renderCompletionState()}
      </h4>

      <div className="w-40 mr-3">
        <ProjectLocaleState project={project} projectLocale={projectLocale} />
      </div>
    </li>
  )
}

export default ProjectLocaleRow
