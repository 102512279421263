import React from "react"
import { isContentable, isCuistoProject } from "../../helpers/project"
import Icon from "../Icon"
import { removeProtocol, getHostName } from "../../helpers/path"
import { CONNECTORS } from "../../store/models/connectors"
import { isApiConnector } from "../../helpers/connector"

const ProjectLink = ({ project, className }) => {
  const siteExtension = project.connector?.type === CONNECTORS.bitbucket ? "org" : "com";
  const isResourcableWithDynamicUrl = [CONNECTORS.azurerepos, CONNECTORS.lokalise].includes(project.connector.type);
  const renderLink = () => {
    if (isApiConnector(project) || (isCuistoProject(project) && !project.connector?.url)) return null
    if(isContentable(project) || isResourcableWithDynamicUrl || isCuistoProject(project)){
      return (<div className={className}>
        <a
          target="_blank"
          rel="noreferrer"
          href={project.connector?.url || ""}
          className="text-gray-500">
          {  removeProtocol(getHostName(project.connector?.url)) }
          <Icon name="external-link" className="ml-2" />
        </a>
        </div>);
    }

    if(project.connector.mode === "document"){
      return (<div className={className}>
        <a
          target="_blank"
          rel="noreferrer"
          href={project.connector?.url || ""}
          className="text-gray-500">
          { project?.connector?.url ? new URL(project?.connector?.url).searchParams.get("filePath").split("/").join(" / ") : ""}
          <Icon name="external-link" className="ml-2" />
        </a>
        </div>);
    }
    return (<div className={className}>
      <a
        target="_blank"
        href={`https://${project.connector?.type}.${siteExtension}/${project.connector?.repository}`}
        className="text-gray-500">
        {`${project.connector?.type}.${siteExtension}/${project.connector?.repository}`}
        <Icon name="external-link" className="ml-2" />
      </a>
    </div>);
  }
  return renderLink();
}

export default ProjectLink
