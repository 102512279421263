import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";
import Form from "../../forms/Form";
import FormFields from "../../forms/FormFields";

const ResourcesForm = ({
  fields,
  hiddenFields,
  onSubmit,
  control,
  errors,
}) => (
  <Form onSubmit={onSubmit}>
    <FormFields fields={hiddenFields} errors={errors} control={control} />
    <Card className="mb-0 border-0 shadow-none">
      <Card.Body className="mr-n4 p-0 pr-4">
        <Card.Text>
          <FormattedMessage
            id="components.projects.resource.resourcePatternForm.sourceDescription"
            defaultMessage="We will check for changes in this file pattern. Make sure to include ${localeCode} in the path."
          />
        </Card.Text>
        <ListGroup variant="flush">
          <ListGroup.Item className="pt-0">
            <FormFields fields={fields} errors={errors} control={control} />
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  </Form>
);

export default ResourcesForm;
