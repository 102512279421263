import { FormattedMessage } from "gatsby-plugin-intl"
import React, { useEffect, useRef, useState } from "react"
import { Overlay, Tooltip } from "react-bootstrap"
import Icon from "./Icon"

let timeout = null
const delay = 5000

const Copy = ({ text }) => {
  const target = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      if (timeout) clearTimeout(timeout)

      timeout = setTimeout(() => {
        setShow(false)
      }, delay)
    }
  }, [show])

  return (
    <>
      <button
        ref={target}
        className="px-1 py-0 rounded border border-1 bg-white border-gray-400 text-gray-600"
        onClick={() => {
          setShow(true)
          navigator.clipboard.writeText(text)
        }}
      >
        <Icon name="copy" />
      </button>
      <Overlay target={target.current} show={show} placement="top">
        {props => (
          <Tooltip {...props}>
            <FormattedMessage
              id="components.copy.copied"
              defaultMessage="Copied"
            />
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}

export default Copy
