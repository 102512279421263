import React, { useEffect, useState } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { Button } from "react-bootstrap"
import { path } from "../../config/routes"

import PageContainer from "../../components/layout/PageContainer"
import Form from "../../components/forms/Form"
import { fieldRequired } from "../../utils/validations"
import { STATUSES } from "../../components/layout/FormContainer"
import Spinner from "../../components/common/Spinner"
import FormFields from "../../components/forms/FormFields"
import Toast from "../../components/Toast"
import { STAGES as ONBOARDING_STAGES, TYPES as ONBOARDING_TYPES } from "../../models/onboarding"

const DEFAULT_INTEGRATION_ID = "default"

const AccountAdd = () => {
  const intl = useIntl()
  const { isSharedIntegrationMode } = useStoreState(
    state => state.currentVendor
  )
  const { integrations, defaultIntegration, id: currentVendorID } = useStoreState(
    state => state.currentVendor
  )
  const [showToast, setShowToast] = useState(false)
  const [bmsAccountOptions, setBmsAccountOptions] = useState([])
  const newOrg = useStoreActions(actions => actions.organizations.create)
  const { handleSubmit, control, errors, setValue } = useForm()
  const [status, setStatus] = useState(null)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const bmsAccounts = useStoreState(state => state.bms.items)
  const fetchProjectTemplates = useStoreActions(
    actions => actions.projectTemplates.fetch
  )
  const fetchBMSAccounts = useStoreActions(
    actions => actions.bms.fetch,
  )
  const [isLoading, setIsloading] = useState(false)
  const [isBMSloading, setIsBMSloading] = useState(false)
  const { bms, tms, hybrid } = integrations.reduce((acc, integration) => {
    const { id, name, integrationType: { type } } = integration
    if (defaultIntegration.id === id) {
      acc[type].push({
        id: DEFAULT_INTEGRATION_ID,
        name: `Default (${name})`,
        integration,
      })
    }
    acc[type].push({ id, integration, name })
    return acc
  }, { bms: [], tms: [], hybrid: [] })

  const bmsOptions = bms.concat(hybrid);
  const tmsOptions = tms.concat(hybrid);

  const currentIntegration = tmsOptions.find(
    integration =>
      integration.id === DEFAULT_INTEGRATION_ID
  )

  const currentSelectedIntegration = useWatch({
    control,
    name: "organization[integration]",
  })

  const currentSelectedAccountIntegration = useWatch({
    control,
    name: "organization[bmsIntegration]",
  })

  const currentSelectedBMSAccount = useWatch({
    control,
    name: "organization[bmsAccount]",
  })

  const accountName = useWatch({
    control,
    name: "organization[name]",
  })

  const infoFields = [
    {
      name: "organization[name]",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.vendor.accountEdit.fields.name.label",
        defaultMessage: "Account name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: "",
    },
  ]

  const tmsFields = [
    {
      name: "organization[integration]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountEdit.fields.integration.label"
          defaultMessage="Integration"
        />
      ),
      options: tmsOptions,
      defaultValue: tmsOptions.find(
        integration =>
          integration.id === DEFAULT_INTEGRATION_ID
      ),
      validations: { ...fieldRequired },
    },
    ...(currentSelectedIntegration?.integration?.integrationType.configuration
      ?.hasIntegrationTemplate && currentSelectedIntegration.id !== DEFAULT_INTEGRATION_ID
      ? [
          {
            name: "organization[integrationTemplate]",
            type: "select",
            label: (
              <FormattedMessage
                id="containers.admin.accountDetails.field.templateLabel"
                defaultMessage="Select Template"
              />
            ),
            isClearable: true,
            isLoading,
            options: projectTemplates,
            validations: {
              ...(currentSelectedIntegration?.integration?.integrationType
                .configuration?.isIntegrationTemplateRequired
                ? fieldRequired
                : {}),
            },
          },
        ]
      : []),
  ]

  const bmsFields = !bmsOptions.length ? [] : [
    {
      name: "organization[bmsIntegration]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountEdit.fields.integration.label"
          defaultMessage="Integration"
        />
      ),
      options: bmsOptions,
      defaultValue: bmsOptions[0],
      customClass: bmsOptions.length === 1 ? "d-none" : "",
    },
    {
      name: "organization[bmsAccount]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountDetails.field.accountManagementIntegration"
          defaultMessage={`${currentSelectedAccountIntegration?.integration?.name || ""} Account`}
        />
      ),
      isClearable: true,
      isLoading: isBMSloading,
      options: bmsAccountOptions,
    },
  ];

  useEffect(() => {
    if (!currentSelectedAccountIntegration) return
    setIsBMSloading(true)
    fetchBMSAccounts(currentSelectedAccountIntegration.integration).then(() =>
      setIsBMSloading(false)
    )
  }, [currentSelectedAccountIntegration])

  useEffect(() => {
    setBmsAccountOptions(
      bmsAccounts.map(account => ({
        ...account,
        name: `${account.name} - ${account.id}`,
      }))
    )
  }, [bmsAccounts, setBmsAccountOptions])

  useEffect(() => {
    if (currentSelectedBMSAccount?.name) {
      let { name, id } = currentSelectedBMSAccount
      name = name.replace(` - ${id}`, "")
      setValue("organization[name]", name)
    }
  }, [currentSelectedBMSAccount, setValue])

  useEffect(() => {
    setValue("organization[bmsIntegration]", bmsOptions[0])
  }, [setValue])

  useEffect(() => {
    if (!currentSelectedIntegration) {
      return setValue("organization[integration]", currentIntegration)
    }
    setIsloading(true)
    setValue("organization[integrationTemplate]", null)
    fetchProjectTemplates(currentSelectedIntegration.integration).then(() =>
      setIsloading(false)
    )
  }, [currentSelectedIntegration, fetchProjectTemplates, setValue])

  const onSubmit = async ({ organization }) => {
    setStatus(STATUSES.loading)
    if (organization.integration) organization.integrationID = organization.integration.id
    delete organization.integration
    if (organization.integrationID === DEFAULT_INTEGRATION_ID) organization.integrationID = null
    organization.integrationTemplateID = organization.integrationTemplate?.id || null
    organization.bmsIntegrationID = organization.bmsIntegration?.id || null
    organization.bmsAccountID = organization.bmsAccount?.id || null
    organization.type = currentVendorID
    organization.vendorID = currentVendorID
    delete organization.bmsAccount
    delete organization.integrationTemplate
    delete organization.bmsIntegration
    organization.onboarding = {
      stage: ONBOARDING_STAGES.project,
      isCompleted: true,
      type: ONBOARDING_TYPES.addClient,
    }
    const result = await newOrg(organization)
    if (result?.name) {
      setStatus(STATUSES.success)
      setShowToast(true)
      setTimeout(() => {
        window.location = `/en${path("NEW_PROJECT")}?workspace=${result.id}&client=true`
      }, 3000)
    }
    setStatus(STATUSES.error)
  }

  return (
    <PageContainer className="d-flex flex-column">
      <Toast show={showToast} setShow={setShowToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.settings.team.toast"
            defaultMessage="{accountName} has been created 🥳"
            values={{ accountName }}
          />
        </p>
      </Toast>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="d-flex align-items-center main-heading mb-4">
        <span className="text-gray-500">
          <FormattedMessage
            id="containers.admin.accountDetails.addNewAccount"
            defaultMessage="Add New Account"
          />
        </span>
      </h1>
      <h2 className="card-header-title mb-2">Account Info</h2>
      <div className="card mb-4">
        <div className="card-body w-40">
          <FormFields fields={bmsFields} errors={errors} control={control}/>
          <FormFields fields={infoFields} errors={errors} control={control} />
        </div>
      </div>
      {isSharedIntegrationMode && (
        <>
          <h2 className="card-header-title mb-2">Translation Management</h2>
          <div className="card mb-4">
            <div className="card-body w-40">
              <FormFields fields={tmsFields} errors={errors} control={control} />
            </div>
          </div>
        </>
      )}
        <Button type="submit" className="btn primary">
          {status === STATUSES.loading ? (
            <Spinner />
          ) : (
            <FormattedMessage
              id="containers.admin.accountDetails.add"
              defaultMessage="Add"
            />
          )}
        </Button>
      </Form>
    </PageContainer>
  )
}

export default AccountAdd
