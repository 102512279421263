import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { STATUSES } from "../../layout/FormContainer"
import { API } from "aws-amplify"
import { useOnboarding } from "../../../containers/Onboarding"
import Toast from "../../Toast"
import { Accordion } from "react-bootstrap"
import StepField from "../../forms/StepField"
import TradosStepFields from "./fields"
import { EVENT_KEYS } from "./fields"
import Icon from "../../Icon"
import TeamModal from "../../team/TeamModal"
import { URLS } from "../../../config/urls"

const validateCredentials = ({
  trados_languageCloudAccountId,
  trados_clientId,
  trados_clientSecret,
}) => {
  return API.get("nepal", "/trados/projectTemplates", {
    queryStringParameters: {
      accountId: trados_languageCloudAccountId,
      clientId: trados_clientId,
      clientSecret: trados_clientSecret,
    },
  })
}

const TradosForm = ({ isOnboarding, onExit, onSubmit, integration }) => {
  const intl = useIntl()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [activeKey, setActiveKey] = useState(EVENT_KEYS.NAME)
  const [templates, setTemplates] = useState([])
  const stepFields = TradosStepFields({
    intl,
    integration,
    templates,
  })
  const [status, setStatus] = useState()
  const [showTeamInviteToast, setShowTeamInviteToast] = useState(false)
  const defaultValues = {
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[trados_accountId]": integration.trados_accountId,
    "integration[trados_languageCloudAccountId]": integration.trados_languageCloudAccountId,
    "integration[trados_clientId]": integration.trados_clientId,
    "integration[trados_clientSecret]": integration.trados_clientSecret,
    "integration[trados_templateID]": integration.trados_templateID,
    "integration[trados_webhookSecret]": integration.trados_webhookSecret,
    "integration[templateID]": integration.templateID,
    "integration[vendorID]": integration.vendorID,
  }

  const {
    clearErrors,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
  })
  const [template, setTemplate] = useState()
  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const clientId = useWatch({
    control,
    name: "integration[trados_clientId]",
  })
  const clientSecret = useWatch({
    control,
    name: "integration[trados_clientSecret]",
  })
  const currentStepFieldIndex = stepFields.findIndex(
    step => step.eventKey === activeKey
  )
  const teamModalSubmit = () => setShowTeamInviteToast(true)

  const nextStep = () => {
    clearErrors()
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex !== -1 && stepIndex + 1 < stepFields.length)
      setActiveKey(stepFields[stepIndex + 1].eventKey)
  }

  const backStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex === 0 && onExit) onExit()
    if (stepIndex === 0 && !onExit && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (stepIndex !== -1 && stepIndex - 1 >= 0)
      setActiveKey(stepFields[stepIndex - 1].eventKey)
  }

  const checkAndSubmitForm = async ({ integration }) => {
    try {
      clearErrors()
      integration.name = integration.name?.trim()
      integration.trados_accountId = integration.trados_accountId?.trim()
      integration.trados_languageCloudAccountId = integration.trados_languageCloudAccountId?.trim()
      integration.trados_clientId = integration.trados_clientId?.trim()
      integration.trados_clientSecret = integration.trados_clientSecret?.trim()
      integration.templateID = integration.template?.id
      const template = integration.template
      const name = integration.name
      const accountId = integration.trados_accountId
      const languageCloudAccountId = integration.trados_languageCloudAccountId
      const clientId = integration.trados_clientId
      const clientSecret = integration.trados_clientSecret
      delete integration.template

      const requiredMessage = intl.formatMessage({
        id: "src.components.catTool.Settings.required",
        defaultMessage: "This is required.",
      })

      if (activeKey === EVENT_KEYS.NAME) {
        if (!name) {
          setError("integration[name]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if (activeKey === EVENT_KEYS.ACCOUNT_ID) {
        if (!accountId) {
          setError("integration[trados_accountId]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if (activeKey === EVENT_KEYS.LANGUAGE_CLOUD_ACCOUNT_ID) {
        if (!languageCloudAccountId || !accountId) {
          if(!languageCloudAccountId) {
            setError("integration[trados_languageCloudAccountId]", {
              type: "manual",
              message: requiredMessage,
            })
          }
          if(!accountId) {
            setError("integration[trados_accountId]", {
              type: "manual",
              message: requiredMessage,
            })
          }
          return
        }
      }

      if (activeKey === EVENT_KEYS.CLIENT_CREDS) {
        if (!clientId) {
          setError("integration[trados_clientId]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
        if (!clientSecret) {
          setError("integration[trados_clientSecret]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if (activeKey === EVENT_KEYS.DEFAULT_PROJECT_TEMPLATE) {
        if (!template) {
          setError("integration[template]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if ([EVENT_KEYS.LANGUAGE_CLOUD_ACCOUNT_ID, EVENT_KEYS.CLIENT_CREDS].includes(activeKey)) {
        setStatus(STATUSES.loading)
        const data = await validateCredentials(integration)
        setTemplates(data)
        setStatus(STATUSES.success)
      }

      if (currentStepFieldIndex < stepFields.length - 1) {
      	nextStep()
      } else {
        setStatus(STATUSES.loading)
        if (isOnboarding) routeToNextStep()
        const data = await onSubmit({ integration })
        if (data) {
          setStatus(STATUSES.success)
          !isOnboarding && setShowSuccessToast(true)
        } else setStatus(STATUSES.error)
      }
      return true
    } catch (e) {
      setStatus(STATUSES.error)
      switch (e.response?.status) {
        case 401:
          switch (e.response?.data?.code) {
            case "cat#1":
              if (activeKey === EVENT_KEYS.LANGUAGE_CLOUD_ACCOUNT_ID) {
                nextStep()
                break
              }
              setError("integration[trados_clientId]", {
                type: "manual",
                message: intl.formatMessage({
                  id: "src.components.catTool.Settings.clientId",
                  defaultMessage:
                    "Client ID is incorrect. Please check it again.",
                }),
              })
              setError("integration[trados_clientSecret]", {
                type: "manual",
                message: intl.formatMessage({
                  id: "src.components.catTool.settings.clientSecret",
                  defaultMessage:
                    "Client Secret is incorrect. Please check it again.",
                }),
              })
              break
            case "cat#2":
              if (activeKey === EVENT_KEYS.LANGUAGE_CLOUD_ACCOUNT_ID) {
                backStep()
              }
              setError("integration[trados_languageCloudAccountId]", {
                type: "manual",
                message: intl.formatMessage({
                  id: "src.components.catTool.settings.languageCloudAccountId",
                  defaultMessage:
                    "Language Cloud Account ID is incorrect. Please check it again.",
                }),
              })
              break
          }
        default:
          break
      }
      return false
    }
  }

  useEffect(() => {
    if (!integration) return
    reset(defaultValues)
  }, [reset, integration])

  useEffect(() => {
    if (clientId || clientSecret) clearErrors()
  }, [clearErrors, clientId, clientSecret])

  useEffect(() => {
    if (templates.length === 0) return
    setTemplate(
      templates.find(item => item.id === integration.templateID)
    )
    setValue("integration[template]", template)
  }, [integration, template, templates, setValue])

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <a
          target="_blank"
          rel="noreferrer"
          href={URLS.LANGUAGE_CLOUD_WEBSITE}
          className="text-gray-500"
        >
          <span className="text-decoration-underline">
            <FormattedMessage
              id="components.catTool.settings.dashboard"
              defaultMessage="Go to your Trados dashboard"
            />
          </span>
          <Icon name="external-link" className="ml-1" />
        </a>
        <TeamModal onSubmit={teamModalSubmit} />
      </div>
      <Toast show={showTeamInviteToast} setShow={setShowTeamInviteToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.inviteToast"
            defaultMessage="Successfully invited team member ✨"
          />
        </p>
      </Toast>
      <Toast
        show={!isOnboarding && showSuccessToast}
        setShow={setShowSuccessToast}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.toast"
            defaultMessage="Successfully updated your credentials ✨"
          />
        </p>
      </Toast>
      <div className="onboarding-form">
        <div className="form-group">
          <Accordion activeKey={activeKey}>
            {stepFields.map((step, i) => (
              <StepField
                index={i}
                {...step}
                control={control}
                errors={errors}
                onSubmit={handleSubmit(checkAndSubmitForm)}
                noBack={i === 0 && !isOnboarding}
                onBack={backStep}
                status={status}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default TradosForm
