import React from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Dropdown } from "react-bootstrap"
import { useStoreState, useStoreActions } from "easy-peasy"
import Check from "../Check"
import Plus from "../Plus"
import { redirect } from "../../config/routes"
import { stagePath } from "../../containers/Onboarding"
import { TYPES } from "../../models/onboarding"

const OrganizationInitial = ({ orgName = "", noBg, defaultOrgName }) => (
  <h3
    style={{ width: "1.3rem", height: "1.3rem", marginRight: "0.46rem", marginBottom: "0", fontSize: "0.8rem" }}
    className={`d-flex align-items-center justify-content-center text-gray-500 border border-gray-300 rounded ${
      !noBg ? "bg-white p-0" : ""
    }`}
  >
    {orgName?.[0].toUpperCase() || defaultOrgName[0].toUpperCase()}
  </h3>
)

const OrganizationDisplayName = ({ organizatiton, defaultOrgName }) => (
  <>
    <OrganizationInitial orgName={organizatiton?.name} white defaultOrgName={defaultOrgName} />
    <p className="truncate m-0">
      {organizatiton.name}
      {!organizatiton.name && defaultOrgName}
    </p>
  </>
)

const OrganizationSwitcher = ({ newWorkspace }) => {
  const intl = useIntl()
  const organizations = useStoreState(store => store.organizations.items)
  const currentOrg = useStoreState(store => store.currentOrg.item)
  const currentVendor = useStoreState(store => store.currentVendor.item)
  const asClient = useStoreState(state => state.appMode.asClient)

  const { set: setOrg, fetchPaidSubscription }  = useStoreActions(actions => actions.currentOrg)
  const setOnboarding = useStoreActions(actions => actions.onboarding.setAttrs)
  const setHours = useStoreActions(actions => actions.hoursUsage.set)

  const handleSwitchOrg = async organization => {
    setOrg(organization)
    setOnboarding(organization.onboarding)
    fetchPaidSubscription(organization)
    setHours(null)
    redirect("DASHBOARD")
  }

  const handleCreateNewOrg = async () => {
    if (asClient) {
      navigate(stagePath("", TYPES.addClient))
      return
    }
    navigate(stagePath("", TYPES.addWorkspace))
  }

  const defaultOrgName = intl.formatMessage({
    id: "components.workspace.components.switcher.defaultWorkspaceName",
    defaultMessage: "Your account",
  })

  if (!currentVendor?.id) {
    return <div className="d-flex text-white py-2 align-items-center font-weight-bold">
      <OrganizationDisplayName organizatiton={currentOrg} defaultOrgName={defaultOrgName} />
    </div>
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="bg-transparent border-0 shadow-none px-0 w-100 justify-content-between">
        <div className="d-flex align-items-center" style={{ marginLeft: "-0.05rem" }}>
          <OrganizationDisplayName organizatiton={currentOrg} defaultOrgName={defaultOrgName} />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 mt-n5 pb-3">
        {organizations &&
          organizations.map((organization, index) => {
            return (
              <Dropdown.Item
                className="px-3 d-flex justify-content-between"
                key={index}
                onClick={() => {
                  handleSwitchOrg(organization)
                }}
              >
                <div className="d-flex align-items-center">
                  <OrganizationInitial orgName={organization?.name} noBg defaultOrgName={defaultOrgName} />
                  <p className="truncate m-0">
                    {organization.name}
                    {!organization.name && defaultOrgName}
                  </p>
                </div>

                {currentOrg.id === organization.id && <Check />}
              </Dropdown.Item>
            )
          })}
        {newWorkspace && (
          <>
            <Dropdown.Divider className="mt-2 mb-3 dropdown-divider" />
            <Dropdown.Item
              className="py-0 text-green-500 text-sm font-bold"
              onClick={handleCreateNewOrg}
            >
              <Plus className="mr-2" />
              <FormattedMessage
                id="components.workspace.components.switcher.addWorkspaceButton"
                defaultMessage="Add new account"
              />
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default OrganizationSwitcher
