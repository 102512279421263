import React from "react"
import ProjectLocaleRemove from "./Remove"
import Actions from "../../common/Actions"
import ProjectLocaleSettings from "./Settings"

const ProjectLocaleActions = ({ projectLocale }) => {
  const Remove = () => <ProjectLocaleRemove projectLocale={projectLocale} />
  const Settings = () => <ProjectLocaleSettings projectLocale={projectLocale} />
  const actions = [Settings()]
  if (!projectLocale.isSource) actions.push(Remove())

  return <Actions actions={actions} />
}

export default ProjectLocaleActions
