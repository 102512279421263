import React, { useEffect, useState } from "react"
import { useStoreState } from "easy-peasy"
import { useIntl } from "gatsby-plugin-intl"
import Helmet from "react-helmet"
import { API } from "aws-amplify"
import waitForGlobal from "../../utils/waitForGlobal"
import Spinner from "../common/Spinner"

const OpenPaymentMethod = ({ setRenderChargebee, onChargebeeSuccess }) => {
  const intl = useIntl()
  const [chargebee, setChargebee] = useState(null)
  const [chargebeePaymentSection, setChargebeePaymentSection] = useState(null)
  const currentOrg = useStoreState(state => state.currentOrg.item)

  const initChargebee = () => {
    window.Chargebee.init({
      site: `${process.env.CHARGEBEE_SITE}`,
      publishableKey: `${process.env.CHARGEBEE_PUBLISHABLE_KEY}`,
    })
    setChargebee(window.Chargebee.getInstance())
    setChargebeePaymentSection(
      window.Chargebee.getPortalSections().ADD_PAYMENT_SOURCE
    )
  }

  const openPortal = () => {
    const params = {
      queryStringParameters: {
        locale: intl.locale,
        organizationID: currentOrg.id,
      },
    };

    if (chargebee !== null) {
      chargebee.setPortalSession(() =>
        API.get("nepal", "/chargebee/portal-token", params)
      )
      chargebee.createChargebeePortal().open(
        {
          hostedPage: () => {
            return API.get("nepal", "/chargebee/payment-method", params);
          },
          error: err => {
            console.log("Error", err)
            chargebee.closeAll()
          },
          paymentSourceAdd: status => {
            if (status === "success") {
              onChargebeeSuccess()
              chargebee.closeAll()
            }
          },
          close: () => {
            setRenderChargebee(false)
            chargebee.closeAll()
          },
        },
        {
          sectionType: chargebeePaymentSection,
        }
      )
    }
  }

  useEffect(() => {
    waitForGlobal("Chargebee", initChargebee)
  }, [])

  useEffect(() => {
    if (chargebee) openPortal()
  }, [chargebee])

  return (
    <>
      <Helmet>
        <script
          src="https://js.chargebee.com/v2/chargebee.js"
          data-cb-site={process.env.CHARGEBEE_SITE}
        />
      </Helmet>
      <Spinner />
    </>
  )
}

export default OpenPaymentMethod
