import React, { useState, useEffect } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { Accordion } from "react-bootstrap"

import Toast from "../../Toast"
import Icon from "../../Icon"
import { STATUSES } from "../../layout/FormContainer"
import { useOnboarding } from "../../../containers/Onboarding"
import StepField from "../../forms/StepField"
import TextMasterAPIKey from "../../../img/partners/helpers/textmaster-apikey.jpg"
import TextMasterAPISecret from "../../../img/partners/helpers/textmaster-apisecret.jpg"
import { API } from "aws-amplify"
import TeamModal from "../../team/TeamModal"
import { URLS } from "../../../config/urls"

const EVENT_KEYS = {
  NAME: "name",
  API_KEY: "apiKey",
  API_SECRET: "apiSecret",
}

const validateCredentials = ({ textmaster_apiKey, textmaster_apiSecret }) => {
  return API.get("nepal", "/textmaster/projectTemplates", {
    queryStringParameters: {
      clientId: textmaster_apiKey,
      clientSecret: textmaster_apiSecret,
    },
  })
}

const TextmasterForm = ({ isOnboarding, onExit, onSubmit, integration }) => {
  const intl = useIntl()
  const [status, setStatus] = useState()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showTeamInviteToast, setShowTeamInviteToast] = useState(false)
  const defaultValues = {
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[textmaster_apiKey]": integration.textmaster_apiKey,
    "integration[textmaster_apiSecret]": integration.textmaster_apiSecret,
    "integration[vendorID]": integration.vendorID,
  }
  const {
    handleSubmit,
    errors,
    control,
    clearErrors,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
  })
  const apiKey = useWatch({
    control,
    name: "integration[textmaster_apiKey]",
  })
  const apiSecret = useWatch({
    control,
    name: "integration[textmaster_apiSecret]",
  })
  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const [activeKey, setActiveKey] = useState(EVENT_KEYS.NAME)

  const teamModalSubmit = () => setShowTeamInviteToast(true)

  const stepFields = [
    {
      eventKey: EVENT_KEYS.NAME,
      title: (
        <FormattedMessage
          id="src.components.textmaster.settings.integrationName"
          defaultMessage={`Integration Name`}
        />
      ),
      fields: [
        {
          name: "integration[name]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.textmaster.settings.fields.name",
            defaultMessage: "Integration Name",
          })}`,
        },
      ],
      images: [],
    },
    {
      eventKey: EVENT_KEYS.API_KEY,
      title: (
        <FormattedMessage
          id="src.components.textmaster.settings.helpertitle1"
          defaultMessage="Your {name} API key"
          values={{ name: integration.integrationType?.name }}
        />
      ),
      bullets: [
        {
          sectionTitle: (
            <FormattedMessage
              id="src.components.textmaster.settings.helper1.bullet1"
              defaultMessage={`
            Make sure you are logged in to {name}.
            Navigate to the <a>API and Loop page</a>.
            Copy the <b>API key</b> and paste it in the field below.
            Then click <b>Next Step</b>.
          `}
              values={{
                a: msg => (
                  <a
                    className="text-gray-800"
                    target="_blank"
                    rel="noreferrer"
                    href="https://app.textmaster.com/clients/api_info"
                  >
                    <span className="text-decoration-underline">{msg}</span>
                    <Icon name="external-link" className="ml-1" />
                  </a>
                ),
                b: msg => <b>{msg}</b>,
                name: integration.integrationType?.name,
              }}
            />
          ),
        },
      ],
      fields: [
        {
          name: "integration[groups]",
          type: "hidden",
        },
        {
          name: "integration[id]",
          type: "hidden",
        },
        {
          name: "integration[integrationTypeID]",
          type: "hidden",
        },
        {
          name: "integration[name]",
          type: "hidden",
        },
        {
          name: "integration[organizationID]",
          type: "hidden",
        },
        {
          name: "integration[vendorID]",
          type: "hidden",
        },
        {
          name: "integration[textmaster_apiKey]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.catTool.settings.fields.textmaster.apiKey",
            defaultMessage: "API Key",
          })}`,
        },
      ],
      images: [TextMasterAPIKey],
    },
    {
      eventKey: EVENT_KEYS.API_SECRET,
      title: (
        <FormattedMessage
          id="src.components.textmaster.settings.helpertitle2"
          defaultMessage="Your {name} API secret"
          values={{ name: integration.integrationType?.name }}
        />
      ),
      bullets: [
        {
          sectionTitle: (
            <FormattedMessage
              id="src.components.textmaster.helper2.bullet1"
              defaultMessage={`
                On the same <a>API and Loop page</a> copy the <b>API secret</b>
                and paste it in the field below. Then click <b>Next Step</b>.
              `}
              values={{
                a: msg => (
                  <a
                    className="text-gray-800"
                    target="_blank"
                    rel="noreferrer"
                    href="https://app.textmaster.com/clients/api_info"
                  >
                    <span className="text-decoration-underline">{msg}</span>
                    <Icon name="external-link" className="ml-1" />
                  </a>
                ),
                b: msg => <b>{msg}</b>,
              }}
            />
          ),
        },
      ],
      fields: [
        {
          name: "integration[textmaster_apiSecret]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.catTool.settings.fields.textmaster.apiSecret",
            defaultMessage: "API Secret",
          })}`,
        },
      ],
      images: [TextMasterAPISecret],
    },
  ]
  const currentStepFieldIndex = stepFields.findIndex(
    step => step.eventKey === activeKey
  )

  const nextStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex !== -1 && stepIndex + 1 < stepFields.length)
      setActiveKey(stepFields[stepIndex + 1].eventKey)
  }

  const backStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex === 0 && onExit) onExit()
    if (stepIndex === 0 && !onExit && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (stepIndex !== -1 && stepIndex - 1 >= 0)
      setActiveKey(stepFields[stepIndex - 1].eventKey)
  }

  useEffect(() => {
    if (!integration) return
    reset(defaultValues)
  }, [reset, integration])

  useEffect(() => {
    if (apiKey || apiSecret || currentStepFieldIndex >= 0) clearErrors()
  }, [apiKey, apiSecret, clearErrors, currentStepFieldIndex])

  const checkAndSubmitForm = async ({ integration }) => {
    try {
      clearErrors()
      integration.textmaster_apiKey = integration.textmaster_apiKey?.trim()
      integration.textmaster_apiSecret = integration.textmaster_apiSecret?.trim()
      const { textmaster_apiKey, textmaster_apiSecret } = integration

      const requiredMessage = intl.formatMessage({
        id: "src.components.catTool.Settings.required",
        defaultMessage: "This is required.",
      })

      if (activeKey === EVENT_KEYS.API_KEY) {
        if (!textmaster_apiKey) {
          setError("integration[textmaster_apiKey]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if (activeKey === EVENT_KEYS.API_SECRET) {
        if (!textmaster_apiSecret) {
          setError("integration[textmaster_apiSecret]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
      }

      if (currentStepFieldIndex < stepFields.length - 1) {
        nextStep()
      } else {
        setStatus(STATUSES.loading)
        await validateCredentials(integration)
        if (isOnboarding) routeToNextStep()
        const data = await onSubmit({ integration })
        if (data) {
          setStatus(STATUSES.success)
          !isOnboarding && setShowSuccessToast(true)
        } else setStatus(STATUSES.error)
      }
      return true
    } catch (e) {
      setStatus(STATUSES.error)
      setError("integration[textmaster_apiSecret]", {
        type: "manual",
        message: intl.formatMessage({
          id: "src.components.catTool.Settings.clientId",
          defaultMessage:
            "Invalid API Key and/or API Secret. Please check it again.",
        }),
      })
      return false
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <a
          target="_blank"
          rel="noreferrer"
          href={URLS.TEXTMASTER_API_INFO}
          className="text-gray-500"
        >
          <span className="text-decoration-underline">
            <FormattedMessage
              id="components.textmaster.settings.dashboard"
              defaultMessage="Go to your API and Loop page"
            />
          </span>
          <Icon name="external-link" className="ml-1" />
        </a>
        <TeamModal onSubmit={teamModalSubmit} />
      </div>
      <Toast show={showTeamInviteToast} setShow={setShowTeamInviteToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.inviteToast"
            defaultMessage="Successfully invited team member ✨"
          />
        </p>
      </Toast>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.textmaster.settings.successToast"
            defaultMessage="Successfully updated your credentials ✨"
          />
        </p>
      </Toast>
      <div className="onboarding-form">
        <div className="form-group">
          <Accordion activeKey={activeKey}>
            {stepFields.map((step, i) => (
              <StepField
                index={i}
                {...step}
                control={control}
                errors={errors}
                onSubmit={handleSubmit(checkAndSubmitForm)}
                noBack={i === 0 && !isOnboarding}
                onBack={backStep}
                status={status}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </>
  )
}

export default TextmasterForm
