import React from "react"
import { Tab, Container } from "react-bootstrap"
import { QuoteTable } from "./QuoteTable"
import { useFormContext } from "react-hook-form"

export const QuoteTabPane = ({ children, eventKey, onSubmit }) => {
  const form = useFormContext()
  return (
    <Tab.Pane eventKey={eventKey}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        // className="d-flex flex-column overflow-hidden min-vh-100 vh-100"
      >
        <div role="main" class="flex-grow-1 overflow-auto">
          <QuoteTable></QuoteTable>
        </div>
        <div class="flex-grow-0 flex-shrink-1 mb-5">
          <Container fluid>{children}</Container>
        </div>
      </form>
    </Tab.Pane>
  )
}
