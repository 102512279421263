import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import Icon from "../../../components/Icon"
import ResourceModalForm from "./ModalForm"
import { formatPath } from "../../../helpers/format"
import ProjectResourcesCreator from "../../../service/projectResourcesCreator"

const AddResource = ({ project }) => {
  const intl = useIntl()
  const resources = project.locales.items.map(locale => {
    return {
      projectID: project.id,
      path: formatPath(project.format, locale),
      projectLocale: locale,
      resourceListIndex: 0,
    }
  })

  const Trigger = ({ onClick }) => (
    <Button size="sm" className="btn btn--primary d-flex align-items-center" onClick={onClick}>
      <Icon
        name="plus"
        title={intl.formatMessage({
          id: "components.projects.resource.add.trigger.button",
          defaultMessage: "Add File",
        })}
      />
    </Button>
  )

  return (
    <ResourceModalForm
      formAction={ProjectResourcesCreator.call}
      title={intl.formatMessage({
        id: "components.projects.resource.add.modal.title",
        defaultMessage: "Add Files",
      })}
      Trigger={Trigger}
      resources={resources}
    />
  )
}

export default AddResource
