import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { stringify } from "query-string"

const HubspotCalendar = ({ customer, onSubmit }) => {
  const customerQueryString = stringify(customer)

  useEffect(() => {
    if (window) window.addEventListener("message", onSubmit)

    return () => {
      if (window) window.removeEventListener("message", onSubmit)
    }
  }, [onSubmit])

  return (
    <>
      <div
        className="meetings-iframe-container"
        data-src={`${process.env.HUBSPOT_CALENDAR_LINK}&${customerQueryString}`}
      ></div>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
    </>
  )
}

export default HubspotCalendar