import React, { useState } from "react";
import Icon from "../Icon";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage } from "gatsby-plugin-intl";
import { useStoreState } from "easy-peasy";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import TeamUpdater from "../../service/teamUpdater";
import classNames from "classnames";
import { isValidEmail, getCommaSeparatedEmail } from "../../helpers/email";
import { STATUSES } from "../layout/FormContainer"
import Spinner from "../common/Spinner";
import Toast from "../../components/Toast";

const TeamInviteForm = ({ onSubmit, fullWidth, noLabel, noToast, isTabItem }) => {
  const [status, setStatus] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const currentOrg = useStoreState(state => state.currentOrg.item);
  const currentUser = useStoreState(state => state.currentUser.item);
  const [inviteCount, setInviteCount] = useState(0)
  const {
    control,
    handleSubmit,
    errors,
    reset
  } = useForm({
    defaultValues: {
      "emails": "",
    },
  });

  const validateEmails = (rawEmails) => {
    const emails = getCommaSeparatedEmail(rawEmails)

    for (let i = 0; i < emails.length; i++) {
      if (!isValidEmail(emails[i])) return false
    }

    return true
  }

  const submit = async data => {
    setStatus(STATUSES.loading)
    const emails = getCommaSeparatedEmail(data.emails);
    await TeamUpdater.call({
      emails,
      inviterID: currentUser.sub,
      organizationID: currentOrg.id,
    }) ? setStatus(STATUSES.success) : setStatus(STATUSES.error) ;

    setInviteCount(emails.length)
    setShowToast(true);

    if (onSubmit && onSubmit instanceof Function) {
      onSubmit();
    }

    reset();
  }

  const formClass = classNames({
    "w-50 mb-6": fullWidth !== true && !isTabItem,
    "w-50 mb-4": isTabItem,
  });

  const inputClass = classNames({
    "w-75 mb-3": fullWidth !== true,
    "w-full": fullWidth,
  });

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      className={formClass}
    >
      {!noLabel &&
        <Form.Label>
          <FormattedMessage
            id="containers.settings.team.form.label"
            defaultMessage="Invite member"
          />
        </Form.Label>
      }

      {!noToast && (
        <Toast show={showToast} setShow={setShowToast}>
          <p className="text-gray-50 font-normal mb-0">
            {status === STATUSES.success &&
              <FormattedMessage
                id="containers.settings.team.toast"
                defaultMessage="{inviteCount, plural, one {# Invitation} other {# Invitations}} sent 🥳"
                values={{ inviteCount }}
              />
            }

            {status === STATUSES.error &&
              <FormattedMessage
                id="containers.settings.team.toast"
                defaultMessage="An error occured while trying to send {inviteCount, plural, one {# an invite} other {# the invitations}} sent 🤔"
                values={{ inviteCount }}
              />
            }
          </p>
        </Toast>
      )}

      <InputGroup className={inputClass}>
        <Controller
          rules={{ required: true, validate: validateEmails }}
          control={control}
          placeholder="jet@locale.to, jet@example.com, etc."
          isInvalid={errors.emails}
          name="emails"
          errors={errors}
          disabled={status === STATUSES.loading}
          as={<FormControl type="text" />}
        />
        <InputGroup.Append>
          <Button disabled={status === STATUSES.loading} type="submit">
            {status === STATUSES.loading && <Spinner isSmall={true}/>}
            {status !== STATUSES.loading &&
              <>
                <Icon name="send" className="mr-2" />
                <FormattedMessage
                  id="components.components.team.form.invite"
                  defaultMessage="Invite"
                />
              </>
            }
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {errors.emails && errors.emails.type === "required" &&
        <span className="text-danger form-text">
          <FormattedMessage
            id="components.components.team.form.errorMessage.required"
            defaultMessage="This field is required"
          />
        </span>
      }

      {errors.emails && errors.emails.type === "validate" &&
        <span className="text-danger form-text">
          <FormattedMessage
            id="components.components.team.form.errorMessage.invalidEmail"
            defaultMessage="Invalid email"
          />
        </span>
      }
    </Form>
  );
}

export default TeamInviteForm;
