import React from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useStoreState, useStoreActions } from "easy-peasy"
import PageContainer from "../../components/layout/PageContainer"
import { path } from "../../config/routes"
import { IntegrationTypeList } from "../../components/integrations/IntegrationTypeList"
import IntegrationCard from "../../components/integrations/IntegrationCard"

const Integrations = () => {
  const {
    items: integrationTypes,
    public: publicIntegrationTypes,
  } = useStoreState(state => state.integrationTypes)
  const { bmsIntegrations, tmsIntegrations } = useStoreState(
    state => state.currentVendor
  )

  const vendor = useStoreState(state => state.currentVendor.item)
  const { id: defaultIntegrationID } = useStoreState(
    state => state.currentVendor.defaultIntegration
  )
  const { updateDefaultIntegration } = useStoreActions(
    actions => actions.currentVendor
  )

  const redirect = (integrationType) => {
    navigate(path("NEW_INTEGRATION", {
      type: integrationType.id,
    }))
  }

  return integrationTypes.length === 0 ? null : (
    <PageContainer className="d-flex flex-column">
      <h1 className="d-flex align-items-center main-heading mb-4">
        <FormattedMessage
          id="containers.admin.integrations"
          defaultMessage="Integrations"
        />
      </h1>

      {bmsIntegrations.length > 0 && (
        <div className="page-section mt-5">
          <aside className="page-section__aside">
            <h2 className="page-section__heading">
              <FormattedMessage
                id="containers.admin.integrations.configured.heading"
                defaultMessage="Business Management"
              />
            </h2>
          </aside>
          <IntegrationCard integrations={bmsIntegrations} />
        </div>
      )}

      {tmsIntegrations.length > 0 && (
        <div className="page-section mt-5">
          <aside className="page-section__aside">
            <h2 className="page-section__heading">
              <FormattedMessage
                id="containers.admin.integrations.configured.heading"
                defaultMessage="Translation Management"
              />
            </h2>
          </aside>
          <IntegrationCard
            integrations={tmsIntegrations}
            defaultIntegrationID={defaultIntegrationID}
            onSelect={(integration) => updateDefaultIntegration({
              id: vendor.id,
              integration,
            })}
          />
        </div>
      )}

      <div className="page-section mt-6">
        <aside classNames="page-section__aside">
          <h2 className="page-section__heading">
            <FormattedMessage
              id="containers.admin.integrations.available.heading"
              defaultMessage="Available Integrations"
            />
          </h2>
        </aside>

        <IntegrationTypeList
          integrationsTypes={publicIntegrationTypes}
          onClick={redirect}
        />
      </div>
    </PageContainer>
  )
}

export default Integrations
