import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import Form from "../../../../components/forms/Form"
import { fieldRequired, validatePhone } from "../../../../utils/validations"
import { useOnboarding } from "../../../Onboarding"
import VendorUpdator from "../../../../service/vendorUpdator"
import UserUpdater from "../../../../service/userUpdater"

const Workspace = ({ onboarding }) => {
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const currentUser = useStoreState(state => state.currentUser.item)
  const { routeToNextStep, routeToBackStep, hasPreviousStep } = useOnboarding()
  const intl = useIntl()
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })

  const fields = [
    {
      name: "id",
      type: "hidden",
      defaultValue: currentVendor.id,
    },
    {
      name: "name",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.onboarding.company.name",
        defaultMessage: "Company name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentVendor.name || "",
    },
    {
      name: "website",
      type: "text",
      prependInputGroupText: "https://",
      label: `${intl.formatMessage({
        id: "containers.onboarding.company.webiste",
        defaultMessage: "Company website",
      })}`,
      placeholder: "www.example.com",
      validations: { ...fieldRequired },
      defaultValue: currentVendor.website || "",
    },
    { type: "divider" },
    {
      name: "fullName",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.onboarding.company.userFullName",
        defaultMessage: "Your full name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentUser.given_name || "",
    },
    {
      name: "phoneNumber",
      type: "phone",
      label: `${intl.formatMessage({
        id: "containers.onboarding.company.userPhoneNumber",
        defaultMessage: "Your phone number",
      })}`,
      validations: { ...fieldRequired, ...validatePhone },
      defaultValue: currentUser.phone_number || "",
    },
    { type: "divider" },
  ]

  const onSubmit = async payload => {
    routeToNextStep()
    await VendorUpdator.call({
      id: payload.id,
      name: payload.name,
      website: payload.website,
    })

    await UserUpdater.call({
      given_name: payload.fullName,
      phone_number: payload.phoneNumber,
    })
  }

  return (
    <div className="d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.company.h2"
            defaultMessage="Create your Account"
          />
        </h1>
      </div>
      <div className="onboarding-form">
        <div className="w-50">
          <Form
            id="vendor-company-name-update"
            control={control}
            errors={errors}
            fields={fields}
            onSubmit={handleSubmit(onSubmit)}
          />
          <div className="d-flex">
            {hasPreviousStep && (
              <button
                className="btn btn-outline--secondary mr-5"
                onClick={() => routeToBackStep()}
              >
                <FormattedMessage
                  id="containers.onboarding.company.backBtn"
                  defaultMessage="Back"
                />
              </button>
            )}

            <button
              className="btn btn--primary btn-primary mr-0"
              form="vendor-company-name-update"
            >
              <FormattedMessage
                id="containers.onboarding.company.nextBtn"
                defaultMessage="Next"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workspace
