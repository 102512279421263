import React, { useEffect } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import PageContainer from "../../components/layout/PageContainer"
import { DateTime } from "luxon"

const Dashboard = () => {
  const intl = useIntl()
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const chargeableWordsPerMonth = useStoreState(
    state => state.vendor.chargeableWords
  )
  const fetchChargeableWordCount = useStoreActions(
    actions => actions.vendor.chargeableWordCount
  )
  const monthsToDisplay = 3
  let counter = 0
  useEffect(() => {
    if (!currentVendor) return
    while (counter < monthsToDisplay) {
      fetchChargeableWordCount({
        vendorID: currentVendor.id,
        month: DateTime.utc().minus({ months: counter }).toFormat("yyyy-MM"),
      })
      counter++
    }
  }, [currentVendor])

  const Month = ({ index, month, wordCount }) => {
    month = DateTime.fromISO(`${month}-01`)
    const currentMonth =
      DateTime.now().toFormat("yyyy-MM") === month.toFormat("yyyy-MM")
    const displayMonth = currentMonth
      ? `This month (${month.toFormat("MMMM yyyy")})`
      : month.toFormat("MMMM yyyy")
    const style = currentMonth
      ? null
      : { backgroundColor: `#E2E8F0${99 - index * 20}` }
    const classes = classNames("report-card", {
      "report-card--current": currentMonth,
    })
    return (
      <li className={classes} style={style}>
        <h4
          className={classNames("report-card__title", {
            "report-card__title--highlight": currentMonth,
          })}
        >
          {displayMonth}
        </h4>
        <p className="report-card__body">{formatWords(wordCount)}</p>
      </li>
    )
  }
  const formatWords = wordCount => {
    if (wordCount === 0) return "-"
    return intl.formatMessage(
      {
        id: "containers.admin.dashboard.month.wordCount.compact",
        defaultMessage:
          "<a>{wordCount, number, ::compact-short}</a><b>{wordCount, plural, =0 {Words} one {Word} other {Words}}</b>",
      },
      {
        wordCount,
        a: msg => (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={showRealData(wordCount)}
          >
            <span className="report-card__amount">{msg}</span>
          </OverlayTrigger>
        ),
        b: msg => <span className="report-card__unit">{msg}</span>,
      }
    )
  }

  const showRealData = words => (
    <Tooltip>
      {intl.formatMessage(
        {
          id: "containers.admin.dashboard.month.wordCount.real",
          defaultMessage: "{words, number}",
        },
        { words }
      )}
    </Tooltip>
  )
  return (
    <PageContainer className="d-flex flex-row">
      <div>
        <h1 className="d-flex align-items-center main-heading mb-4">
          <FormattedMessage
            id="containers.admin.settings.title"
            defaultMessage="Dashboard"
          />
        </h1>
        <h2 className="page-section__heading mb-2">Usage</h2>
        <ul className="report-cards">
          {Object.values(chargeableWordsPerMonth) &&
            Object.entries(chargeableWordsPerMonth)
              .sort(
                ([monthA, _wordCountA], [monthB, _wordCountB]) =>
                  DateTime.fromISO(`${monthA}-01`).toFormat("yyyyMMdd") -
                  DateTime.fromISO(`${monthB}-01`).toFormat("yyyyMMdd")
              )
              .map(([month, wordCount], index) => (
                <Month
                  index={index}
                  month={month}
                  wordCount={wordCount}
                  key={month}
                />
              ))}
        </ul>
      </div>
    </PageContainer>
  )
}
export default Dashboard
