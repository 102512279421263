import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"

const Congratulations = () => {
  return <>
    <h1>
      <FormattedMessage
        id="containers.onboarding.welcome.h2"
        defaultMessage="Welcome, great to have you! 🎉"
      />
    </h1>

    <p>
      <FormattedMessage
        id="components.onboarding.pending.p"
        defaultMessage="We'll get your account sorted out after the onboarding call"
      />
    </p>
  </>
}

export default Congratulations
