import React from "react";
import { Dropdown } from "react-bootstrap";
import { useStoreState } from "easy-peasy"
import { UserProfileTrigger } from "../../containers/Profile"
import SignOutLink from "../auth/SignOutLink"
import Icon from "../Icon";

const UserIcon = React.forwardRef(({ onClick, children }, ref) => {
  return (
    <button
      ref={ref}
      className="user-dropdown__toggle"
      onClick={onClick}
    >
      {children}
    </button>
  );
});

const UserDropdown = ({ onlyLogout }) => {
  const currentUser = useStoreState(state => state.currentUser.item)
  const userInitial = currentUser?.given_name?.[0]?.toUpperCase() || (
    <Icon name="user" />
  )
  return (
    <div className="user-dropdown mr-3">
      <Dropdown>
        <Dropdown.Toggle as={UserIcon} id="nav-user-dropdown">
          {userInitial}
        </Dropdown.Toggle>

        <Dropdown.Menu className="mt-2">
          {!onlyLogout && <UserProfileTrigger />}
          <SignOutLink />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default UserDropdown;
