import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

import PartnerLogo from "../partner/PartnerLogo"
import { path } from "../../config/routes"
import Icon from "../Icon"
import HoursRemaining from "../projects/locale/HoursRemaining"
import UserDropdown from "../nav/UserDropdown"
import Brand from "../common/Brand"

const TopNavigation = ({
  asClient,
  currentOrg,
  hasPaidSubscriptions,
  isPartner,
  locale,
  vendorDetails,
}) => {
  const topNavBranding = isPartner ? (
    vendorDetails && (
      <PartnerLogo
        vendorDetails={vendorDetails}
        logoClassName="bg-white topbar__partner-logo-img mr-4"
      />
    )
  ) : (
    <Link to={path("ROOT")}>
      <Brand dark className="d-flex align-items-center" classNameImg="topbar__locale-img" />
    </Link>
  )

  return (
    <div
      style={{
        height: "57px",
        ...(isPartner ? { borderColor: vendorDetails?.brandColor } : {}),
      }}
      className={`d-flex align-items-center bg-gray-0 border-b border-gray-200-default ${
        !isPartner ? "justify-content-end" : "border-bottom-3"
      }`}
    >
      <div className="topbar__partner-logo">
        <h3 className="m-0">{currentOrg?.id && topNavBranding}</h3>
        {asClient && (
          <a
            href={`/${locale}${path("ROOT")}?client=false`}
            className="btn bg-white border border-gray-200 px-3 ml-4"
          >
            <FormattedMessage
              id="component.layout.appLayout.banner.message"
              defaultMessage="{icon} Back to admin"
              values={{
                icon: <Icon className="mr-2" name="arrow-left" />,
              }}
            />
          </a>
        )}
      </div>
      <UserDropdown />
    </div>
  )
}

export default TopNavigation
