import React, { useEffect, useState } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useFormContext } from "react-hook-form"
import { localeName } from "../../../helpers/locale"
import { Table } from "react-bootstrap"
import { ServiceModeSelect } from "./ServiceMode"
import { TextPlaceholder } from "../../common/Placeholder"
import Price from "../../billing/Price"

const QuoteTableBodyRow = ({ item }) => {
  const intl = useIntl()
  const { register } = useFormContext()

  const { checkItem, uncheckItem } = useStoreActions(actions => actions.quote)

  const selectChange = (e, item) => {
    e.target.checked ? checkItem(item) : uncheckItem(item)
  }

  return (
    <tr key={item.id}>
      <td>
        <input
          type="checkbox"
          id={item.id}
          onChange={e => selectChange(e, item)}
          checked={item.isChecked}
          name={"items"}
          ref={register()}
          defaultValue={item.id}
        />
      </td>
      <td className="font-semibold">
        <label htmlFor={item.id} className="m-0">
          {localeName(item.locale, intl)}
          <p className=" leading-none text-gray-500 m-0">{item.code}</p>
        </label>
      </td>
      <td class="text-right">
        <FormattedMessage
          id="omponents.projects.quote.QuoteTable.QuoteTableBodyRow.words.value"
          defaultMessage="{words, number, ::compact-short}"
          values={{ words: item.words }}
        />
      </td>
    </tr>
  )
}

const QuoteTableFooterRowWords = () => {
  const quote = useStoreState(state => state.quote)

  return (
    <tr>
      <td />
      <td>
        <FormattedMessage
          id="components.projects.quote.QuoteTable.total.label"
          defaultMessage="Total"
        />
      </td>
      <td class="text-right">
        <FormattedMessage
          id="components.projects.quote.QuoteTable.total.value"
          defaultMessage="{words, number, ::compact-short}"
          values={{ words: quote.words }}
        />
      </td>
    </tr>
  )
}

const QuoteTableFooterRowHours = ({ currency, setCurrency }) => {
  const { quotePrice, isQuoteCalculating } = useStoreState(state => state.billing)

  return (
    <>
      <tr>
        <td class="w-full" colSpan="3">
          <div class="container-fluid mt-4">
            <div class="card text-white bg-secondary">
              <div class="card-header">

            <div class="row">
              <div class="col align-self-center">
                <h3 class="m-0">
                  <FormattedMessage id="components.projects.quote.QuoteTableFooterRowHours.priceGuarantee" defaultMessage="Price Guarantee" />
                </h3>
              </div>
              <div class="col-auto">
                <select onChange={(e) => {setCurrency(e.target.value)}} class="form-select form-select-sm">
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
            </div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col border-right pt-2">
                      <span class="fe fe-bar-chart mr-2"></span>
                      <h6 class="text-uppercase mb-4">
                        <FormattedMessage id="components.projects.quote.QuoteTableFooterRowHours.volume" defaultMessage="Volume" />
                      </h6>
                      <hr class="text-muted"/>
                      <h2>
                        {!isQuoteCalculating
                          ? <FormattedNumber
                            // eslint-disable-next-line react/style-prop-object
                            style="unit"
                            unit="hour"
                            unitDisplay="narrow"
                            value={quotePrice?.quantity}
                          />
                          : <TextPlaceholder width="6rem"/>
                        }
                      </h2>
                      <div class="small">
                        <FormattedMessage id="components.projects.quote.QuoteTableFooterRowHours.professionalTranslation" defaultMessage="Professional translation" />
                      </div>
                  </div>

                  <div class="col border-right pt-2">
                      <span class="fe fe-briefcase mr-2"></span>
                      <h6 class="text-uppercase mb-4">
                        <FormattedMessage id="components.projects.quote.QuoteTableFooterRowHours.rate" defaultMessage="Rate" />
                      </h6>
                      <hr class="text-muted"/>
                      <h2>
                        {!isQuoteCalculating
                          ? <>
                            <Price amount={quotePrice.unitAmount} currency={currency}/>/h
                            </>
                          : <TextPlaceholder width="6rem"/>
                        }
                      </h2>
                      {(quotePrice.discountRate || isQuoteCalculating) && <>
                        <div>
                          <s>
                            {!isQuoteCalculating
                              ? <>
                                <Price amount={quotePrice.baseUnitAmount} currency={currency}/>/h
                               </>
                              : <TextPlaceholder width="6rem"/>
                            }
                          </s>
                        </div>
                        <span class="mt-2 badge bg-success">
                          {!isQuoteCalculating
                            ? <>
                              -<FormattedNumber
                                // eslint-disable-next-line react/style-prop-object
                                style="decimal"
                                unitDisplay="narrow"
                                maximumFractionDigits="0"
                                value={quotePrice.discountRate}
                              />%
                            </>
                            : <TextPlaceholder width="6rem"/>
                          }
                        </span>
                      </>
                    }
                  </div>

                  <div class="col pt-2">
                      <span class="fe fe-credit-card mr-2"></span>
                      <h6 class="text-uppercase mb-4">
                        <FormattedMessage id="components.projects.quote.QuoteTableFooterRowHours.price" defaultMessage="Price" />
                      </h6>
                      <hr class="text-muted"/>
                      <h2>
                        {!isQuoteCalculating
                          ? <>
                            <Price amount={quotePrice.totalAmount} currency={currency}/>
                          </>
                          : <TextPlaceholder width="6rem"/>
                        }
                      </h2>
                      {(quotePrice.discountRate || isQuoteCalculating) && <>
                        <div>
                          <s>
                            {!isQuoteCalculating
                              ? <>
                                <Price amount={quotePrice.originalTotalAmount} currency={currency}/>
                              </>
                              : <TextPlaceholder width="6rem"/>
                            }
                          </s>
                        </div>
                        <span class="mt-2 badge bg-success">
                          {!isQuoteCalculating
                            ? <>
                              <FormattedMessage
                                id="components.projects.quote.QuoteTableFooterRowsHours.totalAmount.label"
                                defaultMessage="Save {discountTotalAmount}"
                                values={{
                                  discountTotalAmount: <Price amount={quotePrice.discountTotalAmount} currency={currency}/>
                                }}
                              />
                            </>
                            : <TextPlaceholder width="6rem"/>
                          }
                        </span>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export const QuoteTable = () => {
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const project = useStoreState(state => state.project.item)
  const quote = useStoreState(state => state.quote)
  const [currency, setCurrency] = useState("USD")
  const { checkAllItems, uncheckAllItems, setItems, setMode } = useStoreActions(
    actions => actions.quote
  )
  const { calculateQuotePrice } = useStoreActions(actions => actions.billing)

  const { control, watch } = useFormContext()

  const { mode } = watch(["mode"])

  useEffect(() => {
    if (!mode) return
    setMode(mode.value)
  }, [setMode, mode])

  useEffect(() => {
    if (!project || !quote.mode) return
    setItems(project)
    checkAllItems()
  }, [quote.mode, project, checkAllItems, setItems])

  useEffect(() => {
    if (!quote.isServiceTypePro) return
    calculateQuotePrice({ organizationId: currentOrg.id, words: quote.words, currency })
  }, [
    calculateQuotePrice,
    quote.mode,
    quote.isServiceTypePro,
    quote.words,
    currentOrg.id,
    currency,
  ])

  const selectAllChange = e => {
    e.target.checked ? checkAllItems() : uncheckAllItems()
  }

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col align-self-center">
            <h3 className="m-0">
              <FormattedMessage
                id="components.projects.quote.contentSelector.label"
                defaultMessage="Content"
              />
            </h3>
          </div>
          <div className="col-auto">
            <ServiceModeSelect control={control} />
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th class="col-1">
              <input
                type="checkbox"
                onChange={e => selectAllChange(e)}
                checked={quote.areAllChecked}
              />
            </th>
            <th class="input-group">
              <FormattedMessage
                id="components.projects.quote.QuoteTable.language.label"
                defaultMessage="LANGUAGE"
              />
            </th>
            <th class="text-right">
              <FormattedMessage
                id="components.projects.quote.QuoteTable.words.label"
                defaultMessage="WORDS"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {quote.items.map(item => (
            <QuoteTableBodyRow key={item.id} item={item} />
          ))}
        </tbody>
        <tfoot class="table-group-divider h3">
          {quote.isServiceTypePro ? (
            <QuoteTableFooterRowHours currency={currency} setCurrency={setCurrency} />
          ) : (
            <QuoteTableFooterRowWords />
          )}
        </tfoot>
      </Table>
    </>
  )
}
