import React from "react"
import { Link } from "gatsby-plugin-intl"
import { Nav, Image } from "react-bootstrap"
import classNames from "classnames"
import Icon from "../Icon"

const NavLink = props => {
  const className = classNames("nav-link", "w-100", props.className)
  const img = props.img;

  const linkChildren = (
    <>
      {props.icon && <Icon className={props.iconClassname} name={props.icon} />}
      {props.children}
    </>
  )

  return (
    <Nav.Item className="d-flex w-100">
      {img && (
        <div className="d-flex position-absolute align-self-center ml-n2 rounded-sm">
          <Image className="nav-item__icon" src={img} />
        </div>
      )}
      {props.disabled ? (
        <div className={`${className} cursor--disabled`}>{linkChildren}</div>
      ) : (
        <Link to={props.to} activeClassName="active" className={className}>
          {linkChildren}
        </Link>
      )}
    </Nav.Item>
  )
}

export default NavLink
