import React, { useEffect, useState } from "react"
import Checkout from "../../../components/billing/Checkout"
import { ONBOARDING_ROOT, useOnboarding } from "../../Onboarding"
import { useLocation } from "@reach/router"
import { useStoreActions, useStoreState } from "easy-peasy"
import Spinner from "../../../components/common/Spinner"
import { API } from "aws-amplify"

const CheckoutPlan = ({ onboarding }) => {
  const location = useLocation()
  const { routeToNextStep } = useOnboarding();
  const { item: currentOrg, hasPaidSubscriptions } = useStoreState(state => state.currentOrg)
  const { fetchPaidSubscription } = useStoreActions(actions => actions.currentOrg)
  const [isLoading, setIsLoading] = useState(false)
  const redirectTo = onboarding?.vendor?.id
    ? `/en${ONBOARDING_ROOT}/schedule-onboarding`
    : location.href

  useEffect(() => {
    if (!currentOrg.id) return

    setIsLoading(true)
    const request = fetchPaidSubscription(currentOrg).then(() => setIsLoading(false))

    return () => {
      API.cancel(request)
    }
  }, [fetchPaidSubscription, currentOrg])

  useEffect(() => {
    if (!hasPaidSubscriptions || !onboarding.nextStage) return
    routeToNextStep()
  }, [onboarding.nextStage, hasPaidSubscriptions, routeToNextStep])

  if (isLoading || hasPaidSubscriptions) {
    return <div className="d-flex"><Spinner className="mx-auto"/></div>
  }

  return (
    <>
      <Checkout redirectTo={redirectTo} />
    </>
  )
}

export default CheckoutPlan