import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import ResourceModalForm from "./ModalForm"
import Icon from "../../Icon"
import ProjectResourcesUpdater from "../../../service/projectResourcesUpdater"

const EditResource = ({ project, resourceIndex }) => {
  const intl = useIntl()
  const projectLocales = project.locales.items
  const currentResource = project.resources[resourceIndex]

  if (!currentResource) {
    return null
  }

  const resources = currentResource.map(resource => {
    const projectLocale = projectLocales.find(projectLocale => projectLocale.id === resource.projectLocaleID)
    return {
      projectID: project.id,
      path: resource.path,
      projectLocale,
      resourceListIndex: resourceIndex,
    }
  })

  const Trigger = ({ onClick }) => (
    <Button className="dropdown-item justify-content-start" variant="text" onClick={onClick}>
      <Icon
        name="edit"
        title={intl.formatMessage({
          id: "components.projects.resource.edit.trigger.button",
          defaultMessage: "Edit",
        })}
      />
    </Button>
  )

  return (
    <ResourceModalForm
      formAction={ProjectResourcesUpdater.call}
      title={intl.formatMessage({
        id: "components.projects.resource.edit.modal.title",
        defaultMessage: "Edit Files",
      })}
      Trigger={Trigger}
      resources={resources}
    />
  )
}

export default EditResource
