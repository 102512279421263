import React from "react"
import { byName } from "../../utils/sort"
import { IntegrationType } from "./IntegrationType"

export const IntegrationTypeList = ({ integrationsTypes, onClick, action }) => {
  return (
    <ul className="page-section__content cards-grid">
      {integrationsTypes.sort(byName).map(integrationType => (
        <IntegrationType
          integrationType={integrationType}
          onClick={onClick}
          action={action}
          key={integrationType.id}
        />
      ))}
    </ul>
  )
}
