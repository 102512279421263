import React, { useEffect, useState } from "react"
import { action, useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

import OrgUpdator from "../../service/orgUpdator"
import PageContainer from "../../components/layout/PageContainer"
import Form from "../../components/forms/Form"
import { fieldRequired } from "../../utils/validations"
import { STATUSES } from "../../components/layout/FormContainer"
import Spinner from "../../components/common/Spinner"
import FormFields from "../../components/forms/FormFields"
import ProjectList from "./ProjectList"
import ProjectUpdater from "../../service/projectUpdater"
import Switch from "../../components/forms/Switch"
import { ORGANIZATION_STATUSES } from "../../models/organization"
import Toast from "../../components/Toast"

const DEFAULT_INTEGRATION_ID = "default"

const AccountEdit = ({ id }) => {
  const intl = useIntl()
  const [showToast, setShowToast] = useState(false)
  const { integrations, defaultIntegration } = useStoreState(
    state => state.currentVendor
  )
  const { handleSubmit, control, errors, setValue } = useForm()
  const account = useStoreState(state => state.clients.byID(id))
  const [accountStatus, setAccountStatus] = useState()
  const [status, setStatus] = useState(null)
  const [isAccountNameAutoUpdate, setIsAccountNameAutoUpdate] = useState(false)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const bmsAccounts = useStoreState(state => state.bms.items)
  const updateStatus = useStoreActions(actions => actions.currentOrg.updateStatus)
  const fetchProjectTemplates = useStoreActions(
    actions => actions.projectTemplates.fetch
  )
  const fetchBMSAccounts = useStoreActions(
    actions => actions.bms.fetch,
  )
  const [isLoading, setIsloading] = useState(false)
  const [isBMSloading, setIsBMSloading] = useState(false)
  const { bms, tms, hybrid } = integrations.reduce((acc, integration) => {
    const { id, name, integrationType: { type } } = integration
    if (defaultIntegration.id === id) {
      acc[type].push({
        id: DEFAULT_INTEGRATION_ID,
        name: `Default (${name})`,
        integration,
      })
    }
    acc[type].push({ id, integration, name })
    return acc
  }, { bms: [], tms: [], hybrid: [] })

  const bmsOptions = bms.concat(hybrid);
  const tmsOptions = tms.concat(hybrid);

  const updateAccountStatus = async () => {
    const status = accountStatus !== ORGANIZATION_STATUSES.SUSPENDED
      ? ORGANIZATION_STATUSES.SUSPENDED
      : ORGANIZATION_STATUSES.ACTIVE

    setAccountStatus(status)
    updateStatus({ id, status })
    setShowToast(true)
  }

  const currentIntegration = tmsOptions.find(
    integration =>
      integration.id === (account.integration?.id || DEFAULT_INTEGRATION_ID)
  )

  const currentbmsIntegration = bmsOptions.find((integration) => (
    account.bmsIntegration?.id === integration.id
  ))

  const currentSelectedIntegration = useWatch({
    control,
    name: "organization[integration]",
  })

  const currentSelectedAccountIntegration = useWatch({
    control,
    name: "organization[bmsIntegration]",
  })

  const currentSelectedBMSAccount = useWatch({
    control,
    name: "organization[bmsAccount]",
  })

  const infoFields = [
    {
      name: "organization[id]",
      type: "hidden",
      defaultValue: account.id,
    },
    {
      name: "organization[name]",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.vendor.accountEdit.fields.name.label",
        defaultMessage: "Name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: account.name,
    },
  ]

  const tmsFields = [
    {
      name: "organization[integration]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountEdit.fields.integration.label"
          defaultMessage="Integration"
        />
      ),
      options: tmsOptions,
      defaultValue: currentIntegration,
      validations: { ...fieldRequired },
    },
    ...(currentSelectedIntegration?.integration?.integrationType.configuration
      ?.hasIntegrationTemplate && currentSelectedIntegration.id !== DEFAULT_INTEGRATION_ID
      ? [
          {
            name: "organization[integrationTemplate]",
            type: "select",
            label: (
              <FormattedMessage
                id="containers.admin.accountDetails.field.templateLabel"
                defaultMessage="Select Template"
              />
            ),
            isClearable: true,
            isLoading,
            options: projectTemplates,
            validations: {
              ...(currentSelectedIntegration?.integration?.integrationType
                .configuration?.isIntegrationTemplateRequired
                ? fieldRequired
                : {}),
            },
          },
        ]
      : []),
  ]

  const bmsFields = !bmsOptions.length ? [] : [
    {
      name: "organization[bmsIntegration]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountEdit.fields.integration.label"
          defaultMessage="Integration"
        />
      ),
      options: bmsOptions,
      defaultValue: currentbmsIntegration,
      validations: { ...fieldRequired }
    },
    {
      name: "organization[bmsAccount]",
      type: "select",
      label: (
        <FormattedMessage
          id="containers.admin.accountDetails.field.accountManagementIntegration"
          defaultMessage={`${currentSelectedAccountIntegration?.integration?.name || "Integration"} Account`}
        />
      ),
      isClearable: true,
      isLoading: isBMSloading,
      options: bmsAccounts,
    },
  ];

  useEffect(() => {
    if (!currentSelectedIntegration) {
      return setValue("organization[integration]", currentIntegration)
    }
    setIsloading(true)
    setValue("organization[integrationTemplate]", null)
    fetchProjectTemplates(currentSelectedIntegration.integration).then(() =>
      setIsloading(false)
    )
  }, [currentSelectedIntegration, fetchProjectTemplates, setValue])

  useEffect(() => {
    setAccountStatus(account.status)
  }, [account, setAccountStatus])

  useEffect(() => {
    if (!currentSelectedAccountIntegration) {
      return setValue("organization[bmsIntegration]", currentbmsIntegration)
    }
  }, [
    currentbmsIntegration,
    currentSelectedAccountIntegration,
    setValue
  ])

  useEffect(() => {
    if (!currentSelectedAccountIntegration) return
    setIsBMSloading(true)
    setIsAccountNameAutoUpdate(false)
    fetchBMSAccounts(currentSelectedAccountIntegration.integration).then(() => {
      setIsBMSloading(false)
      setIsAccountNameAutoUpdate(true)
    })
  }, [currentSelectedAccountIntegration, setValue])

  useEffect(() => {
    if (!projectTemplates.length || !account.integrationTemplateID) {
      return
    }

    setValue(
      "organization[integrationTemplate]",
      projectTemplates.find(
        template => template.id === account.integrationTemplateID
      )
    )
  }, [projectTemplates, setValue, account])

  useEffect(() => {
    if (!bmsAccounts.length || !account.bmsIntegrationID) {
      return
    }
    setValue(
      "organization[bmsAccount]",
      bmsAccounts.find(
        bmsAccount => bmsAccount.id.toString() === account.bmsAccountID
      )
    )
  }, [bmsAccounts, setValue, account])

  useEffect(() => {
    if(!currentSelectedBMSAccount?.name || !isAccountNameAutoUpdate) return
    setValue("organization[name]", currentSelectedBMSAccount?.name)
  }, [currentSelectedBMSAccount, setValue])

  const onSubmit = async ({ organization, projects }) => {
    setStatus(STATUSES.loading)
    organization.integrationID = organization.integration.id
    delete organization.integration
    if (organization.integrationID === DEFAULT_INTEGRATION_ID) organization.integrationID = null
    organization.integrationTemplateID = organization.integrationTemplate?.id || null;
    organization.bmsIntegrationID = organization.bmsIntegration?.id || null
    organization.bmsAccountID = organization.bmsAccount?.id || null
    delete organization.bmsAccount
    delete organization.integrationTemplate
    delete organization.bmsIntegration
    projects = Object.entries(projects || {});
    if (projects.length) {
      await Promise.all(projects.map(([id, project]) => (
        ProjectUpdater.call({ id, integrationTemplateID: project?.integrationTemplate?.id || null })
      )));
    }

    await OrgUpdator.call(organization)
    setStatus(STATUSES.success)
    setShowToast(true)
  }

  return (
    <PageContainer className="d-flex flex-column">
      <Toast show={showToast} setShow={setShowToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.vendor.accountEdit.toast"
            defaultMessage="Account has been updated."
          />
        </p>
      </Toast>
      <div className="row">
        <h1 className="d-flex align-items-center main-heading mb-4">
          <span className="text-gray-500">
            <FormattedMessage
              id="containers.admin.accountDetails.accounts"
              defaultMessage="Accounts"
            />
          </span>
          <span className="d-block mx-  2 text-base text-gray-400">/</span>
          <span className="text-gray-800">{account.name}</span>
        </h1>

        <label className="row ml-auto text-capitalize text-right pr-3">
          <span className="pr-2">{accountStatus}</span>
          <Switch
            className="ml-auto"
            id={`account-edit-switch`}
            isChecked={accountStatus !== ORGANIZATION_STATUSES.SUSPENDED}
            onChange={updateAccountStatus}
          />
        </label>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="card-header-title mb-2">Account</h2>
        <div className="card mb-4">
          <div className="card-body w-40">
            <FormFields fields={bmsFields} errors={errors} control={control} />
            <FormFields fields={infoFields} errors={errors} control={control} />
          </div>
        </div>
        <h2 className="card-header-title mb-2">Translation Management</h2>
        <div className="card mb-4">
          <div className="card-body w-40">
            <FormFields fields={tmsFields} errors={errors} control={control} />
          </div>
        </div>

        <h2 className="card-header-title mb-2">Projects</h2>
        <div className="card mb-4">
          <ProjectList
            control={control}
            errors={errors}
            hasTemplates={currentSelectedIntegration?.integration?.integrationType.configuration?.hasIntegrationTemplate}
            setValue={setValue}
            organizationID={account.id}
            projectTemplates={projectTemplates}
          />
        </div>


        <Button type="submit" className="btn primary">
          {status === STATUSES.loading ? (
            <Spinner />
          ) : (
            <FormattedMessage
              id="containers.admin.accountDetails.save"
              defaultMessage="Save"
            />
          )}
        </Button>
      </Form>
    </PageContainer>
  )
}

export default AccountEdit
