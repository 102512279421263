import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreState, useStoreActions } from "easy-peasy"
import PageContainer from "../../components/layout/PageContainer"
import TeamMembers from "../../components/vendor/team/TeamMembers"
import TeamInviteForm from "../../components/vendor/team/TeamInviteForm"

const Team = () => {
  const fetchTeamMembers = useStoreActions(actions => actions.vendorTeam.fetch)
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const teamMembers = useStoreState(state => state.vendorTeam.item)
  const isFetchingTeamMembers = useStoreState(state => state.vendorTeam.loading)
  const handleFetchMembers = () => {
    fetchTeamMembers({
      vendorID: currentVendor.id,
      ownerID: currentVendor.owner,
    })
  }
  useEffect(() => {
    handleFetchMembers()
  }, [])

  return (
    <PageContainer>
      <h1 className="d-flex align-items-center main-heading mb-4">
        <FormattedMessage
          id="containers.settings.team.titleAffix"
          defaultMessage="Team"
        />
      </h1>

      <p className="w-50 text-sm text-gray-700 font-normal mb-5">
        <FormattedMessage
          id="containers.settings.team.subTitle"
          defaultMessage="Manage who has full access to this account."
        />
      </p>

      <TeamInviteForm onSubmit={handleFetchMembers} />
      <TeamMembers
        onRemove={handleFetchMembers}
        members={teamMembers}
        isLoading={isFetchingTeamMembers}
      />
    </PageContainer>
  )
}

export default Team
