import React from "react"
import { Card, ListGroup } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import Form from "../../forms/Form"
import FormFields from "../../forms/FormFields"

const ResourcesForm = ({
  hiddenFields,
  sourceFields,
  targetFields,
  onSubmit,
  control,
  errors,
}) => {

  return (
    <Form onSubmit={onSubmit}>
      <FormFields fields={hiddenFields} errors={errors} control={control} />
      <Card className="mb-0 border-0 shadow-none">
        <Card.Body className="mr-n4 p-0 pr-4">
          <Card.Title as="h2">
            <FormattedMessage
              id="components.projects.resource.resourceForm.sourceTitle"
              defaultMessage="Source File"
            />
          </Card.Title>
          <Card.Text>
            <FormattedMessage
              id="components.projects.resource.resourceForm.sourceDescription"
              defaultMessage="We will check for changes in this file"
            />
          </Card.Text>
          <ListGroup variant="flush">
            <ListGroup.Item className="pt-0">
              <FormFields fields={sourceFields} errors={errors} control={control} />
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
        <Card.Body className="mr-n4 p-0 pt-4 pr-4 border-t border-gray-200">
          <Card.Title as="h2">
            <FormattedMessage
              id="components.projects.resource.resourceForm.targetTitle"
              defaultMessage="Target Files"
            />
          </Card.Title>
          <Card.Text>
            <FormattedMessage
              id="components.projects.resource.resourceForm.targetDescription"
              defaultMessage="We will provide translations into these files"
            />
          </Card.Text>
          <ListGroup variant="flush">
            <ListGroup.Item className="py-0">
              <FormFields fields={targetFields} errors={errors} control={control} />
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Form>
  )
}

export default ResourcesForm
