import React, { useState, useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Button } from "react-bootstrap"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Icon from "../../Icon"
import { fieldRequired } from "../../../utils/validations"
import ModalForm from "../../forms/ModalForm"
import { STATUSES } from "../../layout/FormContainer"
import { localeOptionsForSelect } from "../../../helpers/locale"
import { triggerQuote } from "../../../helpers/instances"
import ProjectLocaleCreator from "../../../service/projectLocaleCreator"

const ProjectLocaleAdd = ({ projectLocale }) => {
  const intl = useIntl()
  const fetchLocales = useStoreActions(actions => actions.locales.fetch)
  const locales = useStoreState(state => state.locales.availableForProject(projectLocale.projectID))
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [status, setStatus] = useState()
  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })
  const localeSelect = useWatch({
    control,
    name: "projectLocale[locale]"
  })

  useEffect(() => {
    fetchLocales()
  }, [])

  useEffect(() => {
    if (localeSelect) {
      setValue("projectLocale[code]", localeSelect.tag)
    }
  }, [localeSelect])

  const onSubmit = async payload => {
    setStatus(STATUSES.loading)
    const { locale, ...projectLocale } = payload.projectLocale
    projectLocale.localeID = locale.id
    projectLocale.owner = currentOrg.owner
    projectLocale.organizationID = currentOrg.id
    projectLocale.groups = [currentOrg.id]
    if (currentOrg.vendorID) projectLocale.groups.push(currentOrg.vendorID)
    const projectLocaleData = await ProjectLocaleCreator.call(projectLocale)
    if (projectLocaleData) {
      setStatus(STATUSES.success)
      triggerQuote({ projectID: projectLocale.projectID, projectLocaleIDs: [projectLocaleData.id] })
    } else setStatus(STATUSES.error)
  }

  const fields = [
    {
      name: "projectLocale[projectID]",
      type: "hidden",
      defaultValue: projectLocale.projectID
    },
    {
      name: "projectLocale[isSource]",
      type: "hidden",
      defaultValue: projectLocale.isSource
    },
    {
      name: "projectLocale[locale]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.locale.add.locale.label",
        defaultMessage: "Language",
      })}`,
      options: localeOptionsForSelect(locales, intl),
      validations: { ...fieldRequired }
    },
    {
      name: "projectLocale[code]",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.projects.locale.add.code.label",
        defaultMessage: "Code",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: projectLocale.code || "",
    },
  ]

  const Trigger = ({ onClick }) => (
    <button
      className="d-flex align-items-center font-semibold text-sm text-gray-600 btn bg-gray-100 border-gray-300 py-1 px-3"
      onClick={onClick}
    >
      <Icon name="plus-circle" />
      <span className="mt-1px">
        <FormattedMessage
          id="components.projects.locale.add.trigger.button"
          defaultMessage="Add new language"
        />
      </span>
    </button>
  )

  return (
    <ModalForm
      control={control}
      errors={errors}
      fields={fields}
      status={status}
      setStatus={setStatus}
      onSubmit={handleSubmit(onSubmit)}
      title={intl.formatMessage({
        id: "components.projects.locale.add.modal.title",
        defaultMessage: "Add a language",
      })}
      Trigger={Trigger}
    />
  )
}

export default ProjectLocaleAdd
