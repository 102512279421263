import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button, ButtonGroup } from "react-bootstrap"
import { SERVICE_TYPES } from "../../models/service"

export const ProductFamilySwitcher = ({ familyId, setFamilyId }) => {
  return (
    <ButtonGroup>
      <Button
        onClick={() => setFamilyId(SERVICE_TYPES.LOCALE_AI)}
        variant={familyId === SERVICE_TYPES.LOCALE_AI ? "secondary" : "light"}
      >
        <FormattedMessage
          id="components.billing.productFamilySwitcher.localeAI"
          defaultMessage="Locale AI"
        />
      </Button>

      <Button
        onClick={() => setFamilyId(SERVICE_TYPES.CONNECTOR_CLOUD)}
        variant={ familyId === SERVICE_TYPES.CONNECTOR_CLOUD ? "secondary" : "light"}
      >
        <FormattedMessage
          id="components.billing.productFamilySwitcher.localeTeam"
          defaultMessage="Locale Team"
        />
      </Button>
    </ButtonGroup>
  )
}
