import React, { useEffect, useState } from "react"
import { Tab, Nav, Button } from "react-bootstrap"
import Icon from "../../Icon"
import { QuoteTabPane } from "../quote/QuoteTabPane"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { CardSelect } from "../../billing/CardSelect"
import { CardTabPane } from "../../billing/CardTabPane"
import { FormProvider, useForm } from "react-hook-form"
import Spinner from "../../common/Spinner"
import OrderSummary from "./OrderSummary"

const TABS = {
  QUOTE: "quote",
  CARD: "card",
  SUMMARY: "summary",
}

export const ProNavLink = ({ eventKey }) => (
  <Nav.Item className="ml-1 flex-fill">
    <Nav.Link eventKey={eventKey}>
      <h2 className="m-0">
        <Icon name="users" title="Pro" className="mr-2" />
      </h2>
    </Nav.Link>
  </Nav.Item>
)

export const ProTabPane = ({ eventKey, setShowModal }) => {
  const [activeKey, setActiveKey] = useState(TABS.QUOTE)
  const { triggerProInstance } = useStoreActions(actions => actions.project)
  const quote = useStoreState(state => state.quote)
  const { addCreditCard, createCustomer } = useStoreActions(
    actions => actions.billing
  )
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [instanceWordCount, setInstanceWordCount] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const { quotePrice } = useStoreState(state => state.billing)

  const form = useForm()

  const registerCard = async ({ cardToken, items, mode, card }) => {
    setIsSubmitting(true)
    let paymentSourceID
    if (!currentOrg.customerID)
      await createCustomer({ organizationId: currentOrg.id })
    if (cardToken) {
      paymentSourceID = (
        await addCreditCard({
          organizationId: currentOrg.id,
          tokenId: cardToken,
        })
      )?.id
    } else {
      paymentSourceID = card.payment_source.id
    }
    await triggerProInstance({
      mode: mode.value,
      projectLocaleIDs: items,
      paymentSourceID,
    })
    setInstanceWordCount(quote.words);
    setTotalCost(quotePrice);
    setActiveKey(TABS.SUMMARY)
    setIsSubmitting(false)
  }

  useEffect(() => {
    setIsSubmitDisabled( isSubmitting || quote.words === 0)
  }, [quote.words, isSubmitting])

  return (
    <Tab.Pane eventKey={eventKey}>
      <Tab.Container activeKey={activeKey}>
        <Tab.Content>
          <FormProvider {...form}>
            <QuoteTabPane
              eventKey={TABS.QUOTE}
              onSubmit={async ({ items, mode, card }) => {
                setIsSubmitting(true)
                if (!card) {
                  setActiveKey(TABS.CARD)
                } else {
                  await triggerProInstance({
                    mode: mode.value,
                    projectLocaleIDs: items,
                    paymentSourceID: card.payment_source.id,
                  })
                  setInstanceWordCount(quote.words);
                  setTotalCost(quotePrice);
                  setActiveKey(TABS.SUMMARY)
                }
                setIsSubmitting(false)
              }}
            >
              <div className="custom-modal-footer">
                <CardSelect
                  control={form.control}
                  setValue={form.setValue}
                  onAddNewCard={() => setActiveKey(TABS.CARD)}
                />
                <Button
                  type="submit"
                  className="mt-4 w-100"
                  disabled={isSubmitDisabled}
                >
                  { isSubmitting ? <Spinner/> :
                    <FormattedMessage
                      id="components.projects.serviceTypes.ProTab.translateButton"
                      defaultMessage="Pay & Translate"
                    />
                  }
                </Button>
              </div>
            </QuoteTabPane>
            <Tab.Pane eventKey={TABS.CARD}>
              <form>
                <CardTabPane
                  back={() => setActiveKey(TABS.QUOTE)}
                  onPay={async () => {
                    await form.handleSubmit(registerCard)()
                  }}
                />
              </form>
            </Tab.Pane>
          </FormProvider>
          <Tab.Pane eventKey={TABS.SUMMARY}>
            <OrderSummary serviceType={"PRO"} totalWords={instanceWordCount} totalCost={totalCost} onBack={() => { setActiveKey(TABS.QUOTE)}} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  )
}
