import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import InfoModal from "../../layout/modal/InfoModal"
import { localeName } from "../../../helpers/locale"
import { Button } from "react-bootstrap"
import Icon from "../../Icon"
import ProjectLocaleEdit from "./Edit"

const ProjectLocaleSettings = ({ projectLocale }) => {
  const intl = useIntl()
  const projectLocaleName = localeName(projectLocale.locale, intl)
  const [status, setStatus] = useState()

  const Trigger = ({ onClick }) => (
    <Button className="dropdown-item justify-content-start" variant="text" onClick={onClick}>
      <Icon
        name="settings"
        title={intl.formatMessage({
          id: "components.project.locale.settings.trigger.button",
          defaultMessage: "Settings",
        })}
      />
    </Button>
  )

  return (
    <InfoModal
      Trigger={Trigger}
      subtitle={intl.formatMessage({
        id: "components.project.locale.settings.subtitle",
        defaultMessage: "Change the settings for",
      })}
      title={projectLocaleName}
      status={status}
      setStatus={setStatus}
    >
      <ProjectLocaleEdit
        projectLocale={projectLocale}
        status={status}
        setStatus={setStatus}
      />
    </InfoModal>
  )
}

export default ProjectLocaleSettings
