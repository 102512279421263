import React, { useEffect } from "react"
import Select from "react-select"
import { CURRENCY } from "../../models/billing"
import { useController } from "react-hook-form"

const options = [
  { label: `${CURRENCY.USD} $`, value: CURRENCY.USD },
  { label: `${CURRENCY.EUR} €`, value: CURRENCY.EUR },
  { label: `${CURRENCY.GBP} £`, value: CURRENCY.GBP },
]

export const CurrencySelect = ({ control, setValue, defaultCurrency }) => {
  useEffect(() => {
    if (!defaultCurrency) return
    setValue("currency", options.find(({ value }) => value === defaultCurrency))
  }, [defaultCurrency])

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "currency",
    control,
    defaultValue: options[0],
  })

  return (
    <Select
      {...inputProps}
      inputRef={ref}
      className="form-control form-control-flush p-0"
      options={options}
      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        control: base => ({
          ...base,
          minWidth: "100px",
          borderStyle: "none",
        }),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#00D792",
        },
      })}
    />
  )
}
