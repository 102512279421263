import React from "react"
import Icon from "./Icon"

export const ExternalLink = ({ href, name }) => (
  <a target="_blank" rel="noreferrer" href={href}>
    {name}
    <Icon name="external-link" className="ml-2" />
  </a>
)

export default ExternalLink
