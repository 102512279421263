import React from "react"

const Source = ({ className = "", isSmall }) => (
  <div
    className={`border border-2 rounded-circle border-gray-300 p-1 ${
      isSmall ? "square-1_3" : "square-1_5"
    } ${className}`}
  >
    <div className="rounded-circle bg-gray-300 w-100 h-100" />
  </div>
)

export default Source
