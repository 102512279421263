import store from "../store/index"

export default class OrgUpdater {
  constructor(org) {
    this._org = org
  }

  static async call(args) {
    return new OrgUpdater(args).call()
  }

  async call() {
    try {
      await this.updateOrg()
      return true
    } catch (err) {
      return false
    }
  }

  updateOrg() {
    return store.getActions().currentOrg.update(this._org)
  }
}
