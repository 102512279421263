import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Table, Button } from "react-bootstrap"
import Icon from "../../Icon"
import Price from "../../billing/Price"

export const OrderSummary = ({ serviceType, totalWords, totalCost, onBack }) => {
  return (
    <>
      <div className="card card-body p-5">
        <div className="row">
          <div className="col text-center">
            <h3 className="mb-4">
              <FormattedMessage
                id="components.projects.serviceTypes.orderSummary.header"
                defaultMessage="Your content is pushed for {serviceType} translation 🚀"
                values={{ serviceType }}
              />
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <Table>
                <tbody>
                  <tr>
                    <td className="px-0">
                      <FormattedMessage
                        id="components.projects.serviceTypes.orderSummary.totalWords.label"
                        defaultMessage="Total words"
                      />
                    </td>
                    <td className="px-0 text-end">
                      <FormattedMessage
                        id="components.projects.serviceTypes.orderSummary.totalWords.value"
                        defaultMessage="{totalWords, number, ::compact-short}"
                        values={{ totalWords }}
                      />
                    </td>
                  </tr>
                  {totalCost ? (
                    <tr>
                      <td className="px-0">
                        <FormattedMessage
                          id="components.projects.serviceTypes.orderSummary.totalCost.label"
                          defaultMessage="Total cost"
                        />
                      </td>
                      <td className="px-0 text-end">
                        <Price amount={totalCost?.originalTotalAmount} currency={totalCost?.currency}/>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td className="px-0">
                      <FormattedMessage
                        id="components.projects.serviceTypes.orderSummary.serviceTypeUsed"
                        defaultMessage="Service used"
                      />
                    </td>
                    <td className="px-0 text-end">{serviceType}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-modal-footer">
        <Button variant="link" onClick={() => onBack()}>
          <Icon name="arrow-left" className="mr-2" />
          <FormattedMessage
            id="src.components.projects.serviceTypes.orderSummary.backButton"
            defaultMessage="Back"
          />
        </Button>
      </div>
    </>
  )
}

export default OrderSummary
