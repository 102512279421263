import store from "../store/index"
import ProjectResourcesUpdater from "./projectResourcesUpdater"

export default class ProjectLocaleResourcesDestroyer {
  constructor(projectLocale) {
    this._projectLocale = projectLocale
  }

  static async call(args) {
    return new ProjectLocaleResourcesDestroyer(args).call()
  }

  async call() {
    try {
      await ProjectResourcesUpdater.call({
        projectID: this.projectID(),
        resources: this.resources(),
      })
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  projectID() {
    return this.projectLocale().projectID
  }

  projectLocale() {
    return this._projectLocale
  }

  projectResources() {
    return store.getState().projects.byId(this.projectID()).resources
  }

  resources() {
    return this.projectResources().map(resources =>
      resources.filter(
        resource => resource.projectLocaleID !== this.projectLocale().id
      )
    )
  }
}
