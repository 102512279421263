import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useOnboarding } from "../../Onboarding"
import PlanList from "../../../components/billing/Plans"

const Plans = ({ onboarding, familyId, frequency }) => {
  const { routeToBackStep, routeToNextStep, hasPreviousStep } = useOnboarding()

  const onClickPlan = (item_price_id) => {
    routeToNextStep({ item_price_id })
  }

  return (
    <div className="onboarding-form d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.profile.h2"
            defaultMessage="Select your plan"
          />
        </h1>
        <PlanList familyId={familyId} frequency={frequency} onClickPlan={onClickPlan} showRecommended/>
      </div>
      <div>
        <div className="w-50">
          <div className="d-flex">
            {hasPreviousStep && (
              <button
                className="btn btn-outline--secondary mr-5"
                onClick={() => routeToBackStep()}
              >
                <FormattedMessage
                  id="containers.onboarding.profile.backBtn"
                  defaultMessage="Back"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plans
