import store from "../store/index"

export default class ProjectUpdater {
  constructor(project) {
    this._project = project
  }

  static async call(args) {
    return new ProjectUpdater(args).call()
  }

  async call() {
    try {
      await this.updateProject()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  updateProject() {
    return store.getActions().projects.update(this.project())
  }

  project() {
    const { connector, integration, integrationTemplate, ...project } = this._project
    if (connector) {
      project.connector = {
        ...connector,
        branch: connector.branch?.name,
      }
    }

    if (integration) {
      project.integrationID = integration.id
    }

    if (integrationTemplate) {
      project.integrationTemplateID = integrationTemplate.id
    }

    return project
  }
}
