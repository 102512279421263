import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import { useController } from "react-hook-form"
import Select from "react-select"
import Icon from "../Icon"
import { formatCreditCardBrand } from "../../helpers/format"

export const CardSelect = ({ control, setValue, onAddNewCard }) => {
  const fetchCreditCards = useStoreActions(
    actions => actions.billing.fetchCreditCards
  )
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { creditCards } = useStoreState(state => state.billing)

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "card",
    control,
    defaultValue: creditCards[0],
  })

  useEffect(() => {
    if (!currentOrg.customerID) return
    fetchCreditCards({ organizationId: currentOrg.id })
  }, [currentOrg, fetchCreditCards])

  useEffect(() => {
    if (creditCards.length === 0) return
    setValue("card", creditCards[0])
  }, [creditCards, setValue])

  if (creditCards.length === 0) return null

  return (
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center mb-2 justify-content-between">
          <div class="col-auto">
            <h5 class="mb-0">
              <FormattedMessage
                id="components.billing.CardSelect.title"
                defaultMessage="Payment method"
              />
            </h5>
          </div>
          <div class="col-auto">
            <Button
              variant="link"
              className="p-0"
              size="sm"
              onClick={onAddNewCard}
            >
              <Icon name="plus" className="mr-2" />
              <FormattedMessage
                id="components.billing.CardSelect.addNew"
                defaultMessage="Add new"
              />
            </Button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group">
              <Select
                {...inputProps}
                inputRef={ref}
                className="form-control p-0"
                options={creditCards}
                getOptionLabel={option =>
                  `${formatCreditCardBrand(option.payment_source.card.brand)} *** *** *** *** ${
                    option.payment_source.card.last4
                  }`
                }
                getOptionValue={option => option.payment_source.id}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#00D792",
                  },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
