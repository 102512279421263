import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import ProjectLocaleRow from "./locale/Row"
import AddLocale from "./locale/Add"
import { byCode } from "../../utils/sort"
import { isDocumentTypeConnector } from "../../helpers/connector"

const ProjectLocales = ({ project }) => {
  const projectLocales = project.locales.items || []

  const source = projectLocales.find(locale => !!locale.isSource)
  const targets = projectLocales.filter(locale => !locale.isSource).sort(byCode)
  const newProjectLocale = {
    isSource: false,
    projectID: project.id,
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between pl-6 py-3 bg-gray-0 w-full">
          <div className="d-flex align-items-center">
            {targets.length > 0 && (
              <h2 className="text-gray-800 font-weight-bolder letter-spacing_0_03 mb-0 mr-4">
                <FormattedMessage
                  id="components.projects.language.itemlabel"
                  defaultMessage="{languages, plural, one {{languages} language} other {{languages} languages}}"
                  values={{ languages: projectLocales.length }}
                />
              </h2>
            )}
            {(!isDocumentTypeConnector(project) || ( isDocumentTypeConnector(project) && !project.atleastOneCompleted && !project.atLeastOneInProgress )) && (
              <AddLocale projectLocale={newProjectLocale} />
            )}
          </div>
        </div>
        <ul className="clear-list">
          {source && (
            <ProjectLocaleRow projectLocale={source} key={source.id} />
          )}
          {targets.map(target => (
            <ProjectLocaleRow projectLocale={target} key={target.id} />
          ))}
        </ul>
      </div>
    </>
  )
}

export default ProjectLocales
