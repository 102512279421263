import React, { useEffect, useState } from "react"
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper"
import waitForGlobal from "../../utils/waitForGlobal"
import { useController } from "react-hook-form"

const chargebeeProps = {
  classes: {
    focus: "focus",
    invalid: "invalid",
    empty: "empty",
    complete: "complete",
  },
  styles: {
    base: {
      color: "#12263f",
      fontWeight: "400",
      fontFamily: "Cerebri Sans, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      color: "#e63757",
    },
  },
}

export const CardFields = ({ cardRef, control }) => {
  const [isChargebeeLoaded, setChargebeeLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const { field } = useController({ name: "cardToken", control })

  useEffect(() => {
    waitForGlobal("Chargebee", () => {
      window.Chargebee.init({
        isItemsModel: true,
        site: process.env.CHARGEBEE_SITE,
        publishableKey: process.env.CHARGEBEE_PUBLISHABLE_KEY,
      })
      setChargebeeLoaded(true)
    })
  }, [])

  return (
    <>
      <input type="hidden" {...field} />
      {isChargebeeLoaded && (
        <CardComponent
          ref={cardRef}
          className="fieldset field"
          onChange={event => setErrorMessage(event.error?.message)}
          {...chargebeeProps}
        />
      )}
      {errorMessage && (
        <div className="text-danger" role="alert">
          {errorMessage}
        </div>
      )}
    </>
  )
}
