import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const placeholderPropTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.numer]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.numer]),
  color: PropTypes.string,
  light: PropTypes.bool,
  shadeLevel: PropTypes.oneOf([1, 2, 3]),
  delay: PropTypes.number,
};

const darkShades = [
  '#D1D5DB',
  '#9CA3AF',
  '#6B7280',
];

const lightShades = [
  '#374151',
  '#4B5563',
  '#6B7280',
];

const animationProps = {
  color: 'rgba(0, 0, 0, 0)',
  animationDuration: '1700ms',
  animationTiming: 'cubic-bezier(0.4, 0, 0.6, 1)',
  animationName: 'pulse',
  animationIterationCount: 'infinite',
};

const getDelayInMs = delay => +delay * 190;

export const TextPlaceholder = ({
  height,
  width,
  color,
  darkBackground,
  style,
  shadeLevel,
  delay,
  ...props
}) => {
  const [enableAnimation, setEnableAnimation] = useState(delay === 0);

  useEffect(() => {
    if (delay > 0) {
      setTimeout(() => {
        setEnableAnimation(true);
      }, getDelayInMs(delay));
    }
  }, [delay]);

  const divStyle = {
    height,
    width,
    backgroundColor: color,
    borderRadius: '3px',
    ...animationProps,
    ...style,
  };

  if (!enableAnimation) {
    delete divStyle.animationName;
  }

  if (!divStyle.backgroundColor) {
    divStyle.backgroundColor = darkBackground
      ? lightShades[shadeLevel - 1]
      : darkShades[shadeLevel - 1];
  }
  return (
    <div style={divStyle} {...props}>
      &nbsp;
    </div>
  );
};

TextPlaceholder.propTypes = placeholderPropTypes;

TextPlaceholder.defaultProps = {
  height: undefined,
  color: undefined,
  width: '100%',
  darkBackground: false,
  shadeLevel: 1,
  style: {},
  delay: 0,
};

export const SquarePlaceholder = ({
  size,
  ...rest
}) => {
  const props = {
    height: size,
    width: size,
    ...rest
  };

  return <TextPlaceholder {...props} />;
};

SquarePlaceholder.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...placeholderPropTypes,
};

SquarePlaceholder.defaultProps = {
  size: "1rem",
};

export const CirclePlaceholder = ({
  size,
  style,
  ...rest
}) => {
  const props = {
    height: size,
    width: size,
    style: {
      borderRadius: '100%',
      ...style,
    },
    ...rest
  };

  return <TextPlaceholder {...props} />;
};

CirclePlaceholder.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...placeholderPropTypes,
};

CirclePlaceholder.defaultProps = {
  size: "1rem",
};

export default TextPlaceholder;
