import React, { useState } from "react";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";

const TargetRow = ({
  open,
  resource,
  resourceListIndex,
}) => (
  <Collapse
    in={open}
    timeout={0}
    key={`resource-${resourceListIndex}-locale-${resource.projectLocaleID}`}
  >
    <tr>
      <td />
      <td className="align-top">
        <h4 className="mb-0 text-secondary text-nowrap">{resource.locale.code}</h4>
      </td>
      <td className="align-top">{resource.path}</td>
      <td />
    </tr>
  </Collapse>
);

TargetRow.propTypes = {
  resourceListIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  resource: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default TargetRow;
