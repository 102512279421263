import React, { useEffect, useState } from "react"
import { Tab, Nav, Button } from "react-bootstrap"
import Icon from "../../Icon"
import { QuoteTabPane } from "../quote/QuoteTabPane"
import { PlanSubscriptionWizard } from "../../billing/PlanSubscriptionWizard"
import { useStoreActions, useStoreState } from "easy-peasy"
import { SERVICE_TYPES } from "../../../models/service"
import { FormattedMessage } from "gatsby-plugin-intl"
import CreditsUsage from "../../CreditsUsage"
import { FormProvider, useForm } from "react-hook-form"
import Spinner from "../../common/Spinner"
import Toast from "../../Toast"
import OrderSummary from "./OrderSummary"
import { useChargebeeEntitlements } from "../../../helpers/chargebee"
import { AVAILABLE_FEATURES } from "../../../store/models/billing"
const TABS = {
  QUOTE: "quote",
  BILLING: "billing",
  SUMMARY: "summary",
}

export const AINavLink = ({ eventKey }) => (
  <Nav.Item className="ml-1 flex-fill">
    <Nav.Link eventKey={eventKey}>
      <h2 className="m-0">
        <Icon name="cpu" title="AI" className="mr-2" />
      </h2>
    </Nav.Link>
  </Nav.Item>
)

export const AITabPane = ({ eventKey, setShowModal }) => {
  const [activeKey, setActiveKey] = useState(TABS.QUOTE)
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const { subscription, isSubscriptionAI, hasNoSubscriptions } = useStoreState(
    state => state.billing
  )
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const quote = useStoreState(state => state.quote)
  const { triggerAIInstance } = useStoreActions(actions => actions.project)
  const [hideCreditsUsage, setHideCreditsUsage] = useState()
  const [showUpgrade, setShowUpgrade] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [showSuccessSubscription, setShowSuccessSubscription] = useState(false)
  const [instanceWordCount, setInstanceWordCount] = useState(0)
  const features = useChargebeeEntitlements()

  const form = useForm()

  useEffect(() => {
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg.id])

  useEffect(() => {
    if (!subscription.credits || !subscription.details) return
    const { remaining } = subscription.credits
    const hasWrongPlan = isSubscriptionAI && quote.isServiceTypeConnectorCloud
    setShowUpgrade(
      hasNoSubscriptions || hasWrongPlan || remaining < quote.words || !features?.[AVAILABLE_FEATURES.localeAI]
    )
  }, [
    subscription.credits,
    subscription.details,
    quote.words,
    quote.serviceType,
    features,
  ])

  useEffect(() => {
    const hasWrongPlan =
      (isSubscriptionAI && quote.isServiceTypeConnectorCloud) ||
      quote.isServiceTypePro
    setHideCreditsUsage(hasWrongPlan || hasNoSubscriptions || !features?.[AVAILABLE_FEATURES.localeAI])
  }, [subscription.details, quote.words, quote.serviceType])

  useEffect(() => {
    setIsSubmitDisabled( isSubmitting || quote.words === 0)
  }, [quote.words, isSubmitting])

  return (
    <Tab.Pane eventKey={eventKey}>
      <Tab.Container activeKey={activeKey}>
        <Tab.Content>
          <Toast show={showSuccessSubscription} setShow={setShowSuccessSubscription} delay={5000}>
            <p className="text-gray-50 font-normal mb-0">
              <FormattedMessage
                id="components.projects.serviceTypes.TMSTab.successSubscription"
                defaultMessage="Congratulations! You subscribed to {planName} 🎉"
                values={{ planName: subscription?.details?.planName }}
              />
            </p>
          </Toast>
          <FormProvider {...form}>
            <QuoteTabPane
              eventKey={TABS.QUOTE}
              onSubmit={async ({ items, mode }) => {
                setIsSubmitting(true)
                setInstanceWordCount(quote.words);
                await triggerAIInstance({ mode: mode.value, projectLocaleIDs: items })
                fetchSubscription({ organizationId: currentOrg.id })
                setActiveKey(TABS.SUMMARY)
                setIsSubmitting(false)
              }}
              form={form}
            >
              <div className="custom-modal-footer">
                {!hideCreditsUsage && <CreditsUsage />}
                  {showUpgrade ? (
                    <Button
                      onClick={() => {
                        setActiveKey(TABS.BILLING)
                      }}
                      className="mt-4 w-100"
                    >
                      <FormattedMessage
                        id="components.projects.serviceTypes.AITab.upgradeButton"
                        defaultMessage="Upgrade"
                      />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="mt-4 w-100"
                      disabled={isSubmitDisabled}
                    >
                    { isSubmitting ? <Spinner/> :
                      <FormattedMessage
                        id="components.projects.serviceTypes.AITab.translateButton"
                        defaultMessage="Translate"
                      />
                    }
                    </Button>
                  )}
              </div>
            </QuoteTabPane>
          </FormProvider>
          <Tab.Pane eventKey={TABS.BILLING}>
            <PlanSubscriptionWizard
              familyId={SERVICE_TYPES.LOCALE_AI}
              filterByVolume={true}
              onCancel={() => {
                setActiveKey(TABS.QUOTE)
              }}
              onSubscription={() => {
                setActiveKey(TABS.QUOTE)
                setShowSuccessSubscription(true)
              }}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.SUMMARY}>
            <OrderSummary serviceType={"AI"} totalWords={instanceWordCount} onBack={() => { setActiveKey(TABS.QUOTE)}}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  )
}
