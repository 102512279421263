import React, { useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"
import Icon from "../Icon"
import { CardFields } from "./CardFields"
import { useFormContext } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Spinner from "../common/Spinner"

export const CardTabPane = ({ back, onPay, plans }) => {
  const intl = useIntl()
  const cardRef = React.createRef()
  const { control, setValue, watch } = useFormContext()
  const [price, setPrice] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { plan } = watch(["plan"])

  useEffect(() => {
    if (!plan) return
    setPrice(
      intl.formatNumber(plan.price / 100, {
        style: "currency",
        currency: plan.currency_code,
        minimumFractionDigits: 0,
      })
    )
  }, [plan])

  const tokenize = async () => {
    const data = await cardRef.current.tokenize().catch(e => console.error(e))
    setValue("cardToken", data?.token)
    return data?.token
  }

  return (
    <>

      <div className="card m-4 p-3">
        <div class="row align-items-center mb-2 justify-content-between">
          <div class="col-auto w-100">
            <h5 class="mb-4">
              <FormattedMessage
                id="components.billing.CardTabPane.title"
                defaultMessage="New payment method"
              />
            </h5>
            <CardFields cardRef={cardRef} control={control} />
          </div>
        </div>
      </div>
      <div class="flex-grow-0 flex-shrink-1 mb-5">
        <Container fluid>
          <div className="row mb-3">
            <div className="col">
              <Button
                className="mt-4 w-100"
                onClick={async () => {
                  setIsSubmitting(true)
                  await tokenize()
                  await onPay()
                  setIsSubmitting(false)
                }}
                disabled={isSubmitting}
              >
                { isSubmitting ? <Spinner/> :
                <FormattedMessage
                  id="src.components.billing.CardTabPane.nextButton"
                  defaultMessage="Pay {price}"
                  values={{ price }}
                />
                }
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                variant="link"
                onClick={() => back()}
                disabled={isSubmitting}
              >
                <Icon name="arrow-left" className="mr-2" />
                {plans ? (
                  <FormattedMessage
                    id="src.components.billing.CardTabPane.backPlansButton"
                    defaultMessage="Choose a different plan"
                  />
                ) : (
                  <FormattedMessage
                    id="src.components.billing.CardTabPane.backButton"
                    defaultMessage="Back"
                  />
                )}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
