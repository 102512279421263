import { useStoreState } from "easy-peasy"
import React, { useEffect, useState } from "react"
import HubspotCalendar from "../../../components/hubspot/Calendar"
import { STAGES } from "../../../models/onboarding"

const ScheduleOnboarding = ({ onboarding }) => {
  const currentUser = useStoreState((state) => state.currentUser.item)
  const [customer, setCustomer] = useState({})

  useEffect(() => {
    onboarding.setStage(STAGES.scheduleOnboarding)
  }, [])

  useEffect(() => {
    if (!currentUser.email || !currentUser.given_name) return

    const nameParts = currentUser.given_name.split(" ")
    const firstName = nameParts.shift();
    const lastName = nameParts.join(" ");
    setCustomer({
      firstName,
      lastName,
      email: currentUser.email,
    })
  }, [currentUser])

  const onSubmit = (payload) => {
    if (payload?.data?.meetingBookSucceeded && onboarding?.nextStage) {
      onboarding.moveToNextStage()
    }
  }

  return <>
    <h1 className="mb-6">Thank you for your subscription <span role="img" aria-label="tada emoji">🎉</span> Please schedule an onboarding call.</h1>
    <HubspotCalendar
      customer={customer}
      onSubmit={onSubmit}
    />
  </>
}

export default ScheduleOnboarding
