import React, { useState, useEffect } from "react"
import { useStoreState } from "easy-peasy"
import { useForm, useWatch } from "react-hook-form"
import { useIntl } from "gatsby-plugin-intl"
import Form from "../forms/Form"
import ProjectUpdater from "../../service/projectUpdater"
import Toast from "../Toast"
import { FormattedMessage } from "gatsby-plugin-intl"
import { CONNECTOR_AUTO_UPDATE_FREQUENCIES } from "../../store/models/connectors"


const AutoUpdateFrequency = ({ project }) => {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const connector = useStoreState(state => state.connectors.getConnector(project?.connector?.type))
  const [autoUpdateFrequencyOptions, setAutoUpdateFrequencyOptions] = useState([])
  const [realTimeUpdateDescription, setRealTimeUpdateDescription] = useState("")
  const { errors, control, setValue } = useForm({
    defaultValues: {
      autoUpdateFrequency: ""
    },
  });

  const autoUpdateFrequency = useWatch({
    control,
    name: "autoUpdateFrequency",
  })

  useEffect(() => {
    const autoUpdateModes = connector?.autoUpdateModes;
    if(!autoUpdateModes.length) return;
    if(connector?.metadata?.realTimeUpdateDescription) setRealTimeUpdateDescription(connector?.metadata?.realTimeUpdateDescription)
    setAutoUpdateFrequencyOptions(
      autoUpdateModes.reduce((acc, mode) => {
        acc.push(...CONNECTOR_AUTO_UPDATE_FREQUENCIES[mode]);
        return acc;
      }, [])
    );
  }, [connector])

  useEffect(() => {
    if( !autoUpdateFrequencyOptions.length ) return
    const currentProjectFrequency = autoUpdateFrequencyOptions.find( ({ id }) => id === project.autoUpdateFrequency);
    setValue("autoUpdateFrequency", { ...currentProjectFrequency, isDefault: true })
  }, [project.autoUpdateFrequency, autoUpdateFrequencyOptions, setValue])

  useEffect(() => {
    if(autoUpdateFrequency === "" || autoUpdateFrequency.isDefault) return
    updateProjectAutoUpdateFrequency();
  }, [autoUpdateFrequency])

  const updateProjectAutoUpdateFrequency = async () => {
    try {
      setIsLoading(true)
      await ProjectUpdater.call({ id: project.id , autoUpdateFrequency: autoUpdateFrequency.id })
      setIsLoading(false)
      setShowSuccessToast(true)
      return true
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setShowSuccessToast(false)
      return false
    }
  }

  const autoUpdateFrequencyField = [
    {
      name: "autoUpdateFrequency",
      isLoading,
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.autoUpdateFrequency.label",
        defaultMessage: "Frequency",
      })}`,
      options: autoUpdateFrequencyOptions
    },
  ]

  return (
    <>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast} delay={1200}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.projects.autoUpdateFrequency.updated"
            defaultMessage="Auto-update Frequency Updated ✨"
          />
        </p>
      </Toast>
      <Form
        errors={errors}
        fields={autoUpdateFrequencyField}
        control={control}
      />
       <p className="text-gray-500 text-sm ml-2 mb-0">
        { autoUpdateFrequency?.description }
        </p>
        { ( realTimeUpdateDescription !== "" && autoUpdateFrequency?.id === "realtime" ) && (
          <p className="text-gray-500 text-sm ml-2 mb-0">
          { realTimeUpdateDescription }
          </p>
        )}
    </>
  )
}

export default AutoUpdateFrequency
