import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { fieldRequired, validatePathPattern } from "../../../utils/validations";
import { useForm } from "react-hook-form";
import ModalForm from "../../forms/ModalForm";
import { STATUSES } from "../../forms/ModalForm";
import ResourcePatternForm from "./ResourcePatternForm";
import { triggerQuote } from "../../../helpers/instances";
import ProjectResourcePatternsUpdater from "../../../service/projectResourcePatternsUpdater";
import ProjectResourcePatternsCreator from "../../../service/projectResourcePatternsCreator";

const ResourceModalForm = ({
  formAction,
  title,
  Trigger,
  index,
  project,
  resourcePattern,
}) => {
  const intl = useIntl();
  const { handleSubmit, errors, control } = useForm({
    mode: "onChange",
  });
  const [status, setStatus] = useState();

  const hiddenFields = [
    {
      name: "projectID",
      type: "hidden",
      validations: { ...fieldRequired },
      defaultValue: project.id,
    },
  ];
  const fields = [
    {
      name: `resourcePatterns[${index}][path]`,
      type: "text",
      label: intl.formatMessage(
        {
          id: "components.projects.resourcePattern.path.label",
          defaultMessage: "File path",
        },
      ),
      validations: { ...validatePathPattern },
      defaultValue: resourcePattern?.pattern.path,
      placeholder: project.format?.path,
    },
  ];

  const onSubmit = async payload => {
    setStatus(STATUSES.loading)
    let action;
    switch (formAction) {
      case "update":
        action = ProjectResourcePatternsUpdater.call;
        break;
      case "create":
        action = ProjectResourcePatternsCreator.call;
        break;
    }
    if (action) {
      const success = await action(payload);
      if (success) {
        setStatus(STATUSES.success)
        triggerQuote({ projectID: project.id })
      } else {
        setStatus(STATUSES.error)
      }
    }
  };

  return (
    <ModalForm
      status={status}
      setStatus={setStatus}
      title={title}
      Trigger={Trigger}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
    >
      <ResourcePatternForm
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        hiddenFields={hiddenFields}
        fields={fields}
      />
    </ModalForm>
  );
};

ResourceModalForm.defaultProps = {
  index: 0,
};

export default ResourceModalForm;
