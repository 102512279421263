/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAccessRequest = /* GraphQL */ `
  subscription OnCreateAccessRequest($owner: String) {
    onCreateAccessRequest(owner: $owner) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccessRequest = /* GraphQL */ `
  subscription OnUpdateAccessRequest($owner: String) {
    onUpdateAccessRequest(owner: $owner) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccessRequest = /* GraphQL */ `
  subscription OnDeleteAccessRequest($owner: String) {
    onDeleteAccessRequest(owner: $owner) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConnector = /* GraphQL */ `
  subscription OnCreateConnector {
    onCreateConnector {
      category
      description
      id
      name
      rank
      status
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConnector = /* GraphQL */ `
  subscription OnUpdateConnector {
    onUpdateConnector {
      category
      description
      id
      name
      rank
      status
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConnector = /* GraphQL */ `
  subscription OnDeleteConnector {
    onDeleteConnector {
      category
      description
      id
      name
      rank
      status
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContentResource = /* GraphQL */ `
  subscription OnCreateContentResource {
    onCreateContentResource {
      connectorID
      groups
      id
      organizationID
      namespace
      parentId
      path
      providerCreatedAt
      providerID
      providerType
      providerUpdatedAt
      title
      translatable
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContentResource = /* GraphQL */ `
  subscription OnUpdateContentResource {
    onUpdateContentResource {
      connectorID
      groups
      id
      organizationID
      namespace
      parentId
      path
      providerCreatedAt
      providerID
      providerType
      providerUpdatedAt
      title
      translatable
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContentResource = /* GraphQL */ `
  subscription OnDeleteContentResource {
    onDeleteContentResource {
      connectorID
      groups
      id
      organizationID
      namespace
      parentId
      path
      providerCreatedAt
      providerID
      providerType
      providerUpdatedAt
      title
      translatable
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFormat = /* GraphQL */ `
  subscription OnCreateFormat {
    onCreateFormat {
      framework
      id
      isVisible
      name
      parser
      path
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFormat = /* GraphQL */ `
  subscription OnUpdateFormat {
    onUpdateFormat {
      framework
      id
      isVisible
      name
      parser
      path
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFormat = /* GraphQL */ `
  subscription OnDeleteFormat {
    onDeleteFormat {
      framework
      id
      isVisible
      name
      parser
      path
      createdAt
      updatedAt
    }
  }
`;
export const onCreateIntegration = /* GraphQL */ `
  subscription OnCreateIntegration($owner: String) {
    onCreateIntegration(owner: $owner) {
      id
      groups
      name
      integrationTypeID
      integrationType {
        id
        status
        type
        createdAt
        updatedAt
      }
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateIntegration = /* GraphQL */ `
  subscription OnUpdateIntegration($owner: String) {
    onUpdateIntegration(owner: $owner) {
      id
      groups
      name
      integrationTypeID
      integrationType {
        id
        status
        type
        createdAt
        updatedAt
      }
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteIntegration = /* GraphQL */ `
  subscription OnDeleteIntegration($owner: String) {
    onDeleteIntegration(owner: $owner) {
      id
      groups
      name
      integrationTypeID
      integrationType {
        id
        status
        type
        createdAt
        updatedAt
      }
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateIntegrationType = /* GraphQL */ `
  subscription OnCreateIntegrationType {
    onCreateIntegrationType {
      id
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIntegrationType = /* GraphQL */ `
  subscription OnUpdateIntegrationType {
    onUpdateIntegrationType {
      id
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIntegrationType = /* GraphQL */ `
  subscription OnDeleteIntegrationType {
    onDeleteIntegrationType {
      id
      status
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocale = /* GraphQL */ `
  subscription OnCreateLocale {
    onCreateLocale {
      id
      language
      region
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocale = /* GraphQL */ `
  subscription OnUpdateLocale {
    onUpdateLocale {
      id
      language
      region
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocale = /* GraphQL */ `
  subscription OnDeleteLocale {
    onDeleteLocale {
      id
      language
      region
      tag
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization($owner: String) {
    onCreateOrganization(owner: $owner) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      integration {
        id
        groups
        name
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization($owner: String) {
    onUpdateOrganization(owner: $owner) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      integration {
        id
        groups
        name
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization($owner: String) {
    onDeleteOrganization(owner: $owner) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      integration {
        id
        groups
        name
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String) {
    onCreateProject(owner: $owner) {
      connector {
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        integration {
          id
          groups
          name
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
      organizationID
      owner
      providerID
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String) {
    onUpdateProject(owner: $owner) {
      connector {
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        integration {
          id
          groups
          name
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String) {
    onDeleteProject(owner: $owner) {
      connector {
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        integration {
          id
          groups
          name
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProjectLocale = /* GraphQL */ `
  subscription OnCreateProjectLocale($owner: String) {
    onCreateProjectLocale(owner: $owner) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            integrationTypeID
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            protemos_apiKey
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProjectLocale = /* GraphQL */ `
  subscription OnUpdateProjectLocale($owner: String) {
    onUpdateProjectLocale(owner: $owner) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            integrationTypeID
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            protemos_apiKey
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProjectLocale = /* GraphQL */ `
  subscription OnDeleteProjectLocale($owner: String) {
    onDeleteProjectLocale(owner: $owner) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            integrationTypeID
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            protemos_apiKey
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
