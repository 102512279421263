import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Button } from "react-bootstrap";
import Icon from "../../../components/Icon";
import ModalForm from "./ModalForm";

const Add = ({ project }) => {
  const intl = useIntl();

  const Trigger = ({ onClick }) => (
    <Button size="sm" className="btn btn--primary d-flex align-items-center" onClick={onClick}>
      <Icon
        name="plus"
        title={intl.formatMessage({
          id: "components.projects.resourcePattern.add.trigger.button",
          defaultMessage: "Add",
        })}
      />
    </Button>
  );

  return (
    <ModalForm
      formAction="create"
      title={intl.formatMessage({
        id: "components.projects.resource.add.modal.title",
        defaultMessage: "Add File Path",
      })}
      Trigger={Trigger}
      project={project}
    />
  );
};

export default Add;
