import React, { useState } from "react";
import PropTypes from "prop-types";
import Edit from "./Edit";
import SourceRow from "./SourceRow";
import TargetRow from "./TargetRow";
import Actions from "../../common/Actions";
import Remove from "./Remove";

const ProjectPathPatternListRow = ({
  project,
  resourceList,
  resourceListIndex,
}) => {
  const [open, setOpen] = useState(false);
  const onToggle = () => setOpen(!open);

  const source = resourceList.find(resource => resource.isSource);
  const targets = resourceList.filter(resource => !resource.isSource);
  return (
    <>
      <SourceRow
        open={open}
        resource={source}
        onToggle={onToggle}
        resourceListIndex={resourceListIndex}
        actions={(
          <Actions
            actions={[
              <Edit project={project} resourcePatternIndex={resourceListIndex} resourcePattern={source} />,
              <Remove project={project} index={resourceListIndex} />
            ]}
          />
        )}
      />
      {targets.map((resource) => (
        <TargetRow
          key={resource.projectLocaleID}
          open={open}
          resourceListIndex={resourceListIndex}
          resource={resource} />
      ))}
    </>
  );
};

ProjectPathPatternListRow.propTypes = {
  project: PropTypes.shape().isRequired,
  resourceList: PropTypes.array.isRequired,
  resourceListIndex: PropTypes.number.isRequired,
};

export default ProjectPathPatternListRow
