import React, { useCallback, useEffect, useState, Fragment } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Router, Redirect } from "@reach/router"
import { instrumentSignIn } from "../utils/instrumentation"
import { useLocation, useMatch } from "@reach/router"
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { parse } from "query-string"

import ROUTES from "../config/routes"

import Authenticator from "../components/auth/Authenticator"
import ClientLayout from "../components/layout/AppLayout"
import AdminLayout from "../components/layout/vendor/Layout";
import CenteredLayout from "../components/layout/CenteredLayout"
import SignInLayout from "../components/layout/SignInLayout"

import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components"
import Amplify from "aws-amplify"
import awsconfig from "../aws-exports"
import { ORG_TYPES } from "../models/organization";
import HybridStorage from "../config/hybridStorage";

import { getWorkspaceTypeFromPath, replaceLocationFromWorkspaceType, vendorHasSubdomain } from "../helpers/user"
import Root from "../containers/Root"
import Dashboard from "../containers/Dashboard"
import NewProject from "../containers/projects/NewProject"
import ShowProject from "../containers/projects/ShowProject"
import Onboarding from "../containers/Onboarding"
import UserProfile from "../containers/Profile"
import Settings from "../containers/settings/Settings"

import VendorDashboard from "../containers/vendor/Dashboard"
import VendorAccounts from "../containers/vendor/Accounts"
import VendorTeam from "../containers/vendor/Team"
import VendorRoot from "../containers/vendor/Root"
import VendorAccountAdd from "../containers/vendor/AccountAdd"
import VendorAccountEdit from "../containers/vendor/AccountEdit"
import VendorOnboarding from "../containers/vendor/Onboarding"
import IntegrationEdit from "../containers/integrations/edit"
import VendorIntegrations from "../containers/vendor/Integrations"
import Meta from "../components/layout/Meta"
import Spinner from "../components/common/Spinner"
import { graphql } from "gatsby"
import VendorIntegrationNew from "../containers/vendor/IntegrationNew"
import IntegrationNew from "../containers/integrations/new"
import IntegrationSettings from "../containers/settings/IntegrationSettings"
import PlanList from "../containers/PlanList"
import PlanCheckout from "../containers/PlanCheckout"
import General from "../containers/settings/General"
import TeamContainer from "../containers/Team"
import BillingPage from "../containers/Billing"
import Connectors from "../containers/Connectors"
import ConnectorInstaller from "../components/connectors/ConnectorInstaller"
import Plans from "../components/billing/Plans"

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});

const AppLayout = ({ isVendor, children, ...props }) => {
  if (isVendor) return <AdminLayout {...props}>{children}</AdminLayout>
  return <ClientLayout {...props}>{children}</ClientLayout>
}

const Main = props => {
  const { dataFetched, hasPaidSubscriptions, isLoading, isLoggedIn, isPartner, isVendor } = props
  const location = useLocation()
  const userType = getWorkspaceTypeFromPath(location.hostname)

  const match = useMatch("/:locale/*")
  const app = (
    <Authenticator isLoggedIn={isLoggedIn} isLoading={isLoading}>
      {isLoggedIn &&
        (dataFetched ? (
          <Router basepath={`/:locale`}>
            {!isVendor ? (
              <Root path={ROUTES.ROOT.path}>
                <Dashboard path={ROUTES.DASHBOARD.path} />
                <NewProject path={`${ROUTES.NEW_PROJECT.path}/*`} />
                <ShowProject path={ROUTES.PROJECT.path} />
                <Onboarding path={`${ROUTES.SIGN_UP.path}/*`} />
                <UserProfile path={ROUTES.PROFILE.path} />
                <IntegrationEdit path={ROUTES.EDIT_INTEGRATION.path} />
                <IntegrationNew path={ROUTES.NEW_INTEGRATION.path} />
                <PlanCheckout path={ROUTES.PLAN_CHECKOUT.path} />
                <Settings path={ROUTES.SETTINGS.path}>
                  <General default path={ROUTES.GENERAL.path} />
                  <TeamContainer  path={ROUTES.TEAM.path} isTabItem />
                  <IntegrationSettings path={ROUTES.INTEGRATIONS.path} />
                  <BillingPage path={ROUTES.BILLING.path}/>
                  <Connectors path={ROUTES.CONNECTORS.path} isTabItem />
                  <Plans path={ROUTES.PLANS.path} />
                  <PlanList path={ROUTES.PLAN_CHECKOUT.path} />
                </Settings>
                <ConnectorInstaller path={ROUTES.CONNECTOR_INSTALLER.path} />
                <Fragment path={ROUTES.ROOT.path} />
                <Redirect
                  from={`/${match?.locale}`}
                  to={`/${match?.locale}/404`}
                  noThrow
                  default
                />
              </Root>
            ) : (
              <VendorRoot path={ROUTES.ROOT.path}>
                <VendorAccountAdd path={ROUTES.ACCOUNT_ADD.path} />
                <VendorAccountEdit path={ROUTES.ACCOUNT_DETAILS.path} />
                <VendorDashboard path={ROUTES.DASHBOARD.path} />
                <VendorAccounts path={ROUTES.ACCOUNTS.path} />
                <IntegrationEdit path={ROUTES.EDIT_INTEGRATION.path} />
                <VendorTeam path={ROUTES.TEAM.path} />
                <VendorIntegrationNew path={ROUTES.NEW_INTEGRATION.path} />
                <VendorIntegrations path={ROUTES.INTEGRATIONS.path} />
                <VendorOnboarding path={`${ROUTES.SIGN_UP.path}/*`} />
                <div path={ROUTES.ROOT.path} />
                <Redirect
                  from={`/${match?.locale}`}
                  to={`/${match?.locale}/404`}
                  noThrow
                  default
                />
              </VendorRoot>
            )}
          </Router>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <Spinner />
          </div>
        ))}
    </Authenticator>
  )

  const SignIn = ({ children }) => {
    const defaultSignIn = ORG_TYPES.locale === userType

    if (defaultSignIn)
      return <CenteredLayout {...props}>{children}</CenteredLayout>

    return <SignInLayout {...props}>{children}</SignInLayout>
  }

  if (isLoggedIn) {
    return <AppLayout isVendor={isVendor} {...props}>{app}</AppLayout>
  }
  return <SignIn>{app}</SignIn>
}

const App = ({ data }) => {
  const {
    allContentfulVendor: { nodes: vendorData },
    allContentfulConnector: { nodes: connectorData }
  } = data
  const location = useLocation()
  const userType = getWorkspaceTypeFromPath(location.hostname)
  const ldClient = useLDClient();
  const [dataFetched, setDataFetched] = useState(false)
  const { item: currentUser, isVendor } = useStoreState(
    state => state.currentUser
  )
  const [isLoading, setIsLoading] = useState(true)
  const isLoggedIn = useStoreState(state => state.currentUser.isLoggedIn)
  const {
    fetch: fetchCurrentUser,
    set: setCurrentUser
  } = useStoreActions(actions => actions.currentUser)
  const vendorDetails = useStoreState(state => state.vendor.item)
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const { hasPaidSubscriptions, isPartner } = useStoreState(state => state.currentOrg)
  const { vendorLSP } = useStoreState(state => state.currentUser)
  const setAsClient = useStoreActions(actions => actions.appMode.setAsClient)
  const asClient = useStoreState(state => state.appMode.asClient)
  const fetchVendors = useStoreActions(actions => actions.currentVendor.fetch)
  const findVendor = useStoreActions(actions => actions.currentVendor.find)
  const setIsLoggedIn = useStoreActions(
    actions => actions.currentUser.setIsLoggedIn
  )
  const fetchCurrentOrg = useStoreActions(actions => actions.currentOrg.fetch)
  const resetCurrentOrg = useStoreActions(actions => actions.currentOrg.reset)
  const fetchProjects = useStoreActions(actions => actions.projects.fetch)
  const fetchConnectors = useStoreActions(actions => actions.connectors.fetch)
  const setVendorData = useStoreActions(actions => actions.vendor.setItem)
  const fetchFormats = useStoreActions(actions => actions.formats.fetch)
  const fetchClients = useStoreActions(actions => actions.clients.fetch)
  const resetStore = useStoreActions(actions => actions.reset)
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const { item: currentOrg, workspaceType } = useStoreState(state => state.currentOrg)
  const currentPath = location.pathname
  const isOnboarding = !!currentPath.match(ROUTES.SIGN_UP.path)
  const query = parse(location.search)

  const nonLocaleSubdomainType = ORG_TYPES.locale !== userType ? userType : null
  const nonLocaleWorkspaceType = ORG_TYPES.locale !== workspaceType ? workspaceType : null
  const nonLocaleType = nonLocaleSubdomainType || nonLocaleWorkspaceType
  const fetchIntegrationTypes = useStoreActions(actions => actions.integrationTypes.fetchAll)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await fetchCurrentUser()
      } catch (err) {
        setIsLoading(false)
      }
    };
    fetchUser();
    (() => {
      onAuthUIStateChange((newAuthState, data) => {
        if (newAuthState === AuthState.SignedIn) {
          const user = { ...currentUser, ...data.attributes }
          setCurrentUser(user)
          instrumentSignIn(user)
          setIsLoggedIn(true)
          setIsLoading(true)
        }
      })
    })();
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (nonLocaleSubdomainType !== null) {
      const loggedVendor = vendorData.find(({ slug }) => slug === nonLocaleSubdomainType)
      setVendorData(loggedVendor)
      return
    }
  }, [ isLoggedIn, nonLocaleSubdomainType ])

  const vendorID = currentVendor?.id || null
  useEffect(() => {
    if (!isVendor && nonLocaleType) {
      const loggedVendor = vendorData.find(({ slug }) => slug === nonLocaleType)
      setVendorData(loggedVendor)
      return
    }
    if (isVendor && vendorID) {
      const loggedVendor = vendorData.find(({ slug }) => slug === vendorID)
      setVendorData(loggedVendor)
      return
    }
  }, [nonLocaleType, isVendor, vendorID])

  useEffect(() => {
    if (!ldClient || !currentUser || !currentOrg) return
    ldClient.identify({
      key: currentUser.id,
      name: currentUser.given_name,
      email: currentUser.email,
      custom: {
        organizationId: currentOrg.id,
        organizationName: currentOrg.name,
      },
      anonymous: false,
    })
  }, [ldClient, currentUser, currentOrg])

  const redirectToSubdomain = useCallback(vendorID => {
    const hasSubdomain = vendorHasSubdomain(vendorID)
    const workspaceTypeFromPath = getWorkspaceTypeFromPath(location.hostname)
    if (!hasSubdomain && workspaceTypeFromPath !== ORG_TYPES.locale) {
      window.location.href = replaceLocationFromWorkspaceType(
        ORG_TYPES.locale,
        location
      )
    } else if (
      hasSubdomain &&
      vendorID &&
      vendorID !== workspaceTypeFromPath
    ) {
      window.location.href = replaceLocationFromWorkspaceType(
        vendorID,
        location
      )
    }
  }, [location])

  useEffect(() => {
    if (!vendorLSP) return
    if (isVendor) redirectToSubdomain(vendorLSP)
  }, [isVendor, location, redirectToSubdomain, vendorLSP])

  useEffect(() => {
    if (!workspaceType) return
    if (!isVendor) redirectToSubdomain(workspaceType)
  }, [location, workspaceType, isVendor, redirectToSubdomain])

  const getInitialClientData = async () => {
    fetchCurrentOrg(query?.workspace)
    fetchFormats()
    fetchConnectors()
  }

  const getInitialVendorData = async () => {
    setDataFetched(false)
    setIsLoading(true)
    resetCurrentOrg()
    if (userType === ORG_TYPES.locale) {
      await findVendor()
    } else {
      await fetchVendors({ id: userType })
    }
    setIsLoading(false)
    await fetchClients({ vendorID: userType })
    setDataFetched(true)
  }

  useEffect(() => {
    if (isVendor && query?.client === "true") {
      setAsClient(true)
    }
  }, [query?.client, isVendor])

  useEffect(() => {
    if (asClient && query?.client === "false") {
      setAsClient(false)
    }
  }, [query?.client, asClient])

  useEffect(() => {
    if (isLoggedIn === true) {
      if (!isVendor) {
        getInitialClientData()
      } else {
        fetchIntegrationTypes()
        getInitialVendorData()
      }

      return () => {
        resetStore()
      }
    }
  }, [isLoggedIn, isVendor, asClient, userType, currentVendor?.id])

  useEffect(() => {
    if (!isLoggedIn) return
    if (!isVendor && currentOrg.id) {
      setIsLoading(true);
      setDataFetched(false);
      (async () => {
        await fetchProjects()
        setIsLoading(false)
        setDataFetched(true)
      })();
    }
    if (asClient) {
      (async () => {
        await fetchVendors({ id: userType })
        setDataFetched(true)
      })();
    }
  }, [isVendor, isLoggedIn, currentOrg.id, asClient, userType])

  useEffect(() => {
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg.id])

  return (
    <>
      <Meta />
      <Main
        dataFetched={dataFetched}
        hasPaidSubscriptions={hasPaidSubscriptions}
        isLoading={isLoading}
        isLoggedIn={isLoggedIn}
        isOnboarding={isOnboarding}
        isPartner={isPartner}
        isVendor={isVendor}
        vendorDetails={vendorDetails}
        connectorData={connectorData}
      />
    </>
  )
}

export default App

export const query = graphql`
  query {
    allContentfulVendor {
      nodes {
        brandColor
        contentful_id
        id
        name
        slug
        logo {
          file {
            url
          }
        }
      }
    }

    allContentfulConnector {
      nodes {
        logo {
          file {
            url
          }
          title
        }
        id: slug
        status
        type
        url
        name
        category
        settings {
          hasAutoUpdate
          enablePushContent
        }
      }
    }
  }
`
