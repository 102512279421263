import React from "react"
import { FormattedNumber } from "gatsby-plugin-intl"

const Price = ({ amount, currency }) => (
  <FormattedNumber
    // eslint-disable-next-line react/style-prop-object
    style="currency"
    currency={currency}
    currencyDisplay="narrowSymbol"
    minimumFractionDigits="0"
    value={amount}
  />
)

export default Price
