import React from "react"
import { useStoreState } from "easy-peasy"
import { useLocation, useMatch } from "@reach/router"
import { parse } from "query-string"
import { navigate } from "gatsby-plugin-intl"

import { redirect } from "../../config/routes"
import { isSignInPath } from "../../helpers/authentication"
import { ONBOARDING_ROOT, stagePath } from "./Onboarding"

const Root = ({ children }) => {
  const location = useLocation()
  const currentPath = location.pathname
  const isRoot = useMatch("/:locale")
  const query = parse(location.search)
  const onboarding = useStoreState(state => state.onboarding.item)
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const isOnboarding = !!currentPath.match(ONBOARDING_ROOT)
  const isOnboardingRoot = currentPath.endsWith(`${ONBOARDING_ROOT}/`) || currentPath.endsWith(`${ONBOARDING_ROOT}`)

  if (!currentVendor.id) {
    return null
  }

  if ((!isOnboarding || isOnboardingRoot) && onboarding && !onboarding.isCompleted) {
    navigate(stagePath(onboarding.getPathOfStep()?.path || "", onboarding.type, location.search))
    return null
  }

  if (isSignInPath(currentPath)) {
    redirect("ROOT")
    return null
  }
  if (isRoot && query?.client !== "true") {
    redirect("DASHBOARD")
    return null
  }

  return <>{children}</>
}

export default Root
