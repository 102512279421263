import React from "react"
import PropTypes from "prop-types"
import Sidebar from "./Sidebar"
import Footer from "../Footer"
import "../../../scss/theme.scss"
import UserDropdown from "../../nav/UserDropdown"
import PartnerLogo from "../../partner/PartnerLogo"
import { useStoreState } from "easy-peasy"
import Brand from "../../common/Brand"

const TopNavigation = ({ currentVendor, vendorDetails, onboarding }) => (
  <div
    style={{ minHeight: "57px", borderColor: vendorDetails?.brandColor }}
    className="d-flex align-items-center bg-gray-0 border-b border-gray-200-default border-bottom-3"
  >
    <div className="topbar__partner-logo">
      <h3 className="m-0">
        {currentVendor?.id && onboarding?.isCompleted !== false &&(
          <PartnerLogo
            vendorDetails={vendorDetails}
            logoClassName="bg-white topbar__partner-logo-img mr-4"
          />
        )}
        {onboarding && onboarding?.isCompleted === false && (
          <Brand dark className="d-flex align-items-center" classNameImg="topbar__locale-img" />
        )}
      </h3>
    </div>
    <UserDropdown onlyLogout />
  </div>
)

const AdminLayout = ({ children, ...props }) => {
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const vendorDetails = useStoreState(state => state.vendor.item)
  const onboarding = useStoreState(state => state.onboarding.item);
  return (
    <>
      <div className="d-flex flex-column">
        <TopNavigation
          currentVendor={currentVendor}
          vendorDetails={vendorDetails}
          onboarding={onboarding}
        />
        <div className="d-flex">
          {currentVendor.id && onboarding?.isCompleted !== false &&
            <Sidebar {...props} />
          }
          <div className="main-content d-flex flex-column">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminLayout
