import React from "react"
import Select from "react-select"
import { useController } from "react-hook-form"
import { SERVICE_MODES } from "../../../models/service"
import { useIntl } from "gatsby-plugin-intl"

export const ServiceModeSelect = ({ control }) => {
  const intl = useIntl()
  const options = [
    {
      label: intl.formatMessage({
        id:
          "components.projects.quote.ServiceModeSelect.option.synchronization",
        defaultMessage: "Untranslated",
      }),
      value: SERVICE_MODES.SYNCHRONIZATION,
    },
    {
      label: intl.formatMessage({
        id: "components.projects.quote.ServiceModeSelect.option.proofreading",
        defaultMessage: "All",
      }),
      value: SERVICE_MODES.PROOFREADING,
    },
  ]
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "mode",
    control,
    defaultValue: options[0],
  })

  return (
    <Select
      {...inputProps}
      inputRef={ref}
      className="form-control form-control-flush p-0"
      options={options}
      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        control: base => ({
          ...base,
          minWidth: "150px",
          borderStyle: "none",
        }),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#00D792",
        },
      })}
    />
  )
}
