import React, { useState, useEffect } from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import API from "@aws-amplify/api"
import ProjectUpdater from "../../service/projectUpdater"
import Switch from "../forms/Switch"
import Toast from "../Toast"
import { useChargebeeEntitlements } from "../../helpers/chargebee"
import { Button } from "react-bootstrap"
import { path } from "../../config/routes"
import { useStoreState } from "easy-peasy"

const PushModeForm = ({ project }) => {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [isActive, setIsActive] = useState(project.isActive)
  const isPartner = useStoreState((store) => store.currentOrg.isPartner)
  const { 'auto-update': autoUpdate } = useChargebeeEntitlements()
  const hasAutoUpdate = autoUpdate || isPartner
  const redirect = () => {
    navigate(path("UPGRADE_PLAN"))
  }

  useEffect(() => {
    setIsActive(project.isActive)
  }, [project.isActive])

  const updateProjectStatus = async () => {
    try {
      setIsActive(!isActive)
      await API.put("nepal", "/project/status", {
        queryStringParameters: {
          projectID: project.id,
        },
      })
      setShowSuccessToast(true)
      ProjectUpdater.call({ ...project, isActive: !isActive })
      return true
    } catch (e) {
      if (e.response.status === 402) {
        setIsActive(false)
        redirect()
        return false
      }
      console.error(e)
      return false
    }
  }

  return (
    <>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
        <p className="text-gray-50 font-normal mb-0">
          {project.isActive ? (
            <FormattedMessage
              id="components.projects.pushModeForm.enabled.auto-update"
              defaultMessage="Auto-update enabled ✨"
            />
          ) : (
            <FormattedMessage
              id="components.projects.pushModeForm.disabled.auto-update"
              defaultMessage="Auto-update disabled ✨"
            />
          )}
        </p>
      </Toast>

      <div className="d-flex">
        <Switch
          id={`auto-update-switch-${project.id}`}
          isChecked={isActive}
          onChange={updateProjectStatus}
          disabled={!hasAutoUpdate}
        />

        {!hasAutoUpdate && (
          <Button variant="info" size="sm" onClick={redirect}>Upgrade</Button>
        )}
      </div>
    </>
  )
}

export default PushModeForm
