import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { Button } from "react-bootstrap"
import { fieldRequired, validateURL } from "../../utils/validations"
import Form from "../forms/Form"
import { STATUSES } from "../layout/FormContainer"
import ProjectUpdater from "../../service/projectUpdater"
import Spinner from "../common/Spinner"
import { localeName } from "../../helpers/locale"
import {
  hasApiKey,
  hasTestApiKey,
  isCuistoProject,
  isEmailable,
  isWebhookable,
} from "../../helpers/project"
import { CONNECTORS } from "../../store/models/connectors"
import { isGitConnector, isApiConnector } from "../../helpers/connector"
import { triggerQuote } from "../../helpers/instances"
import { API } from "aws-amplify"
import { mapTypeToFieldType } from "../../helpers/cuisto"

const EditForm = ({ project }) => {
  const { id, name, connector, testApiKey, liveApiKey } = project
  const projectLocales = project.locales.items || []
  const source = projectLocales.find(locale => !!locale.isSource)
  const intl = useIntl()
  const [status, setStatus] = useState()
  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      "project[id]": "",
      "project[name]": "",
      "project[connector][type]": "",
      email: "",
      "project[connector][branch]": { name: "" },
      "project[connector][mode]": "project",
      "project[connector][organization]": "",
      "project[connector][project]": "",
      "project[connector][repository]": "",
      "project[connector][webhookURL]": "",
      "project[testApiKey]": "",
      "project[liveApiKey]": "",
      "project[integrationID]": "",
      "project[integrationTemplateID]": "",
      "project[cuistoConfig]": null,
    },
  })

  const fetchAzureBranches = useStoreActions(
    actions => actions.azureBranches.fetch
  )
  const fetchBitbucketBranches = useStoreActions(
    actions => actions.bitbucketBranches.fetch
  )
  const fetchGithubBranches = useStoreActions(
    actions => actions.githubBranches.fetch
  )
  const fetchGitlabBranches = useStoreActions(
    actions => actions.gitlabBranches.fetch
  )
  const azureBranches = useStoreState(state => state.azureBranches.items)
  const bitbucketBranches = useStoreState(
    state => state.bitbucketBranches.items
  )
  const availableIntegrations = useStoreState(
    state => state.integrations.availableIntegrations
  )
  const githubBranches = useStoreState(state => state.githubBranches.items)
  const gitlabBranches = useStoreState(state => state.gitlabBranches.items)
  const { item: currentOrg } = useStoreState(state => state.currentOrg)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const [branchOptions, setBranchOptions] = useState()
  let gitBranchesParams = {
    repoName: connector.repository,
    organizationID: currentOrg.id,
  }

  const [cuistoFields, setCuistoFields] = useState([])

  useEffect(() => {
    if (isCuistoProject(project) && connector) {
      API.get("nepal", `/cuisto/connectors/${connector.type}/config`)
        .then(data => {
          setCuistoFields(
            Object.entries(data?.configuration ?? {}).map(
              ([key, { type, name, description }]) => ({
                description,
                label: name ?? key,
                name: `project[cuistoConfig][${key}]`,
                type: mapTypeToFieldType(type),
              })
            )
          )
        })
        .catch()
    }
  }, [project, connector?.type])

  useEffect(() => {
    Object.entries(project.cuistoConfig).forEach(([key, value]) => {
      setValue(`project[cuistoConfig][${key}]`, value)
    })
  }, [project, cuistoFields, setValue])

  const integration = useWatch({
    control,
    name: "project[integration]",
    defaultValue: availableIntegrations.find(
      ({ id }) =>
        id === project.integrationID ||
        id === project.organization.integrationID
    ),
  })

  useEffect(() => {
    setValue(
      "project[integration]",
      availableIntegrations.find(
        ({ id }) =>
          id === project.integrationID ||
          id === project.organization.integrationID
      )
    )
  }, [setValue, availableIntegrations, project])

  useEffect(() => {
    setValue("project[id]", id)
    setValue("project[name]", name)
    setValue("project[connector][isCuisto]", isCuistoProject(project))
    setValue("project[connector][type]", connector.type)
    setValue("project[connector][mode]", connector.mode)
    setValue("project[connector][organization]", connector.organization)
    setValue("project[connector][project]", connector.project)
    setValue("project[connector][webhookURL]", connector.webhookURL)
    setValue("project[testApiKey]", testApiKey)
    setValue("project[liveApiKey]", liveApiKey)
    setValue(
      "email",
      `wpml+${project.liveApiKey?.replace("live_", "")}@locale.email`
    )

    switch (connector.type) {
      case CONNECTORS.azurerepos:
        gitBranchesParams = {
          gitOrganization: connector.organization,
          gitProject: connector.project,
          gitRepository: connector.repository,
          organizationID: currentOrg.id,
        }
        fetchAzureBranches(gitBranchesParams)
        break
      case CONNECTORS.bitbucket:
        fetchBitbucketBranches(gitBranchesParams)
        break
      case CONNECTORS.github:
        fetchGithubBranches(gitBranchesParams)
        break
      case CONNECTORS.gitlab:
        fetchGitlabBranches(gitBranchesParams)
        break
    }
  }, [project])

  useEffect(() => {
    switch (connector.type) {
      case CONNECTORS.azurerepos:
        setAzureState()
        break
      case CONNECTORS.bitbucket:
        setBitbucketState()
        break
      case CONNECTORS.github:
        setGithubState()
        break
      case CONNECTORS.gitlab:
        setGitlabState()
        break
    }
  }, [azureBranches, bitbucketBranches, githubBranches, gitlabBranches])

  useEffect(() => {
    if (projectTemplates.length > 0)
      setValue(
        "project[integrationTemplate]",
        projectTemplates.find(
          template => template.id === project.integrationTemplateID
        )
      )
  }, [projectTemplates, project, setValue])

  const setAzureState = () => {
    setValue("project[connector][branch]", { name: connector.branch })
    setValue("project[connector][organization]", connector.organization)
    setValue("project[connector][project]", connector.project)
    setValue("project[connector][repository]", connector.repository)
    setBranchOptions(azureBranches)
  }

  const setBitbucketState = () => {
    setValue("project[connector][branch]", { name: connector.branch })
    setValue("project[connector][repository]", connector.repository)
    setBranchOptions(bitbucketBranches)
  }

  const setGithubState = () => {
    setValue("project[connector][branch]", { name: connector.branch })
    setValue("project[connector][repository]", connector.repository)
    setBranchOptions(githubBranches)
  }

  const setGitlabState = () => {
    setValue("project[connector][branch]", { name: connector.branch })
    setValue("project[connector][repository]", connector.repository)
    setBranchOptions(gitlabBranches)
  }

  const fields = [
    {
      name: "project[id]",
      type: "hidden",
      validations: { ...fieldRequired },
    },
    {
      name: "project[name]",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.projects.editForm.fields.projectName",
        defaultMessage: "Project Name",
      })}`,
      validations: { ...fieldRequired },
    },
    {
      name: "project[connector][isCuisto]",
      type: "hidden",
    },
    {
      name: "project[connector][mode]",
      type: "hidden",
    },
    {
      name: "project[connector][type]",
      type: "hidden",
      validations: { ...fieldRequired },
    },
  ]

  const azureFields = [
    {
      name: "project[connector][organization]",
      type: "hidden",
      validations: { ...fieldRequired },
    },
    {
      name: "project[connector][project]",
      type: "hidden",
      validations: { ...fieldRequired },
    },
  ]
  const gitFields = [
    {
      name: "project[connector][repository]",
      type: "hidden",
      validations: { ...fieldRequired },
    },
    {
      name: "project[connector][branch]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.editForm.fields.branchLabel",
        defaultMessage: "Target Branch",
      })}`,
      description: `${intl.formatMessage(
        {
          id: "components.projects.editForm.fields.branchDescription",
          defaultMessage:
            "this branch is watched for changes in your source language ({source})",
        },
        { source: localeName(source?.locale, intl) }
      )}`,
      validations: { ...fieldRequired },
      options: branchOptions,
    },
  ]
  const webhookField = [
    {
      name: "project[connector][webhookURL]",
      type: "text",
      validations: { ...validateURL },
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.api.webhookUrl.label",
        defaultMessage: "Webhook URL",
      })}`,
      description: "Where your translated data is sent to",
      placeholder: "https://example.com/my/webhook/endpoint",
    },
  ]

  const testApiField = {
    name: "project[testApiKey]",
    type: "copyToClip",
    label: intl.formatMessage({
      id: "components.projects.editForm.fields.testApiKey.label",
      defaultMessage: "Test API key",
    }),
    validations: { ...fieldRequired },
  }

  const apiFields = [
    {
      name: "project[liveApiKey]",
      type: "copyToClip",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.apiKey.label",
        defaultMessage: "Live API key",
      }),
      moreInfo: `${process.env.API_DOCUMENTATION_URL}/#section/Authentication`,
      validations: { ...fieldRequired },
    },
  ]
  const emailFields = [
    {
      name: "email",
      type: "copyToClip",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.email.label",
        defaultMessage: "Email address",
      }),
      description: intl.formatMessage({
        id: "components.projects.editForm.fields.email.description",
        defaultMessage: "The email address to give an access to your project",
      }),
      plaintext: true,
      readOnly: true,
      validations: { ...fieldRequired },
    },
  ]

  if (isGitConnector(project)) fields.push(...gitFields)
  if (connector.type === CONNECTORS.azurerepos) fields.push(...azureFields)
  if (isWebhookable(project)) fields.push(...webhookField)
  if (isCuistoProject(project)) fields.push(...cuistoFields)
  if (isApiConnector(project) && !isEmailable(project) && hasApiKey(project.connector.type)) {
    fields.push(...apiFields)
    if (hasTestApiKey(project)) fields.push(testApiField)
  }
  if (isEmailable(project)) fields.push(...emailFields)

  const isBranchChange = updatedBranchName => {
    if (!isGitConnector(project)) return false
    return connector.branch !== updatedBranchName
  }

  const onSubmit = async values => {
    const { translationLevel, ...payload } = values.project
    setStatus(STATUSES.loading)

    if (
      await ProjectUpdater.call({
        ...payload,
        cuistoConfig: JSON.stringify(payload.cuistoConfig),
        connector: { url: project.connector.url, ...payload.connector },
      })
    ) {
      setStatus(STATUSES.success)
      if (isBranchChange(project?.connector?.branch?.name))
        triggerQuote({ projectID: id })
    } else setStatus(STATUSES.error)
  }

  return (
    <>
      <Form
        errors={errors}
        fields={fields}
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        status={status}
      >
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            className="mt-4 btn btn--primary"
            disabled={errors?.length > 0 || status === STATUSES.loading}
          >
            {status === STATUSES.loading ? (
              <Spinner />
            ) : (
              intl.formatMessage({
                id: "components.projects.editForm.settingsSubmitLabel",
                defaultMessage: "Update Project",
              })
            )}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default EditForm
