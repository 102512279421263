import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Form } from "react-bootstrap"
import { useOnboarding } from "../../../Onboarding"
import TeamInviteForm from "../../../../components/vendor/team/TeamInviteForm"

const Team = ({ onboarding }) => {
  const { routeToNextStep, routeToBackStep, hasPreviousStep } = useOnboarding()

  const onSubmit = () => {
    routeToNextStep()
  }

  return (
    <div className="d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.team.h2"
            defaultMessage="Invite your team"
          />
        </h1>
      </div>
      <div className="onboarding-form">
        <div className="w-50">
          <Form.Label className="card-title">
            <FormattedMessage
              id="containers.onboarding.workspace.h2"
              defaultMessage="Invite the team members who can help you set up your Locale account."
            />
          </Form.Label>
          <TeamInviteForm noLabel={true} fullWidth={true} onSubmit={onSubmit} />
          <hr className="my-4" />
          <div className="d-flex">
            {hasPreviousStep &&
              <button
                className="btn btn-outline--secondary mr-5"
                onClick={() => routeToBackStep()}
              >
                <FormattedMessage
                  id="containers.onboarding.company.backBtn"
                  defaultMessage="Back"
                />
              </button>
            }
            <button
              className="btn btn--primary btn-primary mr-0"
              onClick={() => routeToNextStep()}
            >
              <FormattedMessage
                id="containers.onboarding.company.nextBtn"
                defaultMessage="Next"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
