import React, { useState } from "react"
import { Button, Alert } from "react-bootstrap"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Icon from "../../components/Icon"
import ExternalLink from "../../components/ExternalLink"
import ModalForm, { STATUSES } from "../forms/ModalForm"
import ProjectDestroyer from "../../service/projectDestroyer"
import { redirect } from "../../config/routes"
import { CONNECTORS } from "../../store/models/connectors"

const DeleteProjectButton = ({ project }) => {
  const { id, name, connector } = project
  const intl = useIntl()
  const [status, setStatus] = useState()

  const handleDelete = async () => {
    setStatus(STATUSES.loading)
    if (!(await ProjectDestroyer.call(id))) setStatus(STATUSES.error)
  }

  const Trigger = ({ onClick }) => (
    <Button variant="danger" onClick={onClick}>
      <Icon name="trash" className="mr-2" />
      <FormattedMessage
        id="components.projects.deleteProjectButton.button"
        defaultMessage="Delete {name}"
        values={{ name }}
      />
    </Button>
  )

  return (
    <ModalForm
      fields={[]}
      onSubmit={handleDelete}
      status={status}
      setStatus={setStatus}
      submitProps={{
        variant: "danger",
        title: `${intl.formatMessage({
          id: "components.projects.deleteProjectButton.modal.deleteButton",
          defaultMessage: "Permanently Delete",
        })}`,
      }}
      Trigger={Trigger}
    >
      <Alert variant="danger">
        <FormattedMessage
          id="components.projects.deleteProjectButton.modal.title"
          defaultMessage="Are you absolutely sure?"
        />
      </Alert>
      <h4>
        <FormattedMessage
          id="components.projects.deleteProjectButton.modal.body.paragraph1"
          defaultMessage="This action cannot be undone. This will permanently delete {projectName} from Locale."
          values={{ projectName: name }}
        />
      </h4>
      <p>
        <FormattedMessage
          id="components.projects.deleteProjectButton.modal.body.paragraph2"
          defaultMessage="This will not change your billing plan."
        />
      </p>
      {connector.type === CONNECTORS.gitlab && (
        <p>
          <FormattedMessage
            id="components.projects.deleteProjectButton.modal.body.paragraph3"
            defaultMessage="Please make sure you also remove the configured project webhook from your GitLab project <a>settings</a>. "
            values={{
              a: word => (
                <ExternalLink
                  href={`https://gitlab.com/${connector?.repository}/-/hooks`}
                  name={word}
                />
              ),
            }}
          />
        </p>
      )}
    </ModalForm>
  )
}

export default DeleteProjectButton
