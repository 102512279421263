import React from "react"
import { useStoreActions } from "easy-peasy"
import { navigate } from "gatsby-plugin-intl"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import Icon from "../Icon"

const SignOutLink = () => {
  const ldClient = useLDClient();
  const signOut = useStoreActions(actions => actions.currentUser.signOut)
  const setOrg = useStoreActions(actions => actions.currentOrg.set)
  const setVendor = useStoreActions(actions => actions.currentVendor.set)
  const setOnboarding = useStoreActions(actions => actions.onboarding.setAttrs)
  const handleSignOut = async () => {
    try {
      if (window) {
        window.addEventListener("message", (event) => {
          const clearedDomain = document.querySelectorAll(`iframe[src^="${event.origin}"]`);
          if (clearedDomain.length) clearedDomain[0].remove();
        });
        const currentLocation = window.location;
        const hostSplit = currentLocation.host.split(".");
        const vendorAgnosticHost = ["app", ...hostSplit.slice(1)].join(".");
        [vendorAgnosticHost, currentLocation.host].forEach(host => {
          const subdomainFrame = document.createElement("iframe");
          subdomainFrame.src = `${currentLocation.protocol}//${host}/storage/clear`;
          subdomainFrame.className = "subdomain-frame d-none";
          document.body.appendChild(subdomainFrame);
        });
      }
      await signOut()
      await ldClient.identify({ key: "anonymous", anonymous: false })
      setOrg({})
      setVendor({})
      setOnboarding(null)
      navigate("/")
    } catch (error) {
      console.log("error signing out: ", error)
    }
  }

  return (
    <button className="dropdown-item nav-link font-normal" onClick={handleSignOut}>
      <Icon name="log-out" className="user-dropdown__item__icon" />
      <FormattedMessage
        id="components.auth.signOutLink.button"
        defaultMessage="Sign out"
        description="auth sign out button"
      />
    </button>
  )
}

export default SignOutLink
