import React, { useEffect, useRef, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Row, Col, Tab, Nav, Image, Button } from "react-bootstrap"
import ProjectLocales from "../../components/projects/ProjectLocales"
import ProjectResources from "../../components/projects/ProjectResources"
import ContentResources from "../../components/projects/content/ListResources"
import ProjectStatus from "../../components/projects/ProjectStatus"
import Settings from "../../components/projects/Settings"
import { projectLogoURL } from "../../helpers/assets"
import { isResourceable, isContentable } from "../../helpers/project"
import Toast from "../../components/Toast"
import Project from "../../models/project"
import { API, graphqlOperation } from "aws-amplify"
import { onUpdateProjectLocale } from "../../graphql/locale-subscriptions"
import ServiceTypesModal from "../../components/projects/serviceTypes/Modal"
import GetTranslations from "../../components/projects/locale/GetTranslations"
import ProjectLink from "../../components/projects/ProjectLink"
import {
  isApiConnector,
  isDocumentTypeConnector,
  isLokaliseConnector,
} from "../../helpers/connector"

const ShowProject = ({ id, location }) => {
  const { fetch: fetchProject, updateProjectLocale } = useStoreActions(
    actions => actions.projects
  )
  const { updateLocale } = useStoreActions(actions => actions.project)

  const project =
    useStoreState(state => state.projects.byId(id)) || new Project()
  const resetForm = useStoreActions(actions => actions.projectWizard.resetForm)
  const isPartner = useStoreState(state => state.currentOrg.isPartner)
  const [activeKey, setActiveKey] = useState(location.state?.tab || "locales")
  const [showAnalysingToast, setShowAnalysingToast] = useState(false)
  const [showProcessedToast, setShowProcessedToast] = useState(false)
  const [showQuoteModal, setShowQuoteModal] = useState(false)
  const [showAllCompletedToast, setShowAllCompletedToast] = useState(false)
  const [showInProgressToast, setShowInProgressToast] = useState(false)
  const [hasAutoUpdate, setHasAutoUpdate] = useState(false)
  const connector = useStoreState(state =>
    state.connectors.getConnector(project?.connector?.type)
  )
  const lastOrderableState = useRef(null)
  const [projectFetched, setProjectFetched] = useState(false)
  if (connector?.logo) {
    project.connector.logoUrl = connector.logo
  }
  useEffect(() => {
    if (location?.state?.resetForm) resetForm()

    setActiveKey(location.state?.tab || "locales")
  }, [location.state])

  useEffect(() => {
    fetchProject(id).then(() => setProjectFetched(true))
  }, [id])

  useEffect(() => {
    return () => {
      if (!project.id) return
      lastOrderableState.current = null
      setProjectFetched(false)
      setShowAnalysingToast(false)
      setShowProcessedToast(false)
      setShowAllCompletedToast(false)
      setShowInProgressToast(false)
    }
  }, [project.id])

  useEffect(() => {
    if (!project?.id) return
    const onUpdateProjectLocaleSub = API.graphql(
      graphqlOperation(onUpdateProjectLocale, { owner: project.owner })
    ).subscribe({
      next: ({ value }) => {
        const updatedProjectLocale = value.data.onUpdateProjectLocale
        if (updatedProjectLocale?.projectID === project.id) {
          updateProjectLocale(updatedProjectLocale)
          updateLocale(updatedProjectLocale)
        }
      },
    })
    return () => onUpdateProjectLocaleSub.unsubscribe()
  }, [project?.id])

  useEffect(() => {
    if (project.atLeastOneInProgress) {
      setShowInProgressToast(true)
    } else if (project.allCompleted) {
      setShowAllCompletedToast(true)
    }
  }, [project.allCompleted, project.atLeastOneInProgress])

  useEffect(() => {
    if (!connector?.autoUpdateModes) return
    const autoUpdateSetting = connector?.autoUpdateModes.length > 0
    setHasAutoUpdate(
      autoUpdateSetting === undefined || autoUpdateSetting === true
    )
  }, [connector])

  useEffect(() => {
    if (!project.id) return
    if (project.hasQuoteInProgress) {
      setShowAnalysingToast(true)
    } else {
      setShowAnalysingToast(false)
    }
  }, [project.hasQuoteInProgress])

  const disableOpenQuoteModal = isPartner
    ? !project.isPushable
    : !project.canOrderTranslation

  useEffect(() => {
    if (!projectFetched) return
    if (!disableOpenQuoteModal && lastOrderableState.current === false) {
      setShowProcessedToast(true)
      setShowQuoteModal(true)
    } else {
      setShowProcessedToast(false)
    }
    lastOrderableState.current = !disableOpenQuoteModal
  }, [projectFetched, disableOpenQuoteModal])

  return (
    <>
      <Toast
        show={showAllCompletedToast}
        setShow={setShowAllCompletedToast}
        delay={10000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.projects.showProject.completedToast"
            defaultMessage="Translations complete 🤙"
          />
        </p>
      </Toast>
      <Toast
        show={showInProgressToast}
        setShow={setShowInProgressToast}
        delay={10000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.projects.showProject.progressToast"
            defaultMessage="Translations are in progress 🧐"
          />
        </p>
      </Toast>
      <Toast
        show={showAnalysingToast}
        setShow={setShowAnalysingToast}
        delay={10000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.projects.showProject.analysis.inProgress"
            defaultMessage="We are analysing your translation files… 🧐"
          />
        </p>
      </Toast>
      <Toast
        show={showProcessedToast}
        setShow={setShowProcessedToast}
        delay={10000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="containers.projects.showProject.analysis.filesProcessed"
            defaultMessage="Your files are processed 👌"
          />
        </p>
      </Toast>
      <div>
        <Row className="mx-1">
          <div className="format-logo">
            <Image
              src={projectLogoURL(project)}
              className="format-logo__image"
              rounded
              onError={e => {
                if (e.target.src !== projectLogoURL())
                  e.target.src = projectLogoURL()
              }}
            />
          </div>
          <Col className="d-flex flex-column justify-content-center align-items-start">
            <div className="d-flex align-items-center">
              <h1 className="text-gray-900 mb-0 mr-3">{project.name}</h1>
              {hasAutoUpdate ? <ProjectStatus project={project} /> : <></>}
            </div>

            <ProjectLink project={project} />
          </Col>
        </Row>
      </div>
      <Tab.Container activeKey={activeKey} onSelect={k => setActiveKey(k)}>
        <div className="mt-4 ml-6">
          <Nav
            variant="tabs"
            className="flex-nowrap justify-content-between align-items-end"
          >
            <Nav.Item className="ml-1 flex-grow-0">
              <Nav.Link
                eventKey="locales"
                className={`p-2 border-bottom-2 ${
                  activeKey === "locales" ? "border-green-500" : ""
                }`}
              >
                <FormattedMessage
                  id="containers.projects.showProject.tab.nav.locales"
                  defaultMessage="Languages"
                />
              </Nav.Link>
            </Nav.Item>
            {isResourceable(project) && (
              <Nav.Item className="flex-grow-0">
                <Nav.Link
                  eventKey="files"
                  className={`p-2 border-bottom-2 ${
                    activeKey === "files" ? "border-green-500" : ""
                  }`}
                >
                  <FormattedMessage
                    id="containers.projects.showProject.tab.nav.files"
                    defaultMessage="Files"
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            {isContentable(project) && (
              <Nav.Item className="flex-grow-0">
                <Nav.Link
                  eventKey="content"
                  className={`p-2 border-bottom-2 ${
                    activeKey === "content" ? "border-green-500" : ""
                  }`}
                >
                  <FormattedMessage
                    id="containers.projects.showProject.tab.nav.content"
                    defaultMessage="Content"
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item className="flex-grow-0">
              <Nav.Link
                eventKey="settings"
                className={`p-2 border-bottom-2 ${
                  activeKey === "settings" ? "border-green-500" : ""
                }`}
              >
                <FormattedMessage
                  id="containers.projects.showProject.tab.nav.settings"
                  defaultMessage="Settings"
                />
              </Nav.Link>
            </Nav.Item>
            <div className="d-flex justify-content-end w-30">
              {((!isApiConnector(project) &&
                !isLokaliseConnector(project) &&
                !isDocumentTypeConnector(project)) ||
                (isDocumentTypeConnector(project) &&
                  !project.allCompleted &&
                  !project.allInProgress)) &&
                (isPartner ? (
                  <GetTranslations
                    project={project}
                    openModal={showQuoteModal}
                    onModalClose={() => setShowQuoteModal(false)}
                    className="mb-1"
                    triggerDisabled={disableOpenQuoteModal}
                  />
                ) : (
                  <ServiceTypesModal />
                ))}
            </div>
          </Nav>
        </div>
        <div>
          <Tab.Content>
            <Tab.Pane eventKey="locales">
              <ProjectLocales project={project} />
            </Tab.Pane>
            <Tab.Pane eventKey="files">
              <ProjectResources project={project} />
            </Tab.Pane>
            <Tab.Pane eventKey="content">
              <ContentResources project={project} />
            </Tab.Pane>
            <Tab.Pane eventKey="settings">
              <Settings project={project} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  )
}

export default ShowProject
