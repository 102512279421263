import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import Price from "./Price"
export const CurrentPlanDetails = () => {
  const { subscription, hasNoSubscriptions } = useStoreState(
    state => state.billing
  )
  if (hasNoSubscriptions) return null

  return (
    <div className="card">
      <div className="card-body p-2_1">
        <div className="row align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-muted mb-2">
              <FormattedMessage
                id="components.billing.currentPlan.label"
                defaultMessage="Current Plan"
              />
            </h6>
            <span className="h2 mb-0">{subscription?.details?.planName}</span>
          </div>
          <div className="col-auto">
            <h6 className="text-uppercase text-muted mb-2">
              <FormattedMessage
                id="components.billing.currentPlan.price.label"
                defaultMessage="Price"
              />
            </h6>
            <span className="h3 mb-0">
              <Price
                amount={Math.round(subscription?.details?.amount / 100)}
                currency={subscription?.details?.currency}
              />
              /{subscription?.details?.billingFrequency}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
