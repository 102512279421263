import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { path } from "../../config/routes"
import Icon from "../Icon"

const ProjectStatus = ({ project }) => {

  return (
    <Link
      to={path("PROJECT", { id: project.id })}
      state={{ tab: "settings" }}
      className="project-status"
    >
      {project.isActive ? (
        <>
          <span className="project-status__icon text-green-500">
            <Icon name="refresh-cw" />
          </span>
          <span className="text-gray-700 mx-2">
            <FormattedMessage
              id="components.projects.projectStatus.autoUpdate"
              defaultMessage="Auto-Update"
            />
          </span>
        </>
      ) : (
        <>
          <span className="project-status__icon text-red-600">
            <Icon name="alert-circle" className="font-weight-bold" />
          </span>
          <span className="text-gray-700 mx-2">
            <FormattedMessage
              id="components.projects.projectStatus.manual"
              defaultMessage="Manual"
            />
          </span>
        </>
      )}
    </Link>
  )
}
export default ProjectStatus
