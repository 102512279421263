import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Button } from "react-bootstrap";
import ModalForm from "./ModalForm";
import Icon from "../../Icon";

const Edit = ({
  resourcePatternIndex,
  resourcePattern,
  project,
}) => {
  const intl = useIntl();

  const Trigger = ({ onClick }) => (
    <Button className="dropdown-item" variant="text" onClick={onClick}>
      <Icon
        name="edit"
        title={intl.formatMessage({
          id: "components.projects.resourcePattern.edit.trigger.button",
          defaultMessage: "Edit",
        })}
      />
    </Button>
  );

  return (
    <ModalForm
      formAction="update"
      title={intl.formatMessage({
        id: "components.projects.resourcePattern.edit.modal.title",
        defaultMessage: "Update File Path",
      })}
      Trigger={Trigger}
      resourcePattern={resourcePattern}
      index={resourcePatternIndex}
      project={project}
    />
  );
};

export default Edit;
