import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Helmet from "react-helmet"
import { Button } from "react-bootstrap"
import OpenPortal from "../components/billing/OpenPortal"

const BillingPage = () => {
  const [renderChargebee, setRenderChargebee] = useState(false)
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8 mt-5">
          <h2 className=" mt-4">
            <FormattedMessage
              id="containers.billing.title"
              defaultMessage="Billing"
            />
          </h2>
          <Helmet>
            <script
              src="https://js.chargebee.com/v2/chargebee.js"
              data-cb-site={process.env.CHARGEBEE_SITE}
            />
          </Helmet>
          {renderChargebee ? (
            <OpenPortal setRenderChargebee={setRenderChargebee} />
          ) : (
            <Button
              onClick={() => setRenderChargebee(true)}
              className="btn btn--primary"
            >
              <FormattedMessage
                id="components.billing.openPortalButton"
                defaultMessage="Manage account"
                description="Manage account portal button"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BillingPage
