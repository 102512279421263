import React, { useEffect, useState } from "react"
import { useStoreState } from "easy-peasy"
import { useParams } from "@reach/router"
import { useIntl } from "gatsby-plugin-intl"
import Helmet from "react-helmet"
import { API } from "aws-amplify"
import waitForGlobal from "../../utils/waitForGlobal"

const Checkout = ({ redirectTo }) => {
  const intl = useIntl()
  const { item_price_id } = useParams()
  const [chargebee, setChargebee] = useState(null)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const currentVendor = useStoreState(state => state.currentVendor.item)

  useEffect(() => {
    const initChargebee = () => {
      window.Chargebee.init({
        isItemsModel: true,
        site: `${process.env.CHARGEBEE_SITE}`
      })
      setChargebee(window.Chargebee.getInstance())
    }
    waitForGlobal("Chargebee", initChargebee)
  }, [])

  useEffect(() => {
    if (!chargebee || !intl || !item_price_id) return
    if (!currentOrg && !currentVendor) return
    chargebee.openCheckout({
      hostedPage: () => API.get("nepal", "/chargebee/checkout-token", {
        queryStringParameters: {
          item_price_id,
          locale: intl.locale,
          organizationID: currentOrg.id,
          vendorID: !currentOrg.id ? currentVendor.id : undefined,
          redirectTo,
        },
      })
    })
  }, [chargebee, currentOrg, currentVendor, intl, item_price_id])

  return (
    <>
      <Helmet>
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </Helmet>
    </>
  )
}

export default Checkout
