import React from "react"
import { useStoreState } from "easy-peasy"
import ConfigureModal from "./ConfigureModal"
import { generateRedirectUrl } from "../../helpers/connector"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { stateGenerator } from "../../helpers/application"
import Spinner from "../common/Spinner"
import { Row, Col } from "react-bootstrap"
import { path } from "../../config/routes"
import { navigate } from "gatsby-plugin-intl"

const ConnectorInstaller = ({ connectorId }) => {
  const connector = useStoreState(state => state.connectors.byId(connectorId))
  const redirectUrl = generateRedirectUrl(connectorId);
  const onExit = () => {
    navigate(path("SETTINGS") + path("CONNECTORS"))
  }
  if (redirectUrl) {
    const redirectTo = new URL(redirectUrl)
    redirectTo.searchParams.append(
      "state",
      stateGenerator({
        redirectURL: `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(
          connector?.id
        )}`,
      })
    )
    if (typeof window !== "undefined") {
      window.location.href = redirectTo.toString()
    }
  }

  return connector?.id && !redirectUrl ? (
    <ConfigureModal connector={connector} isOpen={true} onExit={onExit} />
  ) : (
    <Row>
      <Col className="mt-5 text-center">
        <Spinner />
      </Col>
    </Row>
  )
}

export default ConnectorInstaller
