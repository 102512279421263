import React from "react"
import Icon from "../Icon"

const DropdownToggleEllipse = React.forwardRef(({ icon, onClick }, ref) => {
  return (
    <a
      href=""
      className="dropdown-ellipses dropdown-toggle"
      role="button"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <Icon name={icon || "more-vertical"} />
    </a>
  )
})

const DropdownMenuEllipse = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    )
  }
)

export { DropdownToggleEllipse, DropdownMenuEllipse }
