import React, { useState } from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import { Button, Card } from "react-bootstrap"
import { STATUSES } from "../../components/layout/FormContainer"
import { fieldRequired } from "../../utils/validations"
import OrgUpdater from "../../service/orgUpdator"
import Form from "../../components/forms/Form"
import Spinner from "../../components/common/Spinner"


let timeout = null

const General = () => {
  const intl = useIntl()
  const currentOrg = useStoreState(state => state.currentOrg.item)

  const [status, setStatus] = useState()
  const { handleSubmit, errors, control } = useForm({
    mode: "onChange",
    defaultValues: {
      "organization[id]": currentOrg?.id,
      "organization[vendorID]": currentOrg?.vendorID,
      "organization[name]": currentOrg?.name,
    },
  })

  const setSuccess = () => {
    setStatus(STATUSES.success)

    timeout = setTimeout(() => {
      setStatus()
    }, 5000)
  }

  const onSubmit = async ({ organization }) => {
    if (timeout) clearTimeout(timeout)

    setStatus(STATUSES.loading)
    ;(await OrgUpdater.call(organization))
      ? setSuccess()
      : setStatus(STATUSES.error)
  }

  const fields = [
    {
      name: "organization[id]",
      type: "hidden",
      defaultValue: currentOrg.id,
    },
    {
      name: "organization[vendorID]",
      type: "hidden",
      defaultValue: currentOrg.vendorID,
    },
    {
      name: "organization[name]",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.organization.settings.nameField",
        defaultMessage: "Company name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentOrg.name,
    },
  ]

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8 mt-5">
          <Card>
            <Card.Body>
              <Form
                errors={errors}
                fields={fields}
                control={control}
                className="flex-column"
              >
                <div className="mt-4">
                  <Button
                    className="btn btn--primary"
                    disabled={errors?.length > 0 || status === STATUSES.loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {status === STATUSES.loading ? (
                      <Spinner />
                    ) : status === STATUSES.success ? (
                      <FormattedMessage
                        id="components.forms.modalForm.modalFooter.updated"
                        defaultMessage="Updated"
                      />
                    ) : (
                      <FormattedMessage
                        id="components.forms.modalForm.modalFooter.saveButton"
                        defaultMessage="Save"
                        description="modal save button"
                      />
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default General
