import React, { useState } from "react"
import Collapse from 'react-bootstrap/Collapse'
import Actions from "../../common/Actions"
import Icon from "../../Icon"
import EditResource from "./Edit"
import RemoveResource from "./Remove"

const ProjectResourceListRow = (props) => {
  const { project, resourceList, resourceListIndex } = props;
  const [open, setOpen] = useState(false);
  const [collapseIcon, setCollapseIcon] = useState("chevron-right");

  const EditLink = () => (
    <EditResource project={project} resourceIndex={resourceListIndex} />
  )

  const RemoveLink = () => (
    <RemoveResource project={project} resourceIndex={resourceListIndex} />
  )

  const onToggle = () => setOpen(!open);

  const sourceRow = () => {
    const resource = resourceList.find(resource => resource.isSource)
    if (!resource) {
      return null;
    }
    return (
      <tr key={`resource-${resourceListIndex}-locale-${resource.projectLocaleID}`}>
        <td className="align-top text-center" onClick={onToggle} onKeyDown={onToggle} role="presentation">
          <span className="text-primary">
            <Icon name={collapseIcon} className="mr-2" />
          </span>
        </td>
        <td className="align-top" colSpan="2" onClick={onToggle} onKeyDown={onToggle} role="presentation">
          {resource.path}
        </td>
        <td className="align-top">
          <Actions actions={[EditLink(resourceListIndex), RemoveLink(resourceListIndex)]} />
        </td>
      </tr>
    )
  }

  const targetRows = () => resourceList.filter(resource => !resource.isSource).map(resource => (
    <Collapse
      key={`resource-${resourceListIndex}-locale-${resource.projectLocaleID}`}
      in={open}
      onExited={() => setCollapseIcon("chevron-right")}
      onEnter={() => setCollapseIcon("chevron-down")}
    >
      <tr>
        <td />
        <td className="align-top">
          <h4 className="mb-0 text-secondary text-nowrap">{resource.locale.code}</h4>
        </td>
        <td className="align-top">{resource.path}</td>
        <td />
      </tr>
    </Collapse>
  ))

  return (
    <>
      { sourceRow() }
      { targetRows() }
    </>
  )
  
}

export default ProjectResourceListRow
