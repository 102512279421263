import React, { useEffect, useState } from "react"
import { Tab, Button, Container } from "react-bootstrap"
import { PlanSelect } from "./PlanSelect"
import { CardSelect } from "./CardSelect"
import { CurrencySelect } from "./CurrencySelect"
import { useStoreActions, useStoreState } from "easy-peasy"
import { TermRadio } from "./Term"
import { useFormContext } from "react-hook-form"
import Icon from "../Icon"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Spinner from "../common/Spinner"
import { getPlanVolume } from "../../models/billing"

export const PlansTabPane = ({
  back,
  eventKey,
  familyId,
  onAddNewCard,
  onPay,
  isSubmitting,
  filterByVolume,
  hasUpgradeDowngradeOptions,
}) => {
  const intl = useIntl()
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { fetchAllFamilyPlans, fetchSubscription } = useStoreActions(actions => actions.billing)
  const { familyPlansCurFreq, isAllPlansLoaded, hasNoSubscriptions } = useStoreState(state => state.billing)
  const [price, setPrice] = useState()
  const [nextButtonText, setNextButtonText] = useState("Pay {price}")
  const { control, register, setValue, watch } = useFormContext()
  const { currency, plan, term } = watch(["currency", "plan", "term"])
  const [plans, setPlans] = useState(familyPlansCurFreq?.[familyId]?.[currency?.value]?.[term])
  const quote = useStoreState(state => state.quote)
  const { subscription } = useStoreState(
    state => state.billing
  )

  useEffect(() => {
    if (subscription.credits || !currentOrg.id || !hasUpgradeDowngradeOptions) return
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg, subscription.credits])

  useEffect(() => {
    if (!familyId || isAllPlansLoaded(familyId)) return
    fetchAllFamilyPlans({ familyId })
  }, [fetchAllFamilyPlans, familyId])

  useEffect(() => {
    if (!plan) return
    setPrice(intl.formatNumber(plan.price / 100, {
      style: "currency",
      currency: plan.currency_code,
      minimumFractionDigits: 0,
    }))
    if(!hasUpgradeDowngradeOptions || hasNoSubscriptions) return
    let buttonText = getPlanVolume(plan) > subscription?.credits?.limit ? "Upgrade" : "Downgrade";
    if(getPlanVolume(plan) === subscription?.credits?.limit) buttonText = "Pay {price}";
    setNextButtonText(buttonText)
  }, [plan])

  useEffect(()=>{
    if(!familyPlansCurFreq?.[familyId]) return
    let plansSelectionList = familyPlansCurFreq?.[familyId]?.[currency?.value]?.[term] || [];
    if(filterByVolume) plansSelectionList = plansSelectionList.filter(({ features }) => features.find(({value}) => +value >= quote.words ));
    setPlans(plansSelectionList)
  },[currency, term, familyId, familyPlansCurFreq, quote.words])

  return (
    <Tab.Pane eventKey={eventKey}>
      <div
      // className="d-flex flex-column overflow-hidden min-vh-100 vh-100"
      >
        <Container className="mt-4">
          <div class="row align-items-center justify-content-between mb-4">
            <div class="col-md-auto">
              <CurrencySelect
                control={control}
                setValue={setValue}
                defaultCurrency={
                  hasUpgradeDowngradeOptions ? subscription?.details?.currency : null
                }
              />
            </div>
            <div class="col-auto">
              <TermRadio
                register={register}
                setValue={setValue}
                defaultTerm={
                  hasUpgradeDowngradeOptions ? subscription?.details?.billingFrequency : null
                }
              />
            </div>
          </div>
          <PlanSelect
            familyId={familyId}
            plans={plans}
            defaultPlanId={
              hasUpgradeDowngradeOptions ? subscription?.details?.planId : null
            }
            control={control}
            setValue={setValue}
          />
          <div className="custom-modal-footer">
            <CardSelect
              control={control}
              setValue={setValue}
              onAddNewCard={onAddNewCard}
            />
            <Button
              className="mt-4 w-100"
              onClick={async () => onPay()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner />
              ) : (
                <FormattedMessage
                  id="src.components.billing.PlansTabPane.nextButton"
                  defaultMessage={nextButtonText}
                  values={{ price }}
                />
              ) }
            </Button>
            { back && <Button variant="link" onClick={() => back()}>
              <Icon name="arrow-left" className="mr-2" />
              <FormattedMessage
                id="src.components.billing.PlansTabPane.backButton"
                defaultMessage="Back"
              />
            </Button>}
          </div>
        </Container>
      </div>
    </Tab.Pane>
  )
}
