import React, { useState, useEffect } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { Accordion } from "react-bootstrap"
import { useOnboarding } from "../../../containers/Onboarding"
import { STATUSES } from "../../layout/FormContainer"
import TeamModal from "../../team/TeamModal"
import StepField from "../../forms/StepField"
import Toast from "../../Toast"
import { isValidEmail } from "../../../helpers/email"

const EVENT_KEYS = {
  NAME: "name",
}

const EmailForm = ({ isOnboarding, onSubmit, integration }) => {
  const intl = useIntl()
  const [status, setStatus] = useState()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showTeamInviteToast, setShowTeamInviteToast] = useState(false)
  const defaultValues = {
    "integration[emailAddresses]": integration.emailAddresses?.join(", ") || "",
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[vendorID]": integration.vendorID,
  }
  const {
    handleSubmit,
    errors,
    control,
    clearErrors,
    setError,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
  })
  const emailAddresses = useWatch({
    control,
    name: "integration[emailAddresses]",
  })


  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const [activeKey, setActiveKey] = useState(EVENT_KEYS.NAME)

  const teamModalSubmit = () => setShowTeamInviteToast(true)

  const stepFields = [
    {
      eventKey: EVENT_KEYS.NAME,
      fields: [
        {
          name: "integration[groups]",
          type: "hidden",
        },
        {
          name: "integration[id]",
          type: "hidden",
        },
        {
          name: "integration[integrationTypeID]",
          type: "hidden",
        },
        {
          name: "integration[organizationID]",
          type: "hidden",
        },
        {
          name: "integration[vendorID]",
          type: "hidden",
        },
        {
          name: "integration[name]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.integrations.email.settings.fields.name",
            defaultMessage: "Integration Name",
          })}`,
        },
        {
          name: "integration[emailAddresses]",
          type: "textarea",
          label: `${intl.formatMessage({
            id: "components.catTool.settings.fields.email.addresses",
            defaultMessage: "Email addresses",
          })}`,
          rows: 3,
          placeholder: "john@example.com, alice@example.com",
        },
      ],
      images: [],
    },
  ]

  const currentStepFieldIndex = stepFields.findIndex(
    step => step.eventKey === activeKey
  )

  const nextStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex !== -1 && stepIndex + 1 < stepFields.length)
      setActiveKey(stepFields[stepIndex + 1].eventKey)
  }

  const backStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex === 0 && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (stepIndex !== -1 && stepIndex - 1 >= 0)
      setActiveKey(stepFields[stepIndex - 1].eventKey)
  }

  useEffect(() => {
    if (!integration) return
    reset(defaultValues)
  }, [reset, integration])

  useEffect(() => {
    if (emailAddresses || currentStepFieldIndex >= 0) clearErrors()
  }, [emailAddresses, clearErrors, currentStepFieldIndex])

  const checkAndSubmitForm = async ({ integration }) => {
    try {
      clearErrors()
      integration.emailAddresses = integration.emailAddresses.split(/[ ,\n]/).filter(Boolean)
      const { emailAddresses } = integration

      const requiredMessage = intl.formatMessage({
        id: "src.components.catTool.Settings.required",
        defaultMessage: "This is required.",
      })

      if (activeKey === EVENT_KEYS.NAME) {
        if (emailAddresses.length === 0) {
          setError("integration[emailAddresses]", {
            type: "manual",
            message: requiredMessage,
          })
          return
        }
        setStatus(STATUSES.loading)
        const emailErrors = emailAddresses.reduce((acc, email) => {
          if (isValidEmail(email)) return acc
          acc.push(intl.formatMessage({
            id: "components.integrations.emails.settings.fields.addresses",
            defaultMessage: "{email} is not a valid email address.",
          }, { email }))
          return acc;
        }, [])
        if (emailErrors.length > 0) {
          setError("integration[emailAddresses]", {
            type: "manual",
            message: emailErrors.join(" "),
          })
          setStatus(STATUSES.error)
          return
        }
        setStatus(STATUSES.success)
      }

      if (currentStepFieldIndex < stepFields.length - 1) {
        nextStep()
      } else {
        setStatus(STATUSES.loading)
        if (isOnboarding) routeToNextStep()
        const data = await onSubmit({ integration })
        if (data) {
          setStatus(STATUSES.success)
          !isOnboarding && setShowSuccessToast(true)
        } else setStatus(STATUSES.error)
      }
      return true
    } catch (e) {
      setStatus(STATUSES.error)
      return false
    }
  }

  return (
    <>
    <div className="d-flex align-items-center justify-content-between mb-5">
      <TeamModal onSubmit={teamModalSubmit} />
    </div>
    <Toast show={showTeamInviteToast} setShow={setShowTeamInviteToast}>
      <p className="text-gray-50 font-normal mb-0">
        <FormattedMessage
          id="src.components.catTool.inviteToast"
          defaultMessage="Successfully invited team member ✨"
        />
      </p>
    </Toast>
    <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
      <p className="text-gray-50 font-normal mb-0">
        <FormattedMessage
          id="components.integrations.emails.settings.successToast"
          defaultMessage="Successfully updated email addresses ✨"
        />
      </p>
    </Toast>
    <div className="onboarding-form">
      <div className="form-group">
        <Accordion activeKey={activeKey}>
          {stepFields.map((step, i) => (
            <StepField
              index={i}
              {...step}
              control={control}
              errors={errors}
              onSubmit={handleSubmit(checkAndSubmitForm)}
              noBack={i === 0 && !isOnboarding}
              onBack={backStep}
              status={status}
              key={i}
            />
          ))}
        </Accordion>
      </div>
    </div>
  </>
  )
}

export default EmailForm
