import React, { useState, useEffect } from "react"
import { API } from "aws-amplify"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import Spinner from "../common/Spinner"
import { STATUSES } from "../layout/FormContainer"
import OpenPaymentMethod from "../billing/OpenPaymentMethod"
import { useStoreState } from "easy-peasy"

const StartSyncButton = ({
  handleSubmit,
  status,
  setStatus,
  setIsPaid,
  projectID,
  projectLocaleIDs,
  mode
}) => {
  const [renderChargebee, setRenderChargebee] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const isPartner = useStoreState(state => state.currentOrg.isPartner)

  useEffect(() => {
    projectLocaleIDs.length === 0 ? setDisabled(true) : setDisabled(false)
  }, [projectLocaleIDs])

  const triggerSynchronization = handleSubmit(async values => {
    setStatus(STATUSES.loading)
    setDisabled(true)

    API.post("nepal", "/instance/trigger", {
      body: {
        projectID,
        mode,
        projectLocaleIDs,
        ...(values?.project?.integrationTemplate
          ? { projectTemplateID: values.project.integrationTemplate.id }
          : {}),
      },
    })
    .then(() => {
        setStatus(STATUSES.success)
    })
    .catch(e => {
      if (e.response.status === 402) setRenderChargebee(true)
      console.error(e)
      setStatus(null)
    })
  })

  const onChargebeeSuccess = () => {
    setRenderChargebee(false)
    triggerSynchronization()
  }

  return (
    <>
      {renderChargebee ? (
        <OpenPaymentMethod
          setRenderChargebee={setRenderChargebee}
          onChargebeeSuccess={onChargebeeSuccess}
        />
      ) : (
        <Button
          className="btn--primary mt-4 w-100"
          onClick={triggerSynchronization}
          disabled={disabled}
        >
          {status === STATUSES.loading ? (
          <Spinner />
        ) : (
          <FormattedMessage
            id="components.projects.quoteCard.push"
            defaultMessage="Push content"
          />
        )}
        </Button>
      )}
    </>
  )
}

export default StartSyncButton
