import React from "react";
import ROUTES from "../../config/routes"
import { Router, useLocation } from "@reach/router"
import Team from "./onboarding/stages/Team";
import Workspace from "./onboarding/stages/Workspace";
import { OnboardingRoutes } from "../../models/onboarding";
import { useOnboarding } from "../Onboarding";
import PartnerIntegration from "./onboarding/stages/PartnerIntegration";
import { parse, stringify } from "query-string"
import Integrations from "../onboarding/stages/Integrations";
import ScheduleOnboarding from "../onboarding/stages/ScheduleOnboarding";
import parseBasePath from "../../helpers/path";
import { ProgressBar } from "react-bootstrap";
import Plans from "../onboarding/stages/Plans";
import CheckoutPlan from "../onboarding/stages/CheckoutPlan";
import CheckoutSuccess from "../onboarding/stages/CheckoutSuccess";
import Congratulations from "../onboarding/stages/Congratulations";
import Spinner from "../../components/common/Spinner";
import { FormattedMessage } from "gatsby-plugin-intl";

export const ONBOARDING_ROOT = `${ROUTES.SIGN_UP.path}`

export const stagePath = (stageRoute, type, search) => {
  const query = stringify({ ...parse(search), type })
  return `${ONBOARDING_ROOT}${stageRoute}?${query}`
}

const Onboarding = () => {
  const location = useLocation()
  const { onboarding, onboardingLoading } = useOnboarding()

  const progress = (onboarding?.getStepFromLocation(
    parseBasePath(ROUTES.SIGN_UP.path, location.pathname)
  ) / onboarding?.length) * 100

  if(onboardingLoading || !onboarding) {
    return <div className="d-flex flex-column justify-content-center align-items-center p-5">
      <Spinner className="mb-4" />
      <FormattedMessage id="containers.onboarding.settingUpYourAccount" defaultMessage="Setting up your account" />
    </div>
  }

  return <>
    <ProgressBar className="progress-bar--custom mb-3" now={progress} />
    <Router location={location}>
      <Workspace
        path={OnboardingRoutes.WORKSPACE.path}
        onboarding={onboarding}
      />
      <Team
        path={OnboardingRoutes.TEAM.path}
        onboarding={onboarding}
      />
      <PartnerIntegration
        partner={onboarding.partner}
        path={OnboardingRoutes.PARTNER_INTEGRATION.path}
        onboarding={onboarding}
      />
      <Integrations
        onboarding={onboarding}
        path={OnboardingRoutes.INTEGRATION.path}
      />
      <ScheduleOnboarding
        onboarding={onboarding}
        path={OnboardingRoutes.SCHEDULE_ONBOARDING.path}
      />
      <Plans
        onboarding={onboarding}
        familyId="LSP"
        frequency="year"
        path={OnboardingRoutes.PLANS.path}
      />
      <CheckoutPlan
        onboarding={onboarding}
        path={OnboardingRoutes.CHECKOUT.path}
      />
      <CheckoutSuccess
        onboarding={onboarding}
        path={OnboardingRoutes.CHECKOUT_SUCCESS.path}
      />
      <Congratulations
        onboarding={onboarding}
        path={OnboardingRoutes.CONGRATULATIONS.path}
      />
    </Router>
  </>
}

export default Onboarding;