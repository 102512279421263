import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { FormattedMessage } from "gatsby-plugin-intl";
import { useController, useWatch } from "react-hook-form";
import Select from "react-select";

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#00D792",
  },
});

const ProjectTMSIntegration = ({ className, setValue, control, project }) => {
  const integrations = useStoreState(state => state.integrations.items);
  const fetchIntegrations = useStoreActions(action => action.integrations.fetch);
  const projectTemplates = useStoreState(state => state.projectTemplates.items);
  const fetchProjectTemplates = useStoreActions(actions => actions.projectTemplates.fetch)

  const {
    field: { ref: integrationRef, ...integrationInputProps },
  } = useController({
    name: "integration",
    control,
  })

  const {
    field: { projectTemplateRef, ...projectTemplateInputProps },
  } = useController({
    name: "template",
    control,
  })

  const integration = useWatch({
    control,
    name: "integration",
    defaultValue: integrations.find((integration) => (project.integrationID === integration.id)),
  })

  useEffect(() => {
    if (!integrations.length && project) {
      fetchIntegrations({ organizationID: project.organizationID }).then(() => {
        setValue('integration', integrations.find((item) => (project.integrationID === item.id)))
      });
    }
  }, [])

  useEffect(() => {
    if(integration) {
      setValue('template', null);
    }

    if (integration?.integrationType?.configuration?.hasIntegrationTemplate) {
      fetchProjectTemplates(integration).then(() => {
        setValue("template", projectTemplates.find((template) => (project?.integrationTemplateID === template.id)))
      });
    }
  }, [
    integration,
  ])

  return (
    <div className="card">
      <div className="card-body">
        <label className="d-inline-block">
          <FormattedMessage
            id="components.projects.settings.projectTMSIntegration.integration"
            defaultMessage="Integration"
          />
        </label>
        <Select
          {...integrationInputProps}
          inputRef={integrationRef}
          options={integrations}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          theme={theme}
        />

        {integration?.integrationType?.configuration?.hasIntegrationTemplate && (
          <div className="w-full mt-2">
            <label className="d-inline-block">
              <FormattedMessage
                id="components.projects.settings.projectTMSIntegration.template"
                defaultMessage="Project Template"
              />
            </label>
            <Select
              {...projectTemplateInputProps}
              inputRef={projectTemplateRef}
              options={projectTemplates}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              theme={theme}
            />
          </div>
      )}
      </div>
    </div>
  )
}

export default ProjectTMSIntegration;
