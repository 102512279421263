import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Logo from "../../img/logo-diap.png"
import DarkLogo from "../../img/logo.png"

const Brand = ({ className, classNameImg, dark, showPoweredBy }) => (
  <div className={className}>
    {showPoweredBy && (
      <span
        className={`${
          dark ? "text-gray-500" : "text-gray-400"
        } font-weight-bold text-xs`}
      >
        <FormattedMessage
          id="components.layout.admin.sidebar.brand.poweredBy"
          defaultMessage="Powered by"
        />
      </span>
    )}
    <img
      src={dark ? DarkLogo : Logo}
      alt="Locale"
      className={classNameImg}
      height="48"
    />
  </div>
)

export default Brand
