import React, { useState } from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import Form from '../components/forms/Form'
import Icon from "../components/Icon"
import { STATUSES } from "../components/layout/FormContainer"
import { fieldRequired } from "../utils/validations"
import UserUpdater from "../service/userUpdater"
import { Button } from "react-bootstrap"
import { redirect } from "../config/routes"
import Spinner from "../components/common/Spinner"

export const UserProfileTrigger = () => (
  <button
    className="dropdown-item nav-link font-normal"
    onClick={() => redirect("PROFILE")}
  >
    <Icon name="user" className="user-dropdown__item__icon" />
    <FormattedMessage
      id="components.profile.settings.button"
      defaultMessage="Profile"
    />
  </button>
)

let timeout = null

const UserProfile = () => {
  const intl = useIntl()
  const currentUser = useStoreState(state => state.currentUser.item)
  const [status, setStatus] = useState()
  const { handleSubmit, errors, control } = useForm({
    mode: "onChange",
  })

  const setSuccess = () => {
    setStatus(STATUSES.success)

    timeout = setTimeout(() => {
      setStatus()
    }, 5000)
  }

  const onSubmit = async payload => {
    if(timeout) clearTimeout(timeout)

    setStatus(STATUSES.loading)
    await UserUpdater.call(payload)
      ? setSuccess()
      : setStatus(STATUSES.error)
  }

  const fields = [
    {
      name: "given_name",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.profile.settings.nameField",
        defaultMessage: "Name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentUser.given_name,
    },
  ]

  return (
    <div className="w-60">
      <h1>
        <FormattedMessage
          id="components.profile.settings.modal.title"
          defaultMessage="Update your profile"
        />
      </h1>
      <Form
        title={intl.formatMessage({
          id: "components.projects.form.formTitle",
          defaultMessage: "Add a project for translation",
        })}
        errors={errors}
        fields={fields}
        control={control}
        className="flex-column"
      >
        <div className="mt-4">
          <Button className="btn btn--primary" onClick={handleSubmit(onSubmit)}>
            {status === STATUSES.loading ? (
              <Spinner />
            ) : status === STATUSES.success ? (
              <FormattedMessage
                id="components.forms.modalForm.modalFooter.updated"
                defaultMessage="Updated"
              />
            ) : (
              <FormattedMessage
                id="components.forms.modalForm.modalFooter.saveButton"
                defaultMessage="Save"
                description="modal save button"
              />
            )}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default UserProfile
