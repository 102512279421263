import { API } from "aws-amplify";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { mapTypeToFieldType } from "../../../helpers/cuisto";
import { fieldRequired } from "../../../utils/validations";
import { Accordion } from "react-bootstrap"
import StepField from "../../forms/StepField";
import { useOnboarding } from "../../../containers/Onboarding";
import { STATUSES } from "../../layout/FormContainer";
import Toast from "../../Toast"
import Spinner from "../../common/Spinner"

const CuistoProcessorForm = ({ integrationTypeID, isOnboarding, integration, onSubmit }) => {
  const [dependsOn, setDependsOn] = useState({});
  const [activeKey, setActiveKey] = useState('step-0');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const intl = useIntl();

  const defaultValues = {
    "integration[isCuisto]": true,
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[vendorID]": integration.vendorID,
  };

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const stepFields = [
    {
      eventKey: `step-0`,
      title: 'Name',
      fields: [
        {
          name: "integration[isCuisto]",
          type: "hidden",
        },
        {
          name: "integration[groups]",
          type: "hidden",
        },
        {
          name: "integration[id]",
          type: "hidden",
        },
        {
          name: "integration[integrationTypeID]",
          type: "hidden",
        },
        {
          name: "integration[organizationID]",
          type: "hidden",
        },
        {
          name: "integration[vendorID]",
          type: "hidden",
        },
        {
          name: "integration[name]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.integrations.email.settings.fields.name",
            defaultMessage: "Integration Name",
          })}`,
        },
      ],
      images: [],
    }
  ]

  stepFields.push(...Object.entries(dependsOn).map(([key, { type, name, description, options }], index) => ({
    eventKey: `step-${index + 1}`,
    title: name,
    description,
    fields: [
      {
        description,
        label: name ?? key,
        name: `integration[cuistoConfig][${key}]`,
        type: mapTypeToFieldType(type),
        options
      }
    ],
    images: [],
  })));

  const getCurrentFieldIndex = () => {
    return Number(activeKey.replace('step-', ''));
  }

  const nextStep = () => {
    const index = getCurrentFieldIndex();
    if (index + 1 < stepFields.length) {
      setActiveKey(`step-${index + 1}`);
    }
  }

  const backStep = () => {
    const index = getCurrentFieldIndex()
    if (index === 0 && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (index !== -1 && index - 1 >= 0)
      setActiveKey(`step-${index - 1}`)
  }

  const checkAndSubmitForm = async ({ integration }) => {
    const currentIndex = getCurrentFieldIndex();

    try {
      if (currentIndex < stepFields.length - 1) {
        nextStep()
      } else {
        setStatus(STATUSES.loading)
        if (isOnboarding) routeToNextStep()
        integration.cuistoConfig = JSON.stringify(integration.cuistoConfig);
        const data = await onSubmit({ integration })
        if (data) {
          setStatus(STATUSES.success)
          !isOnboarding && setShowSuccessToast(true)
        } else setStatus(STATUSES.error)
      }

      return true
    } catch (e) {
      console.log(e)
      return false;
    }
  }

  useEffect(() => {
    if (integration.cuistoConfig) {
      const cuistoConfig = JSON.parse(integration.cuistoConfig);
      Object.entries(cuistoConfig).map(([key, value]) => {
        setValue(`integration[cuistoConfig][${key}]`, value);
      });
    }
  }, [integration.cuistoConfig, stepFields.length])

  useEffect(() => {
    if (isLoading) return

    setIsLoading(true);
    API.get("nepal", `/cuisto/integration-types/${integrationTypeID}/config`)
    .then((data) => {
      setDependsOn(data?.configuration ?? {})
    }).catch()
    .finally(() => setIsLoading(false))
  }, [integrationTypeID])

  return (
    <>
      {Object.values(dependsOn).length ? (
        <>
          <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
            <p className="text-gray-50 font-normal mb-0">
              <FormattedMessage
                id="components.integrations.cuisto.settings.successToast"
                defaultMessage="Successfully saved integration ✨"
              />
            </p>
          </Toast>
          <div className="onboarding-form">
            <div className="form-group">
              <Accordion activeKey={activeKey}>
                {stepFields.map((step, i) => (
                  <StepField
                    index={i}
                    {...step}
                    control={control}
                    errors={errors}
                    onSubmit={handleSubmit(checkAndSubmitForm)}
                    noBack={i === 0 && !isOnboarding}
                    onBack={backStep}
                    status={status}
                    key={i}
                  />
                ))}
              </Accordion>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}
    </>
  )
}

export default CuistoProcessorForm;
