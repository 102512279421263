import React, { useState, useEffect } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button, Alert } from "react-bootstrap"
import Icon from "../../Icon"
import ModalForm from "../../forms/ModalForm"
import { localeName } from "../../../helpers/locale"
import { STATUSES } from "../../layout/FormContainer"
import ProjectLocaleDestroyer from "../../../service/projectLocaleDestroyer"

const ProjectLocaleRemove = ({ projectLocale }) => {
  const intl = useIntl()
  const projectLocaleName = localeName(projectLocale.locale, intl)
  const [status, setStatus] = useState()

  const onSubmit = async () => {
    setStatus(STATUSES.loading)
    await ProjectLocaleDestroyer.call(projectLocale)
      ? setStatus(STATUSES.success)
      : setStatus(STATUSES.error)
  }

  const Trigger = ({ onClick }) => (
    <Button className="dropdown-item justify-content-start" variant="text" onClick={onClick}>
      <Icon
        name="trash"
        title={intl.formatMessage({
          id: "components.projects.locale.remove.trigger.button",
          defaultMessage: "Remove",
        })}
      />
    </Button>
  )

  return (
    <ModalForm
      fields={[]}
      onSubmit={onSubmit}
      status={status}
      setStatus={setStatus}
      submitProps={{
        variant: "danger",
        title: `${intl.formatMessage({
          id: "components.projects.locale.remove.modalForm.submitProps.Title",
          defaultMessage: "Remove from project",
        })}`,
      }}
      Trigger={Trigger}
    >
      <Alert variant="danger">
        <FormattedMessage
          id="components.projects.locale.remove.confirmationAlert"
          defaultMessage="Confirmation Required"
          description="modal remove locale from project confirmation alert"
        />
      </Alert>
      <h4>
        <FormattedMessage
          id="components.projects.locale.remove.header"
          defaultMessage="Are you sure you want to remove {language}?"
          description="modal remove locale from project confirmation header"
          values={{ language: projectLocaleName }}
        />
      </h4>
      <p>
        <FormattedMessage
          id="components.projects.locale.remove.paragraph"
          defaultMessage="This will remove {language} from your project - your source files will not be affected."
          description="modal remove locale from project confirmation paragraph"
          values={{ language: projectLocaleName }}
        />
      </p>
    </ModalForm>
  )
}

export default ProjectLocaleRemove
