import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import PageContainer from "../../components/layout/PageContainer"
import { INTEGRATION_IDS } from "../../models/integrationType"
import TradosForm from "../../components/integrations/trados/form"
import TextmasterForm from "../../components/integrations/textmaster/form"
import { useStoreActions, useStoreState } from "easy-peasy"
import MemsourceForm from "../../components/integrations/memsource/form"
import ProtemosForm from "../../components/integrations/protemos/form"
import MemoqForm from "../../components/integrations/memoq/form"
import EmailForm from "../../components/integrations/email/form"
import PlunetForm from "../../components/integrations/plunet/form"
import CuistoProcessorForm from "../../components/integrations/cuisto/form"

const Section = ({ integration }) => {
  const updateIntegration = useStoreActions(actions => actions.integrations.update)

  const onSubmit = async ({ integration }) => await updateIntegration(integration)

  if (integration.isCuisto) {
    return <CuistoProcessorForm integrationTypeID={integration.integrationTypeID} isOnboarding={false} onSubmit={onSubmit} integration={integration} />
  }

  const sectionForm = () => {
    switch (integration.integrationTypeID) {
      case INTEGRATION_IDS.email:
        return <EmailForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.trados:
        return <TradosForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.textmaster:
        return <TextmasterForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.memsource:
        return <MemsourceForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.protemos:
        return <ProtemosForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.plunet:
        return <PlunetForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      case INTEGRATION_IDS.memoq:
        return <MemoqForm isOnboarding={false} onSubmit={onSubmit} integration={integration} />
      default:
        return null
    }
  }
  return sectionForm()
}

const IntegrationEdit = ({ id }) => {
  const findIntegration = useStoreActions(actions => actions.integrations.find)
  const resetIntegration = useStoreActions(actions => actions.integrations.reset)
  const integration = useStoreState(state => state.integrations.item)

  useEffect(() => {
    if (!id) return
    findIntegration(id)
    return () => {
      resetIntegration()
    }
  }, [id, findIntegration, resetIntegration])

  return (
    <PageContainer className="d-flex flex-column">
      <h1 className="main-heading">
        <FormattedMessage
          id="containers.admin.settings.title"
          defaultMessage="Settings"
        />
      </h1>
        {integration && <Section integration={integration} />}
    </PageContainer>
  )
}

export default IntegrationEdit
