import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage } from "gatsby-plugin-intl"
import ProjectUpdater from "../../service/projectUpdater";
import Switch from "../forms/Switch"
import { useIntl } from "gatsby-plugin-intl"
import { useStoreState } from "easy-peasy"
import Form from "../forms/Form"
import SelectField from "../forms/SelectField";
import { Button } from "react-bootstrap";
import { STATUSES } from "../layout/FormContainer";
import Spinner from "../common/Spinner";

export default ({ project }) => {
  const intl = useIntl()
  const [status, setStatus] = useState();
  const [textmasterProjectSettings, setTextmasterProjectSettings] = useState({});
  const { errors, control, handleSubmit, setValue } = useForm()
  const expertises = useStoreState(state => state.expertises.items)
  const expertisesMerged = useStoreState(state => state.expertises.expertises)

  const clearExpertise = () => {
    setValue('project[textmasterProjectSettings][expertise]', null);
  };

  const updateTextmasterProjectSettings = async (_textmasterProjectSettings) => {
    setTextmasterProjectSettings(_textmasterProjectSettings);
    await ProjectUpdater.call({
      id: project.id,
      textmasterProjectSettings: _textmasterProjectSettings
    })
  }

  const updateAutolaunch = () => (
    updateTextmasterProjectSettings({
      ...textmasterProjectSettings,
      autoLaunch: !textmasterProjectSettings.autoLaunch,
    })
  );

  useEffect(() => {
    const setProjectExpertise = (expertiseId) => {
      if (!expertises) {
        return
      }

      const foundExpertise = expertisesMerged.find(({ id }) => (id === expertiseId))
      setValue('project[textmasterProjectSettings][expertise]', foundExpertise || null);
    }

    setTextmasterProjectSettings({
      autoLaunch: project.textmasterProjectSettings.autoLaunch,
      expertiseId: project.textmasterProjectSettings.expertiseId,
    });

    setProjectExpertise(project.textmasterProjectSettings.expertiseId);
  }, [expertises, setValue, setTextmasterProjectSettings, project.textmasterProjectSettings, expertisesMerged])

  const onSubmit = async values => {
    const { textmasterProjectSettings: _textmasterProjectSettings } = values.project
    setStatus(STATUSES.loading)

    await updateTextmasterProjectSettings({
      ...textmasterProjectSettings,
      expertiseId: _textmasterProjectSettings.expertise?.id || null,
    })
    setStatus(STATUSES.success)
  }

  return (
    <>
      <Form
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        status={status}
      >
        <div>
          <label className="d-inline-flex">
            <Switch
              id={`textmaster-auto-launch-${project.id}`}
              isChecked={project.textmasterProjectSettings.autoLaunch}
              onChange={updateAutolaunch}
            />
            <FormattedMessage
              id="components.projects.settings.textmasterProjectSettings.autoLaunchProject"
              defaultMessage="Auto launch Project"
            />
          </label>
        </div>
        <div>
          <label className="d-inline-block">
            <FormattedMessage
              id="components.projects.settings.textmasterProjectSettings.expertise"
              defaultMessage="Expertise"
            />
          </label>
          <SelectField
            name="project[textmasterProjectSettings][expertise]"
            className="mb-2"
            options={expertises}
            control={control}
            errors={errors}
            placeholder={intl.formatMessage({
              id: "components.projects.settings.textmasterProjectSettings.expertisesList",
              defaultMessage: "Select an expertise",
            })}
          />
          <Button onClick={clearExpertise} variant="link" className="px-0">Clear Selection</Button>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            className="mt-4 btn btn--primary"
            disabled={errors?.length > 0 || status === STATUSES.loading}
          >
            {status === STATUSES.loading ? (
              <Spinner />
            ) : (
              intl.formatMessage({
                id: "components.projects.textmasterProjectSettings.updateTextMasterProjectSettingsButton",
                defaultMessage: "Update Textmaster project settings",
              })
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};
