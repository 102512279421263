import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import PageContainer from "../components/layout/PageContainer"
import ProjectCard from "../components/dashboard/ProjectCard"

const Dashboard = () => {
  const projects = useStoreState(state => state.projects.items)

  return (
    <PageContainer className="d-flex flex-column">
      <h1 className="main-heading">
        <FormattedMessage
          id="containers.dashboard.title"
          defaultMessage="Dashboard"
        />
      </h1>
      <div className="d-flex flex-wrap">
        {projects.map((project, i) => (
          <ProjectCard key={project.id} index={i} project={project} />
        ))}
        <ProjectCard newProject />
      </div>
    </PageContainer>
  )
}

export default Dashboard
