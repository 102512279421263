import store from "../store/index"

export default class ProjectResourcePatternsDestroyer {
  constructor({ projectID, index }) {
    this._projectID = projectID
    this._index = index
  }

  static async call(args) {
    return new ProjectResourcePatternsDestroyer(args).call()
  }

  async call() {
    try {
      await this.deleteProjectResourcePattern()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  deleteProjectResourcePattern() {
    return store
      .getActions()
      .projects
      .removeResourcePattern({
        projectID: this.projectID(),
        index: this.index()
      })
  }

  projectID() {
    return this._projectID
  }

  index() {
    return this._index
  }
}
