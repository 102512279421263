import React, { useEffect, useState } from "react"
import { useController, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Tab, Nav, Form } from "react-bootstrap"
import { localeName } from "../../helpers/locale"
import StartSyncButton from "./StartSyncButton"
import { byCode } from "../../utils/sort"
import { fieldRequired } from "../../utils/validations"
// import Form from "../forms/Form"
import Select from "react-select"
import { INSTANCE_MODES } from "../../models/instanceMode"
import { isDocumentTypeConnector } from "../../helpers/connector"

const QuoteCard = ({
  isPaid,
  project,
  setIsPaid,
  setStatus,
  status,
}) => {
  const intl = useIntl()
  const { setValue, handleSubmit, errors, control } = useForm()
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "project[integrationTemplate]",
    control,
  })

  const { isPartner, isDedicatedMode, item: currentOrg } = useStoreState(state => state.currentOrg)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const fetchProjectTemplates = useStoreActions(actions => actions.projectTemplates.fetch)
  const [activeKey, setActiveKey] = useState(INSTANCE_MODES.SYNCHRONIZATION)
  const [locales, setLocales] = useState(project.translatableLocales);
  const [orderItems, setOrderItems] = useState(project.translatableLocales)

  const formatWords = words => {
    return intl.formatMessage({
      id: "components.projects.quoteCard.words",
      defaultMessage: "{words, number, ::compact-short}",
    },
      { words },
    )
  }

  const selectChange = (e, item) => {
    const items = e.target.checked
      ? [...orderItems, item]
      : orderItems.filter(({ id }) => id !== item.id)
    setOrderItems(items)
  }

  const selectAllChange = (e) => {
    const items = e.target.checked ? locales : []
    setOrderItems(items)
  }

  const isItemChecked = (item) => {
    return !!orderItems.find(({ id }) => id === item.id)
  }

  const isSelectAllChecked = () => {
    return orderItems.length === locales.length
  }

  const renderLocaleItems = (items) => {
    return (items.sort(byCode)?.map((item) => {
      const words = activeKey === INSTANCE_MODES.SYNCHRONIZATION
        ? item.missingWords
        : item.words
      return (
        <div className="quote-container__item-header" key={item.id}>
          <div className="d-flex">
            <div>
              {!isPaid && (
                <input
                  type="checkbox"
                  className="checkbox-green"
                  id={item.id}
                  onChange={e => selectChange(e, item)}
                  checked={isItemChecked(item)}
                />
              )}
              <label
                htmlFor={item.id}
                className="font-semibold text-sm text-gray-900 mb-1"
              >
                {localeName(item.locale, intl)}
              </label>
              <p className="text-sm leading-none text-gray-500 mb-0 ml-1">
                {item.code}
              </p>
            </div>
          </div>
          <span className="text-sm text-gray-600">
            {formatWords(words)}
          </span>
        </div>
      )
    }))
  }

  const renderTotalWordsInfo = () => (
    <dl className="quote-container__item-body d-flex flex-column align-items-end mb-0 bg-gray-50">
      <div className="quote-container__item-row d-flex w-75 py-2 justify-content-between border-0">
        <dt className="d-flex w-50 align-items-center text-gray-600">
          <span className="d-inline-block ml-2">
            <FormattedMessage
              id="components.projects.quoteCard.totalWordsLabel"
              defaultMessage="Total Words"
            />
          </span>
        </dt>
        <dd className="d-flex w-50 justify-content-end mb-0">
          <span className="text-gray-800">
            {formatWords(
              orderItems.reduce((sum, item) => {
                const words = activeKey === INSTANCE_MODES.SYNCHRONIZATION
                  ? item.missingWords
                  : item.words
                return sum + words
              }, 0)
            )}
          </span>
        </dd>
      </div>
    </dl>
  )

  useEffect(() => {
    if (currentOrg?.integration) {
      fetchProjectTemplates(currentOrg?.integration)
    }
  }, [currentOrg?.integration])

  useEffect(() => {
    if (projectTemplates.length > 0) {
      setValue(
        "project[integrationTemplate]",
        projectTemplates.find(
          template => template.id === project.integrationTemplateID
        )
      )
    }
  }, [projectTemplates, project.integrationTemplateID, setValue])

  const projectTemplateFields = [
    {
      name: "project[integrationTemplate]",
      type: "select",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.projectTemplate.label",
        defaultMessage: "Default Project Template",
      }),
      description: intl.formatMessage({
        id: "components.projects.editForm.fields.projectTemplate.description",
        defaultMessage:
          "A project template bundles all the resources needed for project creation in one asset which you can reuse multiple times.",
      }),
      options: projectTemplates,
      defaultValue: projectTemplates.find(
        template => template.id === project.integrationTemplateID
      ),
      validations: { ...fieldRequired },
    }
  ]

  const wordsToCompleteMessage = <FormattedMessage id="components.projects.quoteCard.wordsToComplete" defaultMessage="Words to complete" />

  const wordsMessage = <FormattedMessage id="components.projects.quoteCard.wordsMessage" defaultMessage="Words" />

  const callToAction = ({ mode }) => {
    if (isPaid) return
      return <StartSyncButton
        className="w-100"
        status={status}
        setStatus={setStatus}
        handleSubmit={handleSubmit}
        mode={mode}
        setIsPaid={setIsPaid}
        projectID={project.id}
        projectLocaleIDs={orderItems.map(({ id }) => id)}
      />
  }

  const QuoteContainer = ({ mode }) => (
    <>
      <div className="quote-container py-3">
        {!isPaid && (
          <div className="quote-container__item-header border-0 py-0">
            <div>
              <input
                type="checkbox"
                className="checkbox-green checkbox-green--all"
                id="selectall"
                onChange={e => selectAllChange(e)}
                checked={isSelectAllChecked()}
              />
              <label htmlFor="selectall" className="text-gray-900 mb-1">
                <FormattedMessage
                  id="components.projects.quoteCard.selectAll"
                  defaultMessage="Select all"
                />
              </label>
            </div>
            <span className="text-sm text-gray-600">
              {mode === INSTANCE_MODES.SYNCHRONIZATION
                ? wordsToCompleteMessage
                : wordsMessage}
            </span>
          </div>
        )}
        <div className="mt-2">
          {renderLocaleItems(isPaid ? orderItems : locales)}
          {renderTotalWordsInfo()}
          {isDedicatedMode && currentOrg?.integration?.integrationType?.configuration?.hasIntegrationTemplate && (
            <div className="py-4">
              <Form.Label>
                <FormattedMessage
                  id="components.projects.editForm.fields.projectTemplate.label"
                  defaultMessage="Project Template"
                />
              </Form.Label>
              <Select
                {...inputProps}
                inputRef={ref}
                options={projectTemplates}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#00D792",
                  },
                })}
              />
            </div>
          )}
        </div>
      </div>
      {callToAction({ mode })}
    </>
  )

  const onTabSelect = (key) => {
    setActiveKey(key)
    switch (key) {
      case INSTANCE_MODES.PROOFREADING:
        setLocales(project.targetLocales)
        setOrderItems(project.targetLocales)
        break
      case INSTANCE_MODES.SYNCHRONIZATION:
      default:
        setLocales(project.translatableLocales)
        setOrderItems(project.translatableLocales)
        break
    }
  }

  return (
    <>
      {isPaid && (
        <div className="stamp">
          {!isPartner ? (
            <FormattedMessage
              id="components.projects.locale.getTranslations.paid"
              defaultMessage="paid"
            />
          ) : (
            <FormattedMessage
              id="components.projects.locale.getTranslations.pushed"
              defaultMessage="pushed"
            />
          )}
        </div>
      )}
      { !isDocumentTypeConnector(project) ? (
          <div className="mt-2">
            <Tab.Container activeKey={activeKey} onSelect={onTabSelect}>
              <Nav variant="tabs">
                <Nav.Item className="flex-grow-0">
                  <Nav.Link eventKey={INSTANCE_MODES.SYNCHRONIZATION}>
                    <FormattedMessage
                      id="containers.projects.quoteCard.tab.synchronization"
                      defaultMessage="Untranslated Content"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="ml-1 flex-grow-0">
                  <Nav.Link eventKey={INSTANCE_MODES.PROOFREADING}>
                    <FormattedMessage
                      id="containers.projects.quoteCard.tab.proofreading"
                      defaultMessage="All Content"
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey={INSTANCE_MODES.SYNCHRONIZATION}>
                    <QuoteContainer mode={INSTANCE_MODES.SYNCHRONIZATION} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={INSTANCE_MODES.PROOFREADING}>
                    <QuoteContainer mode={INSTANCE_MODES.PROOFREADING} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        ) : (
          <QuoteContainer mode={INSTANCE_MODES.SYNCHRONIZATION} />
        )
      }
    </>
  )
}

export default QuoteCard
