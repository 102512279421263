import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import RwsAccountId from "../../../img/partners/helpers/rws-account-id.png"
import RwsApiCreds from "../../../img/partners/helpers/rws-api-credentials.jpg"
import RwsWebhook from "../../../img/partners/helpers/rws-webhook.jpg"
import RwsServiceUser from "../../../img/partners/helpers/rws-service-user.jpeg"
import RwsWebhookEvents from "../../../img/partners/helpers/rws-webhook-events.jpeg"
import Copy from "../../Copy"

export const EVENT_KEYS = {
  NAME: "name",
  ACCOUNT_ID: "accountID",
  LANGUAGE_CLOUD_ACCOUNT_ID: "languageCloudAccountID",
  CLIENT_CREDS: "clientCreds",
  WEBHOOK: "webhook",
  DEFAULT_PROJECT_TEMPLATE: "template",
}

const TradosStepFields = ({ intl, templates, integration }) => [
  {
    eventKey: EVENT_KEYS.NAME,
    title: (
      <FormattedMessage
        id="src.components.catTool.settings.helpertitleIntegrationName"
        defaultMessage={`Integration Name`}
      />
    ),
    fields: [
      {
        name: "integration[name]",
        type: "text",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.integrationName",
          defaultMessage: "Integration Name",
        })}`,
      },
    ],
    images: [],
  },
  {
    eventKey: EVENT_KEYS.LANGUAGE_CLOUD_ACCOUNT_ID,
    title: (
      <FormattedMessage
        id="src.components.catTool.settings.helpertitle1"
        defaultMessage={`Account IDs`}
      />
    ),
    bullets: [
      <FormattedMessage
        id="src.components.catTool.settings.helper1.bullet1"
        defaultMessage="Click your avatar in the top-right corner."
      />,
      <FormattedMessage
        id="src.components.catTool.settings.helper1.bullet2"
        defaultMessage={`Select <b>Manage Account</b>.`}
        values={{
          b: msg => <b>{msg}</b>,
        }}
      />,
      <FormattedMessage
        id="src.components.catTool.settings.helper1.bullet3"
        defaultMessage={`Copy and paste your <b>Account ID</b> and your <b>Language Cloud Account ID.</b>`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.settings.helper1.bullet4"
        defaultMessage={`Close the screen by clicking the <b>×</b> in the top-right corner.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
    ],
    fields: [
      {
        name: "integration[groups]",
        type: "hidden",
      },
      {
        name: "integration[id]",
        type: "hidden",
      },
      {
        name: "integration[integrationTypeID]",
        type: "hidden",
      },
      {
        name: "integration[name]",
        type: "hidden",
      },
      {
        name: "integration[organizationID]",
        type: "hidden",
      },
      {
        name: "integration[vendorID]",
        type: "hidden",
      },
      {
        name: "integration[trados_accountId]",
        type: "text",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.languageCloudAccountId",
          defaultMessage: "Account ID",
        })}`,
      },
      {
        name: "integration[trados_languageCloudAccountId]",
        type: "text",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.languageCloudAccountId",
          defaultMessage: "Language Cloud Account ID",
        })}`,
      }
    ],
    images: [RwsAccountId],
  },
  {
    eventKey: EVENT_KEYS.CLIENT_CREDS,
    title: (
      <FormattedMessage
        id="src.components.catTool.settings.helpertitle2"
        defaultMessage={`Client ID & Client Secret`}
      />
    ),
    bullets: [
      {
        sectionTitle: (
          <FormattedMessage
            id="src.components.catTool.settings.sectionTitle"
            defaultMessage="Before continuing make sure you have a Service User set up."
          />
        ),
      },
      <FormattedMessage
        id="src.components.catTool.helper2.bullet1"
        defaultMessage="Select <b>Users</b> from the navigation and select the <b>Service Users</b> tab."
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet2"
        defaultMessage="Click <b>New Service User</b> and enter the requested information."
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet3"
        defaultMessage="Click Create and continue with the next steps."
        values={{ b: msg => <b>{msg}</b> }}
      />,
      {
        sublistHeader: (
          <FormattedMessage
            id="src.components.catTool.helper2.sublistHeader"
            defaultMessage="Create Application"
            values={{ b: msg => <b>{msg}</b> }}
          />
        ),
      },
      <FormattedMessage
        id="src.components.catTool.helper2.bullet4"
        defaultMessage="Click your avatar in the top-right corner."
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet5"
        defaultMessage={`Select <b>Integrations</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet6"
        defaultMessage={`Click <b>New Application</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet7"
        defaultMessage={`Enter a name for your application (eg. Locale Connectors).`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet8"
        defaultMessage={`Select the <b>Service User</b> (the one you created earlier).`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet9"
        defaultMessage={`Click <b>Add</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet10"
        defaultMessage={`Select your newly created application and click <b>Next</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet11"
        defaultMessage={`
              On the following page copy and paste the <b>Client ID</b> and <b>Client
              Secret</b> to Locale.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper2.bullet12"
        defaultMessage={`Click <b>Next</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
    ],
    fields: [
      {
        name: "integration[trados_clientId]",
        type: "text",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.clientId",
          defaultMessage: "Client ID",
        })}`,
      },
      {
        name: "integration[trados_clientSecret]",
        type: "password",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.clientSecret",
          defaultMessage: "Client Secret",
        })}`,
      },
    ],
    images: [RwsServiceUser, RwsApiCreds],
  },
  {
    eventKey: EVENT_KEYS.WEBHOOK,
    title: (
      <FormattedMessage
        id="src.components.catTool.settings.helpertitle3"
        defaultMessage={`Webhook`}
      />
    ),
    bullets: [
      <FormattedMessage
        id="src.components.catTool.helper3.bullet1"
        defaultMessage={`Copy {copyToClip} and paste it into <b>Default Webhook URL</b>.`}
        values={{
          b: msg => <b>{msg}</b>,
          copyToClip: (() => {
            const tradosLink = "https://api.locale.to/trados/callback"

            return (
              <span>
                <b className="mr-1 font-monospace">{tradosLink}</b>
                <Copy text={tradosLink} />
              </span>
            )
          })(),
        }}
      />,
      {
        sublistHeader: (
          <FormattedMessage
            id="src.components.catTool.helper3.sublistHeader"
            defaultMessage="Add webhook Events"
          />
        ),
      },
      <FormattedMessage
        id="src.components.catTool.helper3.bullet2"
        defaultMessage={`Below Actions, click the <b>+</b> button.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper3.bullet3"
        defaultMessage={`Paste the same webhook url below <b>Webhook URL</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper3.bullet4"
        defaultMessage={`In the dropdown under <b>Events</b> choose <b>Project.task.completed</b>.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper3.bullet5"
        defaultMessage={`Copy and paste <b>Secret Key</b> to Locale.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper3.bullet6"
        defaultMessage={`Click <b>Save</b> in Trados and <b>Next Step</b> on Locale.`}
        values={{ b: msg => <b>{msg}</b> }}
      />,
      <FormattedMessage
        id="src.components.catTool.helper3.bullet7"
        defaultMessage={`If there are no errors, you're all set up. Well done!`}
      />,
    ],
    fields: [
      {
        name: "integration[trados_webhookSecret]",
        type: "textarea",
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.webhookSecret",
          defaultMessage: "Secret Key",
        })}`,
      },
    ],
    images: [RwsWebhook, RwsWebhookEvents],
  },
  {
    eventKey: EVENT_KEYS.DEFAULT_PROJECT_TEMPLATE,
    title: (
      <FormattedMessage
        id="src.components.catTool.settings.defaultProjectTemplateTitle"
        defaultMessage={`Project Template`}
      />
    ),
    bullets: [
      <FormattedMessage
        id="src.components.catTool.defaultProjectTemplate.bullet1"
        defaultMessage={`Select the default project template for this integration`}
      />,
    ],
    fields: [
      {
        name: "integration[template]",
        type: "select",
        options: templates,
        label: `${intl.formatMessage({
          id: "components.catTool.settings.fields.template",
          defaultMessage: "Project Templates"
        })}`,
      },
    ],
    images: [],
  },
]

export default TradosStepFields
