import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import InfoModal from "../../layout/modal/InfoModal"
import QuoteCard from "../QuoteCard"
import UpdateBillingButton from "../../billing/UpdateBillingButton"
import Toast from "../../Toast"
import { STATUSES } from "../../layout/FormContainer"
import Icon from "../../Icon"
import { isContentable } from "../../../helpers/project"
import { getEnabledTotal, getResourceTypeForConnector } from "../../../helpers/contentResource"

const GetTranslations = ({
  project,
  openModal,
  onModalClose,
  className,
  triggerDisabled,
}) => {
  const intl = useIntl()
  const resourcesExcluded = project.resourcesExcluded || []
  const { connector } = project
  const fetchProject = useStoreActions(actions => actions.projects.fetch)
  const { resources, total } = useStoreState(state => state.contentResources)
  const { isPartner, partnerText, isSharedMode, item: currentOrg } = useStoreState(state => state.currentOrg)
  const [status, setStatus] = useState()
  const [isPaid, setIsPaid] = useState(false)
  const [successToast, setSuccessToast] = useState(false)
  const [disabledToast, setDisabledToast] = useState(false)
  const availableIntegrations = useStoreState(state => state.integrations.availableIntegrations)
  const fetchAvailableIntegrations = useStoreActions(actions => actions.integrations.fetchAvailableIntegrations)
  const [brandName, setBrandName] = useState()

  const isContent = isContentable(project)
  const enabledTotal = isContent
    ? getEnabledTotal(resources, resourcesExcluded)
    : null

  const triggerText =
    !isContent && project.resourcePatterns.length > 0 ? (
      <FormattedMessage
        id="components.projects.locale.getTranslations.trigger.languageCount"
        defaultMessage="{files, plural, one {{files} file} other {{files} files}}"
        values={{ files: project.resourcePatterns.length }}
      />
    ) : (
      isContent && (
        <FormattedMessage
          id="components.projects.locale.getTranslations.trigger.languageCount"
          defaultMessage="{items} {resourceType}"
          values={{
            items: enabledTotal,
            resourceType: getResourceTypeForConnector(
              intl,
              connector.type,
              total
            ),
          }}
        />
      )
    )  

  const Trigger = ({ onClick }) => (
    <button
      className={
        `btn bg-green-500 text-white
         py-1 px-3 font-weight-bolder letter-spacing-0_03 rounded ` + className
      }
      onClick={() => !triggerDisabled ? onClick() : setDisabledToast(true)}
    >
      <div className="d-flex align-items-center">
        <Icon name="arrow-up" className="mb-1px text-lg" />
        <span className="mr-2">
          <FormattedMessage
            id="components.projects.locale.getTranslations.trigger.pushcontent"
            defaultMessage="Push Content"
          />
        </span>
        {triggerText && (
          <span className={`bg-green-600 rounded-pill px-3 py-0`}>
            {triggerText}
          </span>
        )}
      </div>
    </button>
  )

  useEffect(() => {
    if (availableIntegrations.length || !currentOrg.id || isSharedMode) return
    fetchAvailableIntegrations({ organizationID: currentOrg.id })
  }, [currentOrg, isSharedMode])


  useEffect(() => {
    if (!availableIntegrations.length || (!project?.integrationID && !currentOrg?.integrationID)) return

    const projectIntegration = availableIntegrations.find(({ id }) => (project?.integrationID === id || currentOrg?.integrationID === id));
    setBrandName(projectIntegration ? projectIntegration.name : '')
  }, [availableIntegrations, project, currentOrg])

  const integrationTitle = <FormattedMessage
      id="components.projects.locale.getTranslations.integrationTitle"
      defaultMessage="Push to {brandName}"
      values={{ brandName }}
    />

  const integrationPushSuccess = <FormattedMessage
    id="components.project.locale.getTranslation.toastSuccess"
    defaultMessage="Pushing your content to {brandName} 🚀"
    values={{ brandName }}
  />

  const onExit = () => {
    onModalClose()
    setIsPaid(false)
    fetchProject(project.id)
  }

  let title = isSharedMode ? partnerText.quoteModalTitle : integrationTitle;
  let description = ""

  useEffect(() => {
    if(status === STATUSES.success) {
      setSuccessToast(true)
    }
  }, [status])

  const disabledToastMessage = project.isCalculating ? (
    <FormattedMessage
      id="components.projects.locale.getTranslations.calculatingToast"
      defaultMessage="Your files are still being analyzed 🧐"
    />
  ) : project.allCompleted ? (
    <FormattedMessage
      id="components.projects.locale.getTranslations.allCompletedToast"
      defaultMessage="Translations are already complete 🤙"
    />
  ) : (
    <FormattedMessage
      id="components.projects.locale.getTranslations.disabledToast"
      defaultMessage="No new content to be pushed 🙃"
    />
  )

  useEffect(() => {
    if (project.isCalculating || project.allCompleted) return
    if (project.isQuoteDelivered) setDisabledToast(false)
  }, [project.allCompleted, project.isCalculating, project.isQuoteDelivered])

  return (
    <>
      <Toast show={successToast} setShow={setSuccessToast}>
        <p className="text-gray-50 font-normal mb-0">
          { isSharedMode ? partnerText.toastPushSuccess : integrationPushSuccess}
        </p>
      </Toast>
      <Toast show={disabledToast} setShow={setDisabledToast}>
        <p className="text-gray-50 font-normal mb-0">
          {disabledToastMessage}
        </p>
      </Toast>
      <InfoModal
        Trigger={Trigger}
        title={title}
        description={description}
        status={status}
        setStatus={setStatus}
        onExit={onExit}
        isOpen={openModal}
        className="modal-body--get-translations"
      >
        <QuoteCard
          isPaid={isPaid}
          project={project}
          setIsPaid={setIsPaid}
          setStatus={setStatus}
          status={status}
        />
        {isPaid && !isPartner && <UpdateBillingButton />}
      </InfoModal>
    </>
  )
}

export default GetTranslations
