import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Form } from "react-bootstrap"
import { FREQUENCY } from "../../models/billing"

export const TermRadio = ({ register, setValue, defaultTerm }) => {
  useEffect(() => {
    if (!defaultTerm) return
    setValue("term", defaultTerm)
  }, [defaultTerm])

  return (
    <>
      <Form.Check id={"term-monthly"} inline>
        <Form.Check.Input
          type="radio"
          name="term"
          value={FREQUENCY.MONTHLY}
          ref={register}
        />
        <Form.Check.Label>
          <FormattedMessage
            id="components.billing.frequency.monthly"
            defaultMessage="Pay Monthly"
          />
        </Form.Check.Label>
      </Form.Check>
      <Form.Check id={"term-yearly"} inline>
        <Form.Check.Input
          type="radio"
          name="term"
          value={FREQUENCY.YEARLY}
          ref={register}
        />
        <Form.Check.Label>
          <FormattedMessage
            id="components.billing.frequency.yearly"
            defaultMessage="Pay Yearly (save 15%)"
          />
        </Form.Check.Label>
      </Form.Check>
    </>
  )
}
