import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Icon from "../../components/Icon"
import ModalForm, { STATUSES } from "../forms/ModalForm"
import { useStoreState } from "easy-peasy"
import { triggerQuote } from "../../helpers/instances"
import { triggerSynchronization } from "../../helpers/connector"
import { isContentable } from "../../helpers/project"
import Toast from "../Toast"

const SynchronizeProjectButton = ({ project }) => {
  const connector = useStoreState(state =>
    state.connectors.getConnector(project?.connector?.type)
  )
  const intl = useIntl()
  const [status, setStatus] = useState()
  const [showSuccessToast, setShowSuccessToast] = useState(false)

  const handleOnSubmit = async () => {
    try {
      setStatus(STATUSES.loading)
      isContentable(project)
        ? triggerSynchronization({
            connectorId: connector.id,
            organizationId: project.organizationID,
            providerId: project.providerID,
          })
        : triggerQuote({ projectID: project.id })
      setStatus(STATUSES.success)
      setShowSuccessToast(true)
    } catch (e) {
      setShowSuccessToast(false)
      setStatus(STATUSES.error)
    }
  }

  const Trigger = ({ onClick }) => (
    <Button
      className="btn--primary"
      onClick={onClick}
      disabled={project?.isCalculating}
    >
      <Icon name="refresh-cw" className="mr-2" />
      <FormattedMessage
        id="components.projects.synchronizeProjectButton.button"
        defaultMessage="Synchronize"
      />
    </Button>
  )

  return (
    <>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast} delay={1200}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.projects.synchronizeProjectButton.triggered"
            defaultMessage="Synchronization in progress ✨"
          />
        </p>
      </Toast>
      <ModalForm
        fields={[]}
        onSubmit={handleOnSubmit}
        status={status}
        setStatus={setStatus}
        submitProps={{
          title: `${intl.formatMessage({
            id:
              "components.projects.synchronizeProjectButton.modal.confirmButton",
            defaultMessage: "Confirm",
          })}`,
        }}
        Trigger={Trigger}
      >
        <h4>
          <FormattedMessage
            id="components.projects.synchronizeProjectButton.modal.body.paragraph"
            defaultMessage="This action will refresh data with the most up to date content from {connector}."
            values={{ connector: connector?.name }}
          />
        </h4>
      </ModalForm>
      <p className="text-gray-500 ml-2 mb-0">
        <FormattedMessage
          id="components.projects.synchronizeProjectButton.paragraph"
          defaultMessage="Force content synchronization."
        />
      </p>
    </>
  )
}

export default SynchronizeProjectButton
