import React, { useEffect, useState } from "react"
import Toast from "../Toast"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { PlanSubscriptionWizard } from "./PlanSubscriptionWizard"
import { ProductFamilySwitcher } from "./ProductFamily"
import { SERVICE_TYPES } from "../../models/service"
import { CurrentPlanDetails } from "./CurrentPlan"
import CreditsUsage from "../CreditsUsage"
import { PlanCancellationButton } from "./PlanCancellation"
import Helmet from "react-helmet"
import OpenPortal from "./OpenPortal"

const Plans = () => {
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const [showSuccessSubscription, setShowSuccessSubscription] = useState(false)
  const { subscription, hasNoSubscriptions } = useStoreState(state => state.billing)
  const [familyId, setFamilyId] = useState()
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [renderChargebee, setRenderChargebee] = useState(false)
  const [sectionType, setSectionType] = useState()

  useEffect(() => {
    if (!currentOrg.id) return
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg.id])

  useEffect(() => {
    if (!subscription.credits) return
    setFamilyId(
      subscription.details?.productFamilyId || SERVICE_TYPES.LOCALE_AI
    )
  }, [subscription.credits])

  return (
    <>
      <Helmet>
        <script
          src="https://js.chargebee.com/v2/chargebee.js"
          data-cb-site={process.env.CHARGEBEE_SITE}
        />
      </Helmet>
      {renderChargebee && (
        <OpenPortal
          setRenderChargebee={setRenderChargebee}
          openSectionOn={sectionType}
        />
      )}
      <div className="container-fluid">
        <Toast
          show={showSuccessSubscription}
          setShow={setShowSuccessSubscription}
          delay={5000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.serviceTypes.TMSTab.successSubscription"
              defaultMessage="Congratulations! You subscribed to {planName} 🎉"
              values={{ planName: subscription?.details?.planName }}
            />
          </p>
        </Toast>

        <div className="row justify-content-center mt-5 mb-3">
          <div className="col-3">
            <CurrentPlanDetails />
          </div>
          <div className="col-3">
            <CreditsUsage className="mt-0" />
          </div>
          {currentOrg.customerID &&
          <div className="col-3">
            <div className="card">
              <ul className="list-group">
                <li className="list-group-item py-04">
                  <a
                    href="#"
                    onClick={async () => {
                      setSectionType("PAYMENT_SOURCES")
                      setRenderChargebee(true)
                    }}
                  >
                    <FormattedMessage
                      id="components.billing.Plans.paymentMethods.label"
                      defaultMessage="Payment methods"
                    />
                  </a>
                </li>
                <li className="list-group-item py-04">
                  <a
                    href="#"
                    onClick={async () => {
                      setSectionType("EDIT_BILLING_ADDRESS")
                      setRenderChargebee(true)
                    }}
                  >
                    <FormattedMessage
                      id="components.billing.Plans.billingAddress.label"
                      defaultMessage="Billing address"
                    />
                  </a>
                </li>
                <li className="list-group-item py-04">
                  <a
                    href="#"
                    onClick={async () => {
                      setSectionType("BILLING_HISTORY")
                      setRenderChargebee(true)
                    }}
                  >
                    <FormattedMessage
                      id="components.billing.Plans.invoices.label"
                      defaultMessage="Invoices"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          }
        </div>
        <div className="row justify-content-center mt-5">
          <div className="w-80">
            <div className="row justify-content-center mb-4">
              <div className="col-auto">
                <ProductFamilySwitcher
                  familyId={familyId}
                  setFamilyId={setFamilyId}
                />
              </div>
            </div>
            <PlanSubscriptionWizard
              familyId={familyId}
              onSubscription={() => {
                setShowSuccessSubscription(true)
              }}
              hasUpgradeDowngradeOptions={true}
            />
          </div>
        </div>

        {!hasNoSubscriptions && (
          <p className="text-center mt-8">
            <small className="text-muted ">
              <FormattedMessage
                id="components.billing.Plans.cancelSubscription.label"
                defaultMessage="Don’t need your plan anymore? "
              />
              <PlanCancellationButton />
            </small>
          </p>
        )}
      </div>
    </>
  )
}

export default Plans
