import React from "react"
import { useStoreState } from "easy-peasy"
import { useLocation } from "@reach/router"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import OrgUpdater from "../../../service/orgUpdator"
import { useForm } from "react-hook-form"
import Form from "../../../components/forms/Form"
import { fieldRequired } from "../../../utils/validations"
import parseBasePath from "../../../helpers/path"
import ROUTES from "../../../config/routes"
import { useOnboarding } from "../../Onboarding"

const Workspace = ({ onboarding }) => {
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { routeToNextStep, routeToBackStep, hasPreviousStep } = useOnboarding()
  const intl = useIntl()
  const location = useLocation()
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })

  const fields = [
    {
      name: "id",
      type: "hidden",
      defaultValue: currentOrg.id,
    },
    {
      name: "vendorID",
      type: "hidden",
      defaultValue: currentOrg.vendorID,
    },
    {
      name: "name",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.onboarding.workspace.companyNameField",
        defaultMessage: "Company name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentOrg.name || "",
    },
    { type: "divider" },
  ]

  const onSubmit = payload => {
    routeToNextStep()
    OrgUpdater.call(payload)
  }

  return (
    <div className="d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.workspace.h2"
            defaultMessage="Create your Account"
          />
        </h1>
      </div>
      <div className="onboarding-form">
        <div className="w-50">
          <Form
            id="org-update"
            control={control}
            errors={errors}
            fields={fields}
            onSubmit={handleSubmit(onSubmit)}
          />
          <div className="d-flex">
            {hasPreviousStep && (
              <button
                className="btn btn-outline--secondary mr-5"
                onClick={() => routeToBackStep()}
              >
                <FormattedMessage
                  id="containers.onboarding.workspace.backBtn"
                  defaultMessage="Back"
                />
              </button>
            )}

            <button
              className="btn btn--primary btn-primary mr-0"
              form="org-update"
            >
              <FormattedMessage
                id="containers.onboarding.workspace.nextBtn"
                defaultMessage="Next"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workspace
