import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import UserUpdater from "../../../service/userUpdater"
import { useForm } from "react-hook-form"
import Form from "../../../components/forms/Form"
import { fieldRequired } from "../../../utils/validations"
import { useOnboarding } from "../../Onboarding"

const Profile = ({ onboarding }) => {
  const currentUser = useStoreState(state => state.currentUser.item)
  const location = useLocation()
  const { routeToNextStep, routeToBackStep, hasPreviousStep } = useOnboarding()
  const intl = useIntl()
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })

  const fields = [
    {
      name: "given_name",
      type: "text",
      label: `${intl.formatMessage({
        id: "containers.onboarding.profile.givenName",
        defaultMessage: "Your Name",
      })}`,
      validations: { ...fieldRequired },
      defaultValue: currentUser.given_name || "",
    },
    { type: "divider" },
  ]

  const onSubmit = async payload => {
    routeToNextStep()
    await UserUpdater.call(payload)
  }

  return (
    <div className="d-flex flex-column">
      <div className="card-body p-0 pb-3">
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.profile.h2"
            defaultMessage="Setup your profile"
          />
        </h1>
      </div>
      <div className="onboarding-form">
        <div className="w-50">
          <Form
            id="profile-update"
            control={control}
            errors={errors}
            fields={fields}
            onSubmit={handleSubmit(onSubmit)}
          />
          <div className="d-flex">
            {hasPreviousStep && (
              <button
                className="btn btn-outline--secondary mr-5"
                onClick={() => routeToBackStep()}
              >
                <FormattedMessage
                  id="containers.onboarding.profile.backBtn"
                  defaultMessage="Back"
                />
              </button>
            )}

            <button
              className="btn btn--primary btn-primary mr-0"
              form="profile-update"
            >
              <FormattedMessage
                id="containers.onboarding.profile.nextBtn"
                defaultMessage="Next"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
