import React from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button, Col, Row } from "react-bootstrap"
import { fieldRequired } from "../../../utils/validations"
import Form from "../../forms/Form"
import Spinner from "../../common/Spinner"
import { STATUSES } from "../../layout/FormContainer"
import ProjectLocaleUpdater from "../../../service/projectLocaleUpdater"

const ProjectLocaleEdit = ({ projectLocale, status, setStatus }) => {
  const intl = useIntl()
  const { handleSubmit, errors, control } = useForm({
    mode: "onChange"
  })

  const onSubmit = async ({ projectLocale }) => {
    setStatus(STATUSES.loading)
    await ProjectLocaleUpdater.call(projectLocale)
      ? setStatus(STATUSES.success)
      : setStatus(STATUSES.error)
  }

  const fields = [
    "id", "projectID", "isSource", "localeID"
  ].map(attr => {
    return {
      name: `projectLocale[${attr}]`,
      type: "hidden",
      defaultValue: projectLocale[attr]
    }
  })
  fields.push({
    name: "projectLocale[code]",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.projects.locale.edit.code.label",
        defaultMessage: "Language Code",
      })}`,
      validations: { ...fieldRequired },
    defaultValue: projectLocale.code,
  })

  return (
    <>
      <div className="pt-3 border-t border-gray-200">
        <Form
          control={control}
          className='w-50'
          errors={errors}
          fields={fields}
          status={status}
          setStatus={setStatus}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>

      <div className="buttons">
        <Button
          type="submit"
          className="btn btn--primary"
          disabled={errors?.length > 0 || status === STATUSES.loading}
          onClick={handleSubmit(onSubmit)}
        >
          {status === STATUSES.loading ? (
            <Spinner />
          ) : (
            <FormattedMessage
              id="components.projects.locale.edit.saveButton"
              defaultMessage="Save My Preferences"
            />
          )}
        </Button>
      </div>
    </>
  )
}

export default ProjectLocaleEdit
