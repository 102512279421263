import store from "../store/index"
import ProjectUpdater from "./projectUpdater"

export default class ProjectDestroyer {
  constructor(projectID) {
    this._projectID = projectID
  }

  static async call(args) {
    return new ProjectDestroyer(args).call()
  }

  async call() {
    try {
      await this.deactivateProject()
      this.deleteProjectLocales()
      await this.deleteProject()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  async deactivateProject() {
    const project = {
      id: this.projectID(),
      isActive: false,
    }
    await ProjectUpdater.call(project)
  }

  deleteProject() {
    return store.getActions().projects.delete(this.projectID())
  }

  async deleteProjectLocales() {
    const projectLocales = await store
      .getActions()
      .projectLocales.fetch(this.projectID())

    store.getActions().projectLocales.deleteProjectLocales(projectLocales)
  }

  projectID() {
    return this._projectID
  }
}
