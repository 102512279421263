import React from "react"
import { Container, Navbar } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import NavLink from "../../nav/NavLink"
import { path } from "../../../config/routes"
import { useStoreState } from "easy-peasy"
import Brand from "../../common/Brand"

const Sidebar = () => {
  const { isSharedIntegrationMode } = useStoreState(
    state => state.currentVendor
  )

  return (
    <Navbar expand="md" fixed="left" className="navbar-vertical bg-gray-100">
      <Navbar.Toggle aria-controls="main-navbar-nav" />
      <Container fluid>
        <Navbar.Collapse id="main-navbar-nav">
          <div className="navbar-nav">
            <NavLink
              className="py-0 mt-3 mb-2"
              iconClassname="text-gray-700"
              to={path("DASHBOARD")}
              icon="grid"
            >
              <span className="font-weight-bold text-gray-700">
                <FormattedMessage
                  id="components.layout.sidebar.navLink.dashboard"
                  defaultMessage="Dashboard"
                />
              </span>
            </NavLink>
            <NavLink
              className="py-0 mt-3 mb-2"
              iconClassname="text-gray-700"
              to={path("ACCOUNTS")}
              icon="list"
            >
              <span className="font-weight-bold text-gray-700">
                <FormattedMessage
                  id="components.layout.sidebar.navLink.accounts"
                  defaultMessage="Accounts"
                />
              </span>
            </NavLink>
            <NavLink
              className="py-0 mt-3 mb-2"
              iconClassname="text-gray-700"
              to={path("TEAM")}
              icon="users"
            >
              <span className="font-weight-bold text-gray-700">
                <FormattedMessage
                  id="components.layout.sidebar.navLink.team"
                  defaultMessage="Team"
                />
              </span>
            </NavLink>
            {isSharedIntegrationMode && (
              <NavLink
                className="py-0 mt-3 mb-2"
                iconClassname="text-gray-700"
                to={path("INTEGRATIONS")}
                icon="zap"
              >
                <span className="font-weight-bold text-gray-700">
                  <FormattedMessage
                    id="components.layout.sidebar.navLink.integrations"
                    defaultMessage="Language tools"
                  />
                </span>
              </NavLink>
            )}
          </div>
        </Navbar.Collapse>
        <Brand showPoweredBy dark className="d-flex align-items-center mt-5 align-self-center" classNameImg="navbar__powered-logo mt-n1" />
      </Container>
    </Navbar>
  )
}

export default Sidebar
