import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";
import RemoveFromWorkspaceButton from "./RemoveFromWorkspaceButton";
import { useStoreState } from "easy-peasy";
import { TextPlaceholder } from "../../common/Placeholder";

const TeamMember = ({ name, email, isWorkspaceOwner, children }) => (
  <li className="d-flex flex-row justify-content-between py-3 bg-gray-50 bg-gray-0 border-t border-gray-200">
    <div className="d-flex flex-column">
      <div>
        {name && <span className="font-bold mr-3 py-0 pb-0">{name}</span>}
        {isWorkspaceOwner && (
          <span className="font-bold text-sm text-muted">
            <FormattedMessage
              id="components.team.teamMembers.accountOwner"
              defaultMessage="account owner"
            />
          </span>
        )}
      </div>
      {email && <span className="text-sm">{email}</span>}
    </div>
    {children}
  </li>
)

const TeamPlaceholder = ({ delay }) => (
    <li className="py-3 bg-gray-50 bg-gray-0 border-t border-gray-200">
      <TextPlaceholder
        delay={delay}
        width="25%"
        height="0.9rem"
        className="mb-2"
        shadeLevel={2}
      />
      <TextPlaceholder
        delay={delay}
        width="35%"
        height="0.7rem"
      />
  </li>
);

const TeamMembers = ({ members, onRemove, isLoading }) => {
  const currentUser = useStoreState(state => state.currentUser.item);
  const currentVendor = useStoreState(state => state.currentVendor.item)
  if(members) members = members.filter(element =>  element !== undefined);

  return (
    <Row className="w-50">
      <Col>
        <h3>
          <FormattedMessage
            id="components.settings.team.list.title"
            defaultMessage="Members"
          />
        </h3>
        <ul className="clear-list">
          {isLoading && (
            <>
              <TeamPlaceholder />
              <TeamPlaceholder delay={1} />
              <TeamPlaceholder delay={2} />
            </>
          )}
          {members && members.map((member) => (
            <TeamMember
              key={member.email}
              name={member.name}
              email={member.email}
              isWorkspaceOwner={false}
            >
              {currentUser.sub !== member.sub ? (
                <RemoveFromWorkspaceButton
                  onRemove={onRemove}
                  userId={member.sub}
                  givenName={member.given_name}
                  email={member.email}
                  vendorID={currentVendor.id}
                />
              ) : null}
            </TeamMember>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

TeamMembers.defaultProps = {
  isLoading: false,
};

export default TeamMembers;
