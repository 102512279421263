import React, { useEffect, useState } from "react"
import { useStoreState } from "easy-peasy"
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"
import waitForGlobal from "../../utils/waitForGlobal"
import { API } from "aws-amplify"

const OpenPortal = ({ setRenderChargebee, openSectionOn }) => {
  const intl = useIntl()
  const [chargebee, setChargebee] = useState(null)
  const [sectionType, setSectionType] = useState(null)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const initChargebee = () => {
    window.Chargebee.init({
      site: `${process.env.CHARGEBEE_SITE}`,
      publishableKey: `${process.env.CHARGEBEE_PUBLISHABLE_KEY}`,
    })
    setChargebee(window.Chargebee.getInstance())
    if (openSectionOn)
      setSectionType(window.Chargebee.getPortalSections()[openSectionOn])
  }

  const openPortal = () => {
    const params = {
      queryStringParameters: {
        locale: intl.locale,
        organizationID: currentOrg.id,
      },
    }
    if (chargebee !== null) {
      chargebee.setPortalSession(() =>
        API.get("nepal", "/chargebee/portal-token", params)
      )
      const portal = chargebee.createChargebeePortal()

      const close = () => {
        setRenderChargebee(false)
        chargebee.closeAll()
      }

      if (openSectionOn) {
        portal.open({ close }, { sectionType })
      } else portal.open({ close })
    }
  }

  useEffect(() => {
    waitForGlobal("Chargebee", initChargebee)
  }, [])

  useEffect(() => {
    if (chargebee) openPortal()

    return () => {
      if (chargebee) {
        chargebee.logout()
      }
    }
  }, [chargebee])

  return (
    <Helmet>
      <script
        src="https://js.chargebee.com/v2/chargebee.js"
        data-cb-site={process.env.CHARGEBEE_SITE}
      />
    </Helmet>
  )
}

export default OpenPortal
