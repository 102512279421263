import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Image } from "react-bootstrap"
import { projectLogoURL } from "../../helpers/assets"
import Icon from "../Icon"
import { redirect } from "../../config/routes"
import { useStoreState } from "easy-peasy"

const ProjectCard = ({ newProject = false, project = {}, className }) => {
  const { id, name } = project
  const connector =
    useStoreState(state => state.connectors.getConnector(project?.connector?.type))
  if (connector?.logo) {
    project.connector.logoUrl = connector.logo
  }
  const cardImage = newProject ? (
    <div className="project-card__plus mr-3">
      <Icon name="plus" />
    </div>
  ) : (
    <Image
      className="connector-card--image mr-3"
      src={projectLogoURL(project)}
    />
  )
  const cardTitle = newProject ? (
    <FormattedMessage
      id="components.dashboard.projectCard.cardTitle"
      defaultMessage="Add Project"
    />
  ) : (
    name
  )

  const routeToProject = () =>
    newProject ? redirect("NEW_PROJECT") : redirect("PROJECT", { id })

  return (
    <button className={`project-card ${className}`} onClick={routeToProject}>
      <div className="d-flex align-items-center">
        {cardImage}
        <div className="d-flex flex-column text-left">
          <h3
            className={`mb-0 font-bold text-gray-800 ${
              !newProject ? "text-capitalize" : ""
            }`}
          >
            {cardTitle}
          </h3>
        </div>
      </div>
    </button>
  )
}

export default ProjectCard
