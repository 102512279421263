import React, { useEffect, useState } from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useMatch } from "@reach/router"
import { DateTime } from "luxon"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import PageContainer from "../../components/layout/PageContainer"
import { path } from "../../config/routes"
import { TYPES } from "../../models/onboarding"
import { Table } from "react-bootstrap"
import Icon from "../../components/Icon"
import classNames from "classnames"
import { ORGANIZATION_STATUSES } from "../../models/organization"

const AccountIntegrationIcons = ({ account }) => {
  const integrationTypes = useStoreState(state => state.integrationTypes.items)
  const vendor = useStoreState(state => state.currentVendor.item)
  const [integrations, setIntegrations] = useState([])

  useEffect(() => {
    if (!account || integrationTypes.length === 0 || !vendor) return
    setIntegrations([
      account.bmsIntegration,
      (account.integration || vendor.defaultIntegration),
    ].filter(Boolean))
  }, [account, integrationTypes, vendor])

  const integrationToolTip = (id, name) => {
    if (id === vendor.defaultIntegration.id) name = `Default (${name})`
    return <Tooltip>{name}</Tooltip>
  }

  return (
    <div className="accounts-integrations">
      {integrations.map(({ id, integrationType, name }) => (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={integrationToolTip(id, name)}
          key={id}
        >
          <div className="avatar avatar-xs mr-1">
            <img src={integrationType.metadata?.logoSquare || integrationType.metadata?.logo} className="avatar-img" alt={integrationType.metadata?.name || integrationType.name} />
          </div>
        </OverlayTrigger>
      ))}
    </div>
  )
}

const Accounts = () => {
  const clients = useStoreState(state => state.clients.items)
  const fetchClients = useStoreActions(actions => actions.clients.fetch)
  const { isSharedIntegrationMode, item: currentVendor } = useStoreState(
    state => state.currentVendor
  )
  const { locale } = useMatch("/:locale/*")

  useEffect(() => {
    if (!currentVendor) return
    fetchClients({ vendorID: currentVendor.id })
  }, [currentVendor, fetchClients])

  return (
    <PageContainer className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1 className="d-flex align-items-center main-heading mb-4">
          <FormattedMessage
            id="containers.admin.settings.title"
            defaultMessage="Accounts"
          />
        </h1>
           <Link
              to={path("ACCOUNT_ADD")}
              className="btn bg-green-500 text-white">
              <Icon name="plus-circle" />
                <FormattedMessage
                  id="containers.admin.settings.addAccount"
                  defaultMessage="Add Account"/>
            </Link>
      </div>
      {clients.length > 0 ? (
        <Table className="text-center">
          <thead>
            <tr>
              <th className="p-0" style={{ width: 0 }} />
              <th className="text-left">
                <FormattedMessage
                  id="containers.admin.dashboard.table.header.tablename"
                  defaultMessage="Name"
                />
              </th>
              <th className="text-left">
                <FormattedMessage
                  id="containers.admin.dashboard.table.header.tableStatus"
                  defaultMessage="Status"
                />
              </th>
              {isSharedIntegrationMode && (
                <th className="text-left">
                  <FormattedMessage
                    id="containers.admin.dashboard.table.header.integrationName"
                    defaultMessage="Integrations"
                  />
                </th>
              )}
              <th className="text-right">
                <FormattedMessage
                  id="containers.admin.dashboard.table.header.createdAt"
                  defaultMessage="Created At"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {clients.map(client => (
              <tr key={client.id}>
                <td className="p-0">
                  {client.name && (
                    <h3
                      style={{
                        width: "1.3rem",
                        height: "1.3rem",
                        fontSize: "0.8rem",
                      }}
                      className={`
                        d-flex align-items-center justify-content-center
                        text-gray-500 border border-gray-300 rounded m-0 p-1
                      `}
                    >
                      {client.name[0].toUpperCase()}
                    </h3>
                  )}
                </td>
                <td className="text-left">
                  <span>{client.name}</span>
                </td>
                <td className="text-left">
                  <div className={classNames("status-chip", {
                    "bg-success": client.status !== ORGANIZATION_STATUSES.SUSPENDED,
                    "bg-gray-400": client.status === ORGANIZATION_STATUSES.SUSPENDED,
                  })}></div>
                </td>
                {isSharedIntegrationMode && (
                  <td className="text-left">
                    <AccountIntegrationIcons account={client} />
                  </td>
                )}
                <td className="text-right">
                  {DateTime.fromISO(client.createdAt).toFormat("dd LLL yyyy")}
                </td>
                <td className="d-flex justify-content-end">
                  <div
                    className="btn-group accounts-actions"
                    role="group"
                    aria-label="Basic example"
                  >
                    {isSharedIntegrationMode && (
                      <Link
                        to={path("ACCOUNT_DETAILS", { id: client.id })}
                        className="d-flex align-items-center text-sm text-gray-600 btn bg-gray-100 border-gray-300 py-1 px-3"
                      >
                        <Icon name="edit-2" />
                        <span>
                          <FormattedMessage
                            id="containers.admin.dashboard.editWorkspace"
                            defaultMessage="Edit"
                          />
                        </span>
                      </Link>
                    )}
                    <a
                      href={`/${locale}${path("ROOT")}?workspace=${
                        client.id
                      }&client=true`}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                      className="d-flex align-items-center text-sm text-gray-600 btn bg-gray-100 border-gray-300 py-1 px-3"
                    >
                      <Icon name="eye" />
                      <span>
                        <FormattedMessage
                          id="containers.admin.dashboard.viewWorkspace"
                          defaultMessage="View"
                        />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <h3 className="m-0">
            <FormattedMessage
              id="containers.admin.dashboard.noClients"
              defaultMessage="No accounts"
            />
          </h3>
        </div>
      )}
    </PageContainer>
  )
}

export default Accounts
