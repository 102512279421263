import { useStoreState, useStoreActions } from "easy-peasy";
import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useEffect } from "react"
import { Badge, Image, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Field from "../../components/forms/Field";
import { useMatch } from "@reach/router"
import { path } from "../../config/routes"
import Icon from "../../components/Icon"
import { connectorLogoURL } from "../../helpers/assets";

const renderConnectorName = ({ type }) => {
  return <Tooltip>{type}</Tooltip>
}

const ProjectList = ({ setValue, organizationID, projectTemplates = [], errors, control, hasTemplates }) => {
  const projects = useStoreState((state) => state.projects.items)
  const fetchProjects = useStoreActions((actions) => actions.projects.listByOrganization)
  const projectTemplateField = {
    type: "select",
    options: projectTemplates,
    isClearable: true,
    errors,
    control,
  };

  const { locale } = useMatch("/:locale/*")

  useEffect(() => {
    fetchProjects(organizationID);
  }, [organizationID]);

  useEffect(() => {
    if (!projects.length || !projectTemplates.length) return;

    projects.forEach((project) => {
      setValue(
        `projects[${project.id}][integrationTemplate]`,
        projectTemplates.find(({ id }) => (id === project.integrationTemplateID))
      );
    })
  }, [projects, projectTemplates, setValue])

  return (
    <>
      <Table responsive borderless striped>
        <thead>
          <th>
            <FormattedMessage
              id="containers.admin.project.projectName"
              defaultMessage="Project Name"
            />
          </th>
          <th className="text-center">
            <FormattedMessage
              id="containers.admin.project.connector"
              defaultMessage="Connector"
            />
          </th>
          <th>
            <FormattedMessage
              id="containers.admin.project.sourceLocale"
              defaultMessage="Source Language"
            />
          </th>
          <th>
            <FormattedMessage
              id="containers.admin.project.sourceLocale"
              defaultMessage="Target Language"
            />
          </th>
          <th className="text-center">
            <FormattedMessage
              id="containers.admin.project.sourceLocale"
              defaultMessage="Auto-Update"
            />
          </th>
          {hasTemplates &&
            <th>
              <FormattedMessage
                id="containers.admin.project.projectTemplates"
                defaultMessage="Project Template"
              />
            </th>
          }
          <th></th>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr>
              <td>{project.name}</td>
              <td>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderConnectorName(project.connector)}
                  id={project.id}
                >
                  <Image className="cards-grid-item__logo m-auto" src={connectorLogoURL(project.connector.type)} />
                </OverlayTrigger>
              </td>
              <td>
                <Badge className="bg-gray-400 text-white text-lg">{project.sourceLocale.code}</Badge>
              </td>
              <td>
                {project.targetLocales.sort((a, b) => (a.code.localeCompare(b.code))).map((targetLocale) => (
                  <Badge className="bg-gray-400 text-lg text-white mr-1">{targetLocale.code}</Badge>
                ))}
              </td>
              <td className="text-center text-primary">
                {project.isActive &&
                  <Icon className="primary" name="check"/>
                }
              </td>
              {hasTemplates &&
                <td>
                  <Field
                    name={`projects[${project.id}][integrationTemplate]`}
                    {...projectTemplateField}
                    defaultValue={projectTemplates.find(({ id }) => (id === project.integrationTemplateID))}
                  />
                </td>
              }
              <td>
                <a
                  href={`/${locale}${path("ROOT")}projects/${project.id}?workspace=${organizationID}&client=true`}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  className="d-flex align-items-center text-sm text-gray-600 btn bg-gray-100 border-gray-300 py-1 px-3"
                >
                  <Icon name="eye" />
                  <span>
                    <FormattedMessage
                      id="containers.admin.dashboard.viewWorkspace"
                      defaultMessage="View"
                    />
                  </span>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default ProjectList;