import React from "react"
import { Doughnut } from "react-chartjs-2"

const LocaleCompletionChart = ({ projectLocale }) => {
  const percentageComplete = projectLocale.completion || 0
  const remainder = 100 - percentageComplete
  const data = {
    datasets: [
      {
        data: [percentageComplete, remainder],
        backgroundColor: ["#34D399", "#E2E8F0"],
        borderWidth: [0, 0],
      },
    ],
  }
  return (
    <Doughnut
      data={data}
      legend={{ display: false }}
      width={50}
      height={26}
      options={{
        cutoutPercentage: 50,
        maintainAspectRatio: false,
        hover: { mode: null },
        tooltips: { enabled: false },
      }}
    />
  )
}

export default LocaleCompletionChart
