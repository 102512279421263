import React from "react";
import PropTypes from "prop-types"
import Icon from "../../Icon"

const Source = ({
  open,
  resource,
  onToggle,
  resourceListIndex,
  actions,
}) => (
  <tr key={`resource-${resourceListIndex}-locale-${resource.projectLocaleID}`}>
    <td className="align-top pr-0 text-center">
      <button className="btn" onClick={onToggle} onKeyPress={onToggle}>
        <span className="text-primary">
          <Icon name={open ? 'chevron-down' : 'chevron-right'} className="mr-2" />
        </span>
      </button>
    </td>
    <td className="align-top pl-0" colSpan="2">
      <button className="btn" onClick={onToggle} onKeyPress={onToggle}>
        {resource.path}
      </button>
    </td>
    <td className="align-top">
      {actions}
    </td>
  </tr>
);

Source.propTypes = {
  open: PropTypes.bool.isRequired,
  resource: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
  resourceListIndex: PropTypes.number.isRequired,
  actions: PropTypes.node,
};

export default Source;
