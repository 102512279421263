import store from "../store/index"

export default class ProjectResourcesDestroyer {
  constructor({ projectID, resourceIndex }) {
    this._projectID = projectID
    this._resourceIndex = resourceIndex
  }

  static async call(args) {
    return new ProjectResourcesDestroyer(args).call()
  }

  async call() {
    try {
      await this.deleteProjectResource()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  deleteProjectResource() {
    return store
      .getActions()
      .projects
      .removeResource({
        projectID: this.projectID(),
        resourceIndex: this.resourceIndex()
      })
  }

  projectID() {
    return this._projectID
  }

  resourceIndex() {
    return this._resourceIndex
  }
}
