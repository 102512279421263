import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { fieldRequired } from "../../../utils/validations"
import { useForm } from "react-hook-form"
import ModalForm from "../../forms/ModalForm"
import { STATUSES } from "../../forms/ModalForm"
import { projectLocaleName } from "../../../helpers/locale"
import ResourcesForm from "./ResourcesForm"
import { existingResourcePaths } from "../../../helpers/resources"
import { triggerQuote } from "../../../helpers/instances"

const ResourceModalForm = ({
  formAction,
  title,
  Trigger,
  resources
}) => {
  const intl = useIntl()
  const { handleSubmit, errors, control } = useForm({
    mode: "onChange",
  })
  const [status, setStatus] = useState()

  const { resourceListIndex, projectID } = resources[0] || {}
  const existingPaths = existingResourcePaths(projectID)

  const hiddenFields = [
    {
      name: "projectID",
      type: "hidden",
      validations: { ...fieldRequired },
      defaultValue: `${projectID}`,
    },
  ]

  const sourceFields = []
  const targetFields = []

  const getFields = (name, resource) => {
    return [
      {
        name: `${name}[path]`,
        type: "text",
        label: intl.formatMessage(
          {
            id: "components.projects.resource.path.label",
            defaultMessage: `File for {language}`,
          },
          { language: projectLocaleName(resource.projectLocale, intl) }
        ),
        validations: {
          ...fieldRequired,
          validate: value =>
            !existingPaths.includes(value) ||
            value === resource.path ||
            "Path must be unique",
        },
        defaultValue: resource.path,
      },
      {
        name: `${name}[projectLocaleID]`,
        type: "hidden",
        defaultValue: `${resource.projectLocale.id}`,
      }
    ]
  }

  resources.forEach((resource, index) => {
    const fields = getFields(
      `resources[${resourceListIndex}][${index}]`,
      resource
    )
    if (resource.projectLocale.isSource) {
      sourceFields.push(...fields)
    } else {
      targetFields.push(...fields)
    }
  })

  const onSubmit = async payload => {
    setStatus(STATUSES.loading)
    if (await formAction(payload)) {
      setStatus(STATUSES.success)
      triggerQuote({ projectID })
    } else setStatus(STATUSES.error)
  }

  return (
    <ModalForm
      status={status}
      setStatus={setStatus}
      title={title}
      Trigger={Trigger}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
    >
      <ResourcesForm
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        hiddenFields={hiddenFields}
        sourceFields={sourceFields}
        targetFields={targetFields}
      />
    </ModalForm>
  )
}

export default ResourceModalForm
