import { useStoreActions, useStoreState } from "easy-peasy"
import { useIntl, navigate } from "gatsby-plugin-intl"
import React, { useEffect, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { useForm, useWatch } from "react-hook-form"
import ProjectUpdater from "../../service/projectUpdater"
import { fieldRequired } from "../../utils/validations"
import Spinner from "../common/Spinner"
import Form from "../forms/Form"
import { VISIBILITY } from "../integrations/IntegrationCard"
import { path } from "../../config/routes"

const LanguageToolForm = ({ projectId, integrationID, integrationTemplateID }) => {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showSelection, setShowSelection] = useState(false)
  const availableIntegrations = useStoreState(state => state.integrations.availableIntegrations)
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const { errors, control, handleSubmit, setValue } = useForm()
  const fetchAvailableIntegrations = useStoreActions(actions => actions.integrations.fetchAvailableIntegrations)
  const fetchProjectTemplates = useStoreActions(actions => actions.projectTemplates.fetch)
  const { isDedicatedMode, item: currentOrg } = useStoreState(state => state.currentOrg)

  const { subscription, hasNoSubscriptions } = useStoreState(state => state.billing)

  const integration = useWatch({
    control,
    name: "integration",
    defaultValue: availableIntegrations.find(({ id }) => (id === integrationID))
  })

  const onSubmit = async ({ integration, integrationTemplate }) => {
    setIsSubmitting(true)
    await ProjectUpdater.call({
      id: projectId,
      integrationID: integration?.id,
      integrationTemplateID: integrationTemplate?.id
    })

    setIsSubmitting(false)
  }

  useEffect(()=> {
    if(!currentOrg.id) return
    fetchAvailableIntegrations({ organizationID: currentOrg.id })
  },[fetchAvailableIntegrations, currentOrg.id])

  useEffect(() => {
    setShowSelection(!hasNoSubscriptions || isDedicatedMode)
  }, [subscription.details])

  const redirect = () => {
    navigate(path("UPGRADE_PLAN"))
  }

  const integrationFields = [
    {
      name: "integration",
      type: "select",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.integrationFields.languageTool",
        defaultMessage: "Language tool"
      }),
      options: availableIntegrations,
      validations: { ...fieldRequired },
    },
  ]

  const projectTemplateFields = integration && integration.integrationType?.configuration?.hasIntegrationTemplate ? [
    {
      name: "integrationTemplate",
      type: "select",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.projectTemplate.label",
        defaultMessage: "Project Template"
      }),
      description: intl.formatMessage({
        id: "components.projects.editForm.fields.projectTemplate.description",
        defaultMessage: "A project template bundles all the resources needed for project creation in one asset which you can reuse multiple times."
      }),
      isLoading,
      options: projectTemplates,
      validations: { ...fieldRequired },
    }
  ] : []

  useMemo(() => {
    if (!integration?.id || integration.visibility === VISIBILITY.PUBLIC) return

    setValue("integrationTemplate", null)
    setIsLoading(true)
    fetchProjectTemplates(integration).then(() => (setIsLoading(false)))
  }, [integration, fetchProjectTemplates])

  useEffect(() => {
    if (!availableIntegrations.length) return
    setValue("integration", availableIntegrations.find(({ id }) => (id === integrationID)))
  }, [integrationID, availableIntegrations])

  useEffect(() => {
    if (!projectTemplates.length) return

    setValue("integrationTemplate", projectTemplates.find(({ id }) => (id === integrationTemplateID)))
  }, [integrationTemplateID, projectTemplates])

  return showSelection ? (
    <Form
      errors={errors}
      fields={[...integrationFields, ...projectTemplateFields]}
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      status={isSubmitting}
    >
      <div className="d-flex justify-content-end">
        <Button
          type="submit"
          className="mt-4 btn btn--primary"
          disabled={errors?.length > 0 || isSubmitting}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            intl.formatMessage({
              id: "components.projects.languageTool.settingsSubmitLabel",
              defaultMessage: "Update Language Tool",
            })
          )}
        </Button>
      </div>
    </Form>
  ) : (
    <Button variant="info" size="sm" onClick={redirect}>
      Upgrade
    </Button>
  )
}

export default LanguageToolForm
