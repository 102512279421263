import React, { useEffect, useState } from "react"
import { useFlags } from "gatsby-plugin-launchdarkly"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Card, Row, Col, Container } from "react-bootstrap"
import { useStoreState } from "easy-peasy"
import AddAzureWebhook from "./AddAzureWebhook"
import EditForm from "./EditForm"
import DeleteProjectButton from "./DeleteProjectButton"
import TranslationBriefEditor from "./translationBrief/Editor"
import AddGitLabWebhook from "./AddGitLabWebhook"
import { CONNECTORS } from "../../store/models/connectors"
import PushModeForm from "./PushModeForm"
import { ORG_TYPES } from "../../models/organization"
import TextmasterProjectSettings from "./TextmasterProjectSettings"
import AutoUpdateFrequency from "./AutoUpdateFrequency"
import LanguageToolForm from "./LanguageToolForm"
import SynchronizeProjectButton from "./SynchronizeProjectButton"

const Settings = ({ project }) => {
  const { projectBuffer } = useFlags()
  const { partnerText, workspaceType } = useStoreState(
    state => state.currentOrg
  )
  const isTextmaster = workspaceType === ORG_TYPES.textmaster
  const isPartner = useStoreState(state => state.currentOrg.isPartner)
  const isSharedMode = useStoreState(state => state.currentOrg.isSharedMode)
  const connector = useStoreState(state =>
    state.connectors.byId(project.connector.type)
  )
  const [hasAutoUpdate, setHasAutoUpdate] = useState(false)

  useEffect(() => {
    if (!connector?.autoUpdateModes) return
    const autoUpdateSetting = connector?.autoUpdateModes.length > 0
    setHasAutoUpdate(
      autoUpdateSetting === undefined || autoUpdateSetting === true
    )
  }, [connector])

  return (
    <div className="">
      <Container className="container-md mb-3">
        <Card className="mt-5 p-4">
          <Row className="p-3 mt-3">
            <Col className="col-3">
              <h2 className="text-gray-700">
                <FormattedMessage
                  id="components.projects.settings.header"
                  defaultMessage="Settings"
                />
              </h2>
            </Col>
            <Col>
              <EditForm project={project} />
              {project.connector.type === CONNECTORS.gitlab && (
                <AddGitLabWebhook project={project} />
              )}
              {project.connector.type === CONNECTORS.azurerepos && (
                <AddAzureWebhook project={project} />
              )}
            </Col>
          </Row>
        </Card>

        {!isSharedMode && (
          <Card className="p-4">
            <Row className="p-3 mt-3">
              <Col className="col-3">
                <h2 className="text-gray-700">
                  <FormattedMessage
                    id="components.projects.languageTool.header"
                    defaultMessage="Language tool "
                  />
                </h2>
              </Col>
              <Col>
                <LanguageToolForm
                  projectId={project.id}
                  integrationID={project.integrationID}
                  integrationTemplateID={project.integrationTemplateID}
                />
              </Col>
            </Row>
          </Card>
        )}
        {hasAutoUpdate && (
          <Card className="p-4">
            <Row className="p-3 mt-3">
              <Col className="col-3">
                <h2 className="text-gray-700">
                  <FormattedMessage
                    id="components.projects.settings.autoUpdate.header"
                    defaultMessage="Auto-Update"
                  />
                </h2>
              </Col>
              <Col>
                <PushModeForm project={project} />
                <p className="text-gray-500">
                  <FormattedMessage
                    id="components.projects.settings.autoUpdate.description1"
                    defaultMessage="Automatically push new content when detected on {connectorName}."
                    values={{ connectorName: connector?.name }}
                  />
                </p>
              </Col>
            </Row>
            {projectBuffer && (
              <Row className="p-3 mt-3">
                <Col className="offset-lg-3">
                  <AutoUpdateFrequency project={project} />
                </Col>
              </Row>
            )}
          </Card>
        )}
        {isTextmaster && (
          <Card className="p-4">
            <Row className="p-3 mt-3">
              <Col className="col-3">
                <h2 className="text-gray-700">
                  <FormattedMessage
                    id="components.projects.settings.textmasterSettings.header"
                    defaultMessage="{brandName} Settings"
                    values={partnerText}
                  />
                </h2>
                <p className="text-gray-500">
                  <FormattedMessage
                    id="components.projects.settings.textmasterSettings.description1"
                    defaultMessage="Options used when opening a project on {brandName}."
                    values={partnerText}
                  />
                </p>
              </Col>
              <Col>
                <TextmasterProjectSettings project={project} />
              </Col>
            </Row>
          </Card>
        )}
        <Card className="p-4">
          <Row className="p-3 mt-3">
            <Col className="col-3">
              <h2 className="text-gray-700">
                <FormattedMessage
                  id="components.projects.settings.connection.header"
                  defaultMessage="Connection"
                />
              </h2>
            </Col>
            <Col>
              <SynchronizeProjectButton project={project} />
            </Col>
          </Row>
        </Card>
        {!isPartner && (
          <Card className="p-4">
            <Row className="p-3 mt-3">
              <Col className="col-3">
                <h2 className="text-gray-700">
                  <FormattedMessage
                    id="components.projects.settings.translationBrief.header"
                    defaultMessage="Translation Brief"
                  />
                </h2>
                <p className="text-gray-500">
                  <FormattedMessage
                    id="components.projects.settings.translationBrief.description1"
                    defaultMessage="Your translation brief is custom created for the
                  translators working on this project. It contains project and
                  industry-specific guidance to improve translations."
                  />
                </p>
                <p className="text-gray-500">
                  <FormattedMessage
                    id="components.projects.settings.translationBrief.description2"
                    defaultMessage="You can customize it as needed. 🤙"
                  />
                </p>
              </Col>
              <Col>
                <TranslationBriefEditor project={project} />
              </Col>
            </Row>
          </Card>
        )}
        <Row className="py-3 mt-3">
          <Col className="col-3"></Col>
          <Col className="d-flex justify-content-end">
            <DeleteProjectButton project={project} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Settings
