import store from "../store/index"
import ProjectResourcePatternsValidator from "./projectResourcePatternsValidator"

export default class ProjectResourcePatternsCreator {
  constructor(projectResourcePattern) {
    this._projectResourcePattern = projectResourcePattern
  }

  static async call(args) {
    return new ProjectResourcePatternsCreator(args).call()
  }

  async call() {
    try {
      const error = await ProjectResourcePatternsValidator.call(this.projectResourcePattern());
      if (error) {
        return false
      }
      await this.createProjectResourcePattern()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  createProjectResourcePattern() {
    return store
      .getActions()
      .projects
      .addResourcePatterns(this.projectResourcePattern())
  }

  projectResourcePattern() {
    return this._projectResourcePattern
  }
}
