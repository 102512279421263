import { useStoreActions, useStoreState } from "easy-peasy";
import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useEffect, useState } from "react"
import IntegrationForm from "../../../../components/integrations/IntegrationForm";


const PartnerIntegration = ({ onboarding, type, partner }) => {
  const { item: currentVendor } = useStoreState(state => state.currentVendor)
  const { fetch: fetchIntegrationType, reset: resetIntegrationType } = useStoreActions(actions => actions.integrationTypes)
  const integrationType = useStoreState(state => state.integrationTypes.item)
  const createIntegration = useStoreActions(actions => actions.integrations.create)

  const [integration, setIntegration] = useState({})

  type = type || partner

  useEffect(() => {
    if (!type) return
    fetchIntegrationType(type)
    return () => {
      resetIntegrationType()
    }
  }, [fetchIntegrationType, resetIntegrationType, type])

  useEffect(() => {
    setIntegration({
      groups: [currentVendor.id],
      integrationType,
      integrationTypeID: integrationType.id,
      name: integrationType.name,
      vendorID: currentVendor.id,
    })
  }, [currentVendor, integrationType, setIntegration])

  const onSubmit = async ({ integration }) => {
    integration = await createIntegration(integration)
  }

  return (
    <>
      <h1 className="main-heading mb-5">
        <FormattedMessage
          id="containers.vendor.onboarding.stages.integration"
          defaultMessage="Setup   integration for {name}"
          values={{ name: integrationType.name || "" }}
        />
      </h1>
      <IntegrationForm
        integrationTypeID={type}
        isOnboarding={true}
        integration={integration}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default PartnerIntegration;