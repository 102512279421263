import { useStoreActions, useStoreState } from "easy-peasy"
import { useEffect } from "react"

export const useChargebeeEntitlements = () => {
  const { item: currentOrg, plans, isPartner } = useStoreState(state => state.currentOrg)

  const fetchEntitlements = useStoreActions(actions => actions.billing.fetchEntitlements)
  const features = useStoreState(state => state.billing.features)

  useEffect(() => {
    if (!currentOrg.id || (!isPartner && !plans.length)) return;

    fetchEntitlements(currentOrg.id)
  }, [fetchEntitlements, currentOrg, plans, isPartner])
  return features || {}
}
