import store from "../store/index"
import ProjectLocaleResourcesDestroyer from "./projectLocaleResourcesDestroyer"

export default class ProjectLocaleDestroyer {
  constructor(projectLocale) {
    this._projectLocale = projectLocale
  }

  static async call(args) {
    return new ProjectLocaleDestroyer(args).call()
  }

  async call() {
    try {
      await this.deleteProjectLocale()
      await ProjectLocaleResourcesDestroyer.call(this.projectLocale())
      await this.fetchProject()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  deleteProjectLocale() {
    return store
      .getActions()
      .projectLocales
      .delete(this.projectLocale())
  }

  fetchProject() {
    return store
      .getActions()
      .projects
      .fetch(this.projectID())
  }

  projectID() {
    return this.projectLocale().projectID
  }

  projectLocale() {
    return this._projectLocale
  }
}
