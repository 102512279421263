import React, { useEffect, useState } from "react"
import { Card, Table } from "react-bootstrap"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { API, graphqlOperation } from "aws-amplify"
import {
  onCreateContentResource,
  onUpdateContentResource,
  onDeleteContentResource,
} from "../../../graphql/locale-subscriptions"

import Toast from "../../Toast"
import Resource from "./Resource"
import Spinner from "../../common/Spinner"
import FilterField from "../../forms/FilterField"
import FilterCategories from "../../forms/FilterCategories"
import { getEnabledTotal, getResourceTypeForConnector, isGroupEnabled } from "../../../helpers/contentResource"

const CATEGORIES = {
  ALL: "all",
  ENABLED: "enabled",
  DISABLED: "disabled",
}

let lastTotal

const ProjectContents = ({ currentOption, currentCategory, project, resources, total }) => {
  const resourcesExcluded = project.resourcesExcluded || []
  const update = useStoreActions(state => state.projects.update)
  const [showSaved, setShowSaved] = useState({ toast: false, count: 0 })
  const [showUnsaved, setShowUnsaved] = useState({ toast: false, count: 0 })

  const excludeResource = (id) => [...resourcesExcluded, id]
  const includeResource = id => resourcesExcluded.filter(resource => resource !== id)

  const onCheckboxChange = (e, groupID) => {
    const checked = e.target.checked
    const updatedResourcesExcluded = !checked
      ? excludeResource(groupID)
      : includeResource(groupID)

    if (updatedResourcesExcluded.length === resourcesExcluded.length) return

    update({
      id: project.id,
      resourcesExcluded: updatedResourcesExcluded,
    })
  }

  const filterByStatus = (group) => {
    switch (currentCategory) {
      case CATEGORIES.ENABLED:
        return isGroupEnabled(resourcesExcluded, group)
      case CATEGORIES.DISABLED:
        return !isGroupEnabled(resourcesExcluded, group)
      default:
        return true
    }
  }

  const filterByParentId = (items) => {
    if (!currentOption) return items

    const findDeep = items =>
      items.find(i => i.id === currentOption || findDeep(i.children))

    const filterDeep = item => {
      if (!item) return []
      if (item.id === currentOption) return [item]

      const deeperParent = findDeep(item.children)

      if (deeperParent) {
        return [{
          ...item,
          children: filterDeep(deeperParent)
        }]
      }
    }

    return filterDeep(findDeep(items))
  }

  const filterResources = () => {
    const filteredByStatus = resources.filter(filterByStatus)

    return filterByParentId(filteredByStatus)
  }

  return (
    <>
      <Toast
        show={showSaved.toast}
        setShow={() => setShowSaved({ ...showSaved, toast: false })}
        delay={2000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.projects.content.listResources.saved"
            defaultMessage="{items} items enabled 👍"
            values={{ items: showSaved.count }}
          />
        </p>
      </Toast>
      <Toast
        show={showUnsaved.toast}
        setShow={() => setShowUnsaved({ ...showSaved, toast: false })}
        delay={2000}
      >
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.projects.content.listResources.saved"
            defaultMessage="{items} items disabled 👋"
            values={{ items: showUnsaved.count }}
          />
        </p>
      </Toast>
      {filterResources().length > 0 ? (
        filterResources().map((item, index) => (
          <Resource
            id={item.id}
            key={item.id}
            index={index}
            content={item}
            resourcesExcluded={resourcesExcluded}
            onCheckboxChange={onCheckboxChange}
          />
        ))
      ) : (
        <tr className="d-flex flex-column justify-content-center align-items-center my-5 text-gray-700">
          <td className="border-0">
            <h3 className="mt-3">
              {total > 0 ? (
                <FormattedMessage
                  id="components.projects.content.ListResources.noItemsFiltered"
                  defaultMessage="No items for that selection"
                />
              ) : (
                <FormattedMessage
                  id="components.projects.content.ListResources.noItems"
                  defaultMessage="No content available"
                />
              )}
            </h3>
          </td>
        </tr>
      )}
    </>
  )
}

const TranslationStatus = ({ project }) => {
  const intl = useIntl()
  const statusText = text => <span className="m-2 text-gray-600">{text}</span>

  if (project.hasQuoteInProgress) {
    return (
      <div className="d-flex align-items-center text-gray-600">
        <Spinner isSmall />
        {statusText(
          intl.formatMessage({
            id: "components.project.content.ListResources.processTranslation",
            defaultMessage: "Processing your content for translation...",
          })
        )}
      </div>
    )
  }

  if (project.atLeastOneInProgress) {
    return statusText(
      intl.formatMessage({
        id: "components.projects.content.ListResources.inProgressTranslation",
        defaultMessage: "A Translation is In Progress",
      })
    )
  }

  if (project.allCompleted) {
    return statusText(
      intl.formatMessage({
        id: "components.projects.content.ListResources.allLocalesComplete",
        defaultMessage: "Translations Complete 🎉",
      })
    )
  }

  return <></>
}

const ListResources = ({ project }) => {
  const { organizationID, providerID, resourcesExcluded, connector } = project
  const [isLoading, setIsLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [currentCategory, setCurrentCategory] = useState("all")
  const [currentOption, setCurrentOption] = useState()
  const [showSaved, setShowSaved] = useState({ toast: false, count: 0 })
  const [showUnsaved, setShowUnsaved] = useState({ toast: false, count: 0 })
  const [showInserted, setShowInserted] = useState(false)
  const [showUpdated, setShowUpdated] = useState(false)
  const [showDeleted, setShowDeleted] = useState(false)

  const {
    hasMore: hasMoreResources,
    total,
    namespaces,
    resources
  } = useStoreState(state => state.contentResources)

  const intl = useIntl()
  const enabledTotal = getEnabledTotal(resources, resourcesExcluded)
  const disabledTotal = total - enabledTotal

  const {
    fetch: fetchResources,
    insertContentResource,
    updateContentResource,
    deleteContentResource,
    setQueryFromOption
  } = useStoreActions(actions => actions.contentResources)

  useEffect(() => {
    if (!lastTotal) {
      lastTotal = enabledTotal
      return
    }

    const selectChange = lastTotal - enabledTotal

    if (selectChange < 0) {
      setShowSaved({ toast: true, count: Math.abs(selectChange) })
      setShowUnsaved({ ...showUnsaved, toast: false })
    }
    else if (selectChange > 0) {
      setShowUnsaved({ toast: true, count: Math.abs(selectChange) })
      setShowSaved({ ...showSaved, toast: false })
    }

    lastTotal = enabledTotal
  }, [enabledTotal])

  useEffect(() => {
    if (!providerID) return
    const subscriptions = [
      API.graphql(graphqlOperation(onCreateContentResource)).subscribe({
        next: ({ value }) => {
          const data = value.data.onCreateContentResource
          if (
            data &&
            providerID === data.connectorID &&
            organizationID === data.organizationID
          ) {
            setShowInserted(true)
            insertContentResource({
              data,
              connectorID: providerID,
              organizationID,
            })
          }
        },
      }),
      API.graphql(graphqlOperation(onUpdateContentResource)).subscribe({
        next: ({ value }) => {
          const data = value.data.onUpdateContentResource

          if (data) {
            setShowUpdated(true)
            updateContentResource({ data })
          }
        },
      }),
      API.graphql(graphqlOperation(onDeleteContentResource)).subscribe({
        next: ({ value }) => {
          const data = value.data.onDeleteContentResource

          if (data) {
            setShowDeleted(true)
            deleteContentResource({ data })
          }
        },
      }),
    ]
    return () => subscriptions.forEach(s => s.unsubscribe())
  }, [providerID])

  const fetchData = async setting => {
    try {
      if (!setting?.loadMore) setIsLoading(true)
      else setLoadMoreLoading(true)

      await fetchResources({
        intl,
        loadMore: setting?.loadMore,
        connector: providerID,
        organization: organizationID,
      })

      if (!setting?.loadMore) setIsLoading(false)
      else setLoadMoreLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (providerID) {
      fetchData()
    }
  }, [project.id, providerID])

  const onFilterSelect = option => setCurrentOption(option?.value || null)

  const filterCategories = [
    { id: CATEGORIES.ALL, label: "All", count: total },
    { id: CATEGORIES.ENABLED, label: "Enabled", count: enabledTotal },
    { id: CATEGORIES.DISABLED, label: "Disabled", count: disabledTotal },
  ]

  return (
    <>
      <Card className="col py-2 pl-6">
        <Toast
          show={showSaved.toast}
          setShow={() => setShowSaved({ ...showSaved, toast: false })}
          delay={2000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.content.listResources.saved"
              defaultMessage="{items} items enabled 👍"
              values={{ items: showSaved.count }}
            />
          </p>
        </Toast>
        <Toast
          show={showUnsaved.toast}
          setShow={() => setShowUnsaved({ ...showSaved, toast: false })}
          delay={2000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.content.listResources.saved"
              defaultMessage="{items} items disabled 👋"
              values={{ items: showUnsaved.count }}
            />
          </p>
        </Toast>
        <Toast
          show={showInserted}
          setShow={setShowInserted}
          delay={2000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.content.listResources.inserted"
              defaultMessage="A new content resource is added"
            />
          </p>
        </Toast>
        <Toast
          show={showUpdated}
          setShow={setShowUpdated}
          delay={2000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.content.listResources.updated"
              defaultMessage="A content resource is updated"
            />
          </p>
        </Toast>
        <Toast
          show={showDeleted}
          setShow={setShowDeleted}
          delay={2000}
        >
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="components.projects.content.listResources.deleted"
              defaultMessage="A content resource is deleted"
            />
          </p>
        </Toast>
        <Toast show={showToast} setShow={setShowToast}>
          <p className="text-white">
            <FormattedMessage
              id="components.projects.content.ListResources.toast"
              defaultMessage="Your selected content is now ready for translation. ✨"
            />
          </p>
        </Toast>
        {!isLoading && (
          <div className="d-flex align-items-center justify-content-between border-2 border-bottom pb-2 mb-2">
            <div className="d-flex align-items-center">
              <h2 className="text-gray-800 font-weight-bolder letter-spacing_0_03 mb-0 mr-3">
                <FormattedMessage
                  id="components.projects.content.ListResources.itemlabel"
                  defaultMessage="<s1>{included}</s1> <s2>/</s2> {resources} {resourceType}"
                  values={{
                    s1: msg => <span className="text-gray-600">{msg}</span>,
                    s2: msg => <span className="text-gray-500">{msg}</span>,
                    included: enabledTotal,
                    resources: total,
                    resourceType: getResourceTypeForConnector(
                      intl,
                      connector.type,
                      total
                    ),
                  }}
                />
              </h2>
              <FilterField
                className="text-gray-500 mr-2"
                options={namespaces}
                onChange={onFilterSelect}
                isClearable
                placeholder={intl.formatMessage({
                  id:
                    "components.projects.content.listResources.filte.placeholder",
                  defaultMessage: "Filter content",
                })}
              />
              <FilterCategories
                group="content-resource-filter"
                label="Status"
                categories={filterCategories}
                onChange={key => setCurrentCategory(key)}
                defaultActiveKey="all"
              />
            </div>
            <div className="d-flex align-items-center">
              <TranslationStatus project={project} />
            </div>
          </div>
        )}
        <Table className="resource-table pb-4">
          <tbody>
            {!(isLoading || searching) && (
              <ProjectContents
                currentCategory={currentCategory}
                currentOption={currentOption}
                project={project}
                resources={resources}
                total={total}
              />
            )}
            {(isLoading || searching) && (
              <tr className="d-flex flex-column justify-content-center align-items-center my-5 text-gray-700">
                <td className="d-flex flex-column align-items-center border-0">
                  <Spinner className="m-3" />
                  {isLoading && (
                    <h3 className="mt-3">
                      <FormattedMessage
                        id="components.projects.content.ListResources.fetchingResources"
                        defaultMessage="Fetching your items"
                      />
                    </h3>
                  )}
                </td>
              </tr>
            )}
            {!isLoading && hasMoreResources && !searching && (
              <tr className="d-flex justify-content-center">
                <td className="border-0">
                  <button
                    className="btn bg-gray-200 rounded-pill py-2 my-3"
                    onClick={() => fetchData({ loadMore: true })}
                  >
                    <h4 className="m-0">
                      {loadMoreLoading ? (
                        <>
                          <Spinner isSmall />
                          <FormattedMessage
                            id="components.projects.content.ListResources.loadingMessage"
                            defaultMessage="Loading…"
                          />
                        </>
                      ) : (
                        "Load more"
                      )}
                    </h4>
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  )
}

export default ListResources
