import { FormattedMessage } from "gatsby-plugin-intl"
import React, { useEffect } from "react"
import Spinner from "../../../components/common/Spinner"
import { OnboardingRoutes } from "../../../models/onboarding"
import { useOnboarding } from "../../Onboarding"

const CheckoutSuccess = ({ onboarding }) => {
  const { setCurrentStep, routeToNextStep } = useOnboarding()

  useEffect(() => {
    setCurrentStep(OnboardingRoutes.CHECKOUT_SUCCESS.stage)
  }, [])

  if (!onboarding.organization?.id && !onboarding.vendor?.id) {
    return <div className="d-flex"><Spinner className="mx-auto"/></div>
  }

  return (
    <>
      <h1>Thank you for your subscription <span role="img" aria-label="tada emoji">🎉</span></h1>
      <div className="d-flex flex-column align-items-center mt-3 text-gray-700">
        <span className="fe fe-check-circle text-green-400" style={{ fontSize: "200px"}} />
        <button className="btn btn--primary btn-primary mr-0"
          onClick={() => routeToNextStep()}
        >
          <FormattedMessage
            id="containers.onboarding.checkoutSuccess.nextBtn"
            defaultMessage="Next"
          />
        </button>
      </div>
    </>
  )
}

export default CheckoutSuccess
