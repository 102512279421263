import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Dropdown } from "react-bootstrap"
import {
  DropdownToggleEllipse,
  DropdownMenuEllipse,
} from "../../components/dropdown/DropdownEllipse"
import { Link } from "gatsby-plugin-intl"
import { byName } from "../../utils/sort"
import { INTEGRATION_TYPES } from "../../models/integrationType"
import { path } from "../../config/routes"
import { useStoreState } from "easy-peasy"

export const VISIBILITY = {
  PUBLIC: "public",
  PRIVATE: "public",
}

const IntegrationCard = ({ integrations, defaultIntegrationID, onSelect, disablePublicIntegrations = false }) => {
  return (
    <ul className="page-section__content cards-grid">
      {integrations.sort(byName).map(integration => (
        <li className="cards-grid-item p-3" key={integration.id}>
          <div className="w-100 d-flex align-items-start">
            <img
              src={integration.integrationType?.metadata?.logoSquare || integration.integrationType?.metadata?.logo}
              className="cards-grid-item__logo mr-auto"
              alt={integration.name}
            />
            {integration.id === defaultIntegrationID && (
              <span className="cards-grid-item__default-badge badge mt-1">
                Default
              </span>
            )}
            {
              (integration.visibility !== VISIBILITY.PUBLIC || integration.id !== defaultIntegrationID)
              && !(integration.visibility === VISIBILITY.PUBLIC && disablePublicIntegrations)
              && (
              <Dropdown>
                <Dropdown.Toggle as={DropdownToggleEllipse} />
                <Dropdown.Menu as={DropdownMenuEllipse} className="text-gray-600">
                  {integration.visibility !== VISIBILITY.PUBLIC && (
                    <Dropdown.Item
                      as={Link}
                      to={path("EDIT_INTEGRATION", { id: integration.id })}
                      className="text-gray-600"
                    >
                      <FormattedMessage
                        id="containers.admin.integrations.edit_configuration"
                        defaultMessage="Edit"
                      />
                    </Dropdown.Item>
                  )}
                  {integration.id !== defaultIntegrationID && integration.integrationType.type !== INTEGRATION_TYPES.bms && (
                    <Dropdown.Item
                      className="text-gray-600"
                      onClick={() => onSelect ? onSelect(integration) : undefined}
                    >
                      <FormattedMessage
                        id="containers.admin.integrations.checkbox.default"
                        defaultMessage="Set as default"
                      />
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div className="w-100 d-flex align-items-center justify-content-start mt-3">
            <h3 className="cards-grid-item__heading my-0">
              {integration.name}
            </h3>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default IntegrationCard
