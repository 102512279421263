import React from 'react'

const FilterCategories = ({ group, label, categories, defaultActiveKey, onChange }) => {
  const onCheckChange = e => onChange(e.target.value) 

  return (
    <div className="d-flex align-items-center py-2_5 px-3 border border-gray-200 rounded">
      {label && <span className="text-sm text-gray-500 mr-3">{label}</span>}
      {categories.map((category, i) => (
        <div
          key={category.id}
          className={`d-flex align-items-center ${
            i < categories.length - 1 ? "mr-3" : ""
          }`}
        >
          <input
            id={category.id}
            value={category.id}
            name={group}
            type="radio"
            className="radio--primary font-weight-bold mr-2"
            defaultChecked={defaultActiveKey === category.id}
            onChange={onCheckChange}
          />
          <label htmlFor={category.id} className="mb-0 mr-2">{category.label}</label> 
          {category?.count !== undefined && (
            <span className="rounded text-xs font-weight-bold py-0 px-2 bg-gray-200 text-gray-600">
              {category.count}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default FilterCategories
