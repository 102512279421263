import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import Toast from "../../components/Toast"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { path } from "../../config/routes"
import IntegrationForm from "../../components/integrations/IntegrationForm"

const IntegrationNew = ({ type }) => {
  const { item: currentVendor, integrations } = useStoreState(state => state.currentVendor)
  const { fetch: fetchIntegrationType, reset: resetIntegrationType } = useStoreActions(actions => actions.integrationTypes)

  const integrationType = useStoreState(state => state.integrationTypes.item)
  const createIntegration = useStoreActions(actions => actions.integrations.create)
  const { updateDefaultIntegration } = useStoreActions(actions => actions.currentVendor)
  const [showToast, setShowToast] = useState(false)
  const [integration, setIntegration] = useState({})

  useEffect (() => {
    if (!type) return
    fetchIntegrationType(type)
    return () => {
      resetIntegrationType()
    }
  }, [fetchIntegrationType, resetIntegrationType, type])

  useEffect(() => {
    setIntegration({
      groups: [currentVendor.id],
      integrationType,
      integrationTypeID: integrationType.id,
      name: integrationType.name,
      vendorID: currentVendor.id,
    })
  }, [currentVendor, integrationType, setIntegration])

  const onSubmit = async ({ integration }) => {
    integration = await createIntegration(integration)
    if (integrations.length === 0) updateDefaultIntegration({ id: currentVendor.id, integration })
    setShowToast(true)
    setTimeout(() => navigate(path("INTEGRATIONS")), 2000)
  }

  return (
    <>
      <IntegrationForm
        integrationTypeID={integrationType.id}
        isOnboarding={false}
        isCuisto={integration.isCuisto}
        integration={integration}
        onSubmit={onSubmit}
      />

      <Toast show={showToast} setShow={setShowToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.toast"
            defaultMessage="Successfully created an integration ✨"
          />
        </p>
      </Toast>
    </>
  )
}

export default IntegrationNew
