import store from "../store/index"

export default class ProjectResourcePatternsUpdater {
  constructor(projectResourcePattern) {
    this._projectResourcePattern = projectResourcePattern
  }

  static async call(args) {
    return new ProjectResourcePatternsUpdater(args).call()
  }

  async call() {
    try {
      await this.updateProjectResourcePatterns()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  updateProjectResourcePatterns() {
    return store
      .getActions()
      .projects
      .editResourcePatterns(this.projectResourcePattern())
  }

  projectResourcePattern() {
    return this._projectResourcePattern
  }
}
