import store from "../store/index"

export default class ProjectResourcePatternsValidator {
  constructor({ projectID, resourcePatterns }) {
    this._projectID = projectID
    this._resourcePatterns = resourcePatterns
  }

  static async call(args) {
    return new ProjectResourcePatternsValidator(args).call()
  }

  async call() {
    return this.newPaths().some(path => this.existingPaths().includes(path))
  }

  // PRIVATE METHODS
  existingPaths() {
    return this.existing().map(resourcePattern => resourcePattern.path)
  }

  newPaths() {
    return this.toBeAdded().map(resourcePattern => resourcePattern.path)
  }

  existing() {
    return store.getState().projects.byId(this.projectID()).resourcePatterns || []
  }

  toBeAdded() {
    return this._resourcePatterns
  }

  projectID() {
    return this._projectID
  }
}
