import store from "../store/index"

export default class VendorTeamUpdater {
  constructor(member) {
    this._member = member
  }

  static async call(args) {
    return new VendorTeamUpdater(args).call()
  }

  async call() {
    try {
      await this.addUser()
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }

  // PRIVATE METHODS
  addUser() {
    return store.getActions().vendorTeam.create(this.member)
  }

  get member() {
    return this._member
  }
}
