import React, { useState, useEffect } from "react"
import { useLocation, useMatch } from "@reach/router"
import PropTypes from "prop-types"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Toast from "../../components/Toast"
import "../../scss/theme.scss"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { path } from "../../config/routes"
import TopNavigation from "./TopNavigation"

const Layout = ({ children, ...props }) => {
  const toast = useLocation().state?.toast
  const pageKey = useLocation().state?.key
  const [show, setShow] = useState(false)
  const locale = useMatch("/:locale/*")?.locale || ""

  const {
    hasPaidSubscriptions,
    isPartner,
    item: currentOrg,
  } = useStoreState(state => state.currentOrg)
  const vendorDetails = useStoreState(state => state.vendor.item)
  const asClient = useStoreState(state => state.appMode.asClient)

  useEffect(() => {
    if (toast) {
      setShow(true)
    }
  }, [pageKey])

  return (
    <>
      {toast && (
        <Toast
          delay={toast.delay}
          show={show}
          setShow={setShow}
          type={toast.type}
        >
          <p className="font-normal ml-3 mb-0">{toast.message}</p>
        </Toast>
      )}
      <div className="d-flex flex-column">
        <TopNavigation
          asClient={asClient}
          currentOrg={currentOrg}
          hasPaidSubscriptions={hasPaidSubscriptions}
          isPartner={isPartner}
          locale={locale}
          vendorDetails={vendorDetails}
        />
        <div className="d-flex">
          <Sidebar asClient={asClient} {...props} />
          <div className="main-content d-flex flex-column">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
