import store from "../store/index"

export default class UserUpdater {
  constructor(user) {
    this._user = user
  }

  static async call(args) {
    return new UserUpdater(args).call()
  }

  async call() {
    try {
      await this.updateUser()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  updateUser() {
    return store.getActions().currentUser.update(this.user)
  }

  get user() {
    return this._user
  }
}
