import React, { useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import TeamDestroyer from "../../service/teamDestroyer"
import { Button, Alert } from "react-bootstrap"
import ModalForm from "../forms/ModalForm"
import { STATUSES } from "../forms/ModalForm"

const RemoveFromWorkspaceButton = (
  { organizationID, givenName, email, userId, onRemove }
) => {
  const intl = useIntl();
  const [status, setStatus] = useState()

  onRemove = onRemove || (() => {});

  const onSubmit = async () => {
    try {
      setStatus(STATUSES.loading)
      let res = await TeamDestroyer.call({
        organizationID, userId
      });
  
      if (res) {
        onRemove(userId);
      }

      setStatus(STATUSES.success)
    } catch (e) {
      console.error(e)
      setStatus(STATUSES.error)
    }
  }
  
  const Trigger  = ({ onClick }) => (
    <button className="p-1"
      className="bg-transparent font-bold text-sm text-danger border-0 px-0"
      onClick={onClick}
    >
      <FormattedMessage
        id="components.team.removeMemberLabel"
        defaultMessage="Remove from account"
      />
    </button>
  );

  return (
    <>
      <ModalForm
        fields={[]}
        onSubmit={onSubmit}
        setStatus={setStatus}
        status={status}
        submitProps={{
          variant: "danger",
          title: `${intl.formatMessage({
            id: "components.team.remove.modalTitle",
            defaultMessage: "Remove from account",
          })}`,
        }}
        Trigger={Trigger}
      >
        <Alert variant="danger">
          <FormattedMessage
            id="components.team.remove.modalForm.Alert"
            defaultMessage="Confirmation required"
            description="modal remove team member access confirmation alert"
          />
        </Alert>
        <h4>
          <FormattedMessage
            id="components.team.remove.modalForm.heading"
            defaultMessage="Are you sure you want to remove {member}?"
            description="modal remove team member access confirmation alert"
            values={{ member: givenName || email }}
          />
        </h4>
        <p>
          <FormattedMessage
            id="components.team.remove.modalForm.paragraph"
            defaultMessage="This will disable access for {member} to your account."
            description="modal remove locale confirmation paragraph"
            values={{ member: givenName || email }}
          />
        </p>
      </ModalForm>
    </>
  );
}

export default RemoveFromWorkspaceButton;
