import React, { useState } from "react"
import { Tab, Nav } from "react-bootstrap"
import { useForm, FormProvider } from "react-hook-form"
import Icon from "../Icon"
import { PlansTabPane } from "./PlansTabPane"
import { CardTabPane } from "./CardTabPane"
import { FREQUENCY } from "../../models/billing"
import { useStoreActions, useStoreState } from "easy-peasy"

const TABS = {
  PLANS: "plans",
  CARD: "card",
}

export const PlanSubscriptionWizardNav = () => (
  <Nav variant="tabs" className="d-flex text-center justify-content-center">
    <Nav.Item className="ml-1 flex-fill">
      <Nav.Link eventKey={TABS.PLANS}>
        <h2 className="m-0">
          <Icon name="grid" title="Plans" className="mr-2" />
        </h2>
      </Nav.Link>
    </Nav.Item>
    <Nav.Item className="ml-1 flex-fill">
      <Nav.Link eventKey={TABS.CARD}>
        <h2 className="m-0">
          <Icon name="credit-card" title="Credit Card" className="mr-2" />
        </h2>
      </Nav.Link>
    </Nav.Item>
  </Nav>
)

export const PlanSubscriptionWizard = ({
  familyId,
  showNav,
  onCancel,
  onSubscription,
  filterByVolume,
  hasUpgradeDowngradeOptions,
}) => {
  const [activeKey, setActiveKey] = useState(TABS.PLANS)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    addCreditCard,
    createCustomer,
    createSubscription,
    fetchSubscription,
  } = useStoreActions(actions => actions.billing)

  const form = useForm({
    defaultValues: {
      term: FREQUENCY.YEARLY,
    },
  })

  const { card } = form.watch(["card"])

  const onSubmit = async ({ card, cardToken, plan }) => {
    setIsSubmitting(true)
    let paymentSourceID
    if (!currentOrg.customerID)
      await createCustomer({ organizationId: currentOrg.id })
    if (cardToken) {
      paymentSourceID = (
        await addCreditCard({
          organizationId: currentOrg.id,
          tokenId: cardToken,
        })
      )?.id
    } else {
      paymentSourceID = card.payment_source.id
    }

    if (paymentSourceID && plan?.id)
      await createSubscription({
        organizationId: currentOrg.id,
        paymentSourceID,
        subscriptionItems: [{ item_price_id: plan.id }],
      })
    await fetchSubscription({ organizationId: currentOrg.id })
    onSubscription()
    setIsSubmitting(false)
  }

  const onTabSelect = key => {
    setActiveKey(key)
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Tab.Container activeKey={activeKey} onSelect={onTabSelect}>
          {showNav && PlanSubscriptionWizardNav()}
          <Tab.Content>
            <PlansTabPane
              back={ onCancel ? () => onCancel() : undefined }
              eventKey={TABS.PLANS}
              familyId={familyId}
              filterByVolume={filterByVolume}
              hasUpgradeDowngradeOptions={hasUpgradeDowngradeOptions}
              onPay={async () => {
                if (card) {
                  await form.handleSubmit(onSubmit)()
                  setActiveKey(TABS.PLANS)
                } else {
                  setActiveKey(TABS.CARD)
                }
              }}
              onAddNewCard={() => setActiveKey(TABS.CARD)}
              isSubmitting={isSubmitting}
            />
            <Tab.Pane eventKey={TABS.CARD}>
              <CardTabPane
                back={() => setActiveKey(TABS.PLANS)}
                onPay={async () => {
                  await form.handleSubmit(onSubmit)()
                  setActiveKey(TABS.PLANS)
                }}
                plans
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </form>
    </FormProvider>
  )
}
