import React, { useEffect, useState } from "react"
import { Tab, Nav, Button } from "react-bootstrap"
import Icon from "../../Icon"
import { QuoteTabPane } from "../quote/QuoteTabPane"
import { PlanSubscriptionWizard } from "../../billing/PlanSubscriptionWizard"
import { useStoreActions, useStoreState } from "easy-peasy"
import { SERVICE_TYPES } from "../../../models/service"
import CreditsUsage from "../../CreditsUsage"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { FormProvider, useForm } from "react-hook-form"
import { path } from "../../../config/routes"
import Spinner from "../../common/Spinner"
import ProjectTMSIntegration from "../ProjectTMSIntegration";
import Toast from "../../Toast"
import OrderSummary from "./OrderSummary"

const TABS = {
  QUOTE: "quote",
  BILLING: "billing",
  SUMMARY: "summary",
}

export const TMSNavLink = ({ eventKey }) => (
  <Nav.Item className="ml-1 flex-fill">
    <Nav.Link eventKey={eventKey}>
      <h2 className="m-0">
        <Icon name="edit" title="TMS" className="mr-2" />
      </h2>
    </Nav.Link>
  </Nav.Item>
)

export const TMSTabPane = ({ eventKey, setShowModal, setShowNav }) => {
  const [activeKey, setActiveKey] = useState(TABS.QUOTE)
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const { subscription, hasNoSubscriptions, isSubscriptionAI } = useStoreState(
    state => state.billing
  )
  const project = useStoreState(state => state.project.item)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const quote = useStoreState(state => state.quote)
  const { triggerInstance } = useStoreActions(actions => actions.project)
  const [showUpgrade, setShowUpgrade] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [showSuccessSubscription, setShowSuccessSubscription] = useState(false)
  const form = useForm()
  const [instanceWordCount, setInstanceWordCount] = useState(0)

  const { items: currentIntegrations } = useStoreState(state => state.integrations)
  const fetchAvailableIntegrations = useStoreActions(actions => actions.integrations.fetchAvailableIntegrations)

  useEffect(() => {
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg.id])

  useEffect(() => {
    if (!subscription.credits) return
    const { remaining } = subscription.credits
    setShowUpgrade(
      hasNoSubscriptions || isSubscriptionAI || remaining < quote.words
    )
  }, [subscription.credits, quote.words, isSubscriptionAI, hasNoSubscriptions])

  useEffect(() => {
    fetchAvailableIntegrations({ organizationID: currentOrg.id })
  }, [currentOrg.id])

  useEffect(() => {
    setIsSubmitDisabled( isSubmitting || quote.words === 0)
  }, [quote.words, isSubmitting])

  return (
    <Tab.Pane eventKey={eventKey}>
      <Tab.Container activeKey={activeKey}>
        <Tab.Content className="pb-15">
          <Toast show={showSuccessSubscription} setShow={setShowSuccessSubscription} delay={5000}>
            <p className="text-gray-50 font-normal mb-0">
              <FormattedMessage
                id="components.projects.serviceTypes.TMSTab.successSubscription"
                defaultMessage="Congratulations! You subscribed to {planName} 🎉"
                values={{ planName: subscription?.details?.planName }}
              />
            </p>
          </Toast>
          <FormProvider {...form}>
            <QuoteTabPane
              eventKey={TABS.QUOTE}
              onSubmit={async ({ items, integration, mode, template }) => {
                setIsSubmitting(true)
                setInstanceWordCount(quote.words);
                await triggerInstance({ mode: mode.value, integration, projectLocaleIDs: items, template })
                fetchSubscription({ organizationId: currentOrg.id })
                setActiveKey(TABS.SUMMARY)
                setIsSubmitting(false)
              }}
              form={form}
            >

              <div className="custom-modal-footer">
                <ProjectTMSIntegration {...form} project={project} />
                {!isSubscriptionAI && <CreditsUsage className="mt-0" />}
                  {showUpgrade ? (
                    <Button
                      onClick={() => {
                        setActiveKey(TABS.BILLING)
                      }}
                      className="mt-4 w-100"
                    >
                      <FormattedMessage
                        id="components.projects.serviceTypes.TMSTab.upgradeButton"
                        defaultMessage="Upgrade"
                      />
                    </Button>
                    ) : currentIntegrations.length ? (
                      <Button type="submit" className="mt-4 w-100" disabled={isSubmitDisabled}>
                        { isSubmitting ? <Spinner/> :
                          <FormattedMessage
                            id="components.projects.serviceTypes.TMSTab.translateButton"
                            defaultMessage="Translate"
                          />
                        }
                      </Button>
                    ) : (
                      <div className="container-fluid d-grid mt-4">
                      <Link to={`${path("SETTINGS")}${path("INTEGRATIONS")}`} activeClassName="active" className="btn btn-outline-secondary">
                        <FormattedMessage
                          id="components.projects.serviceTypes.TMSTab.setUpIntegration"
                          defaultMessage="Setup Your TMS"
                        />
                        <span className="fe fe-arrow-right ml-2"></span>
                        </Link>
                      </div>
                  )}
              </div>
            </QuoteTabPane>
          </FormProvider>
          <Tab.Pane eventKey={TABS.BILLING}>
            <PlanSubscriptionWizard
              familyId={SERVICE_TYPES.CONNECTOR_CLOUD}
              filterByVolume={true}
              onCancel={() => {
                setActiveKey(TABS.QUOTE)
              }}
              onSubscription={() => {
                setActiveKey(TABS.QUOTE)
                setShowSuccessSubscription(true)
              }}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.SUMMARY}>
            <OrderSummary serviceType={"TMS"} totalWords={instanceWordCount} onBack={() => { setActiveKey(TABS.QUOTE)}} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  )
}
