import React from "react";
import { INTEGRATION_STATUSES } from "../../models/integrationType";

export const Action = ({ integrationType, onClick, ActionOverride }) => {
  switch (integrationType.status) {
    case INTEGRATION_STATUSES.live:
      return (
        ActionOverride
          ? <ActionOverride integrationType={integrationType} onClick={onClick} />
          : <button
            onClick={() => onClick(integrationType)}
            key={integrationType.id}
            className="cards-grid-items__button cards-grid-items__button--new"
          >
            Configure
          </button>
      )
    case INTEGRATION_STATUSES.pending:
      return (
        <span
          key={integrationType.id}
          className="cards-grid-items__button cards-grid-items__button--upcoming"
        >
          Coming soon
        </span>
      )
    default:
      return null
  }
}

export const IntegrationType = ({ integrationType, onClick, action: ActionOverride }) => {
  return (
    <li className="cards-grid-item p-3">
      <div className="w-100 d-flex align-items-start justify-content-between">
        <img
          src={integrationType.logoSquare || integrationType.logo}
          className="cards-grid-item__logo"
          alt={integrationType.name}
        />
        <Action integrationType={integrationType} onClick={onClick} ActionOverride={ActionOverride} />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-start mt-3">
        <h3 className="cards-grid-item__heading my-0">
          {integrationType.name}
        </h3>
      </div>
    </li>
  )
}
