import React, { useEffect, useState } from "react"
import { Tab, Nav, Modal, Button } from "react-bootstrap"
import { AITabPane, AINavLink } from "./AITab"
import { TMSTabPane, TMSNavLink } from "./TMSTab"
import { ProTabPane, ProNavLink } from "./ProTab"
import Icon from "../../Icon"
import { FormattedMessage } from "gatsby-plugin-intl"
import { isResourceable } from "../../../helpers/project"
import { isContentable } from "../../../helpers/project"
import { useStoreActions, useStoreState } from "easy-peasy"
import { getEnabledTotal } from "../../../helpers/contentResource"
import { SERVICE_TYPES } from "../../../models/service"

const ServiceTypesTrigger = ({ handleShow }) => {
  const project = useStoreState(state => state.project.item)
  const { resources } = useStoreState(state => state.contentResources)

  return (
    <Button
      className="text-white py-1 px-3 mb-1 font-weight-bolder letter-spacing-0_03 rounded"
      onClick={() => handleShow()}
    >
      <div className="d-flex align-items-center">
        <Icon name="arrow-up" className="mb-1px text-lg" />
        <span className="mr-2">
          <FormattedMessage
            id="components.projects.serviceTypes.trigger.label"
            defaultMessage="Push Content"
          />
        </span>
        <span className="bg-green-600 rounded-pill px-3 py-0">
          {isContentable(project) && (
            <FormattedMessage
              id="components.projects.serviceTypes.trigger.contentable.detail"
              defaultMessage="{count, plural, one {{count} item} other {{count} items}}"
              values={{
                count: getEnabledTotal(resources, project?.resourcesExcluded),
              }}
            />
          )}
          {isResourceable(project) && (
            <FormattedMessage
              id="components.projects.serviceTypes.trigger.resourceable.detail"
              defaultMessage="{count, plural, one {{count} file} other {{count} files}}"
              values={{ count: project.resourcePatterns.length }}
            />
          )}
        </span>
      </div>
    </Button>
  )
}

const ServiceTypesModal = () => {
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const { setServiceType } = useStoreActions(actions => actions.quote)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { subscriptionServiceType } = useStoreState(state => state.billing)
  const [activeKey, setActiveKey] = useState()
  const [show, setShow] = useState(false)

  useEffect(() => {
    fetchSubscription({ organizationId: currentOrg.id })
  }, [fetchSubscription, currentOrg.id])

  useEffect(() => {
    setServiceType(activeKey)
  }, [setServiceType, activeKey])

  const onTabSelect = key => {
    setActiveKey(key)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setActiveKey(subscriptionServiceType || SERVICE_TYPES.PRO)
    setShow(true)
  }

  useEffect(() => {
    setActiveKey(subscriptionServiceType || SERVICE_TYPES.PRO)
  }, [subscriptionServiceType])

  return (
    <>
      <ServiceTypesTrigger handleShow={handleShow} />
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-end"
        dialogClassName="w-50 mt-3 mr-3"
        contentClassName="p-0"
        // scrollable
      >
        <div class="d-flex flex-column overflow-hidden vh-97">
          <Tab.Container
            activeKey={activeKey}
            onSelect={onTabSelect}
            transition={false}
          >
            <Nav
              variant="tabs"
              className="d-flex text-center justify-content-center w-100 pt-3"
            >
              <ProNavLink eventKey={SERVICE_TYPES.PRO} />
              <AINavLink eventKey={SERVICE_TYPES.LOCALE_AI} />
              <TMSNavLink eventKey={SERVICE_TYPES.CONNECTOR_CLOUD} />
            </Nav>
            <div role="main" class="flex-grow-1 scrollable-modal-body">
              <Tab.Content>
                <ProTabPane
                  eventKey={SERVICE_TYPES.PRO}
                  setShowModal={setShow}
                />
                <AITabPane
                  eventKey={SERVICE_TYPES.LOCALE_AI}
                  setShowModal={setShow}
                />
                <TMSTabPane
                  eventKey={SERVICE_TYPES.CONNECTOR_CLOUD}
                  setShowModal={setShow}
                />
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </Modal>
    </>
  )
}

export default ServiceTypesModal
