import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { useForm } from "react-hook-form";
import { useOnboarding } from "../../../containers/Onboarding";
import Icon from "../../Icon";
import TeamModal from "../../team/TeamModal";
import { Accordion, Toast } from "react-bootstrap";
import StepField from "../../forms/StepField";
import { API } from "aws-amplify";
import { STATUSES } from "../../layout/FormContainer";

const EVENT_KEYS = {
  NAME: "name",
  BASE_URL: "baseUrl",
  CREDENTIALS: "credentials",
};

const validateLogin = async ({ plunet_baseUrl, plunet_username, plunet_password }) => {
  const { status, response } = await API.post("nepal", "/plunet/login", {
    body: {
      plunet_baseUrl,
      plunet_username,
      plunet_password
    },
  });

  if (status !== "SUCCESS") {
    throw status;
  }

  return response;
}

const PlunetForm = ({ isOnboarding, onExit, onSubmit, integration }) => {
  const intl = useIntl()
  const [status, setStatus] = useState()
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showTeamInviteToast, setShowTeamInviteToast] = useState(false)
  const requiredMessage = intl.formatMessage({
    id: "src.components.catTool.Settings.required",
    defaultMessage: "This is required.",
  })

  const defaultValues = {
    "integration[groups]": integration.groups,
    "integration[id]": integration.id,
    "integration[integrationTypeID]": integration.integrationTypeID,
    "integration[name]": integration.name,
    "integration[organizationID]": integration.organizationID,
    "integration[plunet_baseUrl]": integration.plunet_baseUrl,
    "integration[plunet_username]": integration.plunet_username,
    "integration[plunet_password]": integration.plunet_password,
    "integration[vendorID]": integration.vendorID,
  }

  const {
    handleSubmit,
    errors,
    control,
    clearErrors,
    setError,
    reset,
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues,
  })

  const stepFields = [
    {
      eventKey: EVENT_KEYS.NAME,
      title: (
        <FormattedMessage
          id="src.components.plunet.settings.integrationName"
          defaultMessage={`Integration Name`}
        />
      ),
      fields: [
        {
          name: "integration[name]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.plunet.settings.fields.name",
            defaultMessage: "Integration Name",
          })}`,
        },
      ],
      images: [],
    },
    {
      eventKey: EVENT_KEYS.BASE_URL,
      title: (
        <FormattedMessage
          id="src.components.plunet.settings.baseUrl"
          defaultMessage={`Plunet URL`}
        />
      ),
      fields: [
        {
          name: "integration[plunet_baseUrl]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.plunet.settings.fields.baseUrl",
            defaultMessage: "Plunet URL",
          })}`,
        },
      ],
      images: [],
    },
    {
      eventKey: EVENT_KEYS.CREDENTIALS,
      title: (
        <FormattedMessage
          id="src.components.plunet.settings.helpertitle1"
          defaultMessage="{name} Credentials"
          values={{ name: integration.integrationType?.name }}
        />
      ),
      fields: [
        {
          name: "integration[groups]",
          type: "hidden",
        },
        {
          name: "integration[id]",
          type: "hidden",
        },
        {
          name: "integration[integrationTypeID]",
          type: "hidden",
        },
        {
          name: "integration[name]",
          type: "hidden",
        },
        {
          name: "integration[organizationID]",
          type: "hidden",
        },
        {
          name: "integration[vendorID]",
          type: "hidden",
        },
        {
          name: "integration[plunet_username]",
          type: "text",
          autocomplete: "off",
          label: `${intl.formatMessage({
            id: "components.catTool.settings.fields.plunet.apiKey",
            defaultMessage: "Username",
          })}`,
        },
        {
          name: "integration[plunet_password]",
          type: "password",
          autocomplete: "off",
          label: `${intl.formatMessage({
            id: "components.catTool.settings.fields.plunet.apiKey",
            defaultMessage: "Pasword",
          })}`,
        },
        {
          name: "integration[plunet_serverAuthenticationString]",
          type: "hidden",
        },
      ],
      images: [],
    }
  ]

  const { hasPreviousStep, routeToBackStep, routeToNextStep } = useOnboarding()
  const [activeKey, setActiveKey] = useState(EVENT_KEYS.NAME)

  const currentStepFieldIndex = stepFields.findIndex(
    step => step.eventKey === activeKey
  )


  const checkAndSubmitForm = async ({ integration }) => {
    try {
      clearErrors();
      integration.name = integration.name?.trim()
      integration.plunet_baseUrl = integration.plunet_baseUrl?.trim();
      integration.plunet_username = integration.plunet_username?.trim();
      integration.plunet_password = integration.plunet_password?.trim();
      integration.templateID = integration.template?.id
      const name = integration.name
      const baseUrl = integration.plunet_baseUrl
      const username = integration.plunet_username
      const password = integration.plunet_password

      if (activeKey === EVENT_KEYS.NAME && !name) {
        setError("integration[name]", {
          type: "manual",
          message: requiredMessage,
        })
        return
      }

      if (activeKey === EVENT_KEYS.BASE_URL && !baseUrl) {
        setError("integration[plunet_baseUrl]", {
          type: "manual",
          message: requiredMessage,
        })
        return
      }

      if (activeKey === EVENT_KEYS.CREDENTIALS) {
        if (!username) {
          setError("integration[plunet_username]", {
            type: "manual",
            message: requiredMessage,
          })
        }
        if (!password) {
          setError("integration[plunet_password]", {
            type: "manual",
            message: requiredMessage,
          })
        }

        if (!username || !password) {
          return
        }

        setStatus(STATUSES.loading)
        const response = await validateLogin(integration)

        setValue("integration[plunet_serverAuthenticationString]", response.serverAuthenticationString)
        integration.plunet_serverAuthenticationString = response.serverAuthenticationString;
      }

      if (currentStepFieldIndex < stepFields.length - 1) {
      	nextStep()
      } else {
        setStatus(STATUSES.loading)
        if (isOnboarding) routeToNextStep()
        const data = await onSubmit({ integration })
        if (data) {
          setStatus(STATUSES.success)
          !isOnboarding && setShowSuccessToast(true)
        } else setStatus(STATUSES.success)
      }

      return true
    } catch(e) {
      setError("integration[plunet_username]", {
        type: "manual",
        message: intl.formatMessage({
          id: "src.components.catTool.Settings.plunet.validationError",
          defaultMessage:
            "URL, Username or Password is wrong",
        }),
      })
      setStatus(STATUSES.success)
    }
  };


  const nextStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex !== -1 && stepIndex + 1 < stepFields.length)
      setActiveKey(stepFields[stepIndex + 1].eventKey)
  }

  const backStep = () => {
    const stepIndex = stepFields.findIndex(step => step.eventKey === activeKey)

    if (stepIndex === 0 && onExit) onExit()
    if (stepIndex === 0 && !onExit && isOnboarding && hasPreviousStep) routeToBackStep()
    else if (stepIndex !== -1 && stepIndex - 1 >= 0)
      setActiveKey(stepFields[stepIndex - 1].eventKey)
  }

  useEffect(() => {
    if (!integration) return
    reset(defaultValues)
  }, [reset, integration])

  return (
    <>
    <div className="d-flex align-items-center justify-content-between mb-5">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://helpdesk.plunet.com/plugins/servlet/desk/site/global/article/5243820/API%C2%A0user+configuration+for+Plunet+BusinessManager"
          className="text-gray-500"
        >
          <span className="text-decoration-underline">
            <FormattedMessage
              id="components.plunet.settings.documentation"
              defaultMessage="Go to API user configuration for Plunet BusinessManager documentation"
            />
          </span>
          <Icon name="external-link" className="ml-1" />
        </a>
        <TeamModal onSubmit={() => setShowTeamInviteToast(true)} />
      </div>
      <Toast show={showTeamInviteToast} setShow={setShowTeamInviteToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="src.components.catTool.inviteToast"
            defaultMessage="Successfully invited team member ✨"
          />
        </p>
      </Toast>
      <Toast show={showSuccessToast} setShow={setShowSuccessToast}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.plunet.settings.successToast"
            defaultMessage="Successfully updated your credentials ✨"
          />
        </p>
      </Toast>
      <div className="onboarding-form">
        <div className="form-group">
          <Accordion activeKey={activeKey}>
            {stepFields.map((step, i) => (
              <StepField
                index={i}
                {...step}
                control={control}
                errors={errors}
                onSubmit={handleSubmit(checkAndSubmitForm)}
                noBack={i === 0 && !isOnboarding}
                onBack={backStep}
                status={status}
              />
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default PlunetForm;
