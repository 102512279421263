import React, { useEffect, useState } from "react"
import { useStoreActions, useStoreState } from "easy-peasy";
import { FormattedMessage } from "gatsby-plugin-intl";
import { IntegrationTypeList } from "../../../components/integrations/IntegrationTypeList";
import Spinner from "../../../components/common/Spinner";
import OrgUpdater from "../../../service/orgUpdator";
import { useOnboarding } from "../../Onboarding";
import IntegrationForm from "../../../components/integrations/IntegrationForm";

const Integrations = ({ onboarding }) => {
  const { items: integrationTypes, publicTMS: publicIntegrationTypes } = useStoreState(state => state.integrationTypes)
  const fetchAllIntegrationTypes = useStoreActions(state => state.integrationTypes.fetchAll)
  const [isLoading, setIsloading] = useState(true)
  const [integration, setIntegration] = useState({})
  const [integrationType, setIntegrationType] = useState()
  const { hasPreviousStep, routeToBackStep } = useOnboarding()
  const {
    create: createIntegration,
    update: updateIntegration,
  } = useStoreActions(actions => actions.integrations)

  const onSubmit = async ({ integration }) => {
    const { id: integrationID } = integration.id
      ? await updateIntegration(integration)
      : await createIntegration(integration)
    if (onboarding.organization?.id && !onboarding.organization.integrationID) await OrgUpdater.call({ id: onboarding.organization.id, integrationID })
  }

  const onExit = () => setIntegrationType()

  useEffect(() =>  {
    if (!integrationTypes.length) {
      fetchAllIntegrationTypes().then(() => setIsloading(false))
    } else {
      setIsloading(false)
    }

    return () => {
      setIsloading(true)
    }
  }, [setIsloading, integrationTypes, fetchAllIntegrationTypes]);

  useEffect(() => {
    if (!integrationType) {
      return
    }

    const newIntegration = {
      groups: (onboarding.vendor || onboarding.organization).groups,
      integrationType,
      integrationTypeID: integrationType.id,
      name: integrationType.name,
      organizationID: onboarding.organization?.id,
      vendorID: onboarding.vendor?.id,
    }
    setIntegration(newIntegration)
  }, [integrationType, setIntegration, onboarding])

  if (isLoading) {
    return <div className="d-flex justify-content-center mt-5">
      <Spinner />
    </div>
  }

  return <>
    { !integrationType && !isLoading &&
      <>
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.stages.selectIntegration.h1"
            defaultMessage="Select Integration"
          />
        </h1>
        <IntegrationTypeList
          integrationsTypes={publicIntegrationTypes}
          onClick={(integrationType) => { setIntegrationType(integrationType) }}
        />
        {hasPreviousStep && (
          <button
            className="btn btn-outline--secondary mr-3"
            onClick={routeToBackStep}
          >
            <FormattedMessage
              id="components.catTool.settings.back"
              defaultMessage="Back"
            />
          </button>
        )}
      </>
    }

    { integrationType && !isLoading &&
      <>
        <h1 className="main-heading mb-5">
          <FormattedMessage
            id="containers.onboarding.stages.integrationSetup.h1"
            defaultMessage="Setup integration for {name}"
            values={{ name: integrationType.name || "" }}
          />
        </h1>
        <IntegrationForm
          onExit={onExit}
          isOnboarding={!!onboarding}
          integration={integration}
          onSubmit={onSubmit}
          integrationTypeID={integrationType.id}
        />
      </>
    }
  </>
}
export default Integrations;
