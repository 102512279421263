import React, { useEffect, useState } from "react"
import "codemirror/lib/codemirror.css"
import "@toast-ui/editor/dist/toastui-editor.css"
import { Editor } from "@toast-ui/react-editor"
import { Button } from "react-bootstrap"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import ProjectUpdater from "../../../service/projectUpdater"
import Spinner from "../../common/Spinner"
import { STATUSES } from "../../layout/FormContainer"

const TranslationBriefEditor = ({ project }) => {
  const editorRef = React.createRef()
  const intl = useIntl()
  const [status, setStatus] = useState()

  useEffect(() => {
    editorRef.current.getInstance().setMarkdown(project.translationBrief, false)
  }, [project])

  const saveTranslationBrief = async () => {
    const translationBrief = editorRef.current.getInstance().getMarkdown()
    const payload = {
      id: project.id,
      translationBrief,
    }
    setStatus(STATUSES.loading)
    if (await ProjectUpdater.call(payload)) {
      setStatus(STATUSES.success)
    } else setStatus(STATUSES.error)
  }
  const toolbarItems = [
    "heading",
    "bold",
    "italic",
    "strike",
    "divider",
    "ul",
    "ol",
    "divider",
    "quote",
    "link",
    "divider",
    "code",
    "codeblock",
    "divider",
  ]

  return (
    <>
      <Editor
        placeholder="Add a brief for your translator..."
        useCommandShortcut={true}
        ref={editorRef}
        initialEditType="wysiwyg"
        language={intl.locale}
        toolbarItems={toolbarItems}
      />
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={saveTranslationBrief} className="btn btn--primary">
          {status === STATUSES.loading ? (
            <Spinner />
          ) : (
            <FormattedMessage
              id="components.projects.translationBrief.editor.button"
              defaultMessage="Save"
            />
          )}
        </Button>
      </div>
    </>
  )
}

export default TranslationBriefEditor
