import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import OpenPortal from "./OpenPortal"

const UpdateBillingButton = () => {
  const [renderChargebee, setRenderChargebee] = useState(false)
  return renderChargebee ? (
    <OpenPortal
      setRenderChargebee={setRenderChargebee}
      openSectionOn="EDIT_BILLING_ADDRESS"
    />
  ) : (
    <div>
      <p className="d-flex align-items-center mt-3 text-gray-700">
        <FormattedMessage
          id="components.billing.updateBillingButton.description"
          defaultMessage="Need to update your billing information?"
        />
        <Button
          onClick={() => setRenderChargebee(true)}
          className="d-inline ml-1 p-0 text-green-500 border-0 bg-gray-0 btn btn-primary"
        >
          <FormattedMessage
            id="components.billing.updateBillingButton"
            defaultMessage="Update your billing info"
          />
        </Button>
      </p>
    </div>
  )
}

export default UpdateBillingButton
