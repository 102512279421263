import React, { useState } from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { ORG_TYPES } from "../../models/organization"
import { Nav, Tab } from "react-bootstrap"
import { Link } from "gatsby-plugin-intl"

import { path } from "../../config/routes"

const Settings = ({ location, children }) => {
  const intl = useIntl()
  const { isPartner, workspaceType, isDedicatedMode, isLanguageTeam } = useStoreState(state => state.currentOrg)

  return (
    <div>
      <h1 className="d-flex align-items-center main-heading mt-1">
        {intl.formatMessage({
          id: "components.organization.settings.modal.title",
          defaultMessage: "Settings",
        })}
      </h1>
      <Tab.Container>
        <div className="mt-4">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link as={Link} to={path("SETTINGS") + path("GENERAL")} activeClassName="border-green-500" className="p-2 border-bottom-2">
                <FormattedMessage
                  id="containers.settings.tab.nav.general"
                  defaultMessage="General"
                />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link as={Link} to={path("SETTINGS") + path("TEAM")} activeClassName="border-green-500" className="p-2 border-bottom-2">
                <FormattedMessage
                  id="containers.settings.tab.nav.team"
                  defaultMessage="Team"
                />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link as={Link} to={path("SETTINGS") + path("CONNECTORS")} activeClassName="border-green-500" className="p-2 border-bottom-2">
                <FormattedMessage
                  id="containers.settings.tab.nav.connectors"
                  defaultMessage="Connectors"
                />
              </Nav.Link>
            </Nav.Item>

            {(isDedicatedMode || isLanguageTeam) && (
              <Nav.Item>
                <Nav.Link as={Link} to={path("SETTINGS") + path("INTEGRATIONS")} activeClassName="border-green-500" className="p-2 border-bottom-2">
                  <FormattedMessage
                    id="containers.settings.tab.nav.languageTools"
                    defaultMessage="Language Tools"
                  />
                </Nav.Link>
              </Nav.Item>
            )}

            {(!isPartner || workspaceType === ORG_TYPES.services) && (
              <Nav.Item>
                <Nav.Link as={Link} to={path("SETTINGS") + path("PLANS")} activeClassName="border-green-500" className="p-2 border-bottom-2">
                  <FormattedMessage
                    id="containers.settings.tab.nav.billing"
                    defaultMessage="Billing"
                  />
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </div>
        <Tab.Content>
          {children}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default Settings
