import React, { useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { Button, Alert } from "react-bootstrap";
import Icon from "../../Icon";
import ModalForm from "../../forms/ModalForm";
import { STATUSES } from "../../layout/FormContainer";
import ProjectResourcePatternsDestroyer from "../../../service/projectResourcePatternsDestroyer";
import { triggerQuote } from "../../../helpers/instances";

const Remove = ({ project, index }) => {
  const intl = useIntl();
  const [status, setStatus] = useState();

  const onSubmit = async () => {
    const payload = {
      projectID: project.id,
      index,
    };
    setStatus(STATUSES.loading);
    if (await ProjectResourcePatternsDestroyer.call(payload)) {
      setStatus(STATUSES.success);
      triggerQuote({ projectID: project.id });
    } else {
      setStatus(STATUSES.error);
    }
  }

  const Trigger = ({ onClick }) => (
    <Button className="dropdown-item" variant="text" onClick={onClick}>
      <Icon
        name="trash"
        title={intl.formatMessage({
          id: "components.projects.resourcePattern.remove.trigger.button",
          defaultMessage: "Remove",
        })}
      />
    </Button>
  );

  return (
    <ModalForm
      fields={[]}
      onSubmit={onSubmit}
      status={status}
      setStatus={setStatus}
      submitProps={{
        variant: "danger",
        title: `${intl.formatMessage({
          id: "components.projects.resourcePattern.remove.modalForm.submitProps.Title",
          defaultMessage: "Remove from project",
        })}`,
      }}
      Trigger={Trigger}
    >
      <Alert variant="danger">
        <FormattedMessage
          id="components.projects.resource.remove.confirmationAlert"
          defaultMessage="Confirmation Required"
          description="modal remove resource from project confirmation alert"
        />
      </Alert>
      <h4>
        <FormattedMessage
          id="components.projects.resource.remove.header"
          defaultMessage="Are you sure you want to remove these files from your project?"
          description="modal remove resource from project confirmation header"
        />
      </h4>
      <p>
        <FormattedMessage
          id="components.projects.resource.remove.paragraph"
          defaultMessage="This will remove the files from your config with Locale, your repository will not be affected."
          description="modal remove resource from project confirmation paragraph"
        />
      </p>
    </ModalForm>
  );
};

export default Remove;
