import React, { useState, useEffect } from "react"
import { Alert, Modal, Button } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import Form from "./Form"
import Spinner from "../common/Spinner"
import Icon from "../Icon"

export const STATUSES = {
  success: "success",
  loading: "loading",
  error: "error",
}

const ModalForm = ({
  children,
  clearErrors = () => { },
  control,
  errors,
  fields,
  onSubmit,
  submitProps,
  status,
  setStatus,
  title,
  Trigger,
}) => {
  const [show, setShow] = useState(false)
  const toggleModal = () => {
    if (!show) {
      setStatus(null)
      clearErrors()
    }
    setShow(!show)
  }

  useEffect(() => {
    if (status === STATUSES.success && show === true) {
      toggleModal()
    }
  })

  return (
    <>
      <Trigger onClick={toggleModal} />
      <Modal show={show} onHide={toggleModal}>
        <button className="btn-close" onClick={toggleModal}>
          <Icon name="x"/>
        </button>        
        {title && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="py-3 pr-4">
          {status === STATUSES.error && (
            <Alert variant="danger">
              <FormattedMessage
                id="components.forms.modalForm.modalBody.alert"
                defaultMessage="Whoops! The server returned an error"
                description="modal error alert"
              />
            </Alert>
          )}
          {children || <Form
            control={control}
            errors={errors}
            fields={fields}
            onSubmit={onSubmit}
          />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="text" onClick={toggleModal}>
            <FormattedMessage
              id="components.forms.modalForm.modalFooter.cancelButton"
              defaultMessage="Cancel"
              description="modal cancel button"
            />
          </Button>
          <Button
            type="submit"
            className="btn btn--primary"
            disabled={errors?.length > 0 || status === STATUSES.loading}
            onClick={onSubmit}
            {...submitProps}
          >
            {status === STATUSES.loading ? (
              <Spinner />
            ) : (
              submitProps?.title || (
                <FormattedMessage
                  id="components.forms.modalForm.modalFooter.saveButton"
                  defaultMessage="Save"
                  description="modal save button"
                />
              )
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalForm
