import React from "react"
import Checkout from "../components/billing/Checkout"
import { path } from "../config/routes"

const PlanCheckout = () => {
  const redirectTo = `/en${path("SETTINGS")}${path("PLANS")}`

  return (
    <Checkout redirectTo={redirectTo} />
  )
}

export default PlanCheckout
