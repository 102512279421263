import React from "react"
import PropTypes from "prop-types"
import { Container, Nav, Navbar } from "react-bootstrap"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import NavLink from "../nav/NavLink"
import { path } from "../../config/routes"
import { projectLogoURL } from "../../helpers/assets"
import OrganizationSwitcher from "../organization/Switcher"
import { TextPlaceholder, SquarePlaceholder } from "../common/Placeholder"
import Brand from "../common/Brand"

const ProjectLink = ({ project }) => {
  const connector = useStoreState(state =>
    state.connectors.getConnector(project?.connector?.type)
  )
  if (connector?.logo) {
    project.connector.logoUrl = connector.logo
  }
  const img = projectLogoURL(project)

  return (
    <NavLink to={path("PROJECT", { id: project.id })} img={img}>
      <span className="truncate">{project.name}</span>
    </NavLink>
  )
}

const WorkspacePlaceholder = () => (
  <div className="mt-2 mb-3 d-flex align-items-center">
    <TextPlaceholder
      width="75%"
      height="1.25rem"
      shadeLevel={2}
      darkBackground
    />
  </div>
)

const ProjectPlaceholder = ({ delay }) => (
  <div className="mt-3 mb-3 d-flex align-items-center">
    <SquarePlaceholder
      size="1rem"
      className="mr-3"
      delay={delay}
      darkBackground
    />
    <TextPlaceholder
      height="1.25rem"
      width="60%"
      delay={delay}
      shadeLevel={2}
      darkBackground
    />
  </div>
)

const Sidebar = ({ isLoading, asClient }) => {
  const projects = useStoreState(store => store.projects.items)
  const { item: org } = useStoreState(store => store.currentOrg)
  const { item: onboarding, isLoading: onboardingLoading } = useStoreState(
    store => store.onboarding
  )
  const { isPartner } = useStoreState(
    state => state.currentOrg
  )
  const onboardingComplete = !!onboarding?.isCompleted
  const header = () => {
    if (!org?.name || !onboardingComplete) return

    if (isLoading) return <WorkspacePlaceholder />
    return <OrganizationSwitcher newWorkspace={asClient} />
  }

  const disableSidebar = !onboardingComplete || onboardingLoading

  return (
    <Navbar
      expand="md"
      fixed="left"
      className="navbar-vertical navbar--no-border bg-gray-800-to-900"
    >
      {!disableSidebar && (
        <>
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          <Container fluid>
            <Navbar.Collapse
              id="main-navbar-nav"
              className={disableSidebar ? "navbar--disabled" : ""}
            >
              {header()}
              <div className="navbar-nav">
                <NavLink
                  className="py-0 mt-4 mb-2"
                  disabled={disableSidebar}
                  to={path("DASHBOARD")}
                  icon="home"
                >
                  <FormattedMessage
                    id="components.layout.sidebar.navLink.dashboard"
                    defaultMessage="Dashboard"
                  />
                </NavLink>
              </div>

              <div className="navbar-nav">
                <NavLink
                  icon="plus"
                  to={path("NEW_PROJECT")}
                  className="py-0 mt-4 mb-2"
                  disabled={disableSidebar}
                >
                  <FormattedMessage
                    id="components.layout.sidebar.navLink.newProject"
                    defaultMessage="New Project"
                  />
                </NavLink>
              </div>
              {(isLoading || projects.length) && (
                <h6 class="py-0 navbar-heading mt-4">
                  <FormattedMessage
                    id="components.layout.sidebar.navItem.projects"
                    defaultMessage="Projects"
                  />
                </h6>
              )}
              <Nav className="ml-2">
                {!isLoading && (
                  <>
                    {projects.map(project => (
                      <ProjectLink project={project} key={project.id} />
                    ))}
                  </>
                )}
              </Nav>

              {onboardingComplete && isLoading && (
                <>
                  <ProjectPlaceholder delay={1} />
                  <ProjectPlaceholder delay={2} />
                </>
              )}
              <hr className="navbar-divider mt-auto mb-3"></hr>

              <div className="navbar-nav">
                <NavLink
                  to={`${path("SETTINGS")}${path("GENERAL")}`}
                  className="py-0 my-2"
                  disabled={disableSidebar}
                  icon="settings"
                >
                  <FormattedMessage
                    id="components.organization.settings.button"
                    defaultMessage="Settings"
                  />
                </NavLink>
              </div>
            </Navbar.Collapse>
            {isPartner && <Brand showPoweredBy dark className="d-flex align-items-center mt-5" classNameImg="navbar__powered-logo mt-n1" />}
          </Container>
        </>
      )}
    </Navbar>
  )
}

Sidebar.propTypes = {
  isLoading: PropTypes.bool,
}

Sidebar.defaultProps = {
  isLoading: false,
}

export default Sidebar
