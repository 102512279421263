import React, { useEffect } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Router, useLocation } from "@reach/router"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import Profile from "./onboarding/stages/Profile"
import Workspace from "./onboarding/stages/Workspace"
import Team from "./onboarding/stages/Team"
import NewProject from "./projects/NewProject"
import OnboardingClass, {
  OnboardingRoutes,
  TYPES,
  DEFAULT_PATH,
  ADD_WORKSPACE_PATH,
} from "../models/onboarding"
import { parse, stringify } from "query-string"
import ROUTES, { path_params_substitution, redirect } from "../config/routes"
import parseBasePath from "../helpers/path"
import Spinner from "../components/common/Spinner"
import { refreshToken } from "../helpers/authentication"
import { ORG_TYPES } from "../models/organization"
import { ProgressBar } from "react-bootstrap"
import IntegrationNew from "./integrations/new"
import Integrations from "./onboarding/stages/Integrations"
import Plans from "./onboarding/stages/Plans";
import CheckoutPlan from "./onboarding/stages/CheckoutPlan";
import CheckoutSuccess from "./onboarding/stages/CheckoutSuccess"

export const stagePath = (stageRoute, type) => {
  type = type !== TYPES.newUser ? type : undefined;
  const query = stringify({ ...parse(location.search), type })
  return `${ONBOARDING_ROOT}${stageRoute}${query ? `?${query}` : ""}`
}

export const useOnboarding = () => {
  const { item: onboarding, isLoading: onboardingLoading } = useStoreState(
    state => state.onboarding
  )
  const {
    setAttrs: setOnboarding,
    setLoading: setOnboardingLoading,
  } = useStoreActions(actions => actions.onboarding)

  const hasPreviousStep = onboarding?.currentStepNumber > 1

  const setCurrentStep = (step) => {
   const newOnboarding = onboarding.setStage(step)
   setOnboarding(newOnboarding)
  }

  const routeToNextStep = (params = {}) => {
    const newOnboarding = onboarding.moveToNextStage()
    setOnboarding(newOnboarding)
    const route = newOnboarding.getPathOfStep()
    navigate(
      path_params_substitution(route.absolute ? route.path : stagePath(route.path, newOnboarding.type), params)
    )
  }

  const routeToBackStep = () => {
    const newOnboarding = onboarding.moveToBackStage()
    setOnboarding(newOnboarding)

    const route = newOnboarding.getPathOfStep()
    navigate(
      route.absolute ? route.path : stagePath(route.path, newOnboarding.type)
    )
  }

  const initialize = attrs => {
    const onboardingInst = new OnboardingClass(attrs)

    setOnboarding(onboardingInst.setStage(onboardingInst.stage, false))
    navigate(
      stagePath(onboardingInst.getPathOfStep()?.path, onboardingInst.type)
    )
  }

  return {
    initialize,
    routeToBackStep,
    routeToNextStep,
    hasPreviousStep,
    setCurrentStep,
    setOnboarding,
    onboarding,
    onboardingLoading,
    setOnboardingLoading
  }
}

export const ONBOARDING_ROOT = `${ROUTES.SIGN_UP.path}`

const Onboarding = () => {
  const location = useLocation()
  const {
    initialize,
    onboarding,
    onboardingLoading,
    setOnboardingLoading,
  } = useOnboarding()
  const newOrg = useStoreActions(actions => actions.organizations.create)
  const { item: currentOrg, workspaceType } = useStoreState(state => state.currentOrg)
  const currentVendor = useStoreState(state => state.currentVendor.item)
  const fetchIntegrationTypes = useStoreActions(actions => actions.integrationTypes.fetchAll)
  const type = parse(location.search)?.type || ""
  const isRoot = !!location.pathname.match(new RegExp(`${ONBOARDING_ROOT}(\\?type=\w+)?$`))

  useEffect(() => {
    if (!onboarding) return
    if (isRoot && !type) {
      if (onboarding.isCompleted) {
        redirect("ROOT")
        return
      }
      initialize({ type: TYPES.newUser, organization: currentOrg })
    }
  }, [currentOrg, initialize, isRoot, onboarding, type])

  useEffect(() => {
    if (isRoot && TYPES.addWorkspace === type) {
      setOnboardingLoading(true)
      ;(async () => {
        initialize({ type: TYPES.addWorkspace })
        const org = await newOrg({
          onboarding: {
            isCompleted: false,
            stage: ADD_WORKSPACE_PATH[0],
            type: TYPES.addWorkspace,
          },
          type: workspaceType || ORG_TYPES.locale,
          vendorID: workspaceType,
        })

        setOnboardingLoading(false)
        refreshToken()

        initialize({ type: TYPES.addWorkspace, organization: org })
      })()
    }
  }, [isRoot, newOrg, type, workspaceType])

  useEffect(() => {
    fetchIntegrationTypes();
  }, [fetchIntegrationTypes])

  const vendorID = currentVendor?.id || null
  const defaultIntegration = currentVendor?.integrations?.items.find(integration => integration.default)

  useEffect(() => {
    if (!vendorID) return
    if (isRoot && TYPES.addClient === type) {
      setOnboardingLoading(true)
      ;(async () => {
        initialize({ type: TYPES.addClient, integrationMode: currentVendor.integrationMode })
        const org = await newOrg({
          onboarding: {
            isCompleted: false,
            stage: DEFAULT_PATH[0],
            type: TYPES.addClient,
          },
          integrationID: defaultIntegration?.id || null,
          type: vendorID,
          vendorID: vendorID,
        })

        setOnboardingLoading(false)
        refreshToken()
        initialize({ type: TYPES.addClient, organization: org, integrationMode: currentVendor.integrationMode })
      })()
    }
  }, [isRoot, newOrg, type, vendorID, defaultIntegration])

  const progress =
    (onboarding?.getStepFromLocation(
      parseBasePath(ROUTES.SIGN_UP.path, location.pathname)
    ) /
      onboarding?.length) *
    100

  return onboardingLoading ? (
    <div className="d-flex flex-column justify-content-center align-items-center p-5">
      <Spinner className="mb-4" />
      <FormattedMessage id="containers.onboarding.createAccount" defaultMessage="Creating your new account" />
    </div>
  ) : onboarding ? (
    <div className="p-2">
      <ProgressBar className="progress-bar--custom mb-3" now={progress} />
      <Router location={location}>
        <IntegrationNew path={OnboardingRoutes.INTEGRATION.path} onboarding={onboarding}/>
        <Integrations path={OnboardingRoutes.INTEGRATIONS.path} onboarding={onboarding}/>
        <Profile path={OnboardingRoutes.PROFILE.path} onboarding={onboarding} />
        <Plans path={OnboardingRoutes.PLANS.path} onboarding={onboarding} frequency="year" familyId="Connector-Portal" />
        <CheckoutPlan path={OnboardingRoutes.CHECKOUT.path} onboarding={onboarding} />
        <CheckoutSuccess onboarding={onboarding} path={OnboardingRoutes.CHECKOUT_SUCCESS.path} />
        <Workspace
          path={OnboardingRoutes.WORKSPACE.path}
          onboarding={onboarding}
        />
        <Team path={OnboardingRoutes.TEAM.path} onboarding={onboarding} />
        <NewProject
          path={OnboardingRoutes.NEW_PROJECT.path}
          onboarding={onboarding}
        />
      </Router>
    </div>
  ) : null
}

export default Onboarding
