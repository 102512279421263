import React from "react"
import { ProgressBar } from "react-bootstrap"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { DateTime } from "luxon"

const CreditsUsage = () => {
  const { subscription, hasNoSubscriptions } = useStoreState(
    state => state.billing
  )

  if (hasNoSubscriptions) return null

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5>{subscription?.details?.planName}</h5>
          </div>
          <div className="col text-xs text-right">
            <FormattedMessage
              id="components.CreditsUsage.renewDate"
              defaultMessage="Renews {date}"
              values={{ date: DateTime.fromISO(subscription?.details?.renewAt).toLocaleString(DateTime.DATE_MED) }}
            />
          </div>
        </div>
        <ProgressBar
          className="progress-bar--success mb-3 mt-1 progress-sm"
          now={
            (subscription?.credits?.usage / subscription?.credits?.limit) * 100
          }
        />
        <div className="row">
          <div className="col text-xs">
            <FormattedMessage
              id="components.CreditsUsage.usage"
              defaultMessage="{words, number, ::compact-short} words"
              values={{ words: subscription?.credits?.usage }}
            />
          </div>
          <div className="col text-xs text-right">
            <FormattedMessage
              id="components.CreditsUsage.limit"
              defaultMessage="{words, number, ::compact-short} words"
              values={{ words: subscription?.credits?.limit }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditsUsage
