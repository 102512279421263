import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Dropdown, Button } from "react-bootstrap"
import Icon from "../Icon"

const DropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={e => {
      onClick(e)
    }}
    size="sm"
    className="btn-white border-0"
  >
    {children}
  </Button>
))

const Actions = ({ actions }) => {
  const items = () =>
    actions.map((action, index) =>
      <Dropdown.Item key={index} as={() => action} />
    )
  return (
    <Dropdown>
      <Dropdown.Toggle as={DropdownToggle}>
        <Icon name="more-horizontal" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items()}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Actions
