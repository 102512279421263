import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useController, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FREQUENCY } from "../../models/billing"
import { useStoreActions, useStoreState } from "easy-peasy"
import { mergeRichTextContent  } from "../../helpers/contentful"

export const PlanSelect = ({ familyId, plans, control, setValue, defaultPlanId }) => {
  const intl = useIntl()
  plans = plans || []
  const [selectedPlanIdx, setSelectedPlanIdx] = useState(0)
  const fetchAllProductFamilies = useStoreActions(
    actions => actions.billing.fetchAllProductFamilies
  )
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { productFamilies } = useStoreState(state => state.billing)

  useEffect(() => {
    if (!currentOrg.id || Object.keys(productFamilies).length) return
    fetchAllProductFamilies({ organizationId: currentOrg.id })
  }, [currentOrg, fetchAllProductFamilies])

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "plan",
    control,
  })
  const plan = useWatch({ name: "plan", control })

  useEffect(() => {
    if (plans.length === 0) return
    setValue("plan", plans[selectedPlanIdx])
  }, [plans, setValue, selectedPlanIdx])

  useEffect(() => {
    if (!plan) return
    let index = plans.findIndex(({ id, item_id }) =>
      !defaultPlanId ? id === plan.id : item_id === defaultPlanId
    )
    if (index === -1) index = 0
    setSelectedPlanIdx(index)
  }, [plan, defaultPlanId, familyId])

  const formatPrice = (planItem) => {
    return intl
      .formatNumber(Math.round(planItem.price / 100 / (planItem.period_unit === FREQUENCY.YEARLY ? 12 : 1)), {
        style: "currency",
        currency: planItem.currency_code,
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits: 0,
      })
  }

  return (
    <div className="card">
      <div className="card-body">
        {/* PLan details */}
        <div className="row mb-6">
          <div className="col">
            <h1 className="mb-0">{productFamilies?.[familyId]?.name}</h1>
            <p>{productFamilies?.[familyId]?.description}</p>
          </div>
        </div>

        {/* Plan price */}
        <div className="row">
          <div className="col">
            <h2 className="mb-0">{plan && formatPrice(plan)}</h2>
            <p className="text-sm">
              {plan && plan.period_unit === FREQUENCY.MONTHLY ? (
                <FormattedMessage
                  id="components.plans.plansSelect.perMonthBilledMonthly"
                  defaultMessage="/month, billed monthly"
                />
              ) : (
                <FormattedMessage
                  id="components.plans.plansSelect.perMonthBilledAnnually"
                  defaultMessage="/month, billed annually"
                />
              )}
            </p>
          </div>
        </div>

        {/* Plan select */}
        <div className="row mb-6">
          <div className="col">
            <div className="input-group">
              <Select
                {...inputProps}
                inputRef={ref}
                className="form-control form-control-flush"
                options={plans}
                getOptionLabel={option => `${option.name} - ${formatPrice(option)}`}
                getOptionValue={option => option.id}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#00D792",
                  },
                })}
              />
            </div>
          </div>
        </div>

        {/* Plan features */}
        <div class="row">
          <div class="col plan-features">
            <h4>Plan features:</h4>
            {plan &&
              documentToReactComponents(
                mergeRichTextContent(
                  plan.details,
                  productFamilies?.[familyId]?.features
                )
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
