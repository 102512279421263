import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import ConnectorList from "../components/connectors/ConnectorList"

const ConnectorsHeading = ({ isTabItem }) => {
  return !isTabItem
    ? (
      <>
        <h1 className="d-flex align-items-center mt-1 main-heading">
          <span className="text-gray-800">
            <FormattedMessage
              id="containers.settings.connectors.titleAffix"
              defaultMessage="Connectors"
            />
          </span>
        </h1>
        <p className="w-50 text-xl text-gray-700 font-normal">
          <FormattedMessage
            id="containers.settings.connectors.subTitle"
            defaultMessage="You can connect Locale with many services, creating a seamless translation experience."
          />
        </p>
      </>
    )
    : null
}

const Connectors = ({ isTabItem = false }) => {
  return (
    <div className="container-fluid">
      <ConnectorsHeading isTabItem={isTabItem}/>
      <ConnectorList />
    </div>
  )
}

export default Connectors
