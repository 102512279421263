import React from "react";
import { ListGroup } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";
import RemoveFromWorkspaceButton from "./RemoveFromWorkspaceButton";
import { useStoreState } from "easy-peasy";
import { TextPlaceholder } from "../common/Placeholder";

const TeamMember = ({ name, email, isWorkspaceOwner, children }) => (
  <ListGroup.Item>
    <div className="row align-items-center">
      <div className="col">
        <div className="d-flex flex-column">
          <div>
            {name && <span className="font-bold mr-3 py-0 pb-0">{name}</span>}
            {isWorkspaceOwner && (
              <span className="font-bold text-sm text-muted">
                <FormattedMessage
                  id="components.team.teamMembers.accountOwner"
                  defaultMessage="account owner"
                />
              </span>
            )}
          </div>
          {email && <span className="text-sm">{email}</span>}
        </div>
      </div>
      <div className="col-auto">
        {children}
      </div>
    </div>
  </ListGroup.Item>
)

const TeamPlaceholder = ({ delay }) => (
  <ListGroup.Item className="py-3 bg-gray-50 bg-gray-0 border-t border-gray-200">
    <TextPlaceholder
      delay={delay}
      width="25%"
      height="0.9rem"
      className="mb-2"
      shadeLevel={2}
    />
    <TextPlaceholder
      delay={delay}
      width="35%"
      height="0.7rem"
    />
  </ListGroup.Item>
);

const TeamMembers = ({ members, onRemove, isLoading }) => {
  const currentOrg = useStoreState(state => state.currentOrg.item);

  return (
    <>
      <h3>
        <FormattedMessage
          id="components.settings.team.list.title"
          defaultMessage="Members"
        />
      </h3>
      <ListGroup>
        {isLoading && (
          <>
            <TeamPlaceholder />
            <TeamPlaceholder delay={1} />
            <TeamPlaceholder delay={2} />
          </>
        )}
        {members && members.map((member) => (
          <TeamMember
            key={member.email}
            name={member.given_name}
            email={member.email}
            isWorkspaceOwner={currentOrg && currentOrg.owner === member.sub}
          >
            {currentOrg.owner !== member.sub ? (
              <RemoveFromWorkspaceButton
                onRemove={onRemove}
                userId={member.sub}
                givenName={member.given_name}
                email={member.email}
                organizationID={currentOrg.id}
              />
            ) : null}
          </TeamMember>
        ))}
      </ListGroup>
    </>
  );
};

TeamMembers.defaultProps = {
  isLoading: false,
};

export default TeamMembers;
